import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CatalogoMotivorechazoService } from '../../../services/catalogo-motivorechazo.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { InventaryProductService } from '../../../services/inventary-product.service';

@Component({
  selector: 'app-modal-reason-rejection',
  inputs: [
    'titleModal',
    'labelCode',
    'placeholderCode',
    'labelDesc',
    'placeholderDesc',
    'typeModal',
    'nameModal',
    'information1',
    'information2',
    'information3',
    'information4',
    'campoAct',
    'campoCliente',
    'campoOficina',
    'campoVendedor',
    'maxCode',
    'maxDesc',
    'titleSave',
    'typeInput'],
  templateUrl: './modal-reason-rejection.component.html',
  styleUrls: ['./modal-reason-rejection.component.css']
})
export class ModalReasonRejectionComponent implements OnInit {
  titleModal: string;
  campoCode = 'Codigo:';
  placeholderCode = 'Codigo';
  campoDesc = 'Descripcion:';
  placeholderDesc = 'Descripcion';
  typeModal = 'catalogo';
  nameModal: string;
  information1: string;
  information2: string;
  information3: string;
  information4: string;
  formOtros: FormGroup;
  codeMO: string | null;
  descMO: string | null;
  error_id = false;
  txt_id: string | null;
  error_code = false;
  txt_code: string | null;
  error_desc = false;
  txt_desc: string | null;
  maxCode = 10;
  maxDesc = 60;
  titleSave: string;
  typeInput: boolean = true;
  inputType: string;
  codeId: string;
  campoAct: boolean = false;
  campoCliente: boolean = false;
  campoOficina: boolean = false;
  campoVendedor: boolean = false;
  campoDespachador: boolean = false;
  

  constructor( public auth: AuthService,
    public ProductService: InventaryProductService,
    public CatalogoMotivorechazoService: CatalogoMotivorechazoService,
    private listUrl: UrListService) {
    

    this.formOtros = new FormGroup({
      mocode: new FormControl(this.codeMO, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      modesc: new FormControl(this.descMO, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ])
    });
     }

  ngOnInit() { 
    if (this.CatalogoMotivorechazoService.newMU) {
      this.codeId = this.CatalogoMotivorechazoService.editCode;
      const values = this.CatalogoMotivorechazoService.valuesObjects;


      if (typeof values !== 'undefined') {
        for (let i = 0; i < values.length; i++) {
          const element = values[i];
          if (element.code == this.CatalogoMotivorechazoService.pk) {
            this.CatalogoMotivorechazoService.pass = element.llave
            this.codeMO = element.code;
            this.descMO = element.desc;
            this.campoAct = element.statu;
            this.campoCliente = element.cliente;
  			    this.campoOficina = element.oficina;
  			    this.campoVendedor = element.vendedor;
            this.campoDespachador = element.despachador;
          }
        }
      }
    }
    
  this.CatalogoMotivorechazoService.status = this.campoAct;
  this.CatalogoMotivorechazoService.cliente = this.campoCliente;
  this.CatalogoMotivorechazoService.oficina = this.campoOficina;
  this.CatalogoMotivorechazoService.vendedor = this.campoVendedor;
  this.CatalogoMotivorechazoService.despachador = this.campoDespachador;
  this.CatalogoMotivorechazoService.typeModal = 'catalogo';
  }


  getId() {
    return this.codeId;
  }

  formatCode() {
    this.codeMO = this.codeMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
  }

  formatDesc() {
    this.descMO = this.descMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  saveObject(){
    let id_obj: any;
    if (this.CatalogoMotivorechazoService.newMU) {
      id_obj = '';
      this.CatalogoMotivorechazoService.confirmar = false;
    } else {
      id_obj = this.CatalogoMotivorechazoService.pk;
      this.CatalogoMotivorechazoService.confirmar = true;
    }


    if (this.codeMO.toUpperCase().trim() && this.descMO.toUpperCase().trim() ) {

    const initAjax: Init = {
        method: 'put',
        url: this.CatalogoMotivorechazoService.urlSave,
        auth: this.auth,
        body: {	
          id : this.CatalogoMotivorechazoService.pass,
          mod: this.CatalogoMotivorechazoService.newMU,
          code: this.codeMO.toUpperCase().trim(),
          desc: this.descMO.toUpperCase().trim(),
          status: this.campoAct,
          cliente: this.campoCliente,
  		    oficina: this.campoOficina,
          vendedor: this.campoVendedor,
          despachador: this.campoDespachador
        }
      };

        const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        console.log('resp: ',resp);
        if (resp.success) {
            
            if (resp.nuevo) {
            this.newObject(resp);
            } 
           else {            
             this.editObject(resp);
          }     
        }

        this.CatalogoMotivorechazoService.closeModal.hide();
      }).catch(error => {
        console.log('error: ',error);
        this.viewError(error);
      });
    }

    else{
        this.error_desc = true;
        this.txt_desc = 'Favor debe de llenar los campos correctamente';
    }	
  }

  newObject(resp) {
    this.CatalogoMotivorechazoService.resp= resp;
  }


   editObject(resp) {
    this.CatalogoMotivorechazoService.code = this.codeMO;
    this.CatalogoMotivorechazoService.description = this.descMO;
    this.CatalogoMotivorechazoService.status = this.campoAct;
    this.CatalogoMotivorechazoService.cliente = this.campoCliente;
    this.CatalogoMotivorechazoService.oficina = this.campoOficina;
    this.CatalogoMotivorechazoService.vendedor = this.campoVendedor;
    this.CatalogoMotivorechazoService.despachador = this.campoDespachador;

    this.CatalogoMotivorechazoService.resp= resp;         
  }

  closeModal() {
    this.CatalogoMotivorechazoService.closeModal.hide();
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }
  }

  setTax_Exempt(){
    if(this.campoAct == true){
      this.campoAct = false;
    } else {
      this.campoAct = true;
    }
  }

  setCliente(){
    if(this.campoCliente == true){
      this.campoCliente = false;
    } else {
      this.campoCliente = true;
    }
  }

  setOficina(){
    if(this.campoOficina == true){
      this.campoOficina = false;
    } else {
      this.campoOficina = true;
    }
  }

  setVendedor(){
    if(this.campoVendedor == true){
      this.campoVendedor = false;
    } else {
      this.campoVendedor = true;
    }
  }

  setDespachador(){
    if(this.campoDespachador == true){
      this.campoDespachador = false;
    } else {
      this.campoDespachador = true;
    }
  }

}

@Component({
  selector: 'app-modal-reason-rejection-modal',
  template: `
    <app-modal-reason-rejection
      titleModal="MOTIVOS DE RECHAZO"
      information1="Cliente"
  	  information2="Oficina"
  	  information3="Vendedor"
  	  information4="Despachador"
      nameModal="notaDebito"
      titleSave="Motivo De Rechazo"
      >
    </app-modal-reason-rejection>
  `
})

export class ModalMotivoRetiroComponent implements OnInit {
	constructor(
    public CatalogoMotivorechazoService: CatalogoMotivorechazoService,
    public ProductService: InventaryProductService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoMotivorechazoService.urlSave = this.listUrl.url('saverejection');
    this.CatalogoMotivorechazoService.typeModal = this.ProductService.typeModal;
    this.CatalogoMotivorechazoService.typeModal = 'catalogo';
    
  }
}