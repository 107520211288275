import { Injectable } from '@angular/core';
// import { Company } from '../auth/user';
import { Cache } from '../../cache';
import { session } from 'src/app/core/constantes/sessiones';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CarshopModalComponent } from './carshop-modal/carshop-modal.component';


// Interface para guardar datos referenciales del carrito de compra
export interface Plan {
  plani: any; // id del plan
  plana: string; // nombre del plan
  planp: number; // precio del plan
  topackage: boolean; // ir a paquetes despues de selecionar company?
}

export interface Company {
  companyi: any; // id de la compañia
  companyn: string; // nombre de la compañia
  tocompany: boolean; // muestra el carshop en crear company para completar el flujo de Choicecompany
}

export interface Package {
  packagei: any; // id del paquete
  packagen: string; // nombre del paquete
  packagec: number; // cantidad adquirida
  packagep: number; // precio del paquete
}

export interface Packages extends Array<Package> {
  [key: number]: Package;
}

export interface CarShop extends Object {
  plan: Plan;
  company: Company;
  packages: Packages;
}

@Injectable({
  providedIn: 'root'
})
export class CarshopService {

  bsModalRef: BsModalRef;
  pvar: any = ['plani', 'plana', 'planp', 'topackage'];
  cvar: any = ['companyi', 'companyn', 'tocompany'];
  svar: any = ['packagei', 'packagen', 'packagec', 'packagep'];
  base_plan: Plan = {
    plani: null,
    plana: 'Plan no seleccionado',
    planp: 0,
    topackage: false
  };
  base_company: Company = {
    companyi: null,
    companyn: 'Compañía no seleccionada',
    tocompany: false
  };
  base_packages: Packages = [{
    packagei: null,
    packagen: 'Paquete no seleccionado',
    packagec: 0,
    packagep: 0
  }];
  _inicars: CarShop = {
    plan: this.base_plan,
    company: this.base_company,
    packages: this.base_packages
  };

  srv_inicar: any;
  inpla: any;
  incom: any;
  cspay = false;

  constructor(private router: Router, private modalService: BsModalService) {
    if (Cache.getLocal(session.pname)) {
      this.inicars = Cache.getLocal(session.pname, true);
    } else {
      Cache.setLocal(session.pname_base, {
        plan: this.base_plan,
        company: this.base_company,
        packages: this.base_packages
      });
      this.inicars = this.getinicars_base();
    }
  }

  // Modal Carshop
  openModal() {
    const initialState = {
      componentParent: this
    };
    this.bsModalRef = this.modalService.show(CarshopModalComponent, {initialState});
  }
  // Fin de modal Carshop
  error(errortxt) {
  }

  refresh() {
    this.inicars = this.getinicars_base();
    Cache.setLocal(session.pname, this.getinicars_base());
  }

  hasplan() {
    return this.getplan().plani;
  }

  hascompany() {
    return this.getcompany().companyi;
  }

  getplan() {
    // Al llamar a Carshop.getplan devolvera el objeto de plan
    return this._inicars.plan;
  }

  gettopackage() {
    // Al llamar a Carshop.gettopackage devolvera el atributo topackage
    return this._inicars.plan.topackage;
  }

  getcompany() {
    // Al llamar a Carshop.getcompany devolvera el objeto de company
    return this._inicars.company;
  }

  gettocompany() {
    // Al llamar a Carshop.gettocompany devolvera el atributo topackage
    return this._inicars.company.tocompany;
  }

  getpackages() {
    // Al llamar a Carshop.getpackages devolvera el objeto de paquetes
    return this._inicars.packages;
  }

  getinicars() {
    // Al llamar a Carshop._inicars devolvera el objeto
    return this._inicars;
  }

  getinicars_base() {
    // Al llamar a Carshop.inicars_base devolvera el objeto
    return Cache.getLocal(session.pname_base, true);
  }

  getTotal() {
    let ptt = this.getplan().planp;
    const pkkt = this.getpackages();
    pkkt.forEach((v, k) => {
      ptt += (v.packagec * v.packagep);
    });
    return ptt;
  }

  getcspay() {
    if (this.hasplan() && this.hascompany()) {
      return true;
    } else {
      return this.cspay;
    }
  }

  set plan(plan: Plan) {
    // Al llamar a 'CarShop.plan = objeto' se asignan los atributos del plan
    // En caso de que plan no sea un objeto, devolvera una excepcion
    const plane = this.getinicars();
    plane.plan = plan;
    this.inicars = plane;
  }

  set topackage(tose: boolean) {
    if (typeof tose == 'boolean' ) {
        const toser = this.getinicars();
        toser.plan.topackage = tose;
        this.inicars = toser;
    }
  }

  set company(company: Company) {
    const compane = this.getinicars();
    compane.company = company;
    this.inicars = compane;
  }

  set tocompany(toco: boolean) {
    if (typeof toco == 'boolean' ) {
      const tocom = this.getinicars();
      tocom.company.tocompany = toco;
      this.inicars = tocom;
    }
  }

  set package(packages: any) {
    if (Array.isArray(packages)) {
        // comprobamos que sea un array
        const prome = new Promise((succ, err) => {
            // Iniciamos una promesa para el recorrido de los paquetes escogidos.
            // declaramos la variable srv_inicar para guardar todos los paquetes seleccionados que fueron
            // verificados en las comprobaciones
            this.srv_inicar = [];
            packages.forEach((v, k) => {
                if (typeof v === 'object'  && v != null) {
                    // Se comprueba que sea un objeto de paquete
                    const promes = new Promise((succe, erro) => {
                        let arr = true;
                        for (const a in this.svar) {
                            if (!v.hasOwnProperty(this.svar[a])) {
                                // si no tiene alguno de los atributos, se ignora la seleccion
                                arr = false;
                                return false;
                            }
                        }
                        if (arr) {
                            // Si el arr tiene el paquete, se agrega en la variable this.srv_inicar
                            this.srv_inicar.push(v);
                            succe(true);
                        } else {
                            succe(true);
                        }
                    });
                }
            });
            if (this.srv_inicar != '') {
                // al terminar los procesos, se guardan los datos en packages
                this._inicars.packages = this.srv_inicar;
                succ(true);
            } else {
                err(false);
            }
        }).then((bn) => {
            // se guarda en la sesion para que sea global
            this.error({guardalo: bn});
            // this.getTotal();
            Cache.setLocal(session.pname, this._inicars);
        }).catch((er) => {
            this.error(er);
        });
    } else if (typeof packages === 'object'  && packages != null) {
        let arr = true;
        for (const a in this.svar) {
            if (!packages.hasOwnProperty(this.svar[a])) {
                // si no tiene alguno de los atributos, se ignora la seleccion
                arr = false;
            }
        }
        if (arr) {
            // Si el arr tiene el servicio, se agrega en la variable srv_inicar
            this.inicars.packages = [packages];
            Cache.setLocal(session.pname, this.inicars);
        }
    } else {
        this.inicars.packages = this.base_packages;
        Cache.setLocal(session.pname, this.inicars);
    }
  }

  set inicars(inicars: any) {
    // inicar deberia hacer todos los procesos de verificacion de informacion
    // mover el proceso de comprobaciones a inicar
    // una promesa que le pase la funcion y haga los recorridos
    if (typeof inicars === 'object' && inicars != null) {
        if (inicars.hasOwnProperty('plan')) {
            if (typeof inicars.plan === 'object' && inicars.plan != null) {
                this.inpla = {};
                let noinplan = true;
                for (const a in this.pvar) {
                    if (inicars.plan.hasOwnProperty(this.pvar[a])) {
                      this.inpla[this.pvar[a]] = inicars.plan[this.pvar[a]];
                    } else {
                      noinplan = false;
                    }
                }
                if (noinplan) {
                    this._inicars.plan = this.inpla;
                } else {
                    this._inicars.plan = this.base_plan;
                }
            } else {
                this._inicars.plan = this.base_plan;
                // Cache.setLocal(session.pname, this._inicars);
            }
        }
        if (inicars.hasOwnProperty('company')) {
            if (typeof inicars.company === 'object' && inicars.company != null) {
                this.incom = {};
                let noincom = true;
                for (const a in this.cvar) {
                    if (inicars.company.hasOwnProperty(this.cvar[a])) {
                        this.incom[this.cvar[a]] = inicars.company[this.cvar[a]];
                    } else {
                        noincom = false;
                    }
                }
                if (noincom) {
                    this._inicars.company = this.incom;
                } else {
                    this._inicars.company = this.base_company;
                }
            } else {
                this._inicars.company = this.base_company;
                // Cache.setLocal(session.pname, this._inicars);
            }
        }
        inicars.hasOwnProperty('packages') ? this.package = this.getpackages() : '';
        this.getTotal();
        Cache.setLocal(session.pname, this._inicars);
    } else {
      if (Cache.getLocal(session.pname)) {
        this._inicars = Cache.getLocal(session.pname, true);
      } else {
        this._inicars = this.getinicars_base();
      }
    }
  }

  getinicars_car() {
    return Cache.getLocal(session.pname, true);
  }

}
