import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsSettingService {

  error_prodisco: boolean;
  txt_prodisco: string;
  error_glodisco: boolean;
  txt_glodisco: string;
  error_limitcredit: boolean;
  txt_limitcredit: string;
  error_iva: boolean;
  txt_iva: string;
  error_custostart: boolean;
  txt_custostart: string;
  error_custofinal: boolean;
  txt_custofinal: string;
  error_datecredit: boolean;
  txt_datecredit: string;
  error_redoprec: boolean;
  txt_redoprec: string;
  error_diasanul: boolean;
  txt_diasanul: string;
  error_currency: boolean;
  txt_currency: string;
  error_licencia: boolean;
  txt_licencia: string;
  error_capisoci: boolean;
  txt_capisoci: string;
  error_namel: boolean;
  txt_namel: string;
  error_namet: boolean;
  txt_namet: string;
  error_nit: boolean;
  txt_nit: string;
  error_address: boolean;
  txt_address: string;
  error_phone: boolean;
  txt_phone: string;
  error_owner: boolean;
  txt_owner: string;
  error_codep: boolean;
  txt_codep: string;
  error_state: boolean;
  txt_state: string;
  error_country: boolean;
  txt_country: string;
  error_city: boolean;
  txt_city: string;
  
  constructor() { }
}
