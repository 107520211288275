import { Component, OnInit } from '@angular/core';
import { FiscalPrinterService } from '../../../core/fiscalprinter/fiscal-printer.service';

@Component({
  selector: 'app-fiscal-printer',
  templateUrl: './fiscal-printer.component.html',
  styleUrls: ['./fiscal-printer.component.css']
})
export class FiscalPrinterComponent implements OnInit {

  constructor(public FiscalPrinterService: FiscalPrinterService) {
  }

  ngOnInit() {
  }

  setModeloPuerto(tipo, event){
  	if (tipo == 'modelo') {
      if (event.target.value > 25) {
        this.FiscalPrinterService.portHttp = 6868;
      } else {
        this.FiscalPrinterService.portHttp = 3001;
      }
  		this.FiscalPrinterService.modelo =  event.target.value;
  	} else if(tipo == 'vel') {
  		this.FiscalPrinterService.baudios =  event.target.value;
  	} else if(tipo == 'puerto') {
		  this.FiscalPrinterService.puerto =  event.target.value;
  	}
  }

  imprimir(){

    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.cancelarComprobante();
      this.FiscalPrinterService.datosCliente("Abel Miranda Cordovi %", this.FiscalPrinterService.tdCUIT,
        "20939802593", this.FiscalPrinterService.riResponsableInscripto, "Haefreingue 1686");
               //   Si es nota de credito enviar la factura de referencia
               //   driver.documentoDeReferencia2g(tcFactura_A, "0001-00000023")
      this.FiscalPrinterService.abrirComprobante(this.FiscalPrinterService.tcFactura_A);
      this.FiscalPrinterService.imprimirTextoFiscal("Texto fiscal");
      this.FiscalPrinterService.imprimirItem2g("Item 1", 1, 0.1, 21, 0, this.FiscalPrinterService.Gravado,
        this.FiscalPrinterService.tiFijo, 1, "7790001001054", "", this.FiscalPrinterService.Unidad);
      this.FiscalPrinterService.imprimirDescuentoGeneral("Descuento general", 0.01);
      this.FiscalPrinterService.imprimirPago2g("Efectivo", 100, "", this.FiscalPrinterService.Efectivo, 1, "", "");
      this.FiscalPrinterService.cerrarComprobante();
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!");

    } catch (e){
      alert(e);
    }
  }

  cierreX(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.cierreX();
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
      } catch (e){
        alert(e);
      }
  }

  cierreZ(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.cierreZ();
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
      } catch (e){
        alert(e);
      }
  }

  cancelarComprobante(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.cancelarComprobante();
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
      } catch (e){
        alert(e);
      }
  }

  reporteZFechas(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.reporteZFechas("011216", "031216", false);
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
      } catch (e){
        alert(e);
      }
  }

  reporteZNumeros(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.reporteZNumeros(1, 10, false);
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
    } catch (e){
      alert(e);
    }
  }

  obtenerFechaHora(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.obtenerFechaHora();
      this.FiscalPrinterService.finalizarTrabajo()
      alert(this.FiscalPrinterService.response.fechaHora);
    } catch (e) {
      alert(e);
    }
  }

  ultimoNumeroComprobante(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.ultimoComprobante(this.FiscalPrinterService.tcFactura_A);
      this.FiscalPrinterService.finalizarTrabajo();
      alert(this.FiscalPrinterService.response.ultimoNumero);
    } catch (e){
      alert(e);
    }
  }

  obtenerDatosDeInicializacion(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.obtenerDatosDeInicializacion();
      this.FiscalPrinterService.finalizarTrabajo();
      var serie = this.FiscalPrinterService.response.nroSerie;
      alert("El número de serie de la impresora es: " + this.FiscalPrinterService.response.nroSerie);
      } catch (e){
        alert(e);
      }
  }

  ticketNoFiscal(){
    try {
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.cancelarComprobante();
      this.FiscalPrinterService.abrirComprobante(this.FiscalPrinterService.tcNo_Fiscal);
      this.FiscalPrinterService.imprimirTextoNoFiscal("Texto 1 no fiscal");
      this.FiscalPrinterService.imprimirTextoNoFiscal("Texto 2 no fiscal");
      this.FiscalPrinterService.imprimirTextoNoFiscal("Texto 3 no fiscal");
      this.FiscalPrinterService.imprimirTextoNoFiscal("Texto 4 no fiscal");
      this.FiscalPrinterService.cerrarComprobante();
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
      } catch (e){
        alert(e);
      }
  }

  facturaAAjusteTotal(){
    try {

      var totalSegunSistema = 100;

      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.cancelarComprobante();
      this.FiscalPrinterService.datosCliente("Abel Miranda Cordovi %", this.FiscalPrinterService.tdCUIT, "20939802593", this.FiscalPrinterService.riResponsableInscripto, "Haefreingue 1686");
      this.FiscalPrinterService.documentoDeReferencia2g(this.FiscalPrinterService.tcRemito, "0001-00000001");
      this.FiscalPrinterService.abrirComprobante(this.FiscalPrinterService.tcFactura_A);
                    // Precio final = 100
                    // Precio sin iva = 100 / 1.21 = 82.64
                    // Total segun fiscal = 99.99
                    // Debemos posteriormente leer ese total y ajustar por percepcion
      this.FiscalPrinterService.imprimirItem2g("Item 1", 1, 82.64, 21, 0, this.FiscalPrinterService.Gravado, this.FiscalPrinterService.tiFijo, 1, "7790001001054", "", this.FiscalPrinterService.Unidad);
      this.FiscalPrinterService.finalizarTrabajo();


      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.setearTipoComprobante(this.FiscalPrinterService.tcFactura_A);
      this.FiscalPrinterService.subtotal();
      this.FiscalPrinterService.finalizarTrabajo();
      this.FiscalPrinterService.iniciarTrabajo();
      this.FiscalPrinterService.setearTipoComprobante(this.FiscalPrinterService.tcFactura_A);
      if (this.FiscalPrinterService.response.montoVentas != totalSegunSistema) {
        this.FiscalPrinterService.ImprimirOtrosTributos(99, "Ajuste por redondeo", 0, totalSegunSistema - this.FiscalPrinterService.response.montoVentas, 0);
      }
      this.FiscalPrinterService.imprimirPago2g("Efectivo", 100, "", this.FiscalPrinterService.Efectivo, 1, "", "");
      this.FiscalPrinterService.cerrarComprobante();
      this.FiscalPrinterService.finalizarTrabajo();
      alert("Operación realizada con éxito!")
      } catch (e){
        alert(e);
      }
  }

  facturaBAjusteTotal(){
      try {

        var totalSegunSistema = 100;

        this.FiscalPrinterService.iniciarTrabajo();
        this.FiscalPrinterService.cancelarComprobante();
        this.FiscalPrinterService.datosCliente("Abel Miranda Cordovi %", this.FiscalPrinterService.tdCUIT, "20939802593", this.FiscalPrinterService.riMonotributo, "Haefreingue 1686");
        this.FiscalPrinterService.documentoDeReferencia2g(this.FiscalPrinterService.tcRemito, "0001-00000001");
        this.FiscalPrinterService.abrirComprobante(this.FiscalPrinterService.tcFactura_B);
                    // Precio final = 100
                    // Precio sin iva = 100 / 1.21 = 82.64
                    // Total segun fiscal = 99.99
                    // Debemos posteriormente leer ese total y ajustar por percepcion
        this.FiscalPrinterService.imprimirItem2g("Item 1", 1, 99.99, 21, 0, this.FiscalPrinterService.Gravado, this.FiscalPrinterService.tiFijo, 1, "7790001001054", "", this.FiscalPrinterService.Unidad);
        this.FiscalPrinterService.finalizarTrabajo();


        this.FiscalPrinterService.iniciarTrabajo();
        this.FiscalPrinterService.setearTipoComprobante(this.FiscalPrinterService.tcFactura_B);
        this.FiscalPrinterService.subtotal();
        this.FiscalPrinterService.finalizarTrabajo();
        this.FiscalPrinterService.iniciarTrabajo();
        this.FiscalPrinterService.setearTipoComprobante(this.FiscalPrinterService.tcFactura_B);
        if (this.FiscalPrinterService.response.montoVentas != totalSegunSistema) {
          this.FiscalPrinterService.imprimirDescuentoGeneral("Ajuste por redondeo", this.FiscalPrinterService.response.montoVentas - totalSegunSistema);
        }
        this.FiscalPrinterService.imprimirPago2g("Efectivo", 1, "", this.FiscalPrinterService.Efectivo, 1, "", "");
        this.FiscalPrinterService.cerrarComprobante();
        this.FiscalPrinterService.finalizarTrabajo();
        alert("Operación realizada con éxito!")
        } catch (e){
          alert(e);
        }
  }

  reporteElectronico(){
    try {
        this.FiscalPrinterService.iniciarTrabajo();
        this.FiscalPrinterService.obtenerPrimerBloqueReporteElectronico("1", "9", "c:\\datos\\reporte.zip", this.FiscalPrinterService.trNroCierre);
        this.FiscalPrinterService.finalizarTrabajo();
        alert("Reporte generado con éxito");
        } catch (e){
          alert(e);
        }
  }

  getId() {
    return 'null';
  }
}
