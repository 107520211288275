import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsSetControlsService {

  error_list: any;

  error_general = false;
  txt_error_general = '';

  error_fact = false;
  txt_fact = '';
  error_fact_desde = false;
  txt_fact_desde = '';
  error_fact_hasta = false;
  txt_fact_hasta = '';
  error_fact_ctrl_desde = false;
  txt_fact_ctrl_desde = '';
  error_fact_ctrl_hasta = false;
  txt_fact_ctrl_hasta = '';

  error_devo = false;
  txt_devo = '';
  error_devo_desde = false;
  txt_devo_desde = '';
  error_devo_hasta = false;
  txt_devo_hasta = '';
  error_devo_ctrl_desde = false;
  txt_devo_ctrl_desde = '';
  error_devo_ctrl_hasta = false;
  txt_devo_ctrl_hasta = '';

  error_cred = false;
  txt_cred = '';
  error_cred_desde = false;
  txt_cred_desde = '';
  error_cred_hasta = false;
  txt_cred_hasta = '';
  error_cred_ctrl_desde = false;
  txt_cred_ctrl_desde = '';
  error_cred_ctrl_hasta = false;
  txt_cred_ctrl_hasta = '';

  error_debi = false;
  txt_debi = '';
  error_debi_desde = false;
  txt_debi_desde = '';
  error_debi_hasta = false;
  txt_debi_hasta = '';
  error_debi_ctrl_desde = false;
  txt_debi_ctrl_desde = '';
  error_debi_ctrl_hasta = false;
  txt_debi_ctrl_hasta = '';

  constructor() { }
}
