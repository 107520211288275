import { EventEmitter, Injectable } from '@angular/core';
import { ObjectOne, ObjectsOne } from './objectone';
import { Url } from 'src/app/core/ajax/url';

@Injectable({
  providedIn: 'root'
})
export class CatalogoOneService {

  ambos: boolean;
  create: boolean;
  closeModal: any;
  habilitaBtn = false;
  newMU: boolean = true;
  modelo: string | null;
  modeloBase: string | null;
  pk: number | string;
  preguntaDelete: string | null;
  regresa: string | boolean;
  strConecDelete?: string;
  strDelete?: string;
  urlDelete: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  urlSave: Url | null;
  urlResolving: Url | null;
  valuesObjects: ObjectsOne | any;
  valuesObjectsBase: ObjectsOne | any;
  viewModal: any;
  typeModal: string | null;
  viewCode: string;
  editCode: string;
  addCode: string;
  object: ObjectOne;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
