import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Cache } from 'src/app/cache';
import { session } from 'src/app/core/constantes/sessiones';

@Injectable({
  providedIn: 'root'
})
export class CashOpeningGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (Cache.getSession(session.cash_open)) {
        return true;
      } else {
        this.router.navigate(['/cliente/pdv/cash_opening'], {
          queryParams: {
            return: state.url
          }
        });
        return false;
      }
  }
  
}
