import { Component, OnInit } from '@angular/core';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { Cashings, Cashing } from '../../interface/cashing';
import { Search } from 'src/app/search';
import Swal from 'sweetalert2';
import { Cache } from 'src/app/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { ActivatedRoute, Router } from '@angular/router';
import { Stations, Station } from '../../interface/station';

@Component({
  selector: 'app-cash-opening',
  templateUrl: './cash-opening.component.html',
  styleUrls: ['./cash-opening.component.css']
})
export class CashOpeningComponent implements OnInit {

  apershow: boolean;
  openshow: boolean;
  stations: Stations;
  cashings: Cashings;
  caja = {
    bsValue: new Date(),
    monto: '',
    stationcal: 'null'
  };
  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText:
      'Cancelar',
    cancelButtonAriaLabel: 'Cancelar'
  });
  maxDate: Date;
  minDate: Date;
  return = '/cliente/pdv/billing';

  constructor(private auth: AuthService, private listUrl: UrListService, 
    private route: ActivatedRoute, private router: Router) {
    this.maxDate = new Date();
    this.minDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.minDate.setDate(this.minDate.getDate() - 7);
    this.apershow = true;
    this.openshow = false;
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.return = params.return || '/cliente/pdv/billing');
    this.loadCashing();
    this.loadStations();
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  confirSendCaja() {
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.sendCaja();
      });
    }, 'Enviar Invitacion?',
    `Quiere Aperturar una caja?`);
  }

  getId() {
    return 'ESTPVIU00001';
  }

  loadStations() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('get_stations')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.stations = resp.stations;
      }
    }).catch(error => {
    });
    
  }

  sendCaja() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('add_cashing'),
      auth: this.auth,
      body: {
        date_cashing: this.caja.bsValue,
        amount_cashing: this.caja.monto,
        station_cashing: this.caja.stationcal
      }
    };
    const configAjax: Config = {
      titleLoading: 'Aperturando Caja...',
      titleSuccess: '',
      textErrorNormal: `No se logro aperturar la caja`,
      titleErrorNormal: 'Error al enviar solicitud de apertura',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              }),
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then((resp: any) => {
      if (resp.success) {
        this.caja.bsValue = new Date();
        this.caja.monto = '';
        this.caja.stationcal = 'null';
        this.loadCashing();
        this.loadStations();
        Cache.setSession(session.cash_open, resp.cash);
        this.router.navigateByUrl(this.return);
      }
    }).catch(er => {
      if (er.erro == 1) {
        Swal.mixin({
          icon: 'error',
          title: er.message,
          text: '¿Quiere crear una Estacion?',
          confirmButtonText: 'Si',
          showCancelButton: true,
          preConfirm: () => {
            this.router.navigate(['/cliente/adm/catalogo/station'], {
              queryParams: {
                return: this.router.url
              }
            });
          }
        }).fire();
      } else if (er.erro == 2) {
        Swal.mixin({
          icon: 'error',
          title: er.message,
          text: '¿Quiere configurar la Estacion?',
          confirmButtonText: 'Si',
          showCancelButton: true,
          preConfirm: () => {
            this.router.navigate(['/cliente/adm/config'], {
              queryParams: {
                return: this.router.url,
                station: this.caja.stationcal
              }
            });
          }
        }).fire();
      } else if (er.erro == 3) {
        Swal.mixin({
          icon: 'error',
          title: er.message,
          text: '¿Quiere configurar un Banco?',
          confirmButtonText: 'Si',
          showCancelButton: true,
          preConfirm: () => {
            this.router.navigate(['/cliente/pdv/banks'], {
              queryParams: {
                return: this.router.url
              }
            });
          }
        }).fire();
      }
    });
  }

  loadCashing() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('get_cashing')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        Cache.setSession(session.cash_open, resp.cashings);
        this.router.navigateByUrl(this.return);
      }
    }).catch(error => {
    });
  }

  get_stations_free() {
    if (typeof this.stations !== 'undefined' && this.stations.length) {
      return this.stations.filter((element: Station) => {
        if (typeof this.cashings !== 'undefined' && this.cashings.length) {
          const cashin = Search.filter(this.cashings, (x: Cashing, e: number) => {
            return Search.any([
              Search.search(x.station_id, e)
            ]);
          }, element.id_opening);
          if (cashin.length) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
        
      });
    } else {
      return [];
    }
  }

  get_stations_open() {
    if (typeof this.stations !== 'undefined' && this.stations.length) {
      return this.stations.filter((element: Station) => {
        if (typeof this.cashings !== 'undefined' && this.cashings.length) {
          const cashin = Search.filter(this.cashings, (x: Cashing, e: string) => {
            return Search.any([
              Search.search(x.station_id, e)
            ]);
          }, element.id_station);
          if (cashin.length) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
        
      });
    } else {
      return [];
    }
  }

  aper() {
    this.openshow = false;
    this.apershow = true;
  }

  open() {
    this.apershow = false;
    this.openshow = true
  }

}
