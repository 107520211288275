import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Customers, Customer } from '../../interface/customer';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Utiliti } from 'src/app/core/viewLoader/viewLoader';

@Component({
  selector: 'app-costumer-list-modal',
  inputs: ['search_cus'],
  outputs: ['customer'],
  templateUrl: './costumer-list-modal.component.html',
  styleUrls: ['./costumer-list-modal.component.css']
})
export class CostumerListModalComponent extends Utiliti implements OnInit {

  customer: EventEmitter<any> = new EventEmitter();
  customers: Customers;
  search_cus: string;
  resp: any = {search: ''};

  constructor(public bsModalRef: BsModalRef, public auth: AuthService, public listUrl: UrListService) { super(); }

  ngOnInit() {
    this.buscarCustomer();
  }

  buscarCustomer() {
    this.v_empty(this.search_cus).then(resp => {
      const initCus: Init = {
        method: 'put',
        auth: this.auth,
        body: {
          search: this.search_cus
        },
        url: this.listUrl.url('get_customers')
      };
      const configD: Config = {
        visible: false,
        autoReNew: true
      }
      const ajax = new Ajax(initCus, configD);
      ajax.call().then(resp => {
        if (typeof resp.customers != 'undefined') {
          this.customers = resp.customers;
        }
      }).catch(resp => {
        
      });
    });
  }

  selectCustomer(customer: Customer) {
    this.resp.customer = customer;
    this.closeModal();
  }

  closeModal() {
    this.resp.search = this.search_cus;
    this.customer.emit(this.resp);
    this.bsModalRef.hide();
  }

}
