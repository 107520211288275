import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsProductService {

  error_id: boolean;
  txt_id: string;
  error_group: boolean;
  txt_group: string;
  error_type: boolean;
  txt_type: string;
  error_presentation: boolean;
  txt_presentation: string;
  error_shop: boolean;
  txt_shop: string;
  error_location: boolean;
  txt_location: string;
  error_subgroup: boolean;
  txt_subgroup: string;
  error_kind: boolean;
  txt_kind: string;
  error_tax: boolean;
  txt_tax: string;
  error_supplier: boolean;
  txt_supplier: string;
  error_codep: boolean;
  txt_codep: string;
  error_descp: boolean;
  txt_descp: string;
  error_rubrp: boolean;
  txt_rubrp: string;
  error_cababo: boolean;
  txt_cababo: string;
  error_cabadi: boolean;
  txt_cabadi: string;
  error_cabaun: boolean;
  txt_cabaun: string;
  error_idpssu: boolean;
  txt_idpssu: string;
  error_unpal: boolean;
  txt_unpal: string;
  error_unbox: boolean;
  txt_unbox: string;
  error_contunit: boolean;
  txt_contunit: string;
  error_volbox: boolean;
  txt_volbox: string;
  error_pesbrbox: boolean;
  txt_pesbrbox: string;
  error_descbox: boolean;
  txt_descbox: string;
  error_descunit: boolean;
  txt_descunit: string;
  error_invmin: boolean;
  txt_invmin: string;
  error_invmax: boolean;
  txt_invmax: string;
  error_ormibo: boolean;
  txt_ormibo: string;
  error_daminv: boolean;
  txt_daminv: string;
  error_vemiun: boolean;
  txt_vemiun: string;
  error_changeimg: boolean;
  txt_changeimg: string;
  error_utimin:boolean;
  text_utimin: string;
  error_utibase:boolean;
  text_utibase: string;
  error_utia:boolean;
  text_utia: string;
  error_utib:boolean;
  text_utib: string;
  error_utic:boolean;
  text_utic: string;
  error_utid:boolean;
  text_utid: string;
  error_utie:boolean;
  text_utie: string;
  error_pvbase:boolean;
  text_pvbase: string;
  error_pva:boolean;
  text_pva: string;
  error_pvb:boolean;
  text_pvb: string;
  error_pvc:boolean;
  text_pvc: string;
  error_pvd:boolean;
  text_pvd: string;
  error_pve:boolean;
  text_pve: string;

  constructor() { }
}
