import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CatalogoPaySoonService } from '../../../services/catalogo-pay-soon.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { InventaryProductService } from '../../../services/inventary-product.service';

@Component({
  selector: 'app-modal-pay-soon',
  inputs: [
    'titleModal',
    'labelCode',
    'placeholderCode',
    'labelDesc',
    'placeholderDesc',
    'typeModal',
    'nameModal',
    'information3',
    'placeholderCampo',
    'campoAct',
    'maxCode',
    'maxDesc',
    'titleSave',
    'typeInput'],
  templateUrl: './modal-pay-soon.component.html',
  styleUrls: ['./modal-pay-soon.component.css']
})
export class ModalPaySoonComponent implements OnInit {
  titleModal: string;
  campoCode = 'Codigo:';
  placeholderCode = 'Codigo';
  campoDesc = 'Descripcion:';
  placeholderDesc = 'Descripcion';
  placeholderCampo: string;
  typeModal = 'catalogo';
  nameModal: string;
  information1: string;
  information2: string;
  information3: string;
  formPago: FormGroup;
  codeMO: string | null;
  descMO: string | null;
  error_id = false;
  txt_id: string | null;
  error_code = false;
  txt_code: string | null;
  error_desc = false;
  txt_desc: string | null;
  error_total = false;
  txt_total: string | null;
  maxCode = 10;
  maxDesc = 60;
  maxCapa = 20;
  titleSave: string;
  typeInput: boolean = true;
  inputType: string;
  codeId: string;
  campoAct: boolean = false;
  campoPago: number | null;


  constructor(public auth: AuthService,
    public ProductService: InventaryProductService,
    public CatalogoPaySoonService: CatalogoPaySoonService,
    private listUrl: UrListService) {

    this.formPago = new FormGroup({
      mocode: new FormControl(this.codeMO, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      modesc: new FormControl(this.descMO, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ]),
      mocapa: new FormControl(this.campoPago, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,18}(\.[0-9]{1,8})?$/)
      ])
    });

     }

  ngOnInit() {
  	if (this.CatalogoPaySoonService.newMU) {
      this.codeId = this.CatalogoPaySoonService.editCode;
      const values = this.CatalogoPaySoonService.valuesObjects;


      if (typeof values !== 'undefined') {
        for (let i = 0; i < values.length; i++) {
          const element = values[i];
          if (element.code == this.CatalogoPaySoonService.pk) {
            this.CatalogoPaySoonService.pass = element.llave
            this.codeMO = element.code;
            this.descMO = element.desc;
            this.campoAct = element.statu;
            this.campoPago = element.prontopago;
          }
        }
      }
    }
    
  this.CatalogoPaySoonService.status = this.campoAct;
  this.CatalogoPaySoonService.prontopago = this.campoPago;
  this.CatalogoPaySoonService.typeModal = 'catalogo';
  }

  getId() {
    return this.codeId;
  }

  formatCode() {
    this.codeMO = this.codeMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
  }

  formatDesc() {
    this.descMO = this.descMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  formatCapa() {
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }


  saveObject(){
    let id_obj: any;
    if (this.CatalogoPaySoonService.newMU) {
      id_obj = '';
      this.CatalogoPaySoonService.confirmar = false;
    } else {
      id_obj = this.CatalogoPaySoonService.pk;
      this.CatalogoPaySoonService.confirmar = true;
    }


    if (this.codeMO.toUpperCase().trim() && this.descMO.toUpperCase().trim() ) {

    const initAjax: Init = {
        method: 'put',
        url: this.CatalogoPaySoonService.urlSave,
        auth: this.auth,
        body: {	
          id : this.CatalogoPaySoonService.pass,
          mod: this.CatalogoPaySoonService.newMU,
          code: this.codeMO.toUpperCase().trim(),
          desc: this.descMO.toUpperCase().trim(),
          status: this.campoAct,
          prontopago: this.campoPago
        }
      };

        const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        console.log('resp: ',resp);
        if (resp.success) {
            
            if (resp.nuevo) {
            this.newObject(resp);
            } 
           else {            
             this.editObject(resp);
          }     
        }

        this.CatalogoPaySoonService.closeModal.hide();
      }).catch(error => {
        console.log('error: ',error);
        this.viewError(error);
      });
    }

    else{
        this.error_desc = true;
        this.txt_desc = 'Favor debe de llenar los campos correctamente';
    }	
  }


  newObject(resp) {
    this.CatalogoPaySoonService.resp= resp;
  }

  editObject(resp) {
    this.CatalogoPaySoonService.code = this.codeMO;
    this.CatalogoPaySoonService.description = this.descMO;
    this.CatalogoPaySoonService.status = this.campoAct;
    this.CatalogoPaySoonService.prontopago = this.campoPago;

    this.CatalogoPaySoonService.resp= resp;         
  }

  closeModal() {
    this.CatalogoPaySoonService.closeModal.hide();
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }

    if (error.error_total !== 'undefined') {
      if (error.error_total) {
        this.error_total = error.error_total;
        this.txt_total = error.txt_total;
      }
    }
  }

  setTax_Exempt(){
    if(this.campoAct == true){
      this.campoAct = false;
    } else {
      this.campoAct = true;
    }
  }

}

@Component({
  selector: 'app-modal-prontoPago-modal',
  template: `
    <app-modal-pay-soon
      titleModal="% Porcentaje Pronto Pago"
  	  information3="% Pronto Pago"
  	  placeholderCampo = "0.00"
      nameModal="Pronto Pago"
      titleSave="Catalogo Pronto Pago"
      >
    </app-modal-pay-soon>
  `
})

export class ModalProntoPagoComponent implements OnInit {
	constructor(
    public CatalogoPaySoonService: CatalogoPaySoonService,
    public ProductService: InventaryProductService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoPaySoonService.urlSave = this.listUrl.url('savepay');
    this.CatalogoPaySoonService.typeModal = this.ProductService.typeModal;
    this.CatalogoPaySoonService.typeModal = 'catalogo';
    
  }
}