import { Component, OnInit } from '@angular/core';
import { EntriesExitsService } from '../../../services/entries-exits.service';

@Component({
  selector: 'app-view-product-expira',
  templateUrl: './view-product-expira.component.html',
  styleUrls: ['./view-product-expira.component.css']
})
export class ViewProductExpiraComponent implements OnInit {
  ProductsExpira: any;

  constructor(
    public ViewService: EntriesExitsService
  ) { }

  ngOnInit() {
    if (this.ViewService.nroTypeTrans == 0) {
      this.ProductsExpira = this.ViewService.prodExpiCompra;
    } else if (this.ViewService.nroTypeTrans == 1) {
      this.ProductsExpira = this.ViewService.prodExpiEntrada;
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.ProductsExpira = this.ViewService.prodExpiPedido;
    } else if (this.ViewService.nroTypeTrans == 3) {
      this.ProductsExpira = this.ViewService.prodExpiSalida;
    }
  }

  getId(){
    return 'ADMINIU00012';
  }

  closeModal() {
    this.ViewService.closeModalViewExpira.hide();
  }

}
