import { EventEmitter, Injectable } from '@angular/core';
import { SellerCustomer, SellersCustomers } from './sellers-customers';
import { OneSelect } from '../modals-double/one-select';

@Injectable({
  providedIn: 'root'
})
export class SellerCustomerService {

  ambos: boolean;
  closeModal: any;
  create: boolean;
  habilitaBtn = false;
  urlreturn: boolean | string;
  regresa: string | boolean;
  haveObjects = true;
  isCatalog = true;
  new: boolean = true;
  modalType = '';
  modelo: string | null;
  pk: number | string;
  SellerSelect: OneSelect; //Objeto para listar Rutas en select
  CustomerSelect: OneSelect; //Objeto para listar Rutas en select
  typeModal: any;
  valuesObjects: SellersCustomers | any;
  valuesObjectsBase: SellersCustomers | any;
  CodeCustomer: number | null = null;
  DescCustomer: number | null = null;
  CodeSeller: number | null = null;
  DescSeller: number | null = null;
  Status: boolean = true;
  CodeFrecuency: number | string = '';
  FrecuencySelect = [
    {'id':'', 'code':'---------'},
    {'id':'1', 'code':'INTERDIARIO'},
    {'id':'2', 'code':'SEMANAL'},
    {'id':'3', 'code':'QUINCENAL'},
    {'id':'4', 'code':'MENSUAL'}
  ];
  CodeDetail: number | string = '';
  DetailsSelect = [
    {'id':'', 'code':'---------'},
    {'id':'1', 'code':'PRIMERA SEMANA'},
    {'id':'2', 'code':'SEGUNDA SEMANA'},
    {'id':'3', 'code':'TERCERA SEMANA'},
    {'id':'4', 'code':'CUARTA SEMANA'},
    {'id':'5', 'code':'NO APLICA'}
  ];
  selectFrecu: boolean = false;
  Secu: number = 0;
  Lun: boolean = false;
  Mar: boolean = false;
  Mier: boolean = false;
  Jue: boolean = false;
  Vie: boolean = false;
  Sab: boolean = false;
  Dom: boolean = false;
  CodePay: number | string = '';
  PaymentSelect = [
    {'id':'', 'code':'---------'},
    {'id':'1', 'code':'CONTADO'},
    {'id':'2', 'code':'CRÉDITO'}
  ];
  selectPay: boolean = false;
  DayCredit: number = 0;
  LimitCredit: number = 0;
  CodePrice: number | string = '';
  PricesSelect = [
    {'id':'', 'code':'---------'},
    {'id':'0', 'code':'Base'},
    {'id':'A', 'code':'A'},
    {'id':'B', 'code':'B'},
    {'id':'C', 'code':'C'},
    {'id':'D', 'code':'D'},
    {'id':'E', 'code':'E'}
  ];
  object: SellerCustomer;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
