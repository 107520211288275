import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CatalogoThreeService } from '../services/catalogo-three.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { ModalGroupComponent } from '../modals-one/modals-one.component';
import { CatalogoOneService } from '../services/catalogo-one.service';
import { ModalSubgroupComponent } from '../modals-double/modals-double.component';
import { CatalogotwoService } from '../services/catalogotwo.service';
import { InventaryProductService } from '../services/inventary-product.service';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';

@Component({
  selector: 'app-modals-triple',
  inputs: [
    'titleModal', 'labelOne', 'labelTwo', 'labelThree', 'modalType', 
    'titleSave', 'titleModalOne', 'placeholderOne', 'titleModalTwo', 
    'placeholderTwo', 'placeholderCode', 'maxCode', 'placeholderDesc', 
    'maxDesc', 'typeSelectOne'],
  templateUrl: './modals-triple.component.html',
  styleUrls: ['./modals-triple.component.css']
})
export class ModalsTripleComponent implements OnInit {
  titleModal: string;
  labelOne: string;
  labelTwo: string;
  labelThree: string;
  titleModalOne: string;
  placeholderOne: string;
  titleModalTwo: string;
  placeholderTwo: string;
  placeholderCode: string;
  placeholderDesc: string;
  maxCode: number;
  maxDesc: number;
  codeThreeMT: string | null;
  descThreeMT: string | null;
  error_idprinc: boolean = false;
  txt_idprinc: string | null;
  error_idsecun: boolean = false;
  txt_idsecun: string | null;
  error_idtrip: boolean = false;
  txt_idtrip: string | null;
  error_codetrip: boolean = false;
  txt_codetrip: string | null;
  error_desctrip: boolean = false;
  txt_desctrip: string | null;
  modalType: string | null;
  titleSave: string;
  formThree: FormGroup;
  typeSelectOne: string;
  codeId: string;

  constructor(
    public auth: AuthService, 
    public CatalogoOneService: CatalogoOneService, 
    public CatalogoTwoService: CatalogotwoService, 
    public CatalogoThreeService: CatalogoThreeService, 
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private modalService: BsModalService, 
    private modalRef: BsModalRef
  ){
    this.formThree = new FormGroup({
      mtcodeone: new FormControl(this.CatalogoThreeService.selectOneMT,[
        Validators.required
      ]),
      mtdescone: new FormControl(this.CatalogoThreeService.descOneMT, [
        Validators.required
      ]),
      mtcodetwo: new FormControl({value:this.CatalogoThreeService.selectTwoMT, disabled:true},[
        Validators.required
      ]),
      mtdesctwo: new FormControl({value:this.CatalogoThreeService.descTwoMT, disabled:true},[
        Validators.required
      ]),
      mtcodethree: new FormControl(this.codeThreeMT,[
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      mtdescthree: new FormControl(this.descThreeMT,[
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ])
    });
  }

  ngOnInit(){
    this.loadObjSelect();
    if (!this.CatalogoThreeService.newMT) {
      this.codeId = this.CatalogoThreeService.editCode;
      if (typeof this.CatalogoThreeService.newMT != 'undefined') {
        this.loadObjSelectTwo(this.CatalogoThreeService.object.id_princ);
        this.CatalogoThreeService.descOneMT = this.CatalogoThreeService.object.desc_princ;
        this.CatalogoThreeService.descTwoMT = this.CatalogoThreeService.object.desc_secun;
        this.codeThreeMT = this.CatalogoThreeService.object.code_third;
        this.descThreeMT = this.CatalogoThreeService.object.desc_third;
        this.CatalogoThreeService.selectOneMT = this.CatalogoThreeService.object.id_princ;
        this.CatalogoThreeService.selectTwoMT = this.CatalogoThreeService.object.id_secun;
        this.CatalogoThreeService.selectID = true;
        this.CatalogoThreeService.selectedOne = false;
        this.CatalogoThreeService.selectIDTwo = true;
      }
    } else {
      this.codeId = this.CatalogoThreeService.addCode;
      this.CatalogoThreeService.selectOneMT = null;
      this.CatalogoThreeService.descOneMT = null;
      this.CatalogoThreeService.descTwoMT = null;
      this.CatalogoThreeService.selectedOne = true;
      this.CatalogoThreeService.selectIDTwo = false;
      this.CatalogoThreeService.selectTwoMT = null;
      if (this.ProductService.modelo === 'Producto') {
        this.CatalogoThreeService.OneSelect = this.ProductService.GroupSelect;
        this.CatalogoThreeService.TwoSelect = this.ProductService.SubgroupSelect;
        this.CatalogoThreeService.selectID = true;
        this.CatalogoThreeService.selectedOne = true;
        if (this.ProductService.selectGroup) {
          for (const key in this.CatalogoThreeService.OneSelect) {
            if (this.CatalogoThreeService.OneSelect.hasOwnProperty(key)) {
              const element = this.CatalogoThreeService.OneSelect[key];
              if (this.ProductService.selectGroup == element.id) {
                this.CatalogoThreeService.selectOneMT = element.id;
                this.CatalogoThreeService.descOneMT = element.desc;
                this.CatalogoThreeService.selectedOne = false;
              }
            }
          }
        }
        if (this.ProductService.selectSubgroup) {
          for (const key in this.CatalogoThreeService.TwoSelect) {
            if (this.CatalogoThreeService.TwoSelect.hasOwnProperty(key)) {
              const element = this.CatalogoThreeService.TwoSelect[key];
              if (this.ProductService.selectSubgroup == element.id) {
                this.CatalogoThreeService.selectTwoMT = element.id;
                this.CatalogoThreeService.descTwoMT = element.desc;
              }
            }
          }
        }
      }
    }
  }

  getId() {
    return this.codeId;
  }

  loadObjSelect(){
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.CatalogoThreeService.urlSelect
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.CatalogoThreeService.OneSelect = resp.objects;
      }
    });
  }

  loadObjSelectTwo(pk_princ){
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk_princ
      },
      url: this.CatalogoThreeService.urlSelectTwo
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.CatalogoThreeService.TwoSelect = resp.objects;
        this.CatalogoThreeService.selectedOne = false;
      }
    });
  }

  changeCodeOne() {
    this.error_idprinc = false;
    this.error_idsecun = false;
    this.error_idtrip = false;
    this.txt_idprinc = '';
    this.txt_idsecun = '';
    this.txt_idtrip = '';
    this.CatalogoThreeService.descOneMT = null;
    this.CatalogoThreeService.selectTwoMT = null;
    this.CatalogoThreeService.descTwoMT = null;
    if (this.CatalogoThreeService.selectOneMT) {
      this.loadObjSelectTwo(this.CatalogoThreeService.selectOneMT);
    } else {
      this.CatalogoThreeService.selectedOne = true;
    }
    for (const key in this.CatalogoThreeService.OneSelect){
      if (this.CatalogoThreeService.OneSelect.hasOwnProperty(key)) {
        const element = this.CatalogoThreeService.OneSelect[key];
        if (this.CatalogoThreeService.selectOneMT == element.id) {
          this.CatalogoThreeService.descOneMT = element.desc;
          break;
        }
      }
    }
  }

  changeDescOne() {
    this.error_idprinc = false;
    this.error_idsecun = false;
    this.error_idtrip = false;
    this.txt_idprinc = '';
    this.txt_idsecun = '';
    this.txt_idtrip = '';
    this.CatalogoThreeService.selectOneMT = null;
    this.CatalogoThreeService.selectTwoMT = null;
    this.CatalogoThreeService.descTwoMT = null;
    if (this.CatalogoThreeService.descOneMT) {
      this.loadObjSelectTwo(this.CatalogoThreeService.descOneMT);
    } else {
      this.CatalogoThreeService.selectedOne = true;
    }
    for (const key in this.CatalogoThreeService.OneSelect){
      if (this.CatalogoThreeService.OneSelect.hasOwnProperty(key)) {
        const element = this.CatalogoThreeService.OneSelect[key];
        if (this.CatalogoThreeService.descOneMT == element.id) {
          this.CatalogoThreeService.selectOneMT = element.id;
          break;
        }
      }
    }
  }

  changeCodeTwo(){
    this.error_idprinc = false;
    this.error_idsecun = false;
    this.error_idtrip = false;
    this.txt_idprinc = '';
    this.txt_idsecun = '';
    this.txt_idtrip = '';
    this.CatalogoThreeService.descTwoMT = null;
    for (const key in this.CatalogoThreeService.TwoSelect){
      if (this.CatalogoThreeService.TwoSelect.hasOwnProperty(key)) {
        const element = this.CatalogoThreeService.TwoSelect[key];
        if(this.CatalogoThreeService.selectTwoMT == element.id){
          this.CatalogoThreeService.descTwoMT = element.desc;
          break;
        }
      }
    }
  }

  changeDescTwo(){
    this.error_idprinc = false;
    this.error_idsecun = false;
    this.error_idtrip = false;
    this.txt_idprinc = '';
    this.txt_idsecun = '';
    this.txt_idtrip = '';
    this.CatalogoThreeService.selectTwoMT = null;
    for (const key in this.CatalogoThreeService.TwoSelect){
      if (this.CatalogoThreeService.TwoSelect.hasOwnProperty(key)) {
        const element = this.CatalogoThreeService.TwoSelect[key];
        if(this.CatalogoThreeService.descTwoMT == element.id){
          this.CatalogoThreeService.selectTwoMT = element.id;
          break;
        }
      }
    }
  }

  closeModal(){
    this.CatalogoThreeService.closeModal.hide();
  }

  saveObject(){
    let id_obj: any;
    if(this.CatalogoThreeService.newMT){
      id_obj = '';
    }else{
      id_obj = this.CatalogoThreeService.pkThird;
    }
    if(this.codeThreeMT.toUpperCase().trim() && this.descThreeMT.toUpperCase().trim()){
      const initAjax: Init = {
        method: 'put',
        url: this.CatalogoThreeService.urlSave,
        auth: this.auth,
        body: {
          id_obj: id_obj,
          id_princ: this.CatalogoThreeService.selectOneMT,
          id_secun: this.CatalogoThreeService.selectTwoMT,
          code: this.codeThreeMT.toUpperCase().trim(),
          desc: this.descThreeMT.toUpperCase().trim(),
          tipo: this.CatalogoThreeService.typeModalBase
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if (resp.new) {
            if (resp.tipo == 'catalogo') {
              this.CatalogoThreeService.continuar = true;
              this.CatalogoThreeService.evenAdd.emit(resp);
            } else {
              if (this.CatalogoThreeService.modeloBase === 'Producto' && resp.tipo === 'kind') {
                this.modalProduct(resp);
              } else if (this.CatalogoThreeService.modeloBase === 'Conflicto') {
                this.ResolvingService.seguir = true;
                this.ResolvingService.loadselect = true;
                this.ResolvingService.evenAdd.emit({'new':resp.objeto});
              }
            }
          } else {
            this.CatalogoThreeService.continuar = true;
            this.CatalogoThreeService.evenAdd.emit(resp);
          }
          this.CatalogoThreeService.closeModal.hide();
          this.CatalogoThreeService.selectID = false;
          this.CatalogoThreeService.selectOneMT = null;
        }
      }).catch(error => {
        this.viewError(error);
      });
    }else{
      this.codeThreeMT = '';
      this.descThreeMT = '';
    }
  }

  modalProduct(resp){
    this.ProductService.selectIdGroup = true;
    this.ProductService.selectGroup = resp.pk_one;
    this.ProductService.GroupSelect = resp.obj_one;
    this.ProductService.descGroup = resp.desc_one;
    this.ProductService.selectedGroup = true;
    this.ProductService.selectIdSubgroup = true;   
    this.ProductService.selectSubgroup = resp.pk_two;
    this.ProductService.SubgroupSelect = resp.obj_two;
    this.ProductService.descSubgroup = resp.obj_two;
    this.ProductService.selectedSubGroup = true;
    this.ProductService.selectIdKind = true;
    this.ProductService.selectKind = resp.pk_three;
    this.ProductService.KindSelect = resp.objeto;
    this.ProductService.descKind = resp.pk_three;
  }

  viewError(error){
    if(error.error_idprinc != 'undefined'){
      if(error.error_idprinc){
        this.error_idprinc = error.error_idprinc;
        this.txt_idprinc = error.txt_idprinc;
      }
    }
    if(error.error_idsecun != 'undefined'){
      if(error.error_idsecun){
        this.error_idsecun = error.error_idsecun;
        this.txt_idsecun = error.txt_idsecun;
      }
    }
    if(error.error_idtrip != 'undefined'){
      if(error.error_idtrip){
        this.error_idtrip = error.error_idtrip;
        this.txt_idtrip = error.txt_idtrip;
      }
    }
    if(error.error_codetrip != 'undefined'){
      if(error.error_codetrip){
        this.error_codetrip = error.error_codetrip;
        this.txt_codetrip = error.txt_codetrip;
      }
    }
    if(error.error_desctrip != 'undefined'){
      if(error.error_desctrip){
        this.error_desctrip = error.error_desctrip;
        this.txt_desctrip = error.txt_desctrip;
      }
    }
  }

  formatCodeTrip(){
    this.codeThreeMT = this.codeThreeMT.toUpperCase().trim();
    this.error_idtrip = false;
    this.txt_idtrip = '';
    this.error_codetrip = false;
    this.txt_codetrip = '';
    if (this.codeThreeMT) {
      while (this.codeThreeMT.length < this.maxCode) {
        this.codeThreeMT = '0'+this.codeThreeMT;
      } 
    }
  }

  formatDescTrip(){
    this.descThreeMT = this.descThreeMT.toUpperCase();
    this.error_idtrip = false;
    this.txt_idtrip = '';
    this.error_desctrip = false;
    this.txt_desctrip = '';
  }

  openModalPrinc(tipo, model){
    this.CatalogoThreeService.typeModal = tipo;
    this.CatalogoThreeService.modelo = model;
    const initialState = {
      componentParent: this
    };
    this.modalRef = this.modalService.show(this.CatalogoThreeService.princModal, {initialState});
    this.modalRef.setClass('modal-dialog-centered zindex-one');
    this.CatalogoOneService.closeModal = this.modalRef;
  }

  openModalSecun(tipo, model){
    this.CatalogoTwoService.selectOneMD = this.CatalogoThreeService.selectOneMT;
    this.CatalogoTwoService.OneSelect = this.CatalogoThreeService.OneSelect;
    this.CatalogoThreeService.typeModal = tipo;
    this.CatalogoThreeService.modelo = model;
    const initialState = {
      componentParent: this
    };
    this.modalRef = this.modalService.show(this.CatalogoThreeService.secunModal, {initialState});
    this.modalRef.setClass('modal-dialog-centered');
    this.CatalogoTwoService.closeModal = this.modalRef;
  }
}

@Component({
  selector: 'app-modals-line',
  template: `
    <app-modals-triple
      titleModal="Agregar Linea"
      labelOne="Grupo:"
      labelTwo="Subgrupo:"
      labelThree="Linea:"
      titleModalOne="Crear Grupo"
      placeholderOne="DESCRIPCION GRUPO"
      titleModalTwo="Crear Subgrupo"
      placeholderTwo="DESCRIPCION SUBGRUPO"
      placeholderCode="CODIGO LINEA"
      maxCode=10
      placeholderDesc="DESCRIPCION LINEA"
      maxDesc=80
      modalType="catalogo"
      titleSave="Linea"
      typeSelectOne="linea"
      >
    </app-modals-triple>
  `
})
export class ModalsLineComponent implements OnInit {
  constructor(
    public CatalogoThreeService: CatalogoThreeService, 
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ){}

  ngOnInit() {
    this.CatalogoThreeService.urlSelect = this.listUrl.url('list_modal_group');
    this.CatalogoThreeService.urlSelectTwo = this.listUrl.url('list_modal_subgroup');
    this.CatalogoThreeService.urlSave = this.listUrl.url('saveline');
    this.CatalogoThreeService.princModal = ModalGroupComponent;
    this.CatalogoThreeService.secunModal = ModalSubgroupComponent
    if (typeof this.ProductService.typeModal != 'undefined') {
      this.CatalogoThreeService.typeModal = this.ProductService.typeModal;
      this.CatalogoThreeService.modelo = this.ProductService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined') {
      this.CatalogoThreeService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoThreeService.modelo = this.ResolvingService.modelo;
    } else {
      this.CatalogoThreeService.typeModal = 'catalogo';
    }
    this.CatalogoThreeService.modeloBase = this.CatalogoThreeService.modelo;
    this.CatalogoThreeService.typeModalBase = this.CatalogoThreeService.typeModal;
  }
}