import { Injectable } from '@angular/core';
import { ObjectsMsjFacturacion, ObjectMsjFacturacion } from './objectMsjFacturacion';
import { Url } from 'src/app/core/ajax/url';


@Injectable({
  providedIn: 'root'
})

export class CatalogoMsjFacturacionService {

  pass: number | string; 
  confirmar: boolean;
  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  Modal: any
  closeModal: any;
  pk: number | string;
  newMU: boolean;
  typeModal;
  urlSave: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  habilitaBtn = false;
  valuesObject: ObjectsMsjFacturacion  | any;
  valuesObjects: ObjectsMsjFacturacion | any;
  valuesObjectsBase: ObjectsMsjFacturacion | any;
  viewCode: string;
  editCode: string;
  addCode: string;
  preguntaDelete: string | null;
  urlDelete: Url | null;
  strDelete?: string;
  strConecDelete?: string;
  resp: any;
  object: ObjectsMsjFacturacion;

  status: boolean;
  code: string;
  description: string;
  
  constructor() { 

  }
}