import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { UrListService } from '../ajax/ur-list.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChatClientComponent } from '../chat-client/chat-client.component';
import { ChatsList, ChatControl, Chat } from '../chats/chat';
import { Observable } from 'rxjs';
import { SelectCompanyModalComponent } from '../select-company-modal/select-company-modal.component';
import { MenuMovilModalComponent } from '../menu-movil-modal/menu-movil-modal.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {

  
  showmenuuser = false;
  bsModalRef: BsModalRef;
  bsModalRefS: BsModalRef;
  chat_company = new ChatsList('company');
  private statusCompany: Observable<boolean>;
  private statusSocket: Observable<boolean>;
  private statusgetChat = true;

  ccc = true;
  pc = true;
  fdc = true;
  dtc = true;
  uc = true;
  tnc = true;
  icc = true;
  ecc = true;
  fcc = true;
  mcc = true;
  scc = true;
  cfcc = true;
  svp = true;
  movil = true

  constructor(
    public auth: AuthService,
    public listUrl: UrListService,
    private modalService: BsModalService,
  
    ) { }

  ngOnInit() {
    this.statusSocket = this.auth.onSocket();
    this.statusCompany = this.auth.onCompany();
    
    
    this.statusCompany.subscribe(status => {
      if (status) {
        this.statusSocket.subscribe(resp => {
          if (resp) {
            this.getChatsCompany();
          }
        });
      }
    });
  }


  getChatsCompany() {
    if (this.statusgetChat) {
      this.statusgetChat = false;
      this.auth.socket.emit('get_chat_company', {}, (cb) => {
        this.statusgetChat = true;
        if (cb.success) {
          if (cb.chats.length) {
            if (!this.chat_company.chat_select) {
              this.chat_company.chat_select = new ChatControl(cb.chats[0], this.auth.socket);
            }
            cb.chats.forEach((elemet: Chat) => {
                if (elemet.chat_type_id === 'user_customer') {
                  this.chat_company.chat = new ChatControl(elemet, this.auth.socket);
                }
            });
          }
        }
      });
    }
  }

  get User () {
    return this.auth.user;
  }

  get chatActive() {
    if (!this.auth.isCompany()) {
      return false;
    } else {
      return this.auth.statusSocket;
    }
  }

  get companyActive() {
    return this.auth.isCompany();
  }

  openChatCompany() {
    const initialState = {
      parent: this
    };
    this.bsModalRef = this.modalService.show(ChatClientComponent, {initialState});
    this.bsModalRef.setClass('modal-lg');
  }

  openSelectCOmpany(){
    const initialState = {
      modal: this.closeModalCOmpany
    };
    this.bsModalRefS = this.modalService.show(SelectCompanyModalComponent, {initialState});
  }
//menu movil
  openMenuMovil(){
    const initialState = {
      modalMenu: this.closeModalMenu
    };
    this.bsModalRefS = this.modalService.show(MenuMovilModalComponent, {initialState});
  }
  closeModalMenu(){
    this.bsModalRef.hide();
  }
  

  

  closeModalCOmpany() {
    this.bsModalRefS.hide();
  }

  changeMenushow() {
    this.showmenuuser = !this.showmenuuser;
  }

  logout() {
    this.auth.logout(false);
  }

}


