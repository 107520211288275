import { Component, OnInit } from '@angular/core';
import { StationConfigService } from '../services/station-config.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorsStationConfigService } from '../services/errors-station-config.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { numValidators } from '../setting/validators';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalSetprinterComponent } from '../modal-setprinter/modal-setprinter.component';

@Component({
  selector: 'app-station-config',
  templateUrl: './station-config.component.html',
  styleUrls: ['./station-config.component.css']
})
export class StationConfigComponent implements OnInit {

  formStation: FormGroup;

  /* Variables para el habemusDisabled */
  ismone = true;
  isfact = true;
  isdevo = true;
  iscred = true;
  isdebi = true;
  ispedi = true;
  isctrl = true;
  istpri = true;
  isstation = true;
  /* Variables para el habemusDisabled */

  /* Variables para el habemusRequired */
  isdocu_r = true;
  isesta_r = null;
  ismone_r = null;
  isctrl_r = null;
  istpri_r = null;
  /* Variables para el habemusRequired */

  ctrlGeneral = true;

  modalPrint: BsModalRef;

  return = '';
  retornar: boolean | string = false;
  title = 'Bonaventure :. Configuracion de Estacion';

  station_url: string;
  r_station = false;


  constructor(
    public StationSrvcs: StationConfigService,
    public Errors: ErrorsStationConfigService,
    public auth: AuthService,
    private listUrl: UrListService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService
  ) {
    // this.titleService.setTitle(this.title);
    this.station_url = `/cliente/adm/catalogo/station?return=${this.router.url}`;
    this.route.queryParams.subscribe(params => {
      this.return = params.return || '';
      this.r_station = params.station || false;
    });
    this.formStation = new FormGroup({
      n_control: new FormControl(this.StationSrvcs.n_control, [
        Validators.required
      ]),
      station: new FormControl(this.StationSrvcs.station, []),
      n_factu: new FormControl(this.StationSrvcs.n_factu, [
        Validators.min(0),
        Validators.max(999999999), // 9 digitos
        // numValidators
      ]),
      serie_factu: new FormControl(this.StationSrvcs.serie_factu, [
        Validators.maxLength(1)
      ]),
      ctrl_factu: new FormControl(this.StationSrvcs.ctrl_factu, [
        Validators.min(0),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      n_devol: new FormControl(this.StationSrvcs.n_devol, [
        Validators.min(0),
        Validators.max(999999999), // 9 digitos
        // numValidators
      ]),
      serie_devol: new FormControl(this.StationSrvcs.serie_devol, [
        Validators.maxLength(1)
      ]),
      ctrl_devol: new FormControl(this.StationSrvcs.ctrl_devol, [
        Validators.min(0),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      n_cred: new FormControl(this.StationSrvcs.n_cred, [
        Validators.min(0),
        Validators.max(999999999), // 9 digitos
        // numValidators
      ]),
      serie_cred: new FormControl(this.StationSrvcs.serie_cred, [
        Validators.maxLength(1)
      ]),
      ctrl_cred: new FormControl(this.StationSrvcs.ctrl_cred, [
        Validators.min(0),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      n_debit: new FormControl(this.StationSrvcs.n_debit, [
        Validators.min(0),
        Validators.max(999999999), // 9 digitos
        // numValidators
      ]),
      serie_debit: new FormControl(this.StationSrvcs.serie_debit, [
        Validators.maxLength(1)
      ]),
      ctrl_debit: new FormControl(this.StationSrvcs.ctrl_debit, [
        Validators.min(0),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      n_pedido: new FormControl(this.StationSrvcs.n_pedido, [
        Validators.min(0),
        Validators.max(999999999), // 9 digitos
        // numValidators
      ]),
      serie_pedido: new FormControl(this.StationSrvcs.serie_pedido, [
        Validators.maxLength(1)
      ]),
      depart: new FormControl(this.StationSrvcs.depart, []),
      toma_pedidos: new FormControl(this.StationSrvcs.toma_pedidos),
      factu_pedidos: new FormControl(this.StationSrvcs.factu_pedidos),
      select_deposi: new FormControl(this.StationSrvcs.select_deposi),
      a_factu_sby: new FormControl(this.StationSrvcs.a_factu_sby),
      des_key_manual: new FormControl(this.StationSrvcs.des_key_manual),
      vari_rutas: new FormControl(this.StationSrvcs.vari_rutas),
      print_sby: new FormControl(this.StationSrvcs.print_sby),
      user_sby: new FormControl(this.StationSrvcs.user_sby ),

      selec_type_price: new FormControl(this.StationSrvcs.selec_type_price),
      price_base: new FormControl(this.StationSrvcs.price_base),
      price_a: new FormControl(this.StationSrvcs.price_a),
      price_b: new FormControl(this.StationSrvcs.price_b),
      price_c: new FormControl(this.StationSrvcs.price_c),
      price_d: new FormControl(this.StationSrvcs.price_d),
      price_e: new FormControl(this.StationSrvcs.price_e),
      factu_negativo: new FormControl(this.StationSrvcs.factu_negativo),
      noimagen: new FormControl(this.StationSrvcs.noimagen),
      select_mo_extra: new FormControl(this.StationSrvcs.select_mo_extra),
      moneda: new FormControl(this.StationSrvcs.moneda),
    });
    this.StationSrvcs.selectStations = [];
  }

  get n_control() { return this.formStation.get('n_control'); }
  get station() { return this.formStation.get('station'); }
  get n_factu() { return this.formStation.get('n_factu'); }
  get serie_factu() { return this.formStation.get('serie_factu'); }
  get ctrl_factu() {return this.formStation.get('ctrl_factu'); }
  get n_devol() {return this.formStation.get('n_devol'); }
  get serie_devol() {return this.formStation.get('serie_devol'); }
  get ctrl_devol() {return this.formStation.get('ctrl_devol'); }
  get n_cred() {return this.formStation.get('n_cred'); }
  get serie_cred() {return this.formStation.get('serie_cred'); }
  get ctrl_cred() {return this.formStation.get('ctrl_cred'); }
  get n_debit() {return this.formStation.get('n_debit'); }
  get serie_debit() {return this.formStation.get('serie_debit'); }
  get ctrl_debit() {return this.formStation.get('ctrl_debit'); }
  get n_pedido() {return this.formStation.get('n_pedido'); }
  get serie_pedido() {return this.formStation.get('serie_pedido'); }
  get depart() {return this.formStation.get('depart'); }
  get toma_pedidos() {return this.formStation.get('toma_pedidos'); }
  get factu_pedidos() {return this.formStation.get('factu_pedidos'); }
  get select_deposi() {return this.formStation.get('select_deposi'); }
  get a_factu_sby() {return this.formStation.get('a_factu_sby'); }
  get des_key_manual() {return this.formStation.get('des_key_manual'); }
  get vari_rutas() {return this.formStation.get('vari_rutas'); }
  get print_sby() {return this.formStation.get('print_sby'); }
  get user_sby() {return this.formStation.get('user_sby'); }

  get selec_type_price() {return this.formStation.get('selec_type_price'); }
  get price_base() {return this.formStation.get('price_base'); }
  get price_a() {return this.formStation.get('price_a'); }
  get price_b() {return this.formStation.get('price_b'); }
  get price_c() {return this.formStation.get('price_c'); }
  get price_d() {return this.formStation.get('price_d'); }
  get price_e() {return this.formStation.get('price_e'); }
  get factu_negativo() {return this.formStation.get('factu_negativo'); }
  get noimagen() {return this.formStation.get('noimagen'); }
  get select_mo_extra() {return this.formStation.get('select_mo_extra'); }
  get moneda() {return this.formStation.get('moneda'); }

  // this.listUrl.url('select_currencies');
  ngOnInit() {
    this.loadInitStation();
  }

  resetStationdata() {
    // Restaura los campos a su estado original al cambiar de estacion
    this.ismone = true;
    this.isdevo = true;
    this.iscred = true;
    this.isdebi = true;
    this.ispedi = true;
    this.istpri = true;
    this.isdocu_r = true;
    this.isesta_r = null;
    this.ismone_r = null;
    this.isctrl_r = null;
    this.istpri_r = null;

    this.StationSrvcs.n_factu = null;
    this.StationSrvcs.serie_factu = null;
    this.StationSrvcs.ctrl_factu = 0;

    this.StationSrvcs.n_devol = null;
    this.StationSrvcs.serie_devol = null;
    this.StationSrvcs.ctrl_devol = 0;

    this.StationSrvcs.n_cred = null;
    this.StationSrvcs.serie_cred = null;
    this.StationSrvcs.ctrl_cred = 0;

    this.StationSrvcs.n_debit = null;
    this.StationSrvcs.serie_debit = null;
    this.StationSrvcs.ctrl_debit = 0;

    this.StationSrvcs.n_pedido = null;
    this.StationSrvcs.serie_pedido = null;

    this.StationSrvcs.i_fiscal = false;
    this.StationSrvcs.i_serial = null;
    this.StationSrvcs.n_corte = null;

    this.StationSrvcs.depart = '';

    this.StationSrvcs.toma_pedidos = false;
    this.StationSrvcs.factu_pedidos = false;
    this.StationSrvcs.select_deposi = false;
    this.StationSrvcs.a_factu_sby = false;
    this.StationSrvcs.des_key_manual = false;
    this.StationSrvcs.vari_rutas = false;
    this.StationSrvcs.print_sby = false;
    this.StationSrvcs.user_sby = false;

    this.StationSrvcs.selec_type_price = false;
    this.StationSrvcs.price_base = false;
    this.StationSrvcs.price_a = false;
    this.StationSrvcs.price_b = false;
    this.StationSrvcs.price_c = false;
    this.StationSrvcs.price_d = false;
    this.StationSrvcs.price_e = false;

    this.StationSrvcs.factu_negativo = false;
    this.StationSrvcs.noimagen = false;
    this.StationSrvcs.select_mo_extra = false;

    this.StationSrvcs.moneda = '';
  }

  setCheckboxSelected(type) {
    if(type == 'crtlest') {
      this.ctrlGeneral = false;
    } else if(type == 'crtlgen'){
      this.ctrlGeneral = true;
    }
  }

  loadInitStation() {
    this.StationSrvcs.loadData = true;
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('views_config_station')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp['retornar'] === 'shop') {
        this.router.navigateByUrl(`/cliente/adm/catalogo/productos/departamento?return=${this.router.url}`);
      } else {
        this.StationSrvcs.station_base = resp['stations'];
        this.StationSrvcs.station_g = resp['station_g'];
        this.StationSrvcs.selectStations = this.StationSrvcs.station_g;
        this.StationSrvcs.station = '00';
        this.StationSrvcs.selectMoneda = resp['currencies'];
        this.StationSrvcs.selectDepart = resp['shops'];
        this.load_station();
        this.habemusDisable(resp['stations'], 'station');
      }
      this.StationSrvcs.loadData = null;
    }).catch(error => {
      return false;
    });
  }

  select_station(event) {
    this.StationSrvcs.station_selected = event.target.value;
  }

  load_station() {
    // Al seleccionar estacion, cargar los datos.
    let cargo = true;
    this.resetStationdata();
    if (this.r_station) {
      const ob1 = this.StationSrvcs.station_base;
      const ob2 = this.StationSrvcs.station_g;
      if (this.load_r_station(ob1)) {
        if (this.load_r_station(ob2)) {
          this.router.navigateByUrl(this.return);
        }
      }
    } else {
      const ob = this.StationSrvcs.selectStation;
      for (const k in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, k)) {
          const va = ob[k];
          if (va.code == this.StationSrvcs.station && va.id && va.objs && cargo) {
            cargo = false;
            for (const key in va.objs) {
              if (Object.prototype.hasOwnProperty.call(va.objs, key)) {
                if (  key == 'n_cred' || key == 'n_debit' || key == 'n_devol' || key == 'n_factu' || key == 'n_pedido') {
                  this.StationSrvcs[key] = parseInt(va.objs[key])+1;
                } else {
                  this.StationSrvcs[key] = va.objs[key];
                }
                this.habemusDisable(va.objs[key], key);
              }
            }
          }
        }
      }
    }
    this.habemusDisable(this.StationSrvcs.selec_type_price, 'selec_type_price');
  }

  load_r_station(obj) {
    let cargo = true;
    for (const k in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        const va = obj[k];
        if (va.id == this.r_station && va.id && va.objs && cargo) {
          cargo = false;
          this.StationSrvcs.selectStations = obj;
          this.StationSrvcs.station = va.code;
          for (const key in va.objs) {
            if (Object.prototype.hasOwnProperty.call(va.objs, key)) {
              if (  key == 'n_cred' || key == 'n_debit' || key == 'n_devol' || key == 'n_factu' || key == 'n_pedido') {
                this.StationSrvcs[key] = parseInt(va.objs[key])+1;
              } else {
                this.StationSrvcs[key] = va.objs[key];
              }
              this.habemusDisable(va.objs[key], key);
            }
          }
        }
      }
    }
    return cargo;
  }

  formatUpper(elem, error) {
    elem.setValue(elem.value.trim().toUpperCase());
    // if (elem &&( elem.value !== null || elem.value != undefined)){
    //   const regex = new RegExp('^[^+-]*$');
    //   if (!regex.test(elem.value)) {
    //     elem.setValue(elem.value.trim().toUpperCase());
    //       return {
    //           notFormat: true
    //       };
    //   }
    // }
  }

  habemusDisable(val, key) {
    // Comprueba que un valor sea valido para habilitar las opciones dependientes.
    if (key == 'moneda') {
      this.ismone = val ? null : true;
      this.habemusRequired(val, 'ismone_r');
    }
    if (key == 'n_factu') {
      this.isfact = val ? null : true;
      this.habemusRequired(val, 'isdocu_r');
    }
    if (key == 'n_devol') {
      this.isdevo = val ? null : true;
      this.habemusRequired(val, 'isdocu_r');
    }
    if (key == 'n_cred') {
      this.iscred = val ? null : true;
      this.habemusRequired(val, 'isdocu_r');
    }
    if (key == 'n_debit') {
      this.isdebi = val ? null : true;
      this.habemusRequired(val, 'isdocu_r');
    }
    if (key == 'n_pedido') {
      this.ispedi = val ? null : true;
      this.habemusRequired(val, 'isdocu_r');
    }
    if (key == 'n_control') {
      this.isctrl = val ? null : true;
      if (val) {
        this.StationSrvcs.selectStations = this.StationSrvcs.station_base;
        this.StationSrvcs.station = null;
      } else {
        this.StationSrvcs.selectStations = this.StationSrvcs.station_g;
        this.StationSrvcs.station = '00';
      }
      this.load_station();
      this.habemusRequired(val, 'isctrl_r');
    }
    if (key == 'selec_type_price') {
      this.istpri = val ? null : true;
      this.habemusRequired(this.istpri, 'istpri_r');
    }
    if (key == 'station') {
      if (val.length > 1) {
        this.isstation = null;
      } else {
        this.isstation = true;
      }
    }
  }

  habemusRequired(val, key) {
    // Comprueba que se cumplan ciertos parametros para habilitar el requerido en campos especificos.
    if (key == 'isdocu_r') {
      if (this.StationSrvcs.n_factu && this.StationSrvcs.n_pedido){
        this.isdocu_r = null;
      } else {
        this.isdocu_r = true;
      }
    }
    if (key == 'ismone_r') {
      this.ismone_r = val ? true : null;
    }
    if (key == 'isctrl_r') {
      this.isctrl_r = val ? true : null;
    }
    if (key == 'istpri_r') {
      if (val || this.StationSrvcs.price_base || this.StationSrvcs.price_a || this.StationSrvcs.price_b || this.StationSrvcs.price_c || this.StationSrvcs.price_d || this.StationSrvcs.price_e){
        this.istpri_r = null;
        this.Errors.error_selec_type_price = false;
        this.Errors.txt_selec_type_price = '';
      } else {
        this.istpri_r = true;
      }
    }
  }

  saveObject(values) {
    if (this.formStation.valid && this.StationSrvcs.station) {
      if (this.Errors.error_list) {
        this.Errors.error_list.forEach((v, k) => {
          this.Errors['error_' + v] = false;
          this.Errors['txt_' + v] = '';
        });
      }
      this.Errors.error_list = [];
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('saveconfig_station'),
        auth: this.auth,
        body: {
          n_control: values.n_control,
          station: this.StationSrvcs.station,
          n_factu: values.n_factu,
          serie_factu: values.serie_factu,
          ctrl_factu: values.ctrl_factu,
          n_devol: values.n_devol,
          serie_devol: values.serie_devol,
          ctrl_devol: values.ctrl_devol,
          n_cred: values.n_cred,
          serie_cred: values.serie_cred,
          ctrl_cred: values.ctrl_cred,
          n_debit: values.n_debit,
          serie_debit: values.serie_debit,
          ctrl_debit: values.ctrl_debit,
          n_pedido: values.n_pedido,
          serie_pedido: values.serie_pedido,
          i_fiscal: this.StationSrvcs.i_fiscal, // Aqui impresora fiscal
          i_serial: this.StationSrvcs.i_serial,
          n_corte: this.StationSrvcs.n_corte,
          depart: values.depart,
          toma_pedidos: values.toma_pedidos,
          factu_pedidos: values.factu_pedidos,
          select_deposi: values.select_deposi,
          a_factu_sby: values.a_factu_sby,
          des_key_manual: values.des_key_manual,
          vari_rutas: values.vari_rutas,
          print_sby: values.print_sby,
          user_sby: values.user_sby,
          selec_type_price: values.selec_type_price,
          price_base: values.price_base,
          price_a: values.price_base ? values.price_a : false,
          price_b: values.price_base ? values.price_b : false,
          price_c: values.price_base ? values.price_c : false,
          price_d: values.price_base ? values.price_d : false,
          price_e: values.price_base ? values.price_e : false,
          factu_negativo: values.factu_negativo,
          noimagen: values.noimagen,
          select_mo_extra: values.select_mo_extra,
          moneda: values.moneda
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: 'Configurando Estación',
        titleSuccess: 'Estacion guardada correctamente',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax ) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Campos inválidos',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp.resp);
            } else {
              success(resp.objs);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        this.StationSrvcs.station_base = resp.stations;
        this.StationSrvcs.station_g = resp.station_g;
        if (this.StationSrvcs.station == '00') {
          this.StationSrvcs.selectStations = this.StationSrvcs.station_g;
        } else {
          this.StationSrvcs.selectStations = this.StationSrvcs.station_base;
        }
        this.StationSrvcs.selectMoneda = resp.currencies;
        this.StationSrvcs.selectDepart = resp.shops;
        this.load_station();
      }).catch(error => {
        this.viewError(error);
      });
    }
  }

  viewError(error) {
    // Cargar los campos que tienen errores y su mensaje de error.
    this.Errors.error_list = [];
    for (const k in error) {
      if (Object.prototype.hasOwnProperty.call(error, k)) {
        const va = error[k];
        if (!va.valid && va.valid != undefined) {
          this.Errors.error_list.push(k);
          this.Errors['error_' + k] = true;
          this.Errors['txt_' + k] = va.error;
        }
      }
    }
  }

  openModalSetPrinter() {
    if(!this.ctrlGeneral){
      if(this.StationSrvcs.station_selected === "") {
        Swal.fire({
          icon: 'warning',
          title: 'Opción inhabilitada...',
          text: 'Por favor, Seleccione una estación para poder continuar...'
        });
      } else {
        this.modalPrint = this.modalService.show(ModalSetprinterComponent);
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Opción inhabilitada...',
        text: 'Por favor, Seleccione Número control por estación para activar esta opción...'
      });
    }
    // this.modalPrint.content.printer.subscribe(resp => {
    //
    // });
    // this.modalPrint.setClass('modal-dialog-scrollable modal-dialog-centered modal-xl');
  }

}
