import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { ChangeReturnableComponent } from './../change-returnable/change-returnable.component';

@Component({
  selector: 'app-returnable',
  templateUrl: './returnable.component.html',
  styleUrls: ['./returnable.component.css']
})
export class ReturnableComponent implements OnInit {

  titleMod: string;
  tableProducts = [];
  events: Subscription[] = [];
  modalRef: BsModalRef;

  constructor(
    public ViewService: EntriesExitsService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.ViewService.nroTypeTrans == 0) { 
      this.titleMod = 'Productos Retornables en Compra';
      this.tableProducts = this.ViewService.prodTableCompra;
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.titleMod = 'Productos Retornables en Pedido';
      this.tableProducts = this.ViewService.prodTablePedido;
    }
  }

  getId(){
    return 'ADMINIU00009';
  }

  closeModal() {
    this.ViewService.closeModalViewReturn.hide();
  }

  aggReturn(id) {
    this.ViewService.pkReturn = id;
    const _combine = combineLatest(
      this.modalService.onHide
    ).subscribe(() => this.changeDetection.markForCheck());
    this.events.push(
      this.modalService.onHide.subscribe(() => {
        this.ViewService.pkReturn = null;
        this.unsubscribe();
      })
    );
    this.events.push(_combine);
    this.modalRef = this.modalService.show(ChangeReturnableComponent);
    this.modalRef.setClass('modal-dialog-centered');
    this.ViewService.closeModaAggReturn = this.modalRef;
  }

  unsubscribe() {
    this.events.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.events = [];
  }

}
