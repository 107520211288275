import { Component, OnInit } from '@angular/core';
import { SupplierService } from '../services/supplier.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalProveedorComponent } from '../modal-proveedor/modal-proveedor.component';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewLoader } from 'src/app/core/viewLoader/viewLoader';
import { Supplier } from '../services/suppliers';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';
import { ConflictsDeletingComponent } from 'src/app/core/conflicts-deleting/conflicts-deleting.component';

@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.css']
})
export class ProveedorComponent extends ViewLoader implements OnInit {
  placeholderSearch: string = 'Proveedores';
  titleAddModal: string = 'Agregar Proveedor';
  modalRef: BsModalRef;
  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar',
    cancelButtonColor: '#c82333'
  });
  confirmarResolv = Swal.mixin({
    icon: 'info',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Solucionar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar',
    cancelButtonColor: '#c82333'
  });
  title = 'Bonaventure :. Lista de Proveedores';
  searchUrl: boolean | string = false;
  return = '';
  resp_field = 'objects';
  fields_search = ['code', 'name', 'nit', 'email'];
  field_norepeat = (resp: Supplier) => {
		return resp.id;
	};
  seguir = true;
  not_item = "No hay Proveedores registradas actualmente."
  not_search = "No se encontraron resultados de proveedores para tu busqueda."

  constructor(
    public SupplierService: SupplierService, 
    public ResolvingService: ResolvingService,
    public auth: AuthService, 
    private listUrl: UrListService, 
    private route: ActivatedRoute, 
    private modalService: BsModalService, 
    private titleService: Title
  ){
    super();
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(params => this.return = params.return || '');
  }

  ngOnInit(){
    this.loadingCountry();
    this.of = Number(0);
    this.to = Number(500);
    this.numItem = Number(500);
    this.msg_init = this.not_item;
    this.msg_search = this.not_search;
    this.msg_show = this.msg_init;
    this.initLoader = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('views_suppliers')
    };
    this.configLoader = {visible: false, autoReNew: true};
    this.initScrolin = Object.assign({}, this.initLoader);
    this.initSearch = Object.assign({}, this.initLoader);
    this.configSearch = {titleSuccess: '', visible: true};
		this.confiScrolin = Object.assign({}, this.configLoader);
    this.load_items();
    setInterval(() => {
      if (this.SupplierService.create != this.create) {
        this.load_options();
      }
    }, 1000);
  }

  loadingCountry(){
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('list_modal_country')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.SupplierService.SelectCountry = resp.objects;
      }
    });
  }

  openModalAdd(newSupp=false, pkSupp=''){
    if (newSupp == true) {
      this.SupplierService.codeId = 'ADMCAIU00038';
    } else {
      this.SupplierService.codeId = 'ADMCAIU00039';
    }
    this.SupplierService.new = newSupp;
    this.SupplierService.pk = pkSupp;
    if (this.SupplierService.pk) {
      for (const key in this.item_show) {
        if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
          const element = this.item_show[key];
          if (element.id == this.SupplierService.pk) {
            this.SupplierService.object = element;
            break;
          }
        }
      }
    }
    this.modalRef = this.modalService.show(ModalProveedorComponent);
    this.SupplierService.evenAdd.subscribe(resp => {
			if (this.SupplierService.continuar) {
        this.objCall(resp, (call) => {
          this.SupplierService.continuar = call;
        })
      }
		});
    this.modalRef.setClass('modal-dialog-centered modal-xl mod_tab');
    this.SupplierService.closeModal = this.modalRef;
  }

  objCall(resp, callback: any = false) {
    if (resp.new) {
      if (resp.tipo == 'catalogo') {
        this.newObject(resp);
      }
    } else {
      this.editObject(resp);
    }
    callback(false);
  }

  newObject(resp) {
    this.item_base.unshift(resp.objeto);
    this.item_show.unshift(resp.objeto);
    this.SupplierService.habilitaBtn = true;
  }

  editObject(resp) {
    for (const key in this.item_show) {
      if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
        const element = this.item_show[key];
        if (element.id == resp.objeto.id) {
          this.item_show[key] = resp.objeto;
          break;
        }
      }
    }
    for (const key in this.item_base) {
      if (Object.prototype.hasOwnProperty.call(this.item_base, key)) {
        const element = this.item_base[key];
        if (element.id == resp.objeto.id) {
          this.item_base[key] = resp.objeto;
          break;
        }
      }
    }
    for (const key in this.item_search) {
      if (Object.prototype.hasOwnProperty.call(this.item_search, key)) {
        const element = this.item_search[key];
        if (element.id == resp.objeto.id) {
          this.item_search[key] = resp.objeto;
          break;
        }
      }
    }
  }

  getId() {
    return 'ADMCAIU00037';
  }

  deleteSupplier(pk, code){
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.removeSupplier(pk);
      });
    }, 'Desea Eliminar?',
    'Realmente quiere eliminar el Proveedor: '+code);
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  removeSupplier(pk) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk
      },
      url: this.listUrl.url('delete_supplier')
    };
    const configAjax: Config = {
      titleLoading: 'Eliminando Proveedor...',
      titleSuccess: '',
      textErrorNormal: 'No se logro eliminar el Proveedor',
      titleErrorNormal: 'Error al Eliminar Proveedor',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if(resp.success){
        for (const key in this.item_base) {
          if (Object.prototype.hasOwnProperty.call(this.item_base, key)) {
            const element = this.item_base[key];
            if (element.id == resp.pk) {
              this.item_base.splice(Number(key), 1);
              break;
            }
          }
        }
        for (const key in this.item_show) {
          if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
            const element = this.item_show[key];
            if (element.id == resp.pk) {
              this.item_show.splice(Number(key), 1);
              break;
            }
          }
        }
        for (const key in this.item_search) {
          if (Object.prototype.hasOwnProperty.call(this.item_search, key)) {
            const element = this.item_search[key];
            if (element.id == resp.pk) {
              this.item_search.splice(Number(key), 1);
              break;
            }
          }
        }
        if(this.item_base.length === 0 && this.SupplierService.habilitaBtn){
          this.SupplierService.habilitaBtn = false;
        }
      }
    }).catch(error => {
      if (typeof error['in_use'] != 'undefined') {
        this.confirmResolving(error)
      }
    });
  }

  confirmResolving(error) {
    this.confirResolv(() => {
      this.close_confirmar().then(() => {
        this.ResolvingService.urlDelete = this.listUrl.url('delete_supplier');
        this.ResolvingService.urlResolving = this.listUrl.url('resolving_conflicts_supplier');
        this.ResolvingService.pk = error.pk;
        this.ResolvingService.code = error.code;
        this.ResolvingService.valuesObjects = error.objects;
        this.ResolvingService.valuesList = error.list;
        this.ResolvingService.title = error.message;
        this.ResolvingService.itemResolving = error.item;
        this.ResolvingService.noteResolving = error.note;
        if (typeof error.prohibited !== 'undefined') {
          this.ResolvingService.prohibited = error.prohibited;
        }
        this.ResolvingService.tipo = 'Proveedor';
        this.ResolvingService.viewModal = ModalProveedorComponent;
        this.ResolvingService.strConecDelete = 'el Proveedor';
        this.ResolvingService.component = 'Modal-Supplier';
        this.openModalConflicts();
      });
    }, 'Esta en uso!',
    error['message']);
  }

  confirResolv(action: () => void, title: string, templa: string) {
    this.confirmarResolv.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  openModalConflicts(){
    const initialState = {
      componentParent: this
    };
    this.modalRef = this.modalService.show(ConflictsDeletingComponent, {initialState});
    this.ResolvingService.evenAdd.subscribe(resp => {
      if (typeof resp.continue !== 'undefined' && this.ResolvingService.is_continue) {
        this.ResolvingService.is_continue = false;
        this.removeSupplier(this.ResolvingService.pk);
      }
      if (typeof resp.new !== 'undefined' && this.ResolvingService.seguir) {
        this.insertNewItems(resp.new, (resp) => {
          if (!resp) {
            this.ResolvingService.seguir = resp;
          }
        });
      }
		});
    this.modalRef.setClass('modal-dialog-centered modal-xl mod-conflicts');
    this.ResolvingService.closeModal = this.modalRef;
  }

  insertNewItems(nuevo, callback: any = false) {
    this.item_base.unshift(nuevo);
    this.item_show.unshift(nuevo);
    this.SupplierService.habilitaBtn = true;
    callback(false);
  }

  collapses(id): void {
    for (const i in this.item_show) {
      if (this.item_show.hasOwnProperty(i)) {
        const element = this.item_show[i];
        if (element.id == id){
          element.viewCollapsed = 'view_cir';
          element.marbtn = '';
          element.rowimpo = '';
          break;
        }
      }
    }
  }
 
  expands(id): void {
    for (const key in this.item_show) {
      if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
        const element = this.item_show[key];
        if (element.id == id){
          element.viewCollapsed = 'rotate-90';
          element.marbtn = 'pddbttn0';
          element.rowimpo = 'dsimpor';
          break;
        }
      }
    }
  }

  editSuppl(pk, country, state, city){
    this.SupplierService.country = country;
    this.SupplierService.state = state;
    this.SupplierService.city = String(city);
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('list_modal_geo'),
      body: {
        country: country,
        state: state
      }
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.SupplierService.SelectState = resp.states;
        this.SupplierService.SelectCity = resp.cities;
        this.SupplierService.pk = pk;
        this.SupplierService.new = false;
        this.openModalAdd(this.SupplierService.new, pk);
      }
    });
  }

  load_options() {
    if (this.return) {
      this.searchUrl = this.return;
    }
    if (this.item_show.length > 0) {
      this.SupplierService.habilitaBtn = true;
    }
    if (this.create && this.searchUrl) {
      this.SupplierService.ambos = true;
      this.SupplierService.create = false;
      this.SupplierService.regresa = false;
      this.SupplierService.urlreturn = this.searchUrl;
    } else {
      if (this.create) {
        this.SupplierService.create = this.create;
      }
      if (this.regresa) {
        this.SupplierService.regresa = this.regresa;
        this.SupplierService.urlreturn = this.searchUrl;
      }
    }
  }

  onscroll(event: any) {
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
			this.load_more_item();
		}
	}

}
