import { Component, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/core/auth/auth.service';
import { City, State, Country, Profession } from 'src/app/core/auth/user';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { ListUrl } from 'src/app/core/constantes/request';
import { Init } from 'src/app/core/ajax/init-ajax';
import { HttpParams } from '@angular/common/http';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { UrlBase } from 'src/app/core/ajax/url';


export interface SignupFm extends Object {
  // Interface creada para el formulario y sus campos
  first_name?: any;
  last_name?: any;
  dni?: any;
  telephone?: any;
  email?: any;
  profession_select?: Profession;
  city_select?: City;
  state_select?: State;
  country_select?: Country;
  profession?: any;
  city?: any;
  state?: any;
  country?: any;
  post_code?: any;
  username?: any;
  password?: any;
  repeat_password?: any;
}


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  title = 'Signup';
  signupForm: FormGroup;
  // Objeto con los campos que se envian al servidor
  enviaForm: SignupFm = {
    first_name: null,
    last_name: null,
    dni: null,
    telephone: null,
    email: null,
    profession_select: {
      profession_id: '',
      name_profession: ''
    },
    city_select: {
      city_id: '',
      city_name: ''
    },
    state_select: {
      state_id: '',
      state_name: ''
    },
    country_select: {
      country_id: '',
      country_name: '',
      phone_code: '',
      short_name: ''
    },
    profession: '',
    city: '',
    state: '',
    country: '',
    post_code: null,
    username: null,
    password: null,
    repeat_password: null
  };
  // parte 1
  phone_code = '+00';
  post_code: number | null = null;
  // parte 2
  username: string | null = null;
  password: string | null = null;
  repeat_password: string | null = null;

  professions: any;
  countrys: any;
  states: any;
  citys: any;
  pr_ind: any;
  co_ind: any;
  st_ind: any;
  ci_ind: any;

  remember = false;
  return = '/signin';

  listo: string;
  part1: boolean;
  part2: boolean;
  part3: boolean;
  part = 1;
  maxl: SignupFm = {
    first_name: 30,
    last_name: 150,
    dni: 10,
    telephone: 35,
    email: 70,
    post_code: 6,
    username: 20,
    password: 20
  };
  minl: SignupFm = {
    username: 8,
    password: 8
  };
  patt: object;

  erresp: SignupFm = {
    first_name: null,
    last_name: null,
    dni: null,
    telephone: null,
    email: null,
    profession: null,
    city: null,
    state: null,
    country: null,
    post_code: null,
    username: null,
    password: null,
    repeat_password: null
  };

  errorpart1 = false;
  errorpart2 = false;

  constructor(
    private titleService: Title,
    public auth: AuthService,
    private router: Router
  ) {
    this.titleService.setTitle(this.title);
    this.listo = 'Registro de Usuario';
    this.part1 = true;
    this.part2 = false;
    this.part3 = false;
    this.iniRegister();
    this.signupForm = new FormGroup({
      sf1: new FormGroup({
        first_name: new FormControl(this.enviaForm.first_name, [
          Validators.required,
          Validators.maxLength(this.maxl.first_name),
          Validators.pattern('^([A-Za-záéíóúüÁÉÍÓÚÜñÑ ]+)$')
        ]),
        last_name: new FormControl(this.enviaForm.last_name, [
          Validators.required,
          Validators.maxLength(this.maxl.last_name),
          Validators.pattern('^([A-Za-záéíóúüÁÉÍÓÚÜñÑ ]+)$')
        ]),
        dni: new FormControl(this.enviaForm.dni, [
          Validators.required,
          Validators.maxLength(this.maxl.dni),
          Validators.minLength(1),
          Validators.pattern('^([0-9]+)$')
        ]),
        telephone: new FormControl(this.enviaForm.telephone, [
          Validators.required,
          Validators.maxLength(this.maxl.telephone),
          Validators.minLength(1),
          Validators.pattern('^([0-9]+)$')
        ]),
        email: new FormControl(this.enviaForm.email, [
          Validators.required,
          Validators.email,
          Validators.maxLength(this.maxl.email)
        ]),
        profession: new FormControl(this.enviaForm.profession, Validators.required),
        city: new FormControl(this.enviaForm.city, Validators.required),
        state: new FormControl(this.enviaForm.state, Validators.required),
        country: new FormControl(this.enviaForm.country, Validators.required),
        post_code: new FormControl(this.enviaForm.post_code, [
          Validators.required,
          Validators.maxLength(this.maxl.post_code),
          Validators.pattern('^([0-9]+)$')
        ])
      }),
      sf2: new FormGroup({
        username: new FormControl(this.enviaForm.username, [
          Validators.required,
          Validators.maxLength(this.maxl.username),
          Validators.minLength(this.minl.username),
          Validators.pattern('^([A-Za-z.-_0-9]+)$')
        ]),
        password: new FormControl(this.enviaForm.password, [
          Validators.required,
          Validators.maxLength(this.maxl.password),
          Validators.minLength(this.minl.password)
        ]),
        repeat_password: new FormControl(this.enviaForm.repeat_password, [
          Validators.required,
          Validators.maxLength(this.maxl.password),
          Validators.minLength(this.minl.password)
        ])
      })
    });
    this.pr_ind = 0;
    this.co_ind = 0;
    this.st_ind = 0;
    this.ci_ind = 0;
  }

  ngOnInit() {

  }

  iniRegister() {
    // Carga los datos basicos necesarios.
    // if (this.inputSearch && this.inputSearch !== '') {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.iniregister[1]),
        auth: this.auth,
        body: ''
      };
      const configAjax: Config = {
        visible: false,
        forceNormal: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
        if (respo.success) {
          this.countrys = respo.dat.count;
          this.professions = respo.dat.prof;
        } else {
          return false;
        }
      }).catch(error => {
        return false;
      });
    // }
  }

  searchGeo(camp) {
    // Carga los datos de los "select" de la geolocalizacion
    // if (this.inputSearch && this.inputSearch !== '') {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.searchgeo[1]),
        auth: this.auth,
        body: camp
      };
      const configAjax: Config = {
        visible: false,
        forceNormal: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(response => {
        if (response.success) {
          switch (response.nam) {
            case 'country':
              this.countrys = response.values;
              break;
            case 'state':
              this.states = response.values;
              break;
            case 'city':
              this.citys = response.values;
              break;
          }
        } else {
          return false;
        }
      }).catch(error => {
        return false;
      });
    // }
  }

  // private validatePassword(control: AbstractControl) {
  //   const password = control.value;
  //   let error = null;
  //   if (!password.includes('$')) {
  //     error = { ...error, dollar: 'needs a dollar symbol' };
  //   }
  //   if (!parseFloat(password[0])) {
  //     error = { ...error, number: 'must start with a number' };
  //   }
  //   return error;
  // }

  get sf1() {
    return this.signupForm.get('sf1');
  }

  get sf2() {
    return this.signupForm.get('sf2');
  }

  gotoPart(part: number) {
    // Proceso para cambiar entre las partes 1, 2 y 3 del formulario
    // this.part = part;
    // this.partSelect(this.part);
    if (this.part == 1 && ( part > 0 && part < 4 )) {
      if ((this.part == 1 && this.signupForm.get('sf1').valid)) {
        this.erresp = {
          first_name: null,
          last_name: null,
          dni: null,
          telephone: null,
          email: null,
          profession: null,
          city: null,
          state: null,
          country: null,
          post_code: null,
        };
        this.part = part;
        this.partSelect(this.part);
        this.errorpart1 = false;
      } else {
        for (const k in this.signupForm.get('sf1').value) {
          if (this.signupForm.get('sf1').get(k)) {
            const element = this.signupForm.get('sf1').get(k).markAsTouched();
          }
        }
      }
      // Ejecutar funcion de movimiento
    } else if (this.part == 2 && ( part > 0 && part < 4 )) {
      if (this.part > part) {
        this.part = part;
        this.partSelect(this.part);
      } else if ((this.part == 2 && this.signupForm.get('sf2').valid)) {
        if (this.signupForm.get('sf2').get('password').value === this.signupForm.get('sf2').get('repeat_password').value) {
          this.erresp = {
            username: null,
            password: null,
            repeat_password: null
          };
          this.part = part;
          this.partSelect(this.part);
          this.errorpart2 = false;
        } else {
          this.erresp.password = ['Las contraseñas no coinciden'];
          this.signupForm.get('sf2').get('password').setValue('');
          this.signupForm.get('sf2').get('repeat_password').setValue('');
        }
      } else {
        for (const k in this.signupForm.get('sf2').value) {
          if (this.signupForm.get('sf2').get(k)) {
            const element = this.signupForm.get('sf2').get(k).markAsTouched();
          }
        }
      }
    } else if (this.part == 3 && ( part > 0 && part < 4 )) {
      this.part = part;
      this.partSelect(this.part);
    }
  }

  partSelect(pan: any) {
    // Establece la parte en la que se encuentra el usuario segun "pan"
    this.part1 = false;
    this.part2 = false;
    this.part3 = false;
    if (pan==1) {
      this.part1 = true;
    } else if (pan==2) {
      this.part2 = true;
    } else if (pan==3) {
      this.part3 = true;
    } else {
      this.part1 = true;
    }
  }

  changeGeo(e) {
    // Detecta cuando se elige un campo de uno de los valores del "select" (onchange) y captura su valor.
    switch (e.target.name) {
      case 'country':
        // if (!this.co_ind) {
        this.phone_code = e.target.selectedOptions[0].id;
        this.enviaForm.country = e.target.value;
        this.enviaForm.country_select.country_id = e.target.value;
        this.enviaForm.country_select.country_name = e.target.selectedOptions[0].text;
        this.co_ind = e.target.selectedIndex;
        // } else {
        //   e.target.selectedIndex = this.co_ind;
        // }
        this.citys = null;
        this.searchGeo({country: this.enviaForm.country_select.country_id});
        break;
      case 'state':
        // if (e.target.selectedOptions[0].id == '') {
        this.enviaForm.state = e.target.value;
        this.enviaForm.state_select.state_id = e.target.value;
        this.enviaForm.state_select.state_name = e.target.selectedOptions[0].text;
        this.st_ind = e.target.selectedIndex;
        // } else {
          // e.target.selectedIndex = this.st_ind;
        // }
        this.searchGeo({state: this.enviaForm.state_select.state_id});
        break;
      case 'city':
        // if (e.target.selectedOptions[0].id == '') {
        this.enviaForm.city = e.target.value;
        this.enviaForm.city_select.city_id = e.target.value;
        this.enviaForm.city_select.city_name = e.target.selectedOptions[0].text;
        this.ci_ind = e.target.selectedIndex;
        // } else {
        //   e.target.selectedIndex = this.ci_ind;
        // }
        break;
      case 'profession':
        this.enviaForm.profession = e.target.value;
        this.enviaForm.profession_select.profession_id = e.target.value;
        this.enviaForm.profession_select.name_profession = e.target.selectedOptions[0].text;
        this.pr_ind = e.target.selectedIndex;
        break;
    }
    this.signupForm.get('sf1').get(e.target.name).setValue(e.target.value);
  }

  uneForms(obj1: object, obj2: object) {
    for (const k in obj1) {
      if (obj1.hasOwnProperty(k) && this.enviaForm.hasOwnProperty(k)) {
        this.enviaForm[k] = obj1[k];
      }
    }
    for (const k in obj2) {
      if (obj2.hasOwnProperty(k) && this.enviaForm.hasOwnProperty(k)) {
        this.enviaForm[k] = obj2[k];
      }
    }
    return this.enviaForm;
  }

  validatej(resp: any): Promise<any> {
    return new Promise((success, error) => {
        if (typeof resp.success !== 'undefined' && resp.success) {
            success(resp);
        } else {
            error(resp);
        }
    });
  }

  async onSubmit() {
    if (this.signupForm.valid) {
      this.uneForms(this.sf1.value, this.sf2.value);
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.register[1]),
        auth: this.auth,
        body: this.enviaForm
      };
      const optionsalertaSuccess: SweetAlertOptions = {
        icon: 'success',
        title: 'Su cuenta ha sido creada. Por favor, verifique su email para completar su registro.',
        text: 'Hemos enviado un enlace de confirmación al email proporcionado',
        showConfirmButton: true,
        showCancelButton: false,
        showCloseButton: false,
        timer: 15000,
        preConfirm: () => {
          this.router.navigateByUrl(this.return);
        },
        onClose: () => {
          this.router.navigateByUrl(this.return);
        },
    };
      const configAjax: Config = {
        forceNormal: true,
        titleLoading: 'Validando información, por favor espere.',
        textError: 'Campos inválidos, verifique la información e intente de nuevo.',
        alertaSuccess: Swal.mixin(optionsalertaSuccess),
        validatejson: this.validatej,
        textErrorNormal: 'Campos inválidos, verifique la información e intente de nuevo.',
        alertaError: 'Campos inválidos, verifique la información e intente de nuevo.'
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(rs => {
        this.router.navigateByUrl(this.return);
      }).catch(err => {
        let pase = true;
        let pase_n = null;
        for (const k in err.error) {
          if (err.error.hasOwnProperty(k) && err.error[k] !== '') {
            this.erresp[k] = err.error[k];
            if (k.toString() === 'username' || k.toString() === 'password' || k.toString() === 'repeat_password') {
              if (k.toString() === 'password') {
                this.signupForm.get('sf2').get('repeat_password').setValue('');
              }
              this.signupForm.get('sf2').get(k).setValue('');
              pase_n = pase ? 2 : 1;
              this.errorpart2 = true;
            } else {
              this.signupForm.get('sf1').get(k).setValue('');
              pase_n = 1;
              pase = false;
              this.errorpart1 = true;
            }

          }
        }
        this.gotoPart(pase_n);
        return false;
      });
    }
  }

  getId() {
    return 'BSSACIU00002';
  }
}
