import { Component, OnInit, ElementRef } from '@angular/core';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { FormGroup } from '@angular/forms';
import { AddOtherTaxCoService } from '../../../services/forms/purchase/add-other-tax-co.service';
import { AddOtherTaxPeService } from '../../../services/forms/order/add-other-tax-pe.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewDetailProductComponent } from '../view-detail-product/view-detail-product.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-other-expenses',
  templateUrl: './other-expenses.component.html',
  styleUrls: ['./other-expenses.component.css']
})
export class OtherExpensesComponent implements OnInit {

  formOtherTaxesCo: FormGroup; //Formulario otros impuestos Compra
  formOtherTaxesPe: FormGroup; //Formulario otros impuestos Pedido
  modalRef: BsModalRef;
  titleMod: string;
  Compra = false;
  Pedido = false;
  tableOtherTaxes = [];
  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar'
  });

  constructor(
    public ViewService: EntriesExitsService,
    public formOTCo: AddOtherTaxCoService,
    public formOTPe: AddOtherTaxPeService,
    private modalService: BsModalService,
    private elmRef: ElementRef
  ) { }

  ngOnInit() {
    if (this.ViewService.nroTypeTrans == 0) { 
      this.Compra = true;
      this.titleMod = 'Otros Gastos en Compra';
      this.formOtherTaxesCo = this.formOTCo.formOtherTaxesCo;
      this.formOtherTaxesCo.reset();
      this.tableOtherTaxes = this.ViewService.OtherTaxCo;
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.Pedido = true;
      this.titleMod = 'Otros Gastos en Pedido';
      this.formOtherTaxesPe = this.formOTPe.formOtherTaxesPe;
      this.formOtherTaxesPe.reset();
      this.tableOtherTaxes = this.ViewService.OtherTaxPe;
    }
  }

  // Campos de Formulario de agregar Otro Impuesto a Compra //
  get codeOtherTaxCo() { return this.formOtherTaxesCo.get('codeOtherTaxCo'); }
  get descOtherTaxCo() { return this.formOtherTaxesCo.get('descOtherTaxCo'); }
  get montOtherTaxCo() { return this.formOtherTaxesCo.get('montOtherTaxCo'); }
  get taxOtherTaxCo() { return this.formOtherTaxesCo.get('taxOtherTaxCo'); }
  // FIN Campos de Formulario de agregar Otro Impuesto a Compra //
  // Campos de Formulario de agregar Otro Impuesto a Pedido //
  get codeOtherTaxPe() { return this.formOtherTaxesPe.get('codeOtherTaxPe'); }
  get descOtherTaxPe() { return this.formOtherTaxesPe.get('descOtherTaxPe'); }
  get montOtherTaxPe() { return this.formOtherTaxesPe.get('montOtherTaxPe'); }
  get taxOtherTaxPe() { return this.formOtherTaxesPe.get('taxOtherTaxPe'); }
  // FIN Campos de Formulario de agregar Otro Impuesto a Pedido //

  closeModal() {
    this.ViewService.closeModalLoadOG.hide();
  }

  changeCodeOtMod() {
    if (this.ViewService.nroTypeTrans == 0) {
      this.codeOtherTaxCo.setValue(this.ViewService.codeOTCo.toUpperCase());
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.codeOtherTaxPe.setValue(this.ViewService.codeOTPe.toUpperCase());
    }
  }

  changeDescOtMod() {
    if (this.ViewService.nroTypeTrans == 0) {
      this.descOtherTaxCo.setValue(this.ViewService.descOTCo.toUpperCase());
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.descOtherTaxPe.setValue(this.ViewService.descOTPe.toUpperCase());
    }
  }

  getId() {
    return 'ADMINIU00007';
  }

  formatNroMod(input) {
    if (this.ViewService.nroTypeTrans == 0) {
      if (input === 'ot_mont') {
        if (this.ViewService.montOTCo) {
          this.montOtherTaxCo.setValue(this.trunc(this.ViewService.montOTCo, 8));
        }
      } else if (input === 'ot_tax') {
        if (this.ViewService.taxOTCo) {
          this.taxOtherTaxCo.setValue(this.trunc(this.ViewService.taxOTCo, 8));
        }
      }
    } else if (this.ViewService.nroTypeTrans == 2) {
      if (input === 'ot_mont') {
        if (this.ViewService.montOTPe) {
          this.montOtherTaxPe.setValue(this.trunc(this.ViewService.montOTPe, 8));
        }
      } else if (input === 'ot_tax') {
        if (this.ViewService.taxOTPe) {
          this.taxOtherTaxPe.setValue(this.trunc(this.ViewService.taxOTPe, 8));
        }
      }
    }
  }

  trunc (x, posiciones = 0) {
    let s = x.toString()
    let l = s.length
    let decimalLength = s.indexOf('.') + 1
    if (l - decimalLength <= posiciones){
      return x
    }
    let isNeg  = x < 0
    let decimal =  x % 1
    let entera  = isNeg ? Math.ceil(x) : Math.floor(x)
    let decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    )
    let finalNum = entera + ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
    
    return finalNum
  }

  loadOT(values) {
    let valid = true;
    let code: string;
    let desc: string;
    let amount: number;
    let tax: number;
    let obj: any;
    let nro: number;
    if (this.ViewService.nroTypeTrans == 0) {
      if (values.codeOtherTaxCo.trim() && values.descOtherTaxCo.trim()) {
        code = values.codeOtherTaxCo.trim();
        desc = values.descOtherTaxCo.trim();
        amount = values.montOtherTaxCo;
        tax = values.taxOtherTaxCo;
        nro = this.ViewService.OtherTaxCo.length;
        if (nro > 0) {
          for (const i in this.ViewService.OtherTaxCo) {
            if (this.ViewService.OtherTaxCo.hasOwnProperty(i)) {
              const elem = this.ViewService.OtherTaxCo[i];
              if (elem.id >= nro) {
                nro = elem.id + 1;
              }
            }
          }
        }
      } else {
        this.codeOtherTaxCo.setValue(values.codeOtherTaxCo.trim());
        this.descOtherTaxCo.setValue(values.descOtherTaxCo.trim());
        valid = false;
      }
    } else if (this.ViewService.nroTypeTrans == 2) {
      if (values.codeOtherTaxPe.trim() && values.descOtherTaxPe.trim()) {
        code = values.codeOtherTaxPe.trim();
        desc = values.descOtherTaxPe.trim();
        amount = values.montOtherTaxPe;
        tax = values.taxOtherTaxPe;
        nro = this.ViewService.OtherTaxPe.length;
        if (nro > 0) {
          for (const i in this.ViewService.OtherTaxPe) {
            if (this.ViewService.OtherTaxPe.hasOwnProperty(i)) {
              const elem = this.ViewService.OtherTaxPe[i];
              if (elem.id >= nro) {
                nro = elem.id + 1;
              }
            }
          }
        }
      } else {
        this.codeOtherTaxPe.setValue(values.codeOtherTaxPe.trim());
        this.descOtherTaxPe.setValue(values.descOtherTaxPe.trim());
        valid = false;
      }
    } else {
      valid = false;
    }
    if (valid) {
      if (this.ViewService.editExpenses) {
        let pk = this.ViewService.pkExpense;
        if (this.ViewService.nroTypeTrans == 0) {
          let res = this.ViewService.OtherTaxCo[pk].amount;
          this.ViewService.OtherTaxCo[pk].code = code;
          this.ViewService.OtherTaxCo[pk].description = desc;
          this.ViewService.OtherTaxCo[pk].amount = amount;
          this.ViewService.OtherTaxCo[pk].tax = tax;
          this.ViewService.OtherTaxCo[pk].error = false;
          this.ViewService.OtherTaxCo[pk].error_code = false;
          this.ViewService.OtherTaxCo[pk].error_description = false;
          this.ViewService.OtherTaxCo[pk].error_amount = false;
          this.ViewService.OtherTaxCo[pk].error_tax = false;
          this.ViewService.totalOTCo = this.ViewService.totalOTCo - res + amount;
          this.ViewService.totalCo = this.ViewService.totalBICo + this.ViewService.totalTaxCo + this.ViewService.totOtherTaxCo + this.ViewService.totalOTCo + this.ViewService.totalExemptCo;
          this.ViewService.differenceCo = this.ViewService.totalCo - this.ViewService.origTotalCo;
          this.formOtherTaxesCo.reset();
          this.tableOtherTaxes = this.ViewService.OtherTaxCo;
        } else {
          let res = this.ViewService.OtherTaxPe[pk].amount;
          this.ViewService.OtherTaxPe[pk].code = code;
          this.ViewService.OtherTaxPe[pk].description = desc;
          this.ViewService.OtherTaxPe[pk].amount = amount;
          this.ViewService.OtherTaxPe[pk].tax = tax;
          this.ViewService.OtherTaxPe[pk].error = false;
          this.ViewService.OtherTaxPe[pk].error_code = false;
          this.ViewService.OtherTaxPe[pk].error_description = false;
          this.ViewService.OtherTaxPe[pk].error_amount = false;
          this.ViewService.OtherTaxPe[pk].error_tax = false;
          this.ViewService.totalOTPe = this.ViewService.totalOTPe - res + amount;
          this.ViewService.totalPe = this.ViewService.totalBIPe + this.ViewService.totalTaxPe + this.ViewService.totOtherTaxPe + this.ViewService.totalOTPe + this.ViewService.totalExemptPe;
          this.ViewService.differencePe = this.ViewService.totalPe - this.ViewService.origTotalPe;
          this.formOtherTaxesPe.reset();
          this.tableOtherTaxes = this.ViewService.OtherTaxPe;
        }
        this.ViewService.editExpenses = false;
        this.ViewService.pkExpense = null;
      } else {
        obj = {
          'id': nro,
          'code': code,
          'description': desc,
          'amount': amount,
          'tax': tax,
          'error': false,
          'error_code': false,
          'error_description': false,
          'error_amount': false,
          'error_tax': false
        };
        if (this.ViewService.nroTypeTrans == 0) {
          this.ViewService.totalOTCo = this.ViewService.totalOTCo + amount;
          this.ViewService.totalCo = this.ViewService.totalBICo + this.ViewService.totalTaxCo + this.ViewService.totOtherTaxCo + this.ViewService.totalOTCo + this.ViewService.totalExemptCo;
          this.ViewService.differenceCo = this.ViewService.totalCo - this.ViewService.origTotalCo;
          this.ViewService.OtherTaxCo.unshift(obj);
          this.formOtherTaxesCo.reset();
          this.tableOtherTaxes = this.ViewService.OtherTaxCo;
        } else {
          this.ViewService.totalOTPe = this.ViewService.totalOTPe + amount;
          this.ViewService.totalPe = this.ViewService.totalBIPe + this.ViewService.totalTaxPe + this.ViewService.totOtherTaxPe + this.ViewService.totalOTPe + this.ViewService.totalExemptPe;
          this.ViewService.differencePe = this.ViewService.totalPe - this.ViewService.origTotalPe;
          this.ViewService.OtherTaxPe.unshift(obj);
          this.formOtherTaxesPe.reset();
          this.tableOtherTaxes = this.ViewService.OtherTaxPe;
        }
      }
    }
  }

  viewDetailOTMod(id) {
    this.ViewService.idTableView = id;
    this.ViewService.typeDetail = 'OT';
    this.modalRef = this.modalService.show(ViewDetailProductComponent);
    this.modalRef.setClass('modal-dialog-centered');
    this.ViewService.closeModalDetailPro = this.modalRef;
  }

  deleteOTMod(id, code) {
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.removeOT(id);
      });
    }, 'Desea Eliminar?',
    'Realmente quiere eliminar el Gasto: '+code);
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  removeOT(id) {
    if (this.ViewService.nroTypeTrans == 0) {
      for (const i in this.ViewService.OtherTaxCo) {
        if (this.ViewService.OtherTaxCo.hasOwnProperty(i)) {
          const elem = this.ViewService.OtherTaxCo[i];
          if (elem.id === id) {
            this.ViewService.OtherTaxCo.splice(Number(i), 1);
            this.tableOtherTaxes = this.ViewService.OtherTaxCo;
            break;
          }
        }
      }
    } else if (this.ViewService.nroTypeTrans == 2) {
      for (const i in this.ViewService.OtherTaxPe) {
        if (this.ViewService.OtherTaxPe.hasOwnProperty(i)) {
          const elem = this.ViewService.OtherTaxPe[i];
          if (elem.id === id) {
            this.ViewService.OtherTaxPe.splice(Number(i), 1);
            this.tableOtherTaxes = this.ViewService.OtherTaxPe;
            break;
          }
        }
      }
    }
  }

  editOTMod(id) {
    this.ViewService.editExpenses = true;
    let pk: any;
    if (this.ViewService.nroTypeTrans == 0) {
      for (const i in this.ViewService.OtherTaxCo) {
        if (this.ViewService.OtherTaxCo.hasOwnProperty(i)) {
          const elem = this.ViewService.OtherTaxCo[i];
          if (elem.id == id) {
            pk = i;
          }
        }
      }
      this.ViewService.pkExpense = pk;
      this.ViewService.codeOTCo = this.ViewService.OtherTaxCo[pk].code;
      this.ViewService.descOTCo = this.ViewService.OtherTaxCo[pk].description;
      this.ViewService.montOTCo = this.ViewService.OtherTaxCo[pk].amount;
      this.ViewService.taxOTCo = this.ViewService.OtherTaxCo[pk].tax;
      this.elmRef.nativeElement.querySelector('.codeOTCo').focus();
    } else {
      for (const i in this.ViewService.OtherTaxPe) {
        if (this.ViewService.OtherTaxPe.hasOwnProperty(i)) {
          const elem = this.ViewService.OtherTaxPe[i];
          if (elem.id == id) {
            pk = i;
          }
        }
      }
      this.ViewService.pkExpense = pk;
      this.ViewService.codeOTPe = this.ViewService.OtherTaxPe[pk].code;
      this.ViewService.descOTPe = this.ViewService.OtherTaxPe[pk].description;
      this.ViewService.montOTPe = this.ViewService.OtherTaxPe[pk].amount;
      this.ViewService.taxOTPe = this.ViewService.OtherTaxPe[pk].tax;
      this.elmRef.nativeElement.querySelector('.codeOTPe').focus();
    }
  }

}
