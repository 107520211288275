import { Component, OnInit } from '@angular/core';
import { EspecieServiceService } from '../services/especie-service.service';
import { AuthService } from '../../../core/auth/auth.service';
import { Ajax, Config } from '../../../core/ajax/ajax';
import { Init } from '../../../core/ajax/init-ajax';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UrListService } from '../../../core/ajax/ur-list.service';

@Component({
  selector: 'app-modal-especie',
  templateUrl: './modal-especie.component.html',
  styleUrls: ['./modal-especie.component.css']
})
export class ModalEspecieComponent implements OnInit {
  request: EspecieRequest = new EspecieRequest;
  codeId: string;
  formEspecie: FormGroup;
  error_id = false;
  txt_id: string | null;
  error_code = false;
  txt_code: string | null;
  error_desc = false;
  txt_desc: string | null;

  constructor(
    public especieService: EspecieServiceService,
    public auth: AuthService,
    private listUrl: UrListService
  ) { 
    this.formEspecie = new FormGroup({
      codeesp: new FormControl(this.request.code, [
        Validators.required,
        Validators.maxLength(10)
      ]),
      descesp: new FormControl(this.request.desc, [
        Validators.required,
        Validators.maxLength(60)
      ]),
      estatusesp: new FormControl(this.request.activo)
    });
  }

  ngOnInit() {
    this.especieService.urlSave = this.listUrl.url('savespices');
    this.request.id_obj = this.especieService.pk;
    if (!this.especieService.newMU) {
      this.codeId = this.especieService.editCode;
      this.request.code = this.especieService.object.code;
      this.request.desc = this.especieService.object.description;
      this.request.activo = this.especieService.object.status;
    } else {
      this.codeId = this.especieService.addCode;
    }
  }

  formatCode() {
    this.request.code = this.request.code.toUpperCase().trim();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
    if (this.request.code) {
      while (this.request.code.length < 10) {
        this.request.code = '0'+this.request.code;
      } 
    }
  }

  formatDesc() {
    this.request.desc = this.request.desc.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  closeModal() {
    this.especieService.closeModal.hide();
  }
  
  async process(){
    try {
      if (this.request.code.trim() && this.request.desc.trim()) {
        const initAjax: Init = {
          method: 'put',
          url: this.especieService.urlSave,
          auth: this.auth,
          body: this.request
        };
        const configAjax: Config = {
          numInten : 1,
          titleLoading: `Guardando especie`,
          titleSuccess: '',
          titleErrorNormal: 'Error',
          textErrorNormal: 'Ha ocurrido un Error.',
          confirmButtonTextError: 'Cerrar',
          validatejson: (resp: any, obj: Ajax) => {
            return new Promise((success, error) => {
              if (resp.success === false) {
                obj.declareConfig({
                  alertaError : Swal.mixin({
                    icon: 'error',
                    title: 'Ocurrio un error',
                    text: resp.message,
                    showConfirmButton: false,
                    confirmButtonText: 'Cerrar',
                  })
                });
                error(resp);
              } else {
                success(resp);
              }
            });
          }
        };
        const ajax = new Ajax(initAjax, configAjax);
        const resp = await ajax.call();
        if (resp.success) {
          this.especieService.continuar = true;
          this.especieService.evenAdd.emit(resp);
          this.especieService.closeModal.hide();
        }
      } else {
        this.request.code = this.request.code.trim();
        this.request.desc = this.request.desc.trim();
      }
    } catch (error) {
      this.viewError(error);
    }
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }
  }
}

class EspecieRequest{
  activo: boolean = false;
  id_obj: number | string;
  code: string;
  desc: string; 
  tipo: string = 'catalogo';
}
