import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {defineLocale} from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { ModalPriceChargeProductsComponent } from '../modal-price-charge-products/modal-price-charge-products.component';


@Component({
  selector: 'app-modal-expira-product',
  templateUrl: './modal-expira-product.component.html',
  styleUrls: ['./modal-expira-product.component.css']
})
export class ModalExpiraProductComponent implements OnInit {

  formExpProd: FormGroup;
  modalRef: BsModalRef;
  typeOp: number;
  cantPr: number;
  expiraPr: Date | string;
  lotePr: string;
  cont = 0;
  allEdit: boolean = true;
  oneEdit: boolean = true;
  error_type: boolean = false;
  txt_type: string = '';
  error_all_expira: boolean = false;
  txt_all_expira: string = '';
  error_all_lote: boolean = false;
  txt_all_lote: string = '';
  error_one = [];
  arrProds = [];
  events: Subscription[] = [];
  
  constructor(
    public ViewService: EntriesExitsService,
    private fb: FormBuilder,
    private localeService: BsLocaleService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef
  ) {
    defineLocale('es', esLocale);
    this.localeService.use('es');
    this.formExpProd = this.fb.group({
      typeoption: this.fb.control(this.typeOp, [
        Validators.required
      ]),
      cantidad: new FormControl({value: this.cantPr, disabled: true}, Validators.required),
      expira: new FormControl(this.expiraPr),
      lote: new FormControl(this.lotePr, [
        Validators.maxLength(30)
      ]),
      productos: this.fb.array([])
    });
  }

  ngOnInit() {
    this.cantPr = this.ViewService.unitProd;
    while (this.cont < this.cantPr) {
      this.error_one.push({'error_expira': false, 'txt_expira': '', 'error_lote': false, 'txt_lote': ''});
      this.arrProds.push({"exp": '', "lot": ''})
      this.addProduct(this.cont);
      this.cont = this.cont + 1;
    }
  }

  getId() {
    return 'ADMINIU00013';
  }

  get typeoption() { return this.formExpProd.get('typeoption'); };
  get cantidad() { return this.formExpProd.get('cantidad'); };
  get expira() { return this.formExpProd.get('expira'); };
  get lote() { return this.formExpProd.get('lote'); };
  get productos(): FormArray {
    return this.formExpProd.get('productos') as FormArray;
  }; 

  addProduct(cont) {
    const product = this.fb.group({
      expira: this.arrProds[cont]['exp'],
      lote: [this.arrProds[cont]['lot'], [
        Validators.maxLength(30)
      ]]
    });
    this.productos.push(product);
  }

  closeModal(){
    this.ViewService.closeModalExpira.hide();
  }

  changeType() {
    this.error_type = false;
    this.txt_type = '';
    this.error_all_expira = false;
    this.txt_all_expira = '';
    this.error_all_lote = false;
    this.txt_all_lote = '';
    for (const key in this.error_one) {
      if (this.error_one.hasOwnProperty(key)) {
        const elem = this.error_one[key];
        elem.error_expira = false;
        elem.error_lote = false;
        elem.txt_expira = '';
        elem.txt_lote = '';

      }
    }
    if (this.typeOp === 0) {
      this.oneEdit = false;
      this.allEdit = true;
      this.formExpProd.get('expira').reset();
      this.formExpProd.get('lote').reset();
    } else if (this.typeOp === 1) {
      this.oneEdit = true;
      this.allEdit = false;
      for (const key in this.arrProds) {
        if (this.arrProds.hasOwnProperty(key)) {
          const elem = this.arrProds[key];
          elem.exp = '';
          elem.lot = '';
        }
      }
    } else {
      this.error_type = true;
      this.txt_type = 'Valor invalido.';
    }
  }

  returnFEcha(value: Date){
    var dd = value.getDate();
    var mm = value.getMonth()+1; //Enero is 0!
    var yyyy = value.getFullYear();
    let dia: any;
    let mes:any;
    if (dd<10) {
      dia = '0'+dd;
    } else {
      dia = dd;
    }

    if(mm<10) {
      mes = '0'+mm;
    } else {
      mes = mm;
    } 

    var fecha = dia + '/' + mes + '/' + yyyy;
    return fecha;
  }

  changeAllDate(value: Date | string): void {
    if (value != null) {
      if (value == 'Invalid Date') {
        value = '';
        this.error_all_expira = true;
        this.txt_all_expira = 'Fecha invalida';
      } else {
        if (typeof value === 'object') {
          value = this.returnFEcha(value);
        }
        this.error_all_expira = false;
        this.txt_all_expira = '';
      }
      this.expiraPr = value;
      for (const key in this.arrProds) {
        if (this.arrProds.hasOwnProperty(key)) {
          const element = this.arrProds[key];
          element.exp = value;
        }
      }
    }
  }

  changeAllLot() {
    this.error_all_lote = false;
    this.txt_all_lote = '';
    this.lotePr = this.lotePr.toUpperCase()
    for (const key in this.arrProds) {
      if (this.arrProds.hasOwnProperty(key)) {
        const element = this.arrProds[key];
        element.lot = this.lotePr;
      }
    }
  }

  changeOneExpira(value: Date | string, indice):void {
    this.error_one[indice].error_expira = false;
    this.error_one[indice].txt_expira = '';
    
    if (value != null) {
      if (value == 'Invalid Date') {
        value = '';
        this.arrProds[indice]['exp'] = '';
        this.error_one[indice].error_expira = true;
        this.error_one[indice].txt_expira = 'Fecha invalida';
      }
    }
  }

  changeOneLote(value, indice) {
    this.error_all_lote = false;
    this.txt_all_lote = '';
    this.arrProds[indice].lot = value.toUpperCase();
    this.error_one[indice].error_lote = false;
    this.error_one[indice].txt_lote = '';
  }

  unsubscribe() {
    this.events.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.events = [];
  }

  loadProduct(values) {
    let valid = true;
    this.error_type = false;
    this.txt_type = '';
    this.error_all_expira = false;
    this.txt_all_expira = '';
    this.error_all_lote = false;
    this.txt_all_lote = '';
    for (const key in this.error_one) {
      if (this.error_one.hasOwnProperty(key)) {
        const elem = this.error_one[key];
        elem.error_expira = false;
        elem.error_lote = false;
        elem.txt_expira = '';
        elem.txt_lote = '';
      }
    }
    if (values.typeoption == 0) {
      for (const key in values.productos) {
        if (values.productos.hasOwnProperty(key)) {
          const element = values.productos[key];
          if (element.expira == null || element.expira == '') {
            valid = false;
            this.error_one[key].error_expira = true;
            this.error_one[key].txt_expira = 'Campo requerido.';
          }
          if (element.lote == null || element.lote.trim() == '') {
            valid = false;
            this.error_one[key].error_lote = true;
            this.error_one[key].txt_lote = 'Campo requerido.';
          }
        }
      }
    } else if (values.typeoption == 1) {
      if (values.expira == null || values.expira == '') {
        valid = false;
        this.error_all_expira = true;
        this.txt_all_expira = 'Campo requerido.';
      }
      if (values.lote == null || values.lote.trim() == '') {
        valid = false;
        this.error_all_lote = true;
        this.txt_all_lote = 'Campo requerido.';
      }
    } else {
      valid = false;
      this.error_type = true;
      this.txt_type = 'Valor invalido.';
    }

    if (valid) {
      const _combine = combineLatest(
        this.modalService.onHide
      ).subscribe(() => this.changeDetection.markForCheck());
  
      this.events.push(
        this.modalService.onHide.subscribe(() => {
          if (this.ViewService.boolClosePrices) {
            this.ViewService.closeModalExpira.hide();
          }
          this.unsubscribe();
        })
      );
      
      this.events.push(_combine);
      this.ViewService.objectsExpiration = values;
      this.modalRef = this.modalService.show(ModalPriceChargeProductsComponent);
      this.modalRef.setClass('modal-dialog-centered modal-lg');
      this.ViewService.closeModalPrices = this.modalRef;
    }
  }

}
