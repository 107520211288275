import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  new = false;
  pk: number | string = '';

  code = '';
  desc = '';
  status = true;

  valuesObjects: Stations;
  valuesObjectsBase: Stations;
  habilitaBtn: boolean;
  typeModal: string;
  closeModal: any;

  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  urlreturn: string | boolean;
  viewModal: any;
  object: Station;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }
}

export interface Station {    ​​​
  id: number;
  code: string;
  desc: string;
  status: boolean;
}

export interface Stations extends Array<Station> {
  [key: number]: Station;
}
