export const session = {
    user: 'user_save_aut',
    refreshToken: 'rftksecret',
    refreshTokenTp: 'refresh',
    tokenAc: 'token',
    tokenAcTp: 'tkauto',
    tokenCompany: 'tokenCompany',
    tokenCompanyTp: 'company',
    sendRefrest: 'sendRefresf',
    carshop: 'carshop',
    pname: 'inicars',
    pname_base: 'pname_base',
    activeWaiter: 'activeWaiter',
    guis: 'guis_p',
    cash_open: 'cash_open',
    pedido: 'pdfac',
    pedidos: 'pdfacs',
    document_factura: 'fachead',
    document_pedido: 'pedied',
    block_url: 'blockurl'
};

export const session_p = {
    rememberUser: 'rememberUser',
    usernameSession: 'user_save_name',
}
