import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CatalogoOneService } from '../services/catalogo-one.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { CatalogotwoService } from '../services/catalogotwo.service';
import { CatalogoThreeService } from '../services/catalogo-three.service';
import { CatalogoFourthService } from '../../puntodeventa/services/catalogo-fourth.service';
import { InventaryProductService } from '../services/inventary-product.service';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';
import { SupervisorsService } from '../services/supervisors.service';

@Component({
  selector: 'app-modals-one',
  inputs: [
    'titleModal',
    'labelCode',
    'placeholderCode',
    'labelDesc',
    'placeholderDesc',
    'typeModal',
    'nameModal',
    'maxCode',
    'maxDesc',
    'titleSave',
    'typeInput'],
  templateUrl: './modals-one.component.html',
  styleUrls: ['./modals-one.component.css']
})
export class ModalsOneComponent implements OnInit {

  titleModal: string;
  labelCode = 'Codigo:';
  placeholderCode = 'CODIGO';
  labelDesc = 'Descripcion:';
  placeholderDesc = 'DESCRIPCION';
  typeModal = 'catalogo';
  nameModal: string;
  formOne: FormGroup;
  codeOneMO: string | null;
  descOneMO: string | null;
  error_id = false;
  txt_id: string | null;
  error_code = false;
  txt_code: string | null;
  error_desc = false;
  txt_desc: string | null;
  maxCode = 10;
  maxDesc = 80;
  titleSave: string;
  typeInput: boolean = true;
  inputType: string;
  codeId: string;

  constructor(
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService: CatalogoThreeService,
    public CatalogoFourthService:CatalogoFourthService,
    public auth: AuthService,
    public ProductService: InventaryProductService,
    public SupervisorsService: SupervisorsService,
    public ResolvingService: ResolvingService,
    private CatalogoOneService: CatalogoOneService,
    private listUrl: UrListService
  ) {
    this.formOne = new FormGroup({
      mocodeone: new FormControl(this.codeOneMO, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      modescone: new FormControl(this.descOneMO, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ])
    });
  }

  ngOnInit() {
    if(this.typeInput === true){
      this.inputType = 'text';
    }else{
      this.inputType = 'number';
    }
    if (this.CatalogoOneService.newMU) {
      this.codeId = this.CatalogoOneService.addCode;
    } else {
      this.codeId = this.CatalogoOneService.editCode;
      this.codeOneMO = this.CatalogoOneService.object.code;
      this.descOneMO = this.CatalogoOneService.object.description;
    }
  }

  getId() {
    return this.codeId;
  }

  formatCode() {
    this.codeOneMO = this.codeOneMO.toUpperCase().trim();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
    if (this.codeOneMO) {
      while (this.codeOneMO.length < this.maxCode) {
        this.codeOneMO = '0'+this.codeOneMO;
      }
    }
  }

  formatDesc() {
    this.descOneMO = this.descOneMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  saveObject() {
    let id_obj: any;
    if (this.CatalogoOneService.newMU) {
      id_obj = '';
    } else {
      id_obj = this.CatalogoOneService.pk;
    }
    if (String(this.codeOneMO).trim() && String(this.descOneMO).trim()) {
      const initAjax: Init = {
        method: 'put',
        url: this.CatalogoOneService.urlSave,
        auth: this.auth,
        body: {
          id_obj: id_obj,
          code: String(this.codeOneMO).trim(),
          desc: String(this.descOneMO).trim(),
          tipo: this.CatalogoOneService.typeModal
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if (resp.new) {
            if (resp.tipo == 'catalogo') {
              this.CatalogoOneService.continuar = true;
              this.CatalogoOneService.evenAdd.emit(resp);
            } else {
              if (this.CatalogoOneService.modelo === 'Secundario') {
                this.modalSecun(resp);
              } else if (this.CatalogoOneService.modelo === 'Tercero') {
                this.modalTerce(resp);
              }else if(this.CatalogoOneService.modelo === 'Producto'){
                this.modalProduct(resp);
              }else if(this.CatalogoFourthService.modelo === 'Banks'){
                this.modalBanks(resp);
              }else if(this.CatalogoOneService.modelo === 'Supervisor'){
                this.modalSuper(resp);
              } else if (this.CatalogoOneService.modelo === 'Conflicto') {
                this.ResolvingService.seguir = true;
                this.ResolvingService.loadselect = true;
                this.ResolvingService.evenAdd.emit({'new':resp.objeto});
              }
            }
          } else {
            this.CatalogoOneService.continuar = true;
            this.CatalogoOneService.evenAdd.emit(resp);
          }
          this.CatalogoOneService.closeModal.hide();
        }
      }).catch(error => {
        this.viewError(error);
      });
    } else {
      this.codeOneMO = String(this.codeOneMO).trim();
      this.descOneMO = String(this.descOneMO).trim();
    }
  }

  modalBanks(resp) {
    for (const key in resp.objeto) {
      if (Object.prototype.hasOwnProperty.call(resp.objeto, key)) {
        const elem = resp.objeto[key];
        if (elem.id_currency == resp.pk) {
          this.CatalogoFourthService.curr_selected = resp.pk;
          break;
        }
      }
    }
    this.CatalogoFourthService.currList = resp.objeto;
  }

  modalSecun(resp) {
    this.CatalogoTwoService.OneSelect = resp.objeto;
    this.CatalogoTwoService.selectID = true;
    this.CatalogoTwoService.selectOneMD = resp.pk;
    this.CatalogoTwoService.descOneMD = resp.pk;
    if (typeof this.CatalogoThreeService.typeModal != 'undefined') {
      this.modalTerce(resp);
    }
    if(typeof this.ProductService.typeModal != 'undefined'){
      this.modalProduct(resp);
    }
  }

  modalSuper(resp) {
    this.SupervisorsService.OneSelect = resp.objeto;
    this.SupervisorsService.selectCodeSF = resp.pk;
    this.SupervisorsService.selectDescSF = resp.desc;
  }

  modalTerce(resp) {
    this.CatalogoThreeService.descOneMT = resp.desc;
    this.CatalogoThreeService.OneSelect = resp.objeto;
    this.CatalogoThreeService.selectID = true;
    this.CatalogoThreeService.selectOneMT = resp.pk;
    this.CatalogoThreeService.selectedOne = false;
    this.CatalogoThreeService.selectIDTwo = false;
    this.CatalogoThreeService.descTwoMT = null;
    this.loadObjSelectTwo(resp.pk);
    if(typeof this.ProductService.typeModal != 'undefined'){
      this.modalProduct(resp);
    }
  }

  modalProduct(resp){
    if(this.CatalogoOneService.typeModal === 'tipo'){
      this.ProductService.selectIdType = true;
      this.ProductService.selectType = resp.pk;
      this.ProductService.descType = resp.pk;
      this.ProductService.TypeSelect = resp.objeto;
    }else if(this.CatalogoOneService.typeModal === 'presentacion'){
      this.ProductService.selectIdPresentation = true;
      this.ProductService.selectPresentation = resp.pk;
      this.ProductService.descPresentation = resp.pk;
      this.ProductService.PresentationSelect = resp.objeto;
    }else if(this.CatalogoOneService.typeModal === 'shop'){
      this.ProductService.selectIdShop = true;
      this.ProductService.selectShop = resp.pk;
      this.ProductService.descShop = resp.pk;
      this.ProductService.ShopSelect = resp.objeto;
    }else if(this.CatalogoOneService.typeModal === 'location'){
      this.ProductService.selectIdLocation = true;
      this.ProductService.selectLocation = resp.pk;
      this.ProductService.descLocation = resp.pk;
      this.ProductService.LocationSelect = resp.objeto;
    }else if(this.CatalogoOneService.typeModal === 'grupo' || this.CatalogoOneService.typeModal === 'subgrupo' || this.CatalogoOneService.typeModal === 'linea'){
      this.loadSGroupProduct(resp.pk);
      this.ProductService.selectIdGroup = true;
      this.ProductService.selectGroup = resp.pk;
      this.ProductService.descGroup = resp.desc;
      this.ProductService.GroupSelect = resp.objeto;
      this.ProductService.selectIdSubgroup = false;
      this.ProductService.selectedSubGroup = false;
      this.ProductService.descSubgroup = null;
      this.ProductService.descKind = null;
    }
  }

  loadSGroupProduct(grupo){
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: grupo
      },
      url: this.listUrl.url('list_modal_subgroup')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.ProductService.SubgroupSelect = resp.objects;
        this.ProductService.selectedGroup = true;
      }
    });
  }

  loadObjSelectTwo(pk_princ) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk_princ
      },
      url: this.CatalogoThreeService.urlSelectTwo
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.CatalogoThreeService.TwoSelect = resp.objects;
        this.CatalogoThreeService.selectedOne = false;
      }
    });
  }

  closeModal() {
    this.CatalogoOneService.closeModal.hide();
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }
  }
}

@Component({
  selector: 'app-modal-group',
  template: `
    <app-modals-one
      titleModal="Agregar Grupo"
      nameModal="grupo"
      titleSave="Grupo"
      >
    </app-modals-one>
  `
})
export class ModalGroupComponent implements OnInit {
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoOneService.urlSave = this.listUrl.url('savegroup');
    if (typeof this.CatalogoTwoService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    } else if (typeof this.CatalogoThreeService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ProductService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-location',
  template: `
    <app-modals-one
      titleModal="Agregar Ubicación"
      nameModal="ubicacion"
      titleSave="Ubicación"
      maxDesc= 60
      >
    </app-modals-one>
  `
})
export class ModalLocationComponent implements OnInit {
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoOneService.urlSave = this.listUrl.url('savelocation');
    if (typeof this.CatalogoTwoService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    } else if (typeof this.CatalogoThreeService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ProductService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }  
  }
}

@Component({
  selector: 'app-modal-presentation',
  template: `
    <app-modals-one
      titleModal="Agregar Presentación"
      nameModal="presentacion"
      titleSave="Presentación"
      >
    </app-modals-one>
  `
})
export class ModalPresentationComponent implements OnInit {
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit(){
    this.CatalogoOneService.urlSave = this.listUrl.url('savepresentation');
    if(typeof this.CatalogoTwoService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    }else if(typeof this.CatalogoThreeService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ProductService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-shop',
  template: `
    <app-modals-one
      titleModal="Agregar Departamento"
      nameModal="departamento"
      titleSave="Departamento"
      maxDesc= 60
      >
    </app-modals-one>
  `
})
export class ModalShopComponent implements OnInit{
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit(){
    this.CatalogoOneService.urlSave = this.listUrl.url('saveshop');
    if(typeof this.CatalogoTwoService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    }else if(typeof this.CatalogoThreeService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ProductService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-type-product',
  template: `
    <app-modals-one
      titleModal="Agregar Tipo"
      nameModal="tipo"
      titleSave="Tipo"
      >
    </app-modals-one>
  `
})
export class ModalTypeProductComponent implements OnInit{
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit(){
    this.CatalogoOneService.urlSave = this.listUrl.url('savetypeproduct');
    if(typeof this.CatalogoTwoService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    }else if(typeof this.CatalogoThreeService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ProductService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-currency',
  template: `
    <app-modals-one
      titleModal="Agregar Moneda"
      nameModal="moneda"
      labelCode="Nombre:"
      placeholderCode="NOMBRE MONEDA"
      labelDesc="Simbolo:"
      placeholderDesc="SIMBOLO MONEDA"
      maxCode=20
      maxDesc=5
      titleSave="Moneda"
      >
    </app-modals-one>
  `
})
export class ModalCurrencyComponent implements OnInit{
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public CatalogoFourthService:CatalogoFourthService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit(){
    this.CatalogoOneService.urlSave = this.listUrl.url('savecurrency');
    if(typeof this.CatalogoTwoService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    }else if(typeof this.CatalogoThreeService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.CatalogoFourthService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoFourthService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoFourthService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-tax',
  template: `
    <app-modals-one
      titleModal="Agregar Impuesto"
      nameModal="impuesto"
      titleSave="Impuesto"
      maxCode=8
      maxDesc=80
      typeInput= false;
      labelCode="Porcentaje"
      placeholderCode="PORCENTAJE"
      >
    </app-modals-one>
  `
})
export class ModalTaxComponent implements OnInit{
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit(){
    this.CatalogoOneService.urlSave = this.listUrl.url('savetax');
    if(typeof this.CatalogoTwoService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    }else if(typeof this.CatalogoThreeService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-salesforce',
  template: `
    <app-modals-one
      titleModal="Agregar Fuerza de Venta"
      nameModal="salesforce"
      titleSave="Fuerza de Venta"
      labelCode="Codigo:"
      placeholderCode="CODIGO"
      labelDesc="Descripcion:"
      placeholderDesc="DESCRIPCION"
      maxCode=3
      maxDesc=80
    >
    </app-modals-one>
  `
})
export class ModalSalesForceComponent implements OnInit {
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService: CatalogotwoService,
    public CatalogoThreeService: CatalogoThreeService,
    public ResolvingService: ResolvingService,
    public SupervisorsService: SupervisorsService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoOneService.urlSave = this.listUrl.url('savesalesforce');
    if (typeof this.CatalogoTwoService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    } else if (typeof this.CatalogoThreeService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    } else if (typeof this.SupervisorsService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.SupervisorsService.typeModal;
      this.CatalogoOneService.modelo = this.SupervisorsService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    } else {
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-type-costumer',
  template: `
    <app-modals-one
      titleModal="Agregar Tipo"
      nameModal="tipo"
      titleSave="Tipo"
      >
    </app-modals-one>
  `
})
export class ModalTypeCostumerComponent implements OnInit{
  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit(){
    this.CatalogoOneService.urlSave = this.listUrl.url('savetypecustomer');
    if (typeof this.CatalogoTwoService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    } else if (typeof this.CatalogoThreeService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    } else if (typeof this.ProductService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    } else {
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-group-customer',
  template: `
    <app-modals-one
      titleModal="Agregar Grupo"
      nameModal="grupo"
      titleSave="Grupo"
      >
    </app-modals-one>
  `
})
export class ModalGroupCustomerComponent implements OnInit {

  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoOneService.urlSave = this.listUrl.url('savegroup_customers');
    if (typeof this.CatalogoTwoService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    } else if (typeof this.CatalogoThreeService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    } else if (typeof this.ProductService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined') {
      this.CatalogoOneService.typeModal = this.ResolvingService.typeModal;
      this.CatalogoOneService.modelo = this.ResolvingService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}

@Component({
  selector: 'app-modal-vehicle-customer',
  template: `
    <app-modal-vehicle
      titleModal="Catalogo de Vehiculos"
      nameModal="vehiculo"
      titleSave="Vehiculo"
      >
    </app-modal-vehicle>
  `
})
export class ModalVehicleCustomerComponent implements OnInit {

  constructor(
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService:CatalogotwoService,
    public CatalogoThreeService:CatalogoThreeService,
    public ProductService: InventaryProductService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoOneService.urlSave = this.listUrl.url('set_vehicles_customer');
    if (typeof this.CatalogoTwoService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoTwoService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoTwoService.modelo;
    } else if (typeof this.CatalogoThreeService.typeModal !== 'undefined') {
      this.CatalogoOneService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogoOneService.modelo = this.CatalogoThreeService.modelo;
    }else if(typeof this.ProductService.typeModal != 'undefined'){
      this.CatalogoOneService.typeModal = this.ProductService.typeModal;
      this.CatalogoOneService.modelo = this.ProductService.modelo;
    }else{
      this.CatalogoOneService.typeModal = 'catalogo';
    }
  }
}