import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Cierre } from '../../interface/cierre';

@Component({
  selector: 'app-cierre-caja',
  inputs: ['title', 'cierre', 'monto_apertura', 'estacion', 'id_cashing', 'station', 'action'],
  outputs: ['even_success', 'even_cancel'],
  templateUrl: './cierre-caja.component.html',
  styleUrls: ['./cierre-caja.component.css']
})
export class CierreCajaComponent implements OnInit {

  title: string;
  cierre: Cierre;
  monto_cierre = 0;
  monto_apertura = 0;
  estacion = '';
  id_cashing: number;
  fecha = new Date();
  even_success: EventEmitter<any> = new EventEmitter();
  even_cancel: EventEmitter<any> = new EventEmitter();
  station: number;
  action: string;

  constructor(private mybsModalRef: BsModalRef, public auth: AuthService,
    public listUrl: UrListService) { }

  ngOnInit() {
  }

  get diferencia_efectivo() {
    return Math.abs(this.cierre.cierre_teorico.efectivo - this.cierre.cierre_real.efectivo);
  }

  get diferencia_debito() {
    return Math.abs(this.cierre.cierre_teorico.debito - this.cierre.cierre_real.debito);
  }

  get diferencia_credito() {
    return Math.abs(this.cierre.cierre_teorico.credito - this.cierre.cierre_real.credito);
  }

  get diferencia_documentos() {
    return Math.abs(this.cierre.cierre_teorico.documentos - this.cierre.cierre_real.documentos);
  }

  get total_diferencia() {
    return this.diferencia_efectivo + this.diferencia_debito + this.diferencia_credito + this.diferencia_documentos;
  }

  vacio(text) {
    if (typeof text === 'string') {
      let v = text.replace("&nbsp;", "");
      if (!v || 0 === v.trim().length) {
        return 0;
      } else {
        return v;
      }
    } else {
      return text;
    }
  }

  get total_cuadre_real() {
    let debi = this.vacio(this.cierre.cierre_real.debito);
    let efe = this.vacio(this.cierre.cierre_real.efectivo);
    let credi = this.vacio(this.cierre.cierre_real.credito);
    let docu = this.vacio(this.cierre.cierre_real.documentos);
    return parseFloat(efe) + parseFloat(debi) + parseFloat(credi) + parseFloat(docu);
  }

  get total_operaciones() {
    return this.cierre.total_factura + this.cierre.total_devoluciones;
  }

  close_modal() {
    this.even_success.emit(false);
    this.even_cancel.emit(true);
    this.mybsModalRef.hide();
  }

  get_url() {
    if (this.action == 'parcial') {
      return this.listUrl.url('set_partial')
    } else {
      return this.listUrl.url('set_total')
    }
  }

  send_cierre() {
    const config: Config = {
      titleLoading: 'Generando' + this.title
    };
    const init: Init = {
      url: this.get_url(),
      auth: this.auth,
      method: 'put',
      body: {
        efectivo: this.cierre.cierre_real.efectivo,
        credito: this.cierre.cierre_real.credito,
        debito: this.cierre.cierre_real.debito,
        other: this.cierre.cierre_real.documentos,
        cashing: this.id_cashing,
        amount_cashing: this.monto_cierre,
        station: this.station
      }
    };
    const ajax = new Ajax(init, config);
    ajax.call().then(resp => {
      this.even_success.emit(true);
      this.mybsModalRef.hide();
    }).catch(err => {
    });
  }

}
