import { Component, OnInit, Renderer2, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Products, Product } from '../../interface/products';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { base_url } from 'src/app/core/constantes/request';
import { ViewLoader, Confir } from 'src/app/core/viewLoader/viewLoader';
import { Cache } from 'src/app/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { Station } from '../../interface/station';
import { Cashing } from '../../interface/cashing';
import { StationDocuments } from '../../interface/station_documents';
import { BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import { BuyProductModalComponent } from '../buy-product-modal/buy-product-modal.component';
import { Customer } from '../../interface/customer';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Search } from 'src/app/search';
import { Pedido, ItemPedidos, Pedidos } from '../../interface/pedido';
import { SearchDocumentComponent } from '../search-document/search-document.component';
import { TotalizarComponent } from '../totalizar/totalizar.component';
import { FiscalPrinterService } from '../../../core/fiscalprinter/fiscal-printer.service';
import { CustomerPDVComponent } from '../catalogo/customer/customer.component';
import { ModalCreatecustomerPdvComponent } from '../catalogo/customer/modal-createcustomer-pdv/modal-createcustomer-pdv.component';
import { ModalCierreComponent } from '../modal-cierre/modal-cierre.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import localeUs from '@angular/common/locales/en-US-POSIX';
import localeVe from '@angular/common/locales/es-VE';

registerLocaleData(localeVe, localeUs);

@Component({
	// tslint:disable: indent
	selector: 'app-billing',
	templateUrl: './billing.component.html',
	styleUrls: ['./billing.component.css']
})
export class BillingComponent extends ViewLoader implements OnInit {

	base_url = base_url;
	of_field = 'de';
	resp_field = 'list_product';
	fields_search = ['product_code', 'product_description'];
	field_norepeat = (resp: Product) => {
		return resp.precio_p.id;
	}
	// tslint:disable: member-ordering
	stati: Cashing;
	factura_document: StationDocuments;
	pedido_document: StationDocuments;
	products: Products;
	fecha = new Date();
	modalBuy: BsModalRef;
	modalRmv: BsModalRef;
	modalList: BsModalRef;
	modalRegister: BsModalRef;
	modalTotalizar: BsModalRef;
	modalDocuments: BsModalRef;
	modalCierre: BsModalRef;
	list_buy: ItemPedidos = [];
	customer: Customer | null;
	search_cus: string;
	pedidos: Pedidos = [];
	subTotal: number;
	impuesto: number;

	contraer: boolean;
	noimagen: boolean;
	contraer_txt = 'Expandir';
	busca_producto = [];
	busca_cliente = [];
	search_cliente = {};
	s_cus_loading = null;
	espera = true;
	loading = false;
	readonly = false;
	txtaddtag = 'Buscar en servidor: ';
	txtloading = 'Buscando...';
	currency_name = ''; // Nombre de la moneda que se muestra en los montos, ejemplo: USD (Actualmente en sin uso)
	currency_symbol = '$'; // Mostrar el signo de la moneda segun la configuracion.
	currency_region = 'es-VE'; // Formato de los serpadores de miles y decimales, ejemplo: es-VE
	// NOTA: Si se desea añadir una nueva region, debe llamarse manualmente y añadir a la funcion: registerLocaleData
	currency_decimals = '1.2-2'; // Decimales a mostrar en los montos.
	// 'a.b-c' a: ceros antes de la coma. b: ceros minimos en decimales. c: ceros maximos en decimales
	// Ej: '1.2-2' = 0.00 -- '4.1-4' = 0000.0

	title = 'Bonaventure :. Facturacion';

	constructor(
		public auth: AuthService, public listUrl: UrListService,
		public renderer: Renderer2, private modalService: BsModalService,
		public FiscalPrinterService: FiscalPrinterService, private router: Router,
		private titleService: Title) {
		super();
		this.titleService.setTitle(this.title);
		this.stati = { code_station: '***' };
		this.pedidos = [];
		this.defautl_params();
		this.initLoader = {method: 'put', auth: this.auth, url: this.listUrl.url('get_products')};
		this.configLoader = { visible: false, autoReNew: true };
		this.initScrolin = Object.assign({}, this.initLoader);
		this.initSearch = Object.assign({}, this.initLoader);
		this.configSearch = { titleSuccess: '', visible: true };
		this.confiScrolin = Object.assign({}, this.configLoader);
		this.contraer = true;
		this.noimagen = true;
	}

	ngOnInit() {
		this.stati = Cache.getSession(session.cash_open, true);
		this.load_station_config();
		if (Cache.getSession(session.document_factura) && Cache.getSession(session.pedido)) {
			this.factura_document = Cache.getSession(session.document_factura, true);
			const pedido = Cache.getSession(session.pedido, true);
			this.pedido_document = pedido.headp;
			this.customer = pedido.customer;
			this.list_buy = pedido.list_buy;
			this.products = pedido.products;
			this.valores();
		} else {
			this.load_documents();
		}
		this.load_items();
		this.load_setting();
	}

	searchproductFast($event) {
		this.loading = true;
		this.readonly = true;
		this.busca_producto = [];
		if (this.espera && typeof $event == "string") {
			// this.item_search = [];
			this.espera = false;
			const initD: Init = {
				method: 'put',
				auth: this.auth,
				url: this.listUrl.url('get_products'),
				body: {
					search: $event,
					to: false,
					of: false
				}
			};
			const configD: Config = {
				visible: false,
				autoReNew: true
			};
			const ajax = new Ajax(initD, configD);
			ajax.call().then( resp => {
				this.loading = false;
				this.readonly = false;
				if (resp.success && resp[this.resp_field]) {
					this.item_base.push(resp[this.resp_field][0]);
					this.no_repeat_object(this.item_search, this.item_base).then(
						eleme => {
							this.item_base = [].concat(eleme);
							this.item_show = [].concat(eleme);
							this.item_search = [].concat(eleme);
							if (1 <= (resp[this.resp_field][0].inventory_p.quantity - resp[this.resp_field][0].inventory_p.reserved)) {
								this.openModalBuy(resp[this.resp_field][0], true);
							} else {
								this.basicAlert('error', 'Producto Agotado', '', 'Ok');
								this.espera = true;
							}
						}
					);
				} else {
					this.basicAlert('error', 'Producto no disponible', 'No se encontro el producto en el servidor', 'Ok');
					this.espera = true;
				}
			}).catch((err) => {
				this.loading = false;
				this.readonly = false;
				this.espera = true;
				this.basicAlert('error', 'Error al buscar', 'Ocurrio un error al buscar el producto', 'Ok');
			});
		} else if (this.espera && typeof $event == "object") {
			this.espera = false;
			const canti = 1;
			let product: Product = Object.assign({}, $event);
			if (canti <= (product.inventory_p.quantity - product.inventory_p.reserved)) {
				this.openModalBuy(product, true);
			} else {
				this.basicAlert('error', 'Producto Agotado', '', 'Ok');
				this.espera = true;
			}
			this.loading = false;
			this.readonly = false;
		} else {
			this.loading = false;
			this.readonly = false;
		}
	}

	getId() {
		return 'ESTPVIU00002';
	}

	get total() {
		return this.formatoPrecio(this.subTotal + this.impuesto);
	}

	public defautl_params() {
		this.factura_document = { number: '*********', prefix: '*', control_number: '*********' };
		this.pedido_document = { number: '*********', prefix: '*', control_number: '*********' };
		this.default_pedido();
	}

	default_pedido() {
		this.products = [];
		this.customer = null;
		this.list_buy = [];
		this.subTotal = 0;
		this.impuesto = 0;
	}

	clientCreate(data: any) {
		if (typeof data.customer != 'undefined') {
			if (Cache.getSession(session.pedido)) {
				this.basicAlert('error', 'Cambiar Cliente', 'No puede cambiar el cliente del pedido', 'Ok');
			} else {
				this.customer = data.customer;
				this.search_cus = '';
			}
		}
		if (typeof data.search != 'undefined') {
			this.search_cus = data.search_cus;
		}
	}

	clientRemove() {
		if (typeof this.customer != 'undefined' || !this.customer) {
			if (Cache.getSession(session.pedido)) {
				this.basicAlert('error', 'No se puede cambiar Cliente', 'Hay un pedido en curso', 'Ok');
			} else {
				const oe: SweetAlertOptions = {
					icon: 'question',
					title: 'Cambiar Cliente',
					text: '¿Desea retirar el cliente actual y buscar uno nuevo?',
					showConfirmButton: true,
					confirmButtonText: 'Retirar',
					showCancelButton: true,
					cancelButtonText: 'Cerrar',
					preConfirm: () => {
						this.customer = undefined;
					},
				};
				const alerer = Swal.fire(oe);
			}
		}
	}

	esperaFocus(focus: boolean) {
		let inter = setTimeout(() => {
			if (typeof this.modalBuy != 'undefined') {
				this.modalBuy.content.espera = true;
				if (focus) {
					this.modalBuy.content.canti.nativeElement.focus();
				}
				clearTimeout(inter);
				inter = null;
			} else {
				this.esperaFocus(focus);
			}
		}, 300);
	}

	addproductsearch(name) {
		return name;
    }

	openModalBuy(product: Product, focus= false) {
		if (typeof this.customer != 'undefined' && this.customer) {
			const initialState = {
				product,
				title: 'Añadir Producto',
				action: 'add',
			};
			this.modalService.onShown.subscribe(() => {
				this.esperaFocus(focus);
			});
			this.modalService.onHidden.subscribe(() => {
				this.espera = true;
				this.modalService.onHidden = null;
				this.modalService.onShown = null;
				this.modalService.onHidden = new EventEmitter();
				this.modalService.onShown = new EventEmitter();
			});
			this.modalBuy = this.modalService.show(BuyProductModalComponent, {initialState});
			this.modalBuy.content.evenAdd.subscribe(resp => {
				const position = Search.position(this.products, (ele: Product, value) => {
					return Search.igual(ele.precio_p.id, value);
				}, resp.precio_p.id);
				if ( position != -1) {
					resp.pedido += this.products[position].pedido;
				}
				this.buy_product(resp);
			});
			this.modalBuy.setClass('modal-dialog-scrollable modal-dialog-centered modal-xl');
		} else {
			this.espera = true;
			this.basicAlert('error', 'Necesita un Cliente', 'Asigne un cliente para realizar operaciones', 'Ok');
		}
	}

	openModalRmv(product: Product) {
		const initialState = {
			product: product,
			title: 'Remover Producto',
			action: 'rmv'
		};
		this.modalRmv = this.modalService.show(BuyProductModalComponent, {initialState});
		this.modalRmv.content.espera = true;
		this.modalRmv.content.evenAdd.subscribe(resp => {
			const position = Search.position(this.products, (ele: Product, value)=>{
				return Search.igual(ele.precio_p.id, value);
			}, resp.precio_p.id);
			if ( position != -1) {
				resp.pedido = this.products[position].pedido - resp.pedido;
			}
			this.buy_product(resp);
		});
		this.modalRmv.setClass('modal-dialog-scrollable modal-dialog-centered modal-xl');
	}

	openModalDocuments() {
		this.modalDocuments = this.modalService.show(SearchDocumentComponent);
		this.modalDocuments.content.selectEvent.subscribe(resp => {
			this.add_pedido(resp);
		});
		this.modalDocuments.content.removeEvent.subscribe(resp => {
			if (Cache.getCookie(session.pedidos)) {
				this.pedidos = Cache.getSession(session.pedidos, true);
			}
		});
		this.modalDocuments.setClass('modal-dialog-centered modal-xl');
	}

	openModalCierre() {
		const initialState = {
			cashing: this.stati
		};
		this.modalCierre = this.modalService.show(ModalCierreComponent, {initialState});
		this.modalCierre.content.eventAction.subscribe(resp => {
			Cache.removeItem(session.document_factura);
			Cache.removeItem(session.pedido);
			Cache.removeItem(session.pedidos);
			Cache.removeItem(session.document_pedido);
			Cache.removeItem(session.cash_open);
			this.router.navigate(['/cliente/pdv/cash_opening']);
		});
		this.modalCierre.setClass('modal-dialog-centered');
	}

	openModalTotalizar() {
		if (Cache.getSession(session.pedido)) {
			const initialState = {
				document_factura: this.factura_document
			};
			this.modalTotalizar = this.modalService.show(TotalizarComponent, {initialState});
			this.modalTotalizar.content.even_success.subscribe(resp => {
				if (Cache.getSession(session.pedidos)) {
					this.pedidos = Cache.getSession(session.pedidos, true);
				}
				this.subir_pedido();
				this.subir_factura();
				this.default_pedido();
				Cache.removeItem(session.pedido);
				const position = Search.position(this.pedidos, (x: Pedido, e) => {
					return Search.igual(x.headp.number, e);
				  }, resp.headp.number);
				if (position != -1) {
					this.pedidos.splice(position, 1);
					Cache.setSession(session.pedidos, this.pedidos);
				}
				if (typeof resp.fiscal_printer !== 'undefined') {
					this.setFiscalPrinterParams(resp.fiscal_model, resp.fiscal_vel, resp.fiscal_port);
				}
			});
			this.modalTotalizar.setClass('modal-dialog-centered modal-xl');
		} else {
			const oe: SweetAlertOptions = {
				icon: 'error',
				title: 'Requiere pedido',
				text: 'Se requiere tener un pedido para facturar',
				showConfirmButton: true,
				confirmButtonText: 'ok',
			};
			const alerer = Swal.fire(oe);
		}
	}

  	openModalList() {
		this.s_cus_loading = true;
		this.v_empty(this.search_cus).then( resp => {
			this.getCostumers(resp, (respc) => {
				this.s_cus_loading = null;
				if (respc.length == 1) {
					this.clientCreate({customer: respc[0]});
				} else {
					const initialState = {
						search_cus: resp,
						customerlist: respc
					};
					this.modalList = this.modalService.show(CustomerPDVComponent, {initialState});
					this.modalList.content.even_customer.subscribe( respu => {
						this.clientCreate(respu);
					});
					this.modalList.setClass('modal-dialog-centered modal-xl');
				}
			});
    	}).catch((err) => {
			const initialState = {
				search_cus: '',
			};
			this.modalList = this.modalService.show(CustomerPDVComponent, {initialState});
			this.modalList.content.even_customer.subscribe( respu => {
				this.clientCreate(respu);
			});
			this.modalList.setClass('modal-dialog-centered modal-xl');
			this.s_cus_loading = null;
		});
  	}

	openModalRegister() {
	  	this.modalRegister = this.modalService.show(ModalCreatecustomerPdvComponent);
	  	this.modalRegister.content.even_add.subscribe(resp => {
			this.clientCreate({customer: resp});
	  	});
	  	this.modalRegister.setClass('modal-lg');
	}

	buy_product(product: Product) {
		const ps = {
			units: product.pedido,
			unit_wight: product.unit_weight_m,
			lot_pk: product.precio_p.id,
			id_product: product.id_product,
			lot: product.precio_p.lot,
			id_opening: this.stati.id_opening
		};
		// Proceso de un nuevo producto (add o rmv)
		let p_product;
		let p_p;
		p_p = Search.position(this.products, (ele: Product, value) => {
			return Search.igual(ele.precio_p.id, value) && ele.error;
		}, product.precio_p.id);
		if ( p_p != -1) {
			// Si existe el producto y ha ocurrido un error antes, se reemplaza
			this.products[p_p].loading = true;
			this.products[p_p].error = false;
			this.products[p_p].pedido = product.pedido_item;
		} else {
			// Sino, se crea y se añade a la lista de productos
			p_product = Object.assign({}, product);
			p_product.pedido = p_product.pedido_item;
			this.products.push(p_product);
			p_p = this.products.length - 1;
		}
		this.sendPedido([ps], (resp) => {
			if (resp.success) {
				this.products.splice(p_p, 1);
				const position = Search.position(this.products, (ele: Product, value) => {
					return Search.igual(ele.precio_p.id, value);
				}, product.precio_p.id);
				product.loading = false;
				if ( position != -1) {
					this.products[position] = product;
					if (product.accion == 'rmv' && product.pedido == 0) {
						this.products = this.products.filter(( w, i) => {
							return i != position;
						});
					} else {
						this.products[position].accion = '';
					}
					this.valores();
				} else {
					product.accion = '';
					this.products.push(product);
					this.valores();
				}
				this.list_buy = this.list_buy.filter(( w, i) => {
					return w.units > 0;
				});
				let productos_sin_error = this.products.slice();
				productos_sin_error = productos_sin_error.filter(( w, i) => {
					return !w.error;
				});
				Cache.setSession(session.pedido, {
					cash: this.stati,
					headp: this.pedido_document,
					customer: this.customer,
					list_buy: this.list_buy,
					products: productos_sin_error,
					impuesto: this.impuesto,
					subtotal: this.subTotal
				});
				this.update_load_items();
			} else {
				this.products[p_p].loading = false;
				this.products[p_p].error = true;
				this.products[p_p].errortxt = resp.message;
			}
		});
	}

	add_pedido(pedido: Pedido) {
		this.pedidos = Cache.getSession(session.pedidos, true);
		Cache.setSession(session.pedido, pedido);
		this.default_pedido();
		this.pedido_document = pedido.headp;
		this.customer = pedido.customer;
		this.list_buy = pedido.list_buy;
		this.products = pedido.products;
		this.impuesto = pedido.impuesto;
		this.subTotal = pedido.subtotal;
		const position = Search.position(this.pedidos, (x: Pedido, e) => {
			return Search.igual(x.headp.number, e);
		}, pedido.headp.number);
		if (position != -1) {
			this.pedidos.splice(position, 1);
			Cache.setSession(session.pedidos, this.pedidos);
		}
	}

	removePedido() {
		// Anula el pedido actual
		const pedido = Cache.getSession(session.pedido, true);
		if (pedido) {
			new Confir().confir(
				`¿Desea Anular el Pedido actual?`,
				``,
				'warning'
			).then(() => {
				const initB: Init = {
					method: 'put',
					url: this.listUrl.url('remove_order'),
					auth: this.auth,
					body: {
						pedido: pedido.headp.number,
						id_opening: pedido.cash.id_opening
					}
				};
				const ajax = new Ajax(initB, {visible: true});
				ajax.call().then(resp => {
				if (typeof resp.pedido != 'undefined') {
					this.vaciarPedido(Number(resp.pedido));
				} else {
					this.vaciarPedido(pedido.headp.number);
				}
				}).catch(error => {
					this.basicAlert('error', 'Ocurrio un error inesperado', '', 'Ok');
				});
			});
		} else {
			this.basicAlert('error', 'Actualmente no posee un pedido activo', '', 'Ok');
		}
	}

	sendPedido(details, callback: any = false, visibl = false) {
		const initB: Init = {
			method: 'put',
			url: this.listUrl.url('process_order'),
			auth: this.auth,
			body: {
				head: {
					station_code: this.pedido_document.station_id,
					number: this.pedido_document.number,
					customer: this.customer.id_customers,
					id_opening: this.stati.id_opening
				},
				details
			}
		};
		const confiB: Config = {
			visible: visibl,
			validatejson: (resp, obj) => {
				return new Promise((success, error) => {
					if (typeof resp.success !== 'undefined' && resp.success) {
						success(resp);
					} else {
						error(resp);
					}
				});
			}
		}
		const ajaxB = new Ajax(initB, confiB);
		ajaxB.call().then(resp => {
			if (typeof resp.details != 'undefined' && resp.details.length > 0) {
				Search.forEachAll(resp.details, (element, key, result, next) => {
					let position = -1;
					if (this.list_buy.length) {
						position = Search.position(this.list_buy, (ele, value) => {
							return Search.igual(ele.lot_pk, value);
						}, element.lot_pk);
					}
					if ( position != -1) {
						this.list_buy[position] = element;
						next();
					} else {
						this.list_buy.push(element);
						next();
					}
				}, (all) => {
					callback(resp);
				}, true);
			}
		}).catch(resp => {
			callback(resp);
		});
	}

	subir_pedido() {
		let pedido_document = Cache.getSession(session.document_pedido, true);
		if (this.pedido_document.number < pedido_document.number) {
			this.pedido_document = pedido_document;
		} else {
			pedido_document.number = `${parseInt(this.pedido_document.number) + 1}`;
			this.pedido_document = pedido_document;
			Cache.setSession(session.document_pedido, this.pedido_document);
		}
	}

	subir_factura() {
		this.factura_document.number = `${parseInt(this.factura_document.number) + 1}`;
	}

	Stby() {
		if (Cache.getSession(session.pedido)) {
			this.sendPedido(this.list_buy, (resp) => {
				if (resp.success) {
					let pedido: Pedido = Cache.getSession(session.pedido, true);
					pedido.impuesto = this.impuesto;
					pedido.subtotal = this.subTotal;
					this.pedidos.push(pedido);
					Cache.setSession(session.pedidos, this.pedidos);
					Cache.removeSessionItem(session.pedido);
					this.subir_pedido();
					this.default_pedido();
				}
			}, true);
		} else {
			this.basicAlert('error', 'Actualmente no posee un pedido activo', '', 'Ok');
		}
	}

	imprimirFiscal(pedido: Pedido){
		this.FiscalPrinterService.iniciarTrabajo();
		this.FiscalPrinterService.datosCliente(pedido.customer.trade_name, this.FiscalPrinterService.tdCUIT,
		pedido.customer.nit, this.FiscalPrinterService.riResponsableInscripto, pedido.customer.address);
		this.FiscalPrinterService.abrirComprobante(this.FiscalPrinterService.tcFactura_A);
		this.FiscalPrinterService.imprimirTextoFiscal(pedido.customer.address);
		this.FiscalPrinterService.imprimirTextoFiscal('Teléfono: ' + pedido.customer.phone);
		this.FiscalPrinterService.imprimirTextoFiscal('Correo: ' + pedido.customer.email);
		var productos = pedido.products;
		for(var i = 0; i < productos.length; i++) {
			var producto = productos[i];
			this.FiscalPrinterService.imprimirItemSimple(producto.product_description,
				producto.pedido, producto.precio_p.base_price, producto.tax_p.tax);
		}
		this.FiscalPrinterService.subtotal();
		this.FiscalPrinterService.cerrarComprobante();
		this.FiscalPrinterService.finalizarTrabajo();
	}

	setFiscalPrinterParams(modelo, vel, puerto) {
		this.FiscalPrinterService.modelo = modelo;
		this.FiscalPrinterService.baudios = vel;
		this.FiscalPrinterService.puerto = puerto;
	}

	getCostumers(search_cus, callback) {
		const initAjax: Init = {
			method: 'put',
			url: this.listUrl.url('get_customers'),
			auth: this.auth,
			body: {
				search: search_cus
			},
		};
		const configAjax: Config = {
			visible: false
		};
		const ajax = new Ajax(initAjax, configAjax);
		ajax.call().then(respo => {
			if (respo.success) {
				callback(respo.customers);
			} else {
				callback(false);
			}
		}).catch(error => {
			callback(false);
		});
	}

	onscroll(event: any) {
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
			this.load_more_item();
		}
	}

	load_documents() {
		const initD: Init = {
			method: 'put',
			auth: this.auth,
			url: this.listUrl.url('get_documents'),
			body: {
				station: this.stati.station_id
			}
		};
		const configD: Config = {
			visible: false,
			autoReNew: true
		};
		const ajax = new Ajax(initD, configD);
		ajax.call().then( resp => {
			if (resp.success) {
				this.factura_document = resp.bill;
				this.pedido_document = resp.order;
				this.factura_document.number = `${parseInt(this.factura_document.number) + 1}`;
				this.pedido_document.number = `${parseInt(this.pedido_document.number) + 1}`;
				Cache.setSession(session.document_factura, this.factura_document);
				Cache.setSession(session.document_pedido, this.pedido_document);
			}
		}).catch();
	}

	load_station_config() {
		const initD: Init = {
			method: 'put',
			auth: this.auth,
			url: this.listUrl.url('get_station_config'),
			body: {
				station: this.stati.station_id
			}
		};
		const configD: Config = {
			visible: false,
			autoReNew: true
		};
		const ajax = new Ajax(initD, configD);
		ajax.call().then( resp => {
			if (resp.success && resp.sta.length) {
				this.noimagen = resp.sta[0].no_img;
			}
		}).catch((err) => {
			// do nothing
		});
	}

	load_setting() {
		const initD: Init = {
			method: 'put',
			auth: this.auth,
			url: this.listUrl.url('get_setting'),
			body: {
				billing: true
			}
		};
		const configD: Config = {
			visible: false,
			autoReNew: true
		};
		const ajax = new Ajax(initD, configD);
		ajax.call().then( resp => {
			if (resp.success) {
				let decimals = '1.2-2';
				this.currency_symbol = resp.setting['simbol'];
				this.currency_region = resp.setting['region'];
				const deci = resp.setting['decimals'];
				if (deci == 1) { 
					decimals = '1.1-1';
				} else if (deci != 0 && deci <= 8) {
					decimals = '1.2-' + deci;
				}
				this.currency_decimals = decimals;
			}
		}).catch((err) => {
			// do nothing
		});
	}

	llenar_ceros(numer: string) {
    	return numer.padStart(9, '0');
  	}

	valores() {
		this.impuesto = 0;
		this.subTotal = 0;
		this.products.forEach(product => {
			if(!product.error && !product.loading) {
				this.subTotal += product.precio_p.base_price * product.pedido;
				if (product.tax_p) {
					this.impuesto += (product.precio_p.base_price * product.pedido) * (product.tax_p.tax / 100);
				}
			}
		});
		this.subTotal = this.formatoPrecio(this.subTotal);
		this.impuesto = this.formatoPrecio(this.impuesto);
	}

	quetityReal(product: Product) {
		return Math.abs(product.inventory_p.quantity - product.inventory_p.reserved);
	}

  	producsum(product: Product) {
    	if (product.pedido > 0) {
      		return this.formatoPrecio(product.precio_p.base_price * product.pedido);
    	} else {
      		return this.formatoPrecio(product.precio_p.base_price);
    	}
  	}

    block_user() {
		if (Cache.getSession(session.pedido)) {
			this.basicAlert('error', 'Hay un Pedido en Espera', 'Guarde el pedido antes de bloquear', 'Ok');
		} else {
			const optionsblock: SweetAlertOptions = {
				icon: 'question',
				title: '¿Desea bloquear temporalmente?',
				text: 'El sistema le solicitará su contraseña para volver a acceder',
				showConfirmButton: true,
				showCancelButton: true,
				showCloseButton: false,
				cancelButtonText: 'Cancelar',
				timer: 0,
				confirmButtonText: 'Bloquear',
				preConfirm: () => {
					if (this.auth.block() && this.auth.block_url('/cliente/pdv/billing')) {
						Cache.removeItem(session.pedido);
						Cache.removeItem(session.pedidos);
						Cache.removeItem(session.cash_open);
						Cache.removeItem(session.document_factura);
						Cache.removeItem(session.document_pedido);
						this.router.navigateByUrl('/block');
					}
				}
			};
			Swal.mixin(optionsblock).fire();
		}
	}

	contrae_expande() {
		this.contraer = !this.contraer;
		this.contraer_txt = this.contraer ? 'Expandir' : 'Contraer';
	}

	formatoPrecio(n: number) {
		return Number(n.toFixed(2));
	}

	basicAlert(icon, title, text, txtboton) {
		const oe: SweetAlertOptions = {
			icon,
			title,
			text,
			showConfirmButton: true,
			confirmButtonText: txtboton
		};
		const alerer = Swal.fire(oe);
	}

	vaciarPedido(pedido_number) {
		// Elimina un pedido del front segun el number que se provea
		this.pedidos = Cache.getSession(session.pedidos, true);
		if (this.pedidos) {
			const position = Search.position(this.pedidos, (x: Pedido, e) => {
				return Search.igual(x.headp.number, e);
			}, pedido_number);
			if (position != -1) {
				this.pedidos.splice(position, 1);
				Cache.setSession(session.pedidos, this.pedidos);
			}
		}
		Cache.removeSessionItem(session.pedido);
		this.default_pedido();
		this.update_load_items();
	}

	deletepoductError(i) {
		this.products.splice(i, 1);
	}

}
