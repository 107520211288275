import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {

  title = 'Bloqueado';
  username: string | null = null;
  password: string | null = null;
  remember = false;
  return = '';

  constructor(
    private titleService: Title,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    this.username = this.auth.user.username;
  }

  async onSubmit() {
    if (this.password && this.password !== '') {
      try {
        const login = await this.auth.desblock(this.password);
        if (login) {
          this.router.navigateByUrl(this.auth.block_return);
          this.auth.block_url('/');
        }
      } catch (error) {
      }
    }
  }
}
