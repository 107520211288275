import { Component, ElementRef, OnInit, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CatalogoOneService } from 'src/app/cliente/administrativo/services/catalogo-one.service';
import { CatalogoThreeService } from 'src/app/cliente/administrativo/services/catalogo-three.service';
import { CatalogotwoService } from 'src/app/cliente/administrativo/services/catalogotwo.service';
import { SellersService } from 'src/app/cliente/administrativo/services/sellers.service';
import { SupervisorsService } from 'src/app/cliente/administrativo/services/supervisors.service';
import { SupplierService } from 'src/app/cliente/administrativo/services/supplier.service';
import Swal from 'sweetalert2';
import { Ajax, Config } from '../ajax/ajax';
import { Init } from '../ajax/init-ajax';
import { AuthService } from '../auth/auth.service';
import { ResolvingService } from './resolving.service';

@Component({
  selector: 'app-conflicts-deleting',
  inputs: ['text1'],
  templateUrl: './conflicts-deleting.component.html',
  styleUrls: ['./conflicts-deleting.component.css']
})
export class ConflictsDeletingComponent implements OnInit {

  @ViewChild('my_check', {static: true}) my_check: ElementRef;

  formResolving: FormGroup;
  typeOp: number;
  allCode: number | null = null;
  allDesc: number | null = null;
  arryItems = [];
  error_type: boolean = false;
  txt_type: string = '';
  error_all_code: boolean = false;
  txt_all_code: string = '';
  error_all_desc: boolean = false;
  txt_all_desc: string = '';
  allEdit: boolean = true;
  textSeleccion = 'Seleccionar Todos.';
  allSolution: boolean = false;
  textCrear: string = 'Crear';
  loaded_items: Array<any> = [] as any;
  prohibited: Array<any> = [] as any;
  is_continue: boolean;
  is_invalid: boolean = false;
  error_one: Array<any> = [] as any;
  alerts: any = [
    {
      type: 'primary',
      msg: `<strong>Guardar y Continuar:</strong> Guarda los cambios y Continua a eliminar ${this.ResolvingService.strConecDelete}.`
    },
    {
      type: 'info',
      msg: `<strong>Guardar:</strong> Solo Guarda los cambios, sin eliminar ${this.ResolvingService.strConecDelete}.`
    },
    {
      type: 'danger',
      msg: `<strong>Cerrar:</strong> Cierra el proceso actual sin guardar los cambios.`
    }
  ];

  constructor(
    public ResolvingService: ResolvingService,
    public renderer: Renderer2,
    public CatalogoOneService: CatalogoOneService,
    public CatalogotwoService: CatalogotwoService, 
    public CatalogoThreeService: CatalogoThreeService,
    public SupplierService: SupplierService,
    public SellersService: SellersService,
    public SupervisorsService: SupervisorsService,
    public auth: AuthService,
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private configSelect: NgSelectConfig,
    sanitizer: DomSanitizer
  ) {
    this.configSelect.notFoundText = this.ResolvingService.tipo+' no encontrado';
    this.formResolving = this.fb.group({
      typeoption: this.fb.control(this.typeOp),
      code: this.fb.control(this.allCode),
      description: this.fb.control(this.allDesc),
      items: this.fb.array([])
    });
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.is_continue = false;
    this.ResolvingService.init = true;
    this.ResolvingService.is_continue = false;
    if (typeof this.ResolvingService.prohibited !== 'undefined') {
      this.prohibited = this.ResolvingService.prohibited;
    }
    if (this.ResolvingService.tipo) {
      this.textCrear = this.textCrear+' '+this.ResolvingService.tipo;
    } else {
      this.textCrear = this.textCrear+' Nuevo';
    }
    this.addItemForm();
  }

  addItemForm() {
    for (const key in this.ResolvingService.valuesObjects) {
      if (Object.prototype.hasOwnProperty.call(this.ResolvingService.valuesObjects, key)) {
        const elem = this.ResolvingService.valuesObjects[key];
        this.error_one.push({'error_code': false, 'txt_code': ''})
        this.arryItems.push({"item": elem.id, "item_code": elem.code+' - '+elem.description, "actual": this.ResolvingService.code, "check": false, "code": null, "desc": null, "tipo": elem.tipo});
        this.addItem(key);
      }
    }
  }

  get typeoption() { return this.formResolving.get('typeoption'); };
  get code() { return this.formResolving.get('code'); };
  get description() { return this.formResolving.get('description'); };
  get items(): FormArray {
    return this.formResolving.get('items') as FormArray;
  }; 

  addItem(cont) {
    const product = this.fb.group({
      check: this.fb.control(this.arryItems[cont]['check']),
      item: this.fb.control({value: this.arryItems[cont]['item'], disabled: true}),
      code: this.fb.control({value: this.arryItems[cont]['code'], disabled: true}),
      description: this.fb.control({value: this.arryItems[cont]['desc'], disabled: true}),
      tipo: this.fb.control({value: this.arryItems[cont]['tipo'], disabled: true})
    });
    this.items.push(product);
  }

  closeModal(){
    this.ResolvingService.closeModal.hide();
  }

  changeSelection() {
    if (this.typeOp) {
      var total = this.items.length;
      if (total == 1) {
        this.textSeleccion = 'Ha seleccionado '+this.items.length+' elemento en total.';
      } else {
        this.textSeleccion = 'Ha seleccionado '+this.items.length+' elementos en total.';
      }
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items.at(index);
        element.get('check').setValue(true);
        element.get('code').setValue(this.code.value);
        element.get('description').setValue(this.description.value);
        this.error_one[index].error_code = false;
        this.error_one[index].txt_code = '';
        for (const key in this.prohibited) {
          if (Object.prototype.hasOwnProperty.call(this.prohibited, key)) {
            const elem = this.prohibited[key];
            if (elem.tipo == element.get('tipo').value) {
              for (const lla in elem.items) {
                if (Object.prototype.hasOwnProperty.call(elem.items, lla)) {
                  const prohi = elem.items[lla];
                  if (prohi.id == element.get('code').value) {
                    this.error_one[index].error_code = true;
                    this.error_one[index].txt_code = elem.text_error;
                    break;
                  }
                }
              }
              break;
            }
          }
        }
      }
    } else {
      this.textSeleccion = 'Seleccionar Todos.';
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items.at(index);
        element.get('check').setValue(false);
        element.get('code').setValue(null);
        element.get('description').setValue(null);
        this.error_one[index].error_code = false;
        this.error_one[index].txt_code = '';
      }
    }
    this.checkError();
  }

  changeOneCheck(index) {
    this.error_one[index].error_code = false;
    this.error_one[index].txt_code = '';
    var total_check = 0;
    if (this.items.at(index).get('check').value) {
      this.items.at(index).get('code').setValue(this.code.value);
      this.items.at(index).get('description').setValue(this.description.value);
    } else {
      this.items.at(index).get('code').setValue(null);
      this.items.at(index).get('description').setValue(null);
    }
    for (let key = 0; key < this.items.length; key++) {
      const element = this.items.at(key);
      if (element.get('check').value) {
        total_check = total_check + 1;
      }
    }
    if (total_check > 0) {
      this.typeoption.setValue(true);
      if (total_check != this.items.length) {
        this.renderer.setProperty(this.my_check.nativeElement, 'indeterminate', true);
      } else {
        this.renderer.setProperty(this.my_check.nativeElement, 'indeterminate', false);
      }
      if (total_check == 1) {
        this.textSeleccion = 'Ha seleccionado '+total_check+' elemento en total.';
      } else {
        this.textSeleccion = 'Ha seleccionado '+total_check+' elementos en total.';
      }
    } else {
      this.typeoption.setValue(false);
      this.renderer.setProperty(this.my_check.nativeElement, 'indeterminate', false);
      this.textSeleccion = 'Seleccionar Todos.';
    }
    for (const key in this.prohibited) {
      if (Object.prototype.hasOwnProperty.call(this.prohibited, key)) {
        const elem = this.prohibited[key];
        if (elem.tipo == this.items.at(index).get('tipo').value) {
          for (const lla in elem.items) {
            if (Object.prototype.hasOwnProperty.call(elem.items, lla)) {
              const prohi = elem.items[lla];
              if (prohi.id == this.items.at(index).get('code').value) {
                this.error_one[index].error_code = true;
                this.error_one[index].txt_code = elem.text_error;
                break;
              }
            }
          }
          break;
        }
      }
    }
    this.checkError();
  }

  checkError() {
    let havy_error: boolean = false;
    for (const key in this.error_one) {
      if (Object.prototype.hasOwnProperty.call(this.error_one, key)) {
        const error = this.error_one[key];
        if (error.error_code) {
          havy_error = true;
          break;
        }
      }
    }
    if (havy_error) {
      this.is_invalid = true;
    } else {
      this.is_invalid = false;
    }
  }

  getCode() {
    if (this.ResolvingService.pk != this.code.value) {
      this.description.setValue(this.code.value);
      this.getValues(this.code.value);
      this.error_all_code = false;
      this.txt_all_code = '';
      this.error_all_desc = false;
      this.txt_all_desc = '';
    } else {
      this.error_all_code = true;
      this.txt_all_code = 'Valor no permitido.';
      this.code.setValue(null);
      this.description.setValue(null);
      this.getValues(null);
    }
  }

  getDesc() {
    if (this.ResolvingService.pk != this.code.value) {
      this.code.setValue(this.description.value);
      this.getValues(this.description.value);
      this.error_all_code = false;
      this.txt_all_code = '';
      this.error_all_desc = false;
      this.txt_all_desc = '';
    } else {
      this.error_all_desc = true;
      this.txt_all_desc = 'Valor no permitido.';
      this.code.setValue(null);
      this.description.setValue(null);
      this.getValues(null);
    }
  }

  getValues(valor) {
    for (let index = 0; index < this.items.length; index++) {
      const element = this.items.at(index);
      this.error_one[index].error_code = false;
      this.error_one[index].txt_code = '';
      if (element.get('check').value) {
        element.get('code').setValue(valor);
        element.get('description').setValue(valor);
        for (const key in this.prohibited) {
          if (Object.prototype.hasOwnProperty.call(this.prohibited, key)) {
            const elem = this.prohibited[key];
            if (elem.tipo == element.get('tipo').value) {
              for (const lla in elem.items) {
                if (Object.prototype.hasOwnProperty.call(elem.items, lla)) {
                  const prohi = elem.items[lla];
                  if (prohi.id == element.get('code').value) {
                    this.error_one[index].error_code = true;
                    this.error_one[index].txt_code = elem.text_error;
                    break;
                  }
                }
              }
              break;
            }
          }
        }
      } else {
        element.get('code').setValue(null);
        element.get('description').setValue(null);
      }      
    }
    this.checkError();
  }

  addNew() {
    this.ResolvingService.modelo = 'Conflicto';
    this.ResolvingService.typeModal = 'conflicto';
    
    if (this.ResolvingService.component == 'Modals-One') {
      this.ResolvingService.evenAdd.subscribe(resp => {
        if (typeof resp.new !== 'undefined' && this.ResolvingService.loadselect) {
          this.ResolvingService.valuesList.unshift({'id': resp.new.id,'code': resp.new.code, 'description': resp.new.description});
          this.code.setValue(resp.new.id);
          this.description.setValue(resp.new.id);
          this.getValues(resp.new.id);
          this.ResolvingService.loadselect = false;
        }
      });
      this.CatalogoOneService.newMU = true;
      this.modalRef = this.modalService.show(this.ResolvingService.viewModal);
      this.modalRef.setClass('modal-dialog-centered zindex-one');
      this.CatalogoOneService.closeModal = this.modalRef;
    } else if (this.ResolvingService.component == 'Modals-Double') {
      this.ResolvingService.evenAdd.subscribe(resp => {
        if (typeof resp.new !== 'undefined' && this.ResolvingService.loadselect) {
          this.ResolvingService.valuesList.unshift({'id': resp.new.id_secun,'code': resp.new.code_secun, 'description': resp.new.desc_secun});
          this.code.setValue(resp.new.id_secun);
          this.description.setValue(resp.new.id_secun);
          this.getValues(resp.new.id_secun);
          this.ResolvingService.loadselect = false;
        }
      });
      this.CatalogotwoService.newMD = true;
      this.modalRef = this.modalService.show(this.ResolvingService.viewModal);
      this.modalRef.setClass('modal-dialog-centered zindex-one');
      this.CatalogotwoService.closeModal = this.modalRef;
    } else if (this.ResolvingService.component == 'Modals-Third') {
      this.ResolvingService.evenAdd.subscribe(resp => {
        if (typeof resp.new !== 'undefined' && this.ResolvingService.loadselect) {
          this.ResolvingService.valuesList.unshift({'id': resp.new.id_third,'code': resp.new.code_secun+' - '+resp.new.code_third, 'description': resp.new.code_secun+' - '+resp.new.desc_third});
          this.code.setValue(resp.new.id_third);
          this.description.setValue(resp.new.id_third);
          this.getValues(resp.new.id_third);
          this.ResolvingService.loadselect = false;
        }
      });
      this.CatalogoThreeService.newMT = true;
      this.modalRef = this.modalService.show(this.ResolvingService.viewModal);
      this.modalRef.setClass('modal-dialog-centered');
      this.CatalogoThreeService.closeModal = this.modalRef;
    } else if (this.ResolvingService.component == 'Modal-Supplier') {
      this.ResolvingService.evenAdd.subscribe(resp => {
        if (typeof resp.new !== 'undefined' && this.ResolvingService.loadselect) {
          this.ResolvingService.valuesList.unshift({'id': resp.new.id, 'code': resp.new.code, 'description': resp.new.name});
          this.code.setValue(resp.new.id);
          this.description.setValue(resp.new.id);
          this.getValues(resp.new.id);
          this.ResolvingService.loadselect = false;
        }
      });
      this.SupplierService.new = true;
      this.SupplierService.modelo = this.ResolvingService.modelo;
      this.SupplierService.modalType = this.ResolvingService.typeModal;
      this.modalRef = this.modalService.show(this.ResolvingService.viewModal);
      this.modalRef.setClass('modal-dialog-centered modal-xl mod_tab');
      this.SupplierService.closeModal = this.modalRef;
    } else if (this.ResolvingService.component == 'Seller') {
      this.ResolvingService.evenAdd.subscribe(resp => {
        if (typeof resp.new !== 'undefined' && this.ResolvingService.loadselect) {
          this.ResolvingService.valuesList.unshift({'id': resp.new.pk, 'code': resp.new.code, 'description': resp.new.desc});
          this.code.setValue(resp.new.pk);
          this.description.setValue(resp.new.pk);
          this.getValues(resp.new.pk);
          this.ResolvingService.loadselect = false;
        }
      });
      this.SellersService.new = true;
      this.SellersService.modelo = this.ResolvingService.modelo;
      this.SellersService.isCatalog = false;
      this.SellersService.modalType = this.ResolvingService.typeModal;
      this.modalRef = this.modalService.show(this.ResolvingService.viewModal);
      this.modalRef.setClass('modal-dialog-centered');
      this.SellersService.closeModal = this.modalRef;
    } else if (this.ResolvingService.component == 'Supervisor') {
      this.ResolvingService.evenAdd.subscribe(resp => {
        if (typeof resp.new !== 'undefined' && this.ResolvingService.loadselect) {
          this.ResolvingService.valuesList.unshift({'id': resp.new.id, 'code': resp.new.code, 'description': resp.new.description});
          this.code.setValue(resp.new.id);
          this.description.setValue(resp.new.id);
          this.getValues(resp.new.id);
          this.ResolvingService.loadselect = false;
        }
      });
      this.SupervisorsService.new = true;
      this.SupervisorsService.modelo = this.ResolvingService.modelo;
      this.SupervisorsService.isCatalog = false;
      this.SupervisorsService.modalType = this.ResolvingService.typeModal;
      this.modalRef = this.modalService.show(this.ResolvingService.viewModal);
      this.modalRef.setClass('modal-dialog-centered modal-xl mod_tab');
      this.SupervisorsService.closeModal = this.modalRef;
    }
  }

  load_preview() {
    let list_add = [] as any;
    if (this.code.value) {
      if (this.ResolvingService.pk != this.code.value) {
        this.error_all_code = false;
        this.txt_all_code = '';
        this.error_all_desc = false;
        this.txt_all_desc = '';
        for (let index = 0; index < this.items.length; index++) {
          const element = this.items.at(index);
          if (element.get('check').value) {
            let exist = false;
            let it: any = null;
            for (const key in this.loaded_items) {
              if (Object.prototype.hasOwnProperty.call(this.loaded_items, key)) {
                const elem = this.loaded_items[key];
                if (elem.mover_a == this.code.value) {
                  it = key;
                  exist = true;
                  break;
                }
              }
            }
            if (exist) {
              this.loaded_items[it].items.unshift({'item':element.get('item').value, 'tipo': element.get('tipo').value})
            } else {
              this.loaded_items.unshift({'mover_a': this.code.value, 'items': [{'item':element.get('item').value, 'tipo': element.get('tipo').value}]});
            }
            list_add.unshift({'item':element.get('item').value, 'tipo': element.get('tipo').value});
          }     
        }
        for (const key in list_add) {
          if (Object.prototype.hasOwnProperty.call(list_add, key)) {
            const elem = list_add[key];
            const long = this.ResolvingService.valuesObjects.length;
            if (long > 0) {
              for (let i = long-1; i >= 0; i--) {
                const elemen = this.ResolvingService.valuesObjects[i];
                if (elemen.id == elem.item && elemen.tipo == elem.tipo) {
                  this.ResolvingService.valuesObjects.splice(i, 1);
                  break;
                }
              }
            }
          }
        }
        this.error_one = [];
        this.arryItems = [];
        this.code.setValue(null);
        this.description.setValue(null);
        this.items.clear();
        this.addItemForm();
        if (this.ResolvingService.valuesObjects.length == 0) {
          this.allSolution = true;
          this.ResolvingService.noteResolving = 'Lea las siguientes notas.';
        } else {
          this.typeoption.setValue(false);
          this.renderer.setProperty(this.my_check.nativeElement, 'indeterminate', false);
          this.textSeleccion = 'Seleccionar Todos.';
        }
      } else {
        this.error_all_code = true;
        this.txt_all_code = 'Valor no permitido.';
        this.code.setValue(null);
        this.description.setValue(null);
        this.getValues(null);
      }
    } else {
      this.error_all_code = true;
      this.txt_all_code = 'Debe Seleccionar una Opcion.';
    }
  }

  saveConflict() {
    const initAjax: Init = {
      method: 'put',
      url: this.ResolvingService.urlResolving,
      auth: this.auth,
      body: {
        resolving: this.loaded_items,
        different: this.ResolvingService.pk
      }
    };
    const configAjax: Config = {
      numInten : 1,
      titleLoading: `Guardando Cambios`,
      titleSuccess: '',
      titleErrorNormal: 'Error',
      textErrorNormal: 'Ha ocurrido un Error.',
      confirmButtonTextError: 'Cerrar',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };

    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.ResolvingService.closeModal.hide();
        if (this.is_continue) {
          this.ResolvingService.is_continue = true;
          this.ResolvingService.evenAdd.emit({'continue': this.is_continue});
        }
      }
    }).catch(error => {
      return error;
    });
  }

  saveContinueConflict() {
    if (this.ResolvingService.valuesObjects.length == 0 && this.items.length == 0) {
      this.is_continue = true;
      this.saveConflict();
    } else {
      this.ResolvingService.closeModal.hide();
    }
  }

  saveJustConflict() {
    if (this.ResolvingService.valuesObjects.length == 0 && this.items.length == 0) {
      this.is_continue = false;
      this.saveConflict();
    } else {
      this.ResolvingService.closeModal.hide();
    }
  }

}