import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiscalPrinterService {

  	usarHttps = false;
  	host = "localhost";
  	http = new XMLHttpRequest();
  // Comandos Fiscales

	cmAbrirComprobante = 0;
	cmDatosCliente = 1;
	cmCancelarComprobante = 2;
	cmCierreX = 3;
	cmCierreZ = 4;
	cmImprimirTextoFiscal = 5;
	cmImprimirItem = 6;
	cmImprimirPago = 7;
	cmImprimirDescuento = 8;
	cmImprimirDescuentoUltimoItem = 9;
	cmCerrarComprobante = 10;
	cmReporteZFechas = 11;
	cmObtenerFechaHora = 12;
	cmEspecificarEncabezado = 13;
	cmEspecificarPie = 14;
	cmInformarPercepcionGlobal = 15;
	cmInformarPercepcionIVA = 16;
	cmDocumentoDeReferencia = 17;
	cmObtenerDatosDeInicializacion = 18;
	cmSubtotal = 19;
	cmUltimoComprobante = 20;
	cmImprimirItem2g = 21;
	cmImprimirPago2g = 22;
	cmImprimirOtrosTributos = 23;
	cmDocumentoDeReferencia2g = 24;
	cmEspecificarFechaHora = 25;
	cmCargarTransportista = 26;
	cmImprimirConceptoRecibo = 27;
	cmImprimirTextoNoFiscal = 28;
	cmCopias = 29;
	cmCortarPapel = 30;
	cmPrecioBase = 31;
	cmSetearTipoComprobante = 32;
	cmObtenerPrimerBloqueReporteElectronico = 33;
	cmObtenerSiguienteBloqueReporteElectronico = 34;
	cmAbrirCajon = 35;
	cmReporteZNumeros = 36;
	cmIniciarCargaLogoEmisor = 37;
	cmEliminarLogoEmisor = 38;
	cmCargarCodigoBarras = 39;
	cmConfigurarImpresoraFiscal = 40;
	cmIniciarCargaLogoAdicional = 41;
	cmEliminarLogoAdicional = 42;
	cmCambiarLogoUsuario = 43;
	cmCrearCodigoQR = 44;
	cmEliminarCodigoQR = 45;
	cmCopiarComprobante = 46;
	cmConsultarAcumuladosMT = 47;
	cmImprimirSimple = 48;
	cmCierreParcial = 49;

	// TipoDeComprobante
	tcNo_Fiscal = 0;
	tcFactura_A = 1;
	tcFactura_B = 2;
	tcFactura_C = 3;
	tcNota_Debito_A = 4;
	tcNota_Debito_B = 5;
	tcNota_Debito_C = 6;
	tcNota_Credito_A = 7;
	tcNota_Credito_B = 8;
	tcNota_Credito_C = 9;
	tcTicket = 10;
	tcRemito = 11;
	tcTiqueNotaCredito = 12;
	tcRemitoX = 13;
	tcReciboX = 14;
	tcReciboA = 15;
	tcReciboB = 16;
	tcReciboC = 17;
	tcPresupuestoX = 18;
	tcCierreZ = 19;

	// codigos de error

	errNoError = 0;
	errControladorNoDisponible = 1;
	errComandoInvalido = 2;
	errParametroInvalido = 3;
	errExcepcion = 4;
	errMemoriaFiscal = 5;
	errMemoriaTrabajo = 6;
	errBateriaBaja = 7;
	errComandoDesconocido = 8;
	errDesbordamientoTotales = 9;
	errMemoriaFiscalLlena = 10;
	errMemoriaFiscalCasiLlena = 11;
	errFallaImpresora = 13;
	errImpresoraFueraLinea = 14;
	errFaltaPapelDiario = 15;
	errFaltaPapelTicket = 16;
	errTapaImpresoraAbierta = 18;
	errCajonCerradoOAusente = 19;
	errCampoDatosInvalido = 20;

	// Modelos de impresora

	modHasar715 = 0;
	modHasar715v2 = 2;
	modHasar615 = 3;
	modHasar320 = 4;
	modHasarPR4F = 5;
	modHasarPR5F = 6;
	modHasar950 = 7;
	modHasar951 = 8;
	modHasar441 = 9;
	modHasar321 = 10;
	modHasar322 = 11;
	modHasar322v2 = 12;
	modHasar330 = 13;
	modHasar1120 = 14;
	modHasarPL8F = 15;
	modHasarPL8Fv2 = 16;
	modHasarPL23 = 17;
	modEpsonTM300AF = 18;
	modEpsonTMU220AF = 19;
	modEpsonTM2000 = 20;
	modEpsonTM2000AFPlus = 21;
	modEpsonLX300 = 22;
	modHasarPT1000F = 23;
	modEpsonTMT900FA = 24;
	modEpsonTMU220AFII = 25;

	// Tipos de documento

	tdCUIT = 0;
	tdDNI = 1;
	tdPasaporte = 2;
	tdCedula = 3;

	// Responsabilidad ante IVA

	riResponsableInscripto = 0;
	riMonotributo = 1;
	riExento = 3;
	riConsumidorFinal = 4;

	// Tipos de Tributos

	SinImpuesto = 0;
	ImpuestosNacionales = 1;
	ImpuestosProvinciales = 2;
	ImpuestosMunicipales = 3;
	ImpuestosInternos = 4;
	IIBB = 5;
	PercepcionIVA = 6;
	PercepcionIIBB = 7;
	PercepcionImpuestosMunicipales = 8;
	OtrasPercepciones = 9;
	ImpuestoInternoItem = 10;
	OtrosTributos = 11;

	// Condiciones de IVA

	NoGravado = 1;
	Exento = 2;
	Gravado = 7;

	// Unidades de medida

	SinDescripcion = 0;
	Kilo = 1;
	Metro = 2;
	MetroCuadrado = 3;
	MetroCubico = 4;
	Litro = 5;
	KWH = 6;
	Unidad = 7;
	Par = 8;
	Docena = 9;
	Quilate = 10;
	Millar = 11;
	MegaUInterActAntib = 12;
	UnidadInternaActInmung = 13;
	Gramo = 14;
	Milimetro = 15;
	MilimetroCubico = 16;
	Kilometro = 17;
	Hectolitro = 18;
	MegaUnidadIntActInmung = 19;
	Centimetro = 20;
	KilogramoActivo = 21;
	GramoActivo = 22;
	GramoBase = 23;
	UIACTHOR = 24;
	JuegoPaqueteMazoNaipes = 25;
	MUIACTHOR = 26;
	CentimetroCubico = 27;
	UIACTANT = 28;
	Tonelada = 29;
	DecametroCubico = 30;
	HectometroCubico = 31;
	KilometroCubico = 32;
	Microgramo = 33;
	Nanogramo = 34;
	Picogramo = 35;
	MUIACTANT = 36;
	UIACTIG = 37;
	Miligramo = 41;
	Mililitro = 47;
	Curie = 48;
	Milicurie = 49;
	Microcurie = 50;
	UInterActHormonal = 51;
	MegaUInterActHor = 52;
	KilogramoBase = 53;
	Gruesa = 54;
	MUIACTIG = 55;
	KilogramoBruto = 61;
	Pack = 62;
	Horma = 63;
	Donacion = 90;
	Ajustes = 91;
	Anulacion = 96;
	SenasAnticipos = 97;
	OtrasUnidades = 98;
	Bonificacion = 99;

	// Medios de pago

	Cambio = 0;
	CartaDeCreditoDocumentario = 1;
	CartaDeCreditoSimple = 2;
	Cheque = 3;
	ChequeCancelatorios = 4;
	CreditoDocumentario = 5;
	CuentaCorriente = 6;
	Deposito = 7;
	Efectivo = 8;
	EndosoDeCheque = 9;
	FacturaDeCredito = 10;
	GarantiaBancaria = 11;
	Giro = 12;
	LetraDeCambio = 13;
	MedioDePagoDeComercioExterior = 14;
	OrdenDePagoDocumentaria = 15;
	OrdenDePagoSimple = 16;
	PagoContraReembolso = 17;
	RemesaDocumentaria = 18;
	RemesaSimple = 19;
	TarjetaDeCredito = 20;
	TarjetaDeDebito = 21;
	Ticket = 22;
	TransferenciaBancaria = 23;
	TransferenciaNoBancaria = 24;
	OtrosMediosPago = 99;

	// Tipos de impuestointerno

	tiFijo = 0;
	tiPorcentaje = 1;

	// Tipos de reporte electronico

	trfecha = 0;
	trNroCierre = 1;
	cmd: any;
	params: any;
	printerModels = [{name: 'Hasar715', id: 0}, {name: 'Hasar715v2', id: 2}, {name: 'Hasar615', id: 3},
  							{name: 'Hasar320', id: 4}, {name: 'HasarPR4', id: 5}, {name: 'HasarPR5F', id: 6},
  							{name: 'Hasar950', id: 7}, {name: 'Hasar951', id: 8}, {name: 'Hasar441', id: 9},
  							{name: 'Hasar321', id: 10}, {name: 'Hasar322', id: 11}, {name: 'Hasar322v2', id: 12},
  							{name: 'Hasar330', id: 13}, {name: 'Hasar1120', id: 14}, {name: 'HasarPL8F', id: 15},
  							{name: 'HasarPL8Fv2', id: 16}, {name: 'HasarPL23', id: 17}, {name: 'EpsonTM300AF', id: 18},
  							{name: 'EpsonTMU220AF', id: 19}, {name: 'EpsonTM2000', id: 20}, {name: 'EpsonTM2000AFPlus', id: 21},
  							{name: 'EpsonLX300', id: 22}, {name: 'HasarPT1000F', id: 23}, {name: 'EpsonTMT900FA', id: 24},
  							{name: 'EpsonTMU220AFII', id: 25 }, {name: 'HazarVE', id: 26}, {name: 'EpsonVE', id: 27}, {name: 'BioVE', id: 28}];
    puertos = [{name: 'COM1', id: 1}, {name: 'COM2', id: 2}, {name: 'COM3', id: 3},
    {name: 'COM4', id: 4}, {name: 'COM5', id: 5}, {name: 'COM6', id: 6},
    {name: 'COM7', id: 7}];
  	velPrinter = [{name: 9600, id: 1}, {name: 115200, id: 2}];

  	response: any;
  	request: any;
  	work: any;
  	puerto = 1;
  	modelo = 0;
  	baudios = 9600;
  	datosCli = { nombre: "Abel Miranda Cordovi %", tipoDeDocumento: this.tdCUIT, documento: "20939802593", responsIVA: this.riResponsableInscripto, direccion: "Haefreingue 1686"};
  	errorCode = 0;
    errorDesc = "";
    lastResponse = null;
    portHttp = 3001;


  constructor() {

  	this.cmd = this.cmAbrirComprobante;
    this.params = new Object();
  }

  cierreX() {
  	this.request = { cmd: this.cmCierreX };
    this.work.requests.push(this.request);
  }

  cierreZ() {
  	this.request = { cmd: this.cmCierreZ };
    this.work.requests.push(this.request);
  }

  iniciarTrabajo() {
  	this.work = new Object();
  	this.work.requests = [];
  }

  datosCliente(nombre, tipoDeDocumento, documento, responsIVA, direccion){
  	this.datosCli = { nombre: nombre, tipoDeDocumento: tipoDeDocumento, documento: documento, responsIVA: responsIVA, direccion: direccion};
  }

  imprimirTextoFiscal(texto) {
    this.request = { cmd: this.cmImprimirTextoFiscal, params: texto };
    this.work.requests.push(this.request);
  }

  ImprimirOtrosTributos(codigo, descripcion, baseImponible, importe, alicuota){
        this.request = { cmd: this.cmImprimirOtrosTributos, params: {codigo: codigo, descripcion: descripcion, importe: importe, alicuota: alicuota } };
        this.work.requests.push(this.request);
  }

  abrirComprobante(tipoDeComprobante) {
        if (this.datosCli != null) {
        	this.request = { cmd: this.cmAbrirComprobante, params: { tipoComprobante: tipoDeComprobante, datosCliente: this.datosCli } };
        } else {
        	this.request = { cmd: this.cmAbrirComprobante, params: { tipoComprobante: tipoDeComprobante } };
        }
        this.work.requests.push(this.request);
        this.datosCli = null;
  }

  obtenerPrimerBloqueReporteElectronico(rangoInicial, rangoFinal, nombreArchivo, tipoReporte) {
        this.request = { cmd: this.cmObtenerPrimerBloqueReporteElectronico, params: {rangoInicial: rangoInicial, rangoFinal: rangoFinal, nombreArchivo: nombreArchivo, tipoReporte: tipoReporte } };
        this.work.requests.push(this.request);
  }

  setearTipoComprobante(tipoComprobante){
        this.request = { cmd: this.cmSetearTipoComprobante, params: { tipoComprobante: tipoComprobante } };
        this.work.requests.push(this.request);
  }

  reporteZFechas(fechaInicio, fechaFin, detallado) {
        this.request = { cmd: this.cmReporteZFechas, params: { fechaInicio: fechaInicio, fechaFin: fechaFin, detallado: detallado} };
        this.work.requests.push(this.request);
  }

  documentoDeReferencia2g(tipoComprobante, documento){
        this.request = { cmd: this.cmDocumentoDeReferencia2g, params: {tipoComprobante: tipoComprobante, documento: documento } };
        this.work.requests.push(this.request);
  }

  subtotal(){
        this.request = { cmd: this.cmSubtotal };
        this.work.requests.push(this.request);
  }

  imprimirTextoNoFiscal(texto){
        this.request = { cmd: this.cmImprimirTextoNoFiscal, params: {texto: texto} };
        this.work.requests.push(this.request);
  }

  obtenerDatosDeInicializacion(){
        this.request = { cmd: this.cmObtenerDatosDeInicializacion};
        this.work.requests.push(this.request);
  }

  cierreParcial(){
        this.request = { cmd: this.cmCierreParcial};
        this.work.requests.push(this.request);
  }

  ultimoComprobante(tipoComprobante){
        this.request = { cmd: this.cmUltimoComprobante, params: { tipoComprobante: tipoComprobante}};
        this.work.requests.push(this.request);
  }

  obtenerFechaHora() {
        this.request = { cmd: this.cmObtenerFechaHora};
        this.work.requests.push(this.request);
  }

  cancelarComprobante(){
        this.request = { cmd: this.cmCancelarComprobante };
        this.work.requests.push(this.request);
  }

  reporteZNumeros(nroInicio, nroFin, detallado){
        this.request = {cmd: this.cmReporteZNumeros, params: {nroInicio: nroInicio, nroFin: nroFin, detallado: detallado } };
        this.work.requests.push(this.request);
  }

  imprimirItem2g(descripcion, cantidad, precio, iva, impuestosInternos,
                                   g2CondicionIVA, g2TipoImpuestoInterno, g2UnidadReferencia,
                                   g2CodigoProducto, g2CodigoInterno, g2UnidadMedida){

        this.request = { cmd: this.cmImprimirItem2g, params: { descripcion: descripcion
                	, cantidad: cantidad, precio: precio, iva: iva, impuestosInternos: impuestosInternos,
                	g2CondicionIVA: g2CondicionIVA, g2TipoImpuestoInterno: g2TipoImpuestoInterno,
                	g2UnidadReferencia: g2UnidadReferencia, g2CodigoProducto: g2CodigoProducto,
                	g2CodigoInterno: g2CodigoInterno, g2UnidadMedida: g2UnidadMedida } };

        this.work.requests.push(this.request);
    }

  imprimirItemSimple(descripcion, cantidad, precio, impuestosInternos) {
  		this.request = { cmd: this.cmImprimirSimple, params: { descripcion: descripcion
                	, cantidad: cantidad, precio: precio, impuestosInternos: impuestosInternos } };
        this.work.requests.push(this.request);
  }

  imprimirDescuentoGeneral(descripcion, monto){
        this.request = { cmd: this.cmImprimirDescuento, params: { descripcion: descripcion, monto: monto } };
        this.work.requests.push(this.request);
    }

  imprimirPago2g(descripcion, monto, g2DescripcionAdicional, g2CodigoFormaPago, g2Cuotas,
                                   g2Cupones, g2Referencia){
        this.request = { cmd: this.cmImprimirPago2g, params: { descripcion: descripcion, monto: monto,
        	g2DescripcionAdicional: g2DescripcionAdicional, g2CodigoFormaPago: g2CodigoFormaPago,
        	g2Cuotas: g2Cuotas, g2Cupones: g2Cupones, g2Referencia: g2Referencia }};
        this.work.requests.push(this.request);
    }

  cerrarComprobante(){
        this.request = {cmd: this.cmCerrarComprobante };
        this.work.requests.push(this.request);
    }

  finalizarTrabajo(){
        this.response = this.sendData(this.work);
    }

  async sendData(request) {
  		var url = null;
        if (this.usarHttps){
            //url = "https://" + this.host +  ":3011";
            url = "https://" + this.host +  ":6868";
        } else {
            //url = "http://" + this.host +  ":3001";
            url = "http://" + this.host +  ":" + this.portHttp;
        }
        url = "http://" + this.host +  ":" + this.portHttp;
        this.http.open("POST", url, false);
        this.http.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            var resultado = JSON.parse(this.responseText);
            var errorCode = resultado.result.code;
            var errorDesc = resultado.result.description;
            var lastResponse = null;
            if (typeof(resultado.description) != 'undefined'){
                lastResponse = resultado;
            } else {
            	lastResponse = null;
            	}
	        }
	    };
	    request.modelo = Number(this.modelo);
	    request.puerto = Number(this.puerto);
	    request.baudios = Number(this.baudios);
        var stringData = JSON.stringify(request);
        this.http.send(stringData);

        //this.checkErrors();
        return this.lastResponse;
    }

  /*checkErrors(){
        this.errorCode = this.http.errorCode;
        this.errorDesc = this.http.errorDesc;
        this.lastResponse = this.http.lastResponse;
        if (this.errorCode != 0){
            throw this.errorDesc;
        }
    }*/
}
