import { Component, OnInit, EventEmitter } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StationConfigService } from '../services/station-config.service';
import { FiscalPrinterService } from '../../../core/fiscalprinter/fiscal-printer.service';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { ListUrl } from 'src/app/core/constantes/request';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrlBase } from 'src/app/core/ajax/url';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';

@Component({
  selector: 'app-modal-setprinter',
  outputs: ['printer'],
  templateUrl: './modal-setprinter.component.html',
  styleUrls: ['./modal-setprinter.component.css']
})
export class ModalSetprinterComponent implements OnInit {

  selectEvent: EventEmitter<any> = new EventEmitter();
  formSetprinter: FormGroup;

  isfisc = true;
  iselec = true;

  fc_model = 'Hasar715';
  fc_port = 1;
  fc_vel = 9600;

  enviando = false;

  constructor(
    public bsModalRef: BsModalRef,
    public StationSrvcs: StationConfigService,
    public FiscalPrinterService: FiscalPrinterService,
    private listUrl: UrListService, public auth: AuthService
  ) {
    this.formSetprinter = new FormGroup({
      i_fiscal: new FormControl(this.StationSrvcs.i_fiscal, []),
      i_serial: new FormControl(this.StationSrvcs.i_serial, [
        Validators.maxLength(30),
      ]),
      n_corte: new FormControl(this.StationSrvcs.n_corte, [
        Validators.maxLength(30),
      ]),
      i_digital: new FormControl(this.StationSrvcs.i_digital, []),
      i_email: new FormControl(this.StationSrvcs.i_email, []),
      i_electro: new FormControl(this.StationSrvcs.i_electro, []),
      i_token: new FormControl(this.StationSrvcs.i_token, [
        Validators.maxLength(256),
      ]),
      i_account: new FormControl(this.StationSrvcs.i_account, [
        Validators.maxLength(256),
      ]),
    });
   }

  ngOnInit() {
    this.getPrinterConfig();
  }

  getPrinterConfig() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('getprinter_config'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: true,
      titleLoading: 'Verificando información, por favor espere...',
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.StationSrvcs.printerConfig = respo.dat;
        var stat = this.StationSrvcs.selectStation.find(x => x.code == this.StationSrvcs.station_selected).id;
        var valuesPrinter = this.StationSrvcs.printerConfig.find(x => x.station_id == stat);
        if(valuesPrinter != undefined) {
          this.StationSrvcs.i_serial = valuesPrinter.serial_printer;
          this.StationSrvcs.n_corte = valuesPrinter.cut_number;
          this.StationSrvcs.i_token = valuesPrinter.auth_token;
          this.StationSrvcs.i_account = valuesPrinter.id_account;
          this.formSetprinter.get('i_fiscal').setValue(valuesPrinter.fiscal_printer);
          this.formSetprinter.get('i_digital').setValue(valuesPrinter.digital_printing);
          this.formSetprinter.get('i_email').setValue(valuesPrinter.send_mail);
          this.formSetprinter.get('i_electro').setValue(valuesPrinter.electronic_bill);
          this.fc_model = valuesPrinter.fiscal_model;
          this.fc_port = valuesPrinter.fiscal_port;
          this.fc_vel = valuesPrinter.fiscal_vel;
        }
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  saveChanges() {
    if(this.enviando == false) {
      this.enviando = true;
            const initAjax: Init = {
              method: 'put',
              url: this.listUrl.url('setprinter_config'),
              auth: this.auth,
              body: {
                station: this.StationSrvcs.station_selected,
                digital_printing: this.formSetprinter.value.i_digital,
                send_mail: this.formSetprinter.value.i_email,
                fiscal_printer: this.formSetprinter.value.i_fiscal,
                serial_printer: this.formSetprinter.value.i_serial,
                cut_number: this.formSetprinter.value.n_corte,
                electronic_bill: this.formSetprinter.value.i_electro,
                auth_token: this.formSetprinter.value.i_token,
                id_account: this.formSetprinter.value.i_account,
                fiscal_model: this.fc_model,
                fiscal_port: this.fc_port,
                fiscal_vel: this.fc_vel
              }
            };const optionsalertaSuccess: SweetAlertOptions = {
              icon: 'success',
              title: 'Operación completada!',
              text: 'La operación fue un éxito!',
              showConfirmButton: true,
              showCancelButton: false,
              showCloseButton: false,
              timer: 15000,
              confirmButtonText: 'Entendido',
            }
            const configAjax: Config = {
              titleLoading: 'Validando información, por favor espere...',
              alertaSuccess: Swal.mixin(optionsalertaSuccess),
            };
            const ajax = new Ajax(initAjax, configAjax);
            ajax.call().then(respo => {
              this.enviando = false;
              if (respo.success) {
                this.closeModal();
              }
            }).catch(error => {
              this.enviando = false;
              return false;
            });
    }
  }

  setModeloPuerto(tipo, event){
    if (tipo == 'modelo') {
      this.fc_model =  event.target.value;
    } else if(tipo == 'vel') {
      this.fc_port =  event.target.value;
    } else if(tipo == 'puerto') {
      this.fc_vel =  event.target.value;
    }
  }

  get i_fiscal() {return this.formSetprinter.get('i_fiscal'); }
  get i_serial() {return this.formSetprinter.get('i_serial'); }
  get n_corte() {return this.formSetprinter.get('n_corte'); }
  get i_digital() {return this.formSetprinter.get('i_digital'); }
  get i_email() {return this.formSetprinter.get('i_email'); }
  get i_electro() {return this.formSetprinter.get('i_electro'); }
  get i_token() {return this.formSetprinter.get('i_token'); }
  get i_account() {return this.formSetprinter.get('i_account'); }

  guardarIfiscal(iprint) {
      this.selectEvent.emit(iprint);
      this.closeModal();
  }

  habemusDisable(val, key) {
    // Comprueba que un checkbox este activo para habilitar las opciones dependientes.
    if (key == 'ifiscal') {
      this.isfisc = val ? null : true;
    }
    if (key == 'ielectro') {
      this.iselec = val ? null : true;
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  getId() {
    return 'BSSCFIU00004';
  }
}
