import { EventEmitter, Injectable } from '@angular/core';
import { ObjectsOne } from 'src/app/cliente/administrativo/services/objectone';
import { Url } from '../ajax/url';

@Injectable({
  providedIn: 'root'
})
export class ResolvingService {

  urlDelete: Url | null;
  urlResolving: Url | null;
  pk: number;
  code: string;
  valuesObjects: ObjectsOne | any;
  valuesList: ObjectsOne | any;
  closeModal: any;
  title: string;
  tipo: string = '';
  noteResolving: string;
  itemResolving: string;
  component: string = '';
  viewModal: any;
  typeModal: string | null;
  modelo: string | null;
  init: boolean = false;
  is_continue: boolean = false;
  strConecDelete?: string;
  prohibited: any;
  evenAdd: EventEmitter<any> = new EventEmitter();
  seguir: boolean = false;
  loadselect: boolean = false;

  constructor() { }
}