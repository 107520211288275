import { Component, OnInit, EventEmitter } from '@angular/core';
import { CustomerService } from '../customer-service/customer-service.service';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { ListUrl } from 'src/app/core/constantes/request';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrlBase } from 'src/app/core/ajax/url';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { City, State, Country } from 'src/app/core/auth/user';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-createcustomer-pdv',
  outputs: ['even_add'],
  templateUrl: './modal-createcustomer-pdv.component.html',
  styleUrls: ['./modal-createcustomer-pdv.component.css']
})
export class ModalCreatecustomerPdvComponent implements OnInit {

  idfType = [{name:'Cc',id:0,code:'CC'},{name:'Nit',id:1,code:'NIT'},{name:'Pasaporte',id:2,code:'PASAPORTE'},
  {name:'Rc',id:3,code:'RC'},{name:'Ti',id:4,code:'TI'},{name:'Te',id:5,code:'TE'},{name:'Ce',id:6,code:'CE'},
  {name:'Ie',id:7,code:'IE'},{name:'Nit de otro país',id:8,code:'NIT_OTRO_PAIS'}];
  customerType = [{name:'Natural',id:0,code:'PERSONA_NATURAL'},{name:'Jurídico',id:1,code:'PERSONA_JURIDICA'}];
  tax_level = [{name:'Responsable de Iva',id:0,code:'RESPONSABLE_DE_IVA'},{name:'No responsable de Iva',id:1,code:'NO_RESPONSABLE_DE_IVA'}];
  regimen = [{name:'Simple',id:0,code:'SIMPLE'},{name:'Ordinario',id:1,code:'ORDINARIO'},{name:'Gran contribuyente',id:2,code:'GRAN_CONTRIBUYENTE'},
  {name:'Autor retenedor',id:3,code:'AUTORRETENEDOR'},{name:'Agente de retención Iva',id:4,code:'AGENTE_RETENCION_IVA'}];
  states: any;
  citys: any;
  phone_code = '+00';
  city_selected = -1;
  co_ind: any;
  st_ind: any;
  ci_ind: any;
  enviando = false;
  customerForm: any;
  isNatural = true;
  textId = 'Cc';
  codeTax_level = 'RESPONSABLE_DE_IVA';
  codeRegimen = 'SIMPLE';
  codecustomerType = 'PERSONA_NATURAL';
  codeidfType = 'CC';
  first_name = '';
  last_name = '';
  codeId: string;
  showErrors = false;
  even_add: EventEmitter<any> = new EventEmitter();

  constructor(public CustomerService: CustomerService, public mybsModalRef: BsModalRef,
  	public listUrl: UrListService, public auth: AuthService) {
  		this.getCountries();
  		this.co_ind = -1;
  		this.st_ind = -1;
  		this.ci_ind = -1;
  		this.customerForm = CustomerService.customerForm;
  	}

  getId(){
    return this.codeId;
  }

  ngOnInit() {
  	if(this.CustomerService.pk != -1) {
      this.codeId = 'ESTPVIU00008';
      this.city_selected = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).city_p.city_id;
      this.ci_ind = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).city_p.city_id;
      this.st_ind = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).state_p.state_id;
  	  this.co_ind = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).country_p.country_id;
      this.phone_code = "+" + this.CustomerService.countryList.find(x => x.country_id == this.co_ind).phone_code;
      this.CustomerService.enviaForm.country.country_id = this.co_ind;
      this.CustomerService.enviaForm.country.country_name = this.CustomerService.countryList.find(x => x.country_id == this.co_ind).country_name;
      this.searchGeo({country: this.CustomerService.enviaForm.country.country_id});
      this.CustomerService.enviaForm.state.state_id = this.st_ind;
      this.searchGeo({state: this.CustomerService.enviaForm.state.state_id});
      this.CustomerService.enviaForm.city.city_id = this.ci_ind;
      this.codeTax_level = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).tax_level_code;
      this.codeRegimen = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).regimen;
      this.codecustomerType = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).party_type;
      this.codeidfType = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).party_identification_type;
      if(this.codecustomerType == 'PERSONA_NATURAL') {
        this.isNatural = true;
        this.first_name = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).first_name;
        this.last_name = this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).last_name;
        this.unirNombres();
      } else {
        this.isNatural = false;
        this.customerForm.get('trade_name').setValue(this.CustomerService.customerList.find(x => x.id_customers == this.CustomerService.pk).trade_name);
      }
    } else {
      this.codeId = 'ESTPVIU00007';
    }
  }

  dividirCadena(cadenaADividir,separador) {
     var arrayDeCadenas = cadenaADividir.split(separador);
     this.first_name = arrayDeCadenas[0];
     this.last_name = arrayDeCadenas[1];
  }

  selectData(e){
    switch (e.target.name) {
      case 'customerType':
        if(e.target.value == 0){
          this.isNatural = true;
        } else {
          this.isNatural = false;
        }
        this.codecustomerType = this.customerType.find(x => x.id == e.target.value).code;
        break;
      case 'idfType':
        this.textId = this.idfType.find(x => x.id == e.target.value).name;
        this.codeidfType = this.idfType.find(x => x.id == e.target.value).code;
        break;
      case 'tax_level':
        this.codeTax_level = this.tax_level.find(x => x.id == e.target.value).code;
        break;
      case 'regimen':
        this.codeRegimen = this.regimen.find(x => x.id == e.target.value).code;
        break;
      case 'first_name':
        this.first_name = e.target.value;
        this.unirNombres();
        break;
      case 'last_name':
        this.last_name = e.target.value;
        this.unirNombres();
        break;
    }
  }

  unirNombres(){
    this.customerForm.get('trade_name').setValue(this.first_name + " " + this.last_name);
  }

  async saveCustomer(){
    if(this.customerForm.valid){
    	if(this.enviando == false) {
        if(this.isNatural && (this.first_name == '' || this.last_name == '')){
           Swal.fire({
                icon: 'warning',
                title: 'Operación rechazada!...',
                text: 'Por favor introduzca los campos de nombre y apellido...'
              });
           return;
           this.showErrors = true;
         }
    		if(this.city_selected != -1) {
    			this.enviando = true;
          this.showErrors = false;
  	          const initAjax: Init = {
  	            method: 'put',
  	            url: this.listUrl.url('register_customer_pdv'),
  	            auth: this.auth,
  	            body: {
  	              trade_name: this.customerForm.value.trade_name,
  	              legal_name: this.customerForm.value.trade_name,
                  first_name: this.first_name,
                  last_name: this.last_name,
  	              address: this.customerForm.value.address,
  	              email: this.customerForm.value.email,
  	              phone: this.customerForm.value.phone,
  	              city: this.city_selected,
  	              nit: this.customerForm.value.nit,
  	              id_customer: this.CustomerService.pk,
                  party_type: this.codecustomerType,
                  party_identification_type: this.codeidfType,
                  tax_level_code: this.codeTax_level,
                  regimen: this.codeRegimen
  	            }
  	          };const optionsalertaSuccess: SweetAlertOptions = {
  	            icon: 'success',
  	            title: 'Operación completada!',
  	            text: 'La operación fue un éxito!',
  	            showConfirmButton: true,
  	            showCancelButton: false,
  	            showCloseButton: false,
  	            timer: 15000,
  	            confirmButtonText: 'Entendido',
  	          }
  	          const configAjax: Config = {
  	            titleLoading: 'Validando información, por favor espere...',
  	            alertaSuccess: Swal.mixin(optionsalertaSuccess),
  	          };
  	          const ajax = new Ajax(initAjax, configAjax);
  	          ajax.call().then(respo => {
  	            this.enviando = false;
  	            if (respo.success) {
                  this.even_add.emit(respo.customer);
                  this.closeModal();
  	            }
  	          }).catch(error => {
  	            this.enviando = false;
  	            return false;
  	          });
    		} else {
          this.showErrors = true;
    			Swal.fire({
                icon: 'warning',
                title: 'Operación rechazada!...',
                text: 'Por favor introduzca los campos de geolocalizacion...'
              });
    		}
    	}
    } else {
      this.showErrors = true;
    }
  }

  closeModal() {
    this.mybsModalRef.hide();
    this.CustomerService.pk = -1;
    this.city_selected = -1;
  	this.co_ind = -1;
  	this.st_ind = -1;
  	this.ci_ind = -1;
  }

  getCountries() {
    const initAjax: Init = {
      method: 'put',
      url: new UrlBase(ListUrl.iniregister[1]),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false,
      forceNormal: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.CustomerService.countryList = respo.dat.count;
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  searchGeo(camp) {
    // Carga los datos de los "select" de la geolocalizacion
    // if (this.inputSearch && this.inputSearch !== '') {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.searchgeo[1]),
        auth: this.auth,
        body: camp
      };
      const configAjax: Config = {
        visible: false,
        forceNormal: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(response => {
        if (response.success) {
          switch (response.nam) {
            case 'country':
              this.CustomerService.countryList = response.values;
              break;
            case 'state':
              this.states = response.values;
              break;
            case 'city':
              this.citys = response.values;
              break;
          }
        } else {
          return false;
        }
      }).catch(error => {
        return false;
      });
  }

  changeGeo(e) {
    // Detecta cuando se elige un campo de uno de los valores del "select" (onchange) y captura su valor.
    switch (e.target.name) {
      case 'country':
        this.phone_code = e.target.selectedOptions[0].id;
        this.CustomerService.enviaForm.country.country_id = e.target.value;
        this.CustomerService.enviaForm.country.country_name = e.target.selectedOptions[0].text;
        this.co_ind = e.target.selectedIndex;
        this.citys = null;
        this.searchGeo({country: this.CustomerService.enviaForm.country.country_id});
        break;
      case 'state':
        this.CustomerService.enviaForm.state.state_id = e.target.value;
        this.CustomerService.enviaForm.state.state_name = e.target.selectedOptions[0].text;
        this.st_ind = e.target.selectedIndex;
        this.searchGeo({state: this.CustomerService.enviaForm.state.state_id});
        break;
      case 'city':
        this.CustomerService.enviaForm.city.city_id = e.target.value;
        this.CustomerService.enviaForm.city.city_name = e.target.selectedOptions[0].text;
        this.ci_ind = e.target.selectedIndex;
        this.city_selected = e.target.value;
        break;
    }
  }


}
