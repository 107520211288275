import { Injectable } from '@angular/core';
import { ListUrl } from '../constantes/request';
import { AuthService } from '../auth/auth.service';
import { Url } from './url';
import { Cache } from '../../cache';
import { session } from '../constantes/sessiones';



@Injectable({
  providedIn: 'root'
})
export class UrListService {

  listUrl = ListUrl;

  constructor(public auth: AuthService) { }

  url(value: string): Url {
    return new Url(this.listUrl[value], this);
  }

  set activeWaiter(active: boolean) {
    Cache.setLocal(session.activeWaiter, active);
    this.auth.activeWaiter = active;
  }

  get activeWaiter(): boolean {
    if (this.auth.isLogin) {
      return this.auth.activeWaiter;
    } else {
      return false;
    }
  }
}
