import { Component, OnInit, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Init } from '../ajax/init-ajax';
import { Config, Ajax } from '../ajax/ajax';
import { UrListService } from '../ajax/ur-list.service';

@Component({
  selector: 'app-select-company',
  inputs: ['modal'],
  outputs: ['md'],
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.css']
})
export class SelectCompanyComponent implements OnInit {

  modal: string;
  title = 'Seleciona Compañia';
  company: string | null;
  return = '';
  status_compan = 1;
  md: EventEmitter<any> = new EventEmitter();

  constructor(private titleService: Title,
              public auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              public listUrl: UrListService,
  ) {
    this.titleService.setTitle(this.title);
  }
  
  ngOnInit() {
    this.load_companys();
    this.route.queryParams.subscribe(params => this.return = params.return || '/');
    if (this.auth.isCompany()) {
      this.company = `${this.auth.user.companys[0].company_id}`;
    }
  }

  load_companys() {
    const initD: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('get_usercompanys'),
      body: {dd: true}
    };
    const configD: Config = {
      visible: false,
      autoReNew: false
    };
    const ajax = new Ajax(initD, configD);
    ajax.call().then( resp => {
      if (resp.success) {
        let tuser = this.auth.user;
        tuser.companys = resp.companys;
        this.auth.user = tuser;
        if (resp.companys[0]) {
          this.company = `${this.auth.user.companys[0].company_id}`;
        }
      }
    }).catch( err => {
    } );
  }

  async onSubmit() {
    if (this.company) {
      this.auth.setCompany(this.company).then(resp => {
        if (this.modal) {
          this.md.emit();
        } else {
          this.router.navigateByUrl(this.return);
        }
      });
    }
  }

  getId(){
    return 'BSSADIU00002';
  }
}
