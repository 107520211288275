import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http'
import { AuthService } from 'src/app/core/auth/auth.service';

import { Router } from '@angular/router';

import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { ListUrl } from 'src/app/core/constantes/request';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrlBase } from 'src/app/core/ajax/url';


export interface passForm extends Object {
  email?: any;
}

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  users = [{email: ''}];
  passForm = new FormGroup({
       email: new FormControl()
    });
  return = '/password_reset';
  
  constructor(
   private http: HttpClient, 
   private formBuilder: FormBuilder,
   public auth: AuthService, 
   private router: Router) { 
    this.passForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }


  async onSubmit() {
    
    if (this.passForm.valid) {
      const initAjax: Init = {
        method: 'post',
        url: new UrlBase(ListUrl.password_reset),
        auth: this.auth,
        body: this.passForm.value
      };
      const configAjax: Config = {
        visible: true,
        forceNormal: true,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
        if (respo.success) {
        } else {
          return false;
        }
      }).catch(error => {
        return false;
      });
    }
  }

  validatej(resp: any): Promise<any> {
    return new Promise((success, error) => {
        if (typeof resp.success !== 'undefined' && resp.success) {
            success(resp);
        } else {
            error(resp);
        }
    });
  }

  getId() {
    return 'BSSACIU00003';
  }


  ngOnInit() {
  }
}