import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { numValidators } from '../../../setting/validators';
import { EntriesExitsService } from '../../entries-exits.service';

@Injectable({
  providedIn: 'root'
})
export class AddOtherTaxCoService {
  formOtherTaxesCo: FormGroup; //Formulario otros impuestos Pedido

  constructor(public ViewService: EntriesExitsService) {
    this.formOtherTaxesCo = new FormGroup({
      codeOtherTaxCo: new FormControl(this.ViewService.codeOTCo ,[
        Validators.required,
        Validators.maxLength(20)
      ]),
      descOtherTaxCo: new FormControl(this.ViewService.descOTCo ,[
        Validators.required,
        Validators.maxLength(80)
      ]),
      montOtherTaxCo: new FormControl(this.ViewService.montOTCo ,[
        Validators.required,
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      taxOtherTaxCo: new FormControl(this.ViewService.taxOTCo ,[
        Validators.required,
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
    });
  }
}
