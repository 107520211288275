import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { AddProductService } from '../../../services/forms/purchase/add-product.service';
import { ErrorCompraService } from '../../../services/errors/error-compra.service';
import { ModalExpiraProductComponent } from '../modal-expira-product/modal-expira-product.component';
import { ModalPriceChargeProductsComponent } from '../modal-price-charge-products/modal-price-charge-products.component';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import Swal, { SweetAlertOptions } from 'sweetalert2';


export interface Produ {
  id: number;
  code: string;
}

@Component({
  selector: 'app-load-product',
  templateUrl: './load-product.component.html',
  styleUrls: ['./load-product.component.css']
})
export class LoadProductComponent implements OnInit {

  modalRef: BsModalRef;
  formAddProdCo: FormGroup; //Formuario Agregar Producto en Compra
  filteredProdMod: Observable<Produ[]>;
  events: Subscription[] = [];
  produsMod: Produ[] = [];
  titleMod: string;
  Compra = false;
  Entrada = false;
  Pedido = false;
  Salida = false;
  txtaddtag = 'Buscar en servidor: ';
  txtloading = 'Buscando...';
  loading = false;
  readonly = false;
  espera = true;
  busca_producto = [];
  isOpen = false;

  constructor(
    public auth: AuthService,
    public ViewService: EntriesExitsService,
    public formProdCo: AddProductService,
    public ErrorCompra: ErrorCompraService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private listUrl: UrListService
  ) { }

  ngOnInit() {
    this.ViewService.boolCloseLoadProd = false;
    if (this.ViewService.nroTypeTrans == 0) { 
      this.Compra = true;
      this.titleMod = 'Cargar Producto de Compra';
      this.formAddProdCo = this.formProdCo.formAddProdCo;
      if (!this.ViewService.editProduct) {
        this.formAddProdCo.reset();
        this.ViewService.descCompra = '';
      }
      this.filteredProdMod = this.prodCoCtrl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterModal(value))
      );
    } else if (this.ViewService.nroTypeTrans == 1) {
      this.Entrada = true;
      this.titleMod = 'Cargar Producto de Entrada';
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.Pedido = true;
      this.titleMod = 'Cargar Producto de Pedido';
    } else if (this.ViewService.nroTypeTrans == 3) {
      this.Salida = true;
      this.titleMod = 'Cargar Producto de Salida';
    }
  }
  // Campos de Formulario de agregar Productos a Compra //
  get prodCoCtrl() { return this.formAddProdCo.get('prodCoCtrl'); }
  get descCoCtrl() { return this.formAddProdCo.get('descCoCtrl'); }
  get boxCoCtrl() { return this.formAddProdCo.get('boxCoCtrl'); }
  get unitCoCtrl() { return this.formAddProdCo.get('unitCoCtrl'); }
  get pesoCoCtrl() { return this.formAddProdCo.get('pesoCoCtrl'); }
  get costCoCtrl() { return this.formAddProdCo.get('costCoCtrl'); }
  get disc1CoCtrl() { return this.formAddProdCo.get('disc1CoCtrl'); }
  get disc2CoCtrl() { return this.formAddProdCo.get('disc2CoCtrl'); }
  get disc3CoCtrl() { return this.formAddProdCo.get('disc3CoCtrl'); }
  get taxCoCtrl() { return this.formAddProdCo.get('taxCoCtrl'); }
  // FIN Campos de Formulario de agregar Productos a Compra //

  private _filterModal(value: string): Produ[] {
    if (typeof value != 'undefined' && value != null) {
      const filterValue = value.toLowerCase();
      return this.produsMod.filter(produ => produ.code.toLowerCase().includes(filterValue));
    }
  }

  onSelectionChangeModal(e) {
    if (this.ViewService.nroTypeTrans == 0) {
      this.changeProdModal(e.option.value.toUpperCase());
    }    
  }

  getId() {
    return 'ADMINIU00005';
  }

  checkProdModal(event) {
    if (this.ViewService.nroTypeTrans == 0) {
      if (event.relatedTarget && event.relatedTarget.id.indexOf('mat-option') > -1) {
        event.preventDefault();
      } else {
        if (this.ViewService.prodCompra) {
          this.changeProdModal(this.ViewService.prodCompra.toUpperCase());
        } else {
          this.ViewService.descCompra = '';
        }
      }
    }
  }

  changeProdModal(value) {
    let not_exist = true;
    let exist_add = false;
    if (this.ViewService.nroTypeTrans == 0) {
      for (const key in this.ViewService.ProductSelect) {
        if (this.ViewService.ProductSelect.hasOwnProperty(key)) {
          const element = this.ViewService.ProductSelect[key];
          if (element.product_code === value) {
            this.ViewService.expProdCompra = element.expiration;
            this.ViewService.prodDescCo = element.product_description;
            this.ViewService.descCompra = element.product_description;
            this.ViewService.prodIdCo = element.id_product;
            this.ViewService.prodUnitBoxCo = element.unit_boxes;
            this.boxCoCtrl.setValue(1);
            this.unitCoCtrl.setValue(this.ViewService.prodUnitBoxCo);
            not_exist = false;
            break;
          }
        }
      }

      if (!this.ViewService.editProduct) {
        for (const key in this.ViewService.prodTableCompra) {
          if (this.ViewService.prodTableCompra.hasOwnProperty(key)) {
            const elem = this.ViewService.prodTableCompra[key];
            if (elem.id_product === this.ViewService.prodIdCo) {
              exist_add = true;
              break;
            }
          }
        }
      }

      if (not_exist || exist_add) {
        if (not_exist) {
          this.ErrorCompra.txt_product = 'El codigo: '+value+' no existe.';
        } else if (exist_add) {
          this.ErrorCompra.txt_product = 'El codigo: '+value+' ya ha sido agregado.';
        }
        this.formProdCo.disabled_fields = true;
        this.prodCoCtrl.setValue('');
        this.ViewService.prodCompra = '';
        this.ErrorCompra.error_product = true;
        this.ViewService.expProdCompra = false;
        this.ViewService.prodDescCo = '';
        this.ViewService.prodIdCo = null;
        this.ViewService.prodUnitBoxCo = null;
        this.ViewService.descCompra = '';
      } else {
        this.formProdCo.disabled_fields = false;
        this.ErrorCompra.error_product = false;
        this.ErrorCompra.txt_product = '';
      }
    }
  }

  formatNroMod(input){
    if (this.ViewService.nroTypeTrans == 0) {
      if (input === 'box') {
        if (this.boxCoCtrl.value == 0) {
          this.unitCoCtrl.setValue(1)
          var cajas = this.unitCoCtrl.value / this.ViewService.prodUnitBoxCo;
          this.boxCoCtrl.setValue(this.trunc(cajas, 2));
        } else {
          var unidades = this.boxCoCtrl.value * this.ViewService.prodUnitBoxCo;
          if (Number.isInteger(unidades)) {
            this.unitCoCtrl.setValue(unidades)
          } else {
            var cajas = this.unitCoCtrl.value / this.ViewService.prodUnitBoxCo;
            this.boxCoCtrl.setValue(this.trunc(cajas, 2));
          }
        }
      } else if (input === 'unit') {
        this.unitCoCtrl.setValue(this.trunc(this.ViewService.unitCompra));
        var cajas = this.unitCoCtrl.value / this.ViewService.prodUnitBoxCo;
        this.boxCoCtrl.setValue(this.trunc(cajas, 2));
      } else if (input === 'peso') {
        this.pesoCoCtrl.setValue(this.trunc(this.ViewService.pesoCompra, 8));
      } else if (input === 'cost') {
        this.costCoCtrl.setValue(this.trunc(this.ViewService.costCompra, 8));
      } else if (input === 'dis1') {
        this.disc1CoCtrl.setValue(this.trunc(this.ViewService.disc1Compra, 8));
      } else if (input === 'dis2') {
        this.disc2CoCtrl.setValue(this.trunc(this.ViewService.disc2Compra, 8));
      } else if (input === 'dis3') {
        this.disc3CoCtrl.setValue(this.trunc(this.ViewService.disc3Compra, 8));
      }
    }
  }

  trunc (x, posiciones = 0) {
    let s = x.toString()
    let l = s.length
    let decimalLength = s.indexOf('.') + 1
    if (l - decimalLength <= posiciones){
      return x
    }
    let isNeg  = x < 0
    let decimal =  x % 1
    let entera  = isNeg ? Math.ceil(x) : Math.floor(x)
    let decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    )
    let finalNum = entera + ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
    
    return finalNum
  }

  changeTaxMod(){
    if (this.ViewService.nroTypeTrans == 0) {
      for (const key in this.ViewService.TaxSelect) {
        if (this.ViewService.TaxSelect.hasOwnProperty(key)) {
          const element = this.ViewService.TaxSelect[key];
          if (element.id === this.ViewService.taxCompra) {
            this.ViewService.taxDescCo = element.code
            break;
          }
        }
      }
    }
  }

  closeModal() {
    this.ViewService.closeModalLoadPro.hide();
  }

  unsubscribe() {
    this.events.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.events = [];
  }

  loadProdCo() {
    let exist_add = false;
    let pk = this.ViewService.pkProduct;
    if (this.ViewService.nroTypeTrans == 0) {
      this.ViewService.codeProd = this.ViewService.prodCompra;
      this.ViewService.descProd = this.ViewService.prodDescCo;
      this.ViewService.unitProd = this.ViewService.unitCompra;
      for (const key in this.ViewService.prodTableCompra) {
        if (this.ViewService.prodTableCompra.hasOwnProperty(key)) {
          const elem = this.ViewService.prodTableCompra[key];
          if (elem.id_product === this.ViewService.prodIdCo) {
            if (!this.ViewService.editProduct) {
              exist_add = true;
              break;
            } else {
              if (this.ViewService.prodTableCompra[pk].id_product == elem.id_product && elem.error) {
                exist_add = false;
                break;
              } else {
                exist_add = true;
                break;
              }
            }
          }
        }
      }

      if (exist_add) {
        this.ErrorCompra.txt_product = 'El codigo: '+this.ViewService.prodCompra+' ya ha sido agregado.';
        this.ErrorCompra.error_product = true;
        this.ViewService.prodCompra = '';
        this.ViewService.expProdCompra = false;
        this.ViewService.prodDescCo = '';
        this.ViewService.prodIdCo = null;
      } else {
        this.ViewService.costProd = this.ViewService.costCompra;
        this.ViewService.unitBox = this.ViewService.prodUnitBoxCo;
        const _combine = combineLatest(
          this.modalService.onHidden
        ).subscribe(() => this.changeDetection.markForCheck());
    
        this.events.push(
          this.modalService.onHidden.subscribe(() => {
            if (this.ViewService.boolCloseLoadProd) {
              this.ViewService.closeModalLoadPro.hide();
            }
            this.unsubscribe();
          })
        );
        
        this.events.push(_combine);
        if (this.ViewService.expProdCompra) {
          this.modalRef = this.modalService.show(ModalExpiraProductComponent);
          this.modalRef.setClass('modal-dialog-centered modal-xl mod-exp-load-prod');
          this.ViewService.closeModalExpira = this.modalRef;
        } else {
          this.modalRef = this.modalService.show(ModalPriceChargeProductsComponent);
          this.modalRef.setClass('modal-dialog-centered modal-lg');
          this.ViewService.closeModalPrices = this.modalRef;
        }
      }
    }
  }

  searchproductFast($event) {
		this.loading = true;
		this.readonly = true;
		this.busca_producto = [];
    this.formProdCo.disabled_fields = true;
    if (typeof $event != 'undefined') {
      if (this.espera && typeof $event.label == "string") {
        const initD: Init = {
          method: 'put',
          auth: this.auth,
          url: this.listUrl.url('select_products'),
          body: {
            search: $event.label,
            to: false,
            of: false
          }
        };
        const configD: Config = {
          visible: false,
          autoReNew: true
        };
        const ajax = new Ajax(initD, configD);
        ajax.call().then( resp => {
          this.loading = false;
          this.readonly = false;
          if (resp.success && resp.objects) {
            this.ViewService.ProductSelect = resp.objects;
            this.produsMod = resp.obj_obser;
            for (const key in resp.objects) {
              if (Object.prototype.hasOwnProperty.call(resp.objects, key)) {
                const element = resp.objects[key];
                this.busca_producto = [...this.busca_producto, element];
              }
            }
            this.isOpen = true;
          } else {
            this.basicAlert('error', 'Producto no disponible', 'No se encontro el producto en el servidor', 'Ok');
            this.espera = true;
            this.busca_producto = [];
            this.isOpen = false;
            this.ViewService.ProductSelect = [];
          }
        }).catch((err) => {
          this.loading = false;
          this.readonly = false;
          this.espera = true;
          this.basicAlert('error', 'Error al buscar', 'Ocurrio un error al buscar el producto', 'Ok');
        });
      } else if (this.espera && typeof $event == "string") {
        this.changeProdModal(this.ViewService.prodCompra.toUpperCase());
        this.isOpen = false;
        this.loading = false;
        this.readonly = false;
        this.espera = true;
      } else {
        this.loading = false;
        this.readonly = false;
        this.isOpen = false;
      }
    } else {
      this.loading = false;
			this.readonly = false;
      this.isOpen = false;
      this.formAddProdCo.reset();
      this.ViewService.descCompra = '';
      // this.resetFormProdCo();
    }
	}

  basicAlert(icon, title, text, txtboton) {
		const oe: SweetAlertOptions = {
			icon,
			title,
			text,
			showConfirmButton: true,
			confirmButtonText: txtboton
		};
		const alerer = Swal.fire(oe);
	}

}
