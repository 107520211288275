import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CarshopService } from '../carshop.service';

import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';

import * as crypto from 'crypto-js';

import { PaymentGatewaysService } from 'src/app/core/payment-gateways/payment-gateways.service'

@Component({
  selector: 'app-carshop-modal',
  templateUrl: './carshop-modal.component.html',
  styleUrls: ['./carshop-modal.component.css']
})
export class CarshopModalComponent implements OnInit, OnDestroy {

  componentParent: CarshopService;

  plan: any;
  company: any;
  packages: any;
  total: number;
  cspay: boolean;
  data: any;
  paymentready:boolean;

  constructor(
    public auth: AuthService,
    private listUrl: UrListService,
    private router: Router,
    public bsModalRef: BsModalRef,
    public PaymentGatewaysService: PaymentGatewaysService
  ) { }

  ngOnInit() {
    this.actualiza();
    this.configurarPasarelas();
  }

  ngOnDestroy() {
    this.componentParent.cspay = false;
    this.cspay = false;
    this.paymentready = false;
    this.PaymentGatewaysService.destroyList();
  }

  actualiza() {
    this.plan = this.componentParent.getplan();
    this.company = this.componentParent.getcompany();
    this.packages = this.componentParent.getpackages();
    this.total = this.componentParent.getTotal();
    this.cspay = this.componentParent.getcspay();
  }

  configurarPasarelas() {
    this.PaymentGatewaysService.paymentDetails('USD', 0, 0, false, crypto.MD5(this.company.companyn+'id'+
      this.company.companyi.toString()+this.plan.plana+'id'+this.plan.plani).toString());
    this.PaymentGatewaysService.addProduct('Empresa '+this.company.companyn+" "+this.plan.plana, this.plan.planp, '', 'SERVICE', 1);
    for(var i in this.packages) {
      if(this.packages[i].packagep != 0 && this.packages[i].packagec != 0){
        this.PaymentGatewaysService.addProduct(this.packages[i].packagen, this.packages[i].packagep, '', 'SERVICE', this.packages[i].packagec);
      }
    }
    this.data = this.PaymentGatewaysService;
    this.paymentready = true;
  }

  processEmitter(variable){
    if(variable.event == 'done') {
      this.carshopPay();
    }
  }

  refresh() {
    this.componentParent.refresh();
    this.actualiza();
    this.bsModalRef.hide();
    this.router.navigateByUrl('/plan/plans');
  }

  carshopPay() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('plans'),
      auth: this.auth,
      body: {plan: this.plan.plani, company: this.company.companyi, services: this.packages, topa: this.componentParent.gettopackage()}
    };
    const configAjax: Config = {
      visible: true,
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      // this.router.navigateByUrl(respo.redirect);
      let temp_user = this.auth.user;
      temp_user.companys = respo.companys;
      this.auth.user = temp_user;
      this.bsModalRef.hide();
      this.refresh();
      this.router.navigateByUrl('');
    }).catch(error => {
      return false;
    });
  }

}
