import { Injectable } from '@angular/core';
import { ObjectsItemPromotional, ObjectItemPromotional } from './objectItemPromotional';
import { Url } from 'src/app/core/ajax/url';


@Injectable({
  providedIn: 'root'
})

export class CatalogoItemPromotionalService {

  pass: number | string; 
  confirmar: boolean;
  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  Modal: any
  closeModal: any;
  pk: number | string;
  newMU: boolean;
  typeModal;
  urlSave: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  habilitaBtn = false;
  valuesObject: ObjectsItemPromotional  | any;
  valuesObjects: ObjectsItemPromotional | any;
  valuesObjectsBase: ObjectsItemPromotional | any;
  viewCode: string;
  editCode: string;
  addCode: string;
  preguntaDelete: string | null;
  urlDelete: Url | null;
  strDelete?: string;
  strConecDelete?: string;
  resp: any;
  object: ObjectsItemPromotional;

  status: boolean;
  code: string;
  description: string;
  unidadCajas: number;
  precioCajas: number;
  precioUnidad: number;
  
  constructor() { 

  }
}