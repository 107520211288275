import { Component, OnInit } from '@angular/core';
import { CatalogoInventarioEntradaService } from '../../../services/catalogo-inventario-entrada.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-modal-entrada',
  inputs: [
    'titleModal',
    'campoCode',
    'placeholderCode',
    'campoDes',
    'placeholderDes',
    'campoAct',
    'typeModal',
    'nameModal',
    'maxCode',
    'titleSave',
    'typeInput'],
  templateUrl: './modal-entrada.component.html',
  styleUrls: ['./modal-entrada.component.css','./style.scss']
})
export class ModalEntradaComponent implements OnInit {
	titleModal = 'Agregar Nueva Entrada';
	campoCode = 'Codigo';
	placeholderCode = 'Codigo';
	campoDesc = 'Descripcion';
	placeholderDesc = "Descripcion";
	codeMO: string | null;
  	descMO: string | null;
  	error_id = false;
  	txt_id: string | null;
  	error_code = false;
  	txt_code: string | null;
  	error_desc = false;
  	txt_desc: string | null;
  	campoAct: boolean = false;
  	maxCode = 10;
  	maxDesc = 60;
  	titleSave = "Otras Entradas";
	formEntrada: FormGroup;
	codeId: string;

  constructor(public CatalogoInventarioEntradaService : CatalogoInventarioEntradaService,
  		public auth: AuthService, 
   		private listUrl: UrListService) {


   	 this.formEntrada = new FormGroup({
      mocode: new FormControl(this.codeMO, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      modesc: new FormControl(this.descMO, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ]),
    });
	}

  ngOnInit() {

  if (this.CatalogoInventarioEntradaService.newMU) {
      this.codeId = this.CatalogoInventarioEntradaService.editCode;
      const values = this.CatalogoInventarioEntradaService.valuesObjects;

      if (typeof values !== 'undefined') {
        for (let i = 0; i < values.length; i++) {
          const element = values[i];
          if (element.code == this.CatalogoInventarioEntradaService.pk) {
            this.CatalogoInventarioEntradaService.pass = element.llave
            this.codeMO = element.code;
            this.descMO = element.desc;
            this.campoAct = element.statu;
          }
        }
      }
    }

  this.CatalogoInventarioEntradaService.status = this.campoAct;
  this.CatalogoInventarioEntradaService.urlSave = this.listUrl.url('saveinventaryinput');
  this.CatalogoInventarioEntradaService.typeModal = 'catalogo';
  }



  saveObject(){
    let id_obj: any;
    if (this.CatalogoInventarioEntradaService.newMU) {
      id_obj = '';
      this.CatalogoInventarioEntradaService.confirmar = false;
    } else {
      id_obj = this.CatalogoInventarioEntradaService.pk;
      this.CatalogoInventarioEntradaService.confirmar = true;
    }


    if (this.codeMO.toUpperCase().trim() && this.descMO.toUpperCase().trim() ) {

    const initAjax: Init = {
        method: 'put',
        url: this.CatalogoInventarioEntradaService.urlSave,
        auth: this.auth,
        body: {	
          id : this.CatalogoInventarioEntradaService.pass,
          mod: this.CatalogoInventarioEntradaService.newMU,
          code: this.codeMO.toUpperCase().trim(),
          desc: this.descMO.toUpperCase().trim(),
          status: this.campoAct
        }
      };

        const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        console.log('resp: ',resp);
        if (resp.success) {
            
            if (resp.nuevo) {
            this.newObject(resp);
            } 
           else {            
             this.editObject(resp);
          }     
        }

        this.CatalogoInventarioEntradaService.closeModal.hide();
      }).catch(error => {
        console.log('error: ',error);
        this.viewError(error);
      });
    }

    else{
        this.error_desc = true;
        this.txt_desc = 'Favor debe de llenar los campos correctamente';
    }	
  }

  closeModal() {
    this.CatalogoInventarioEntradaService.closeModal.hide();
   }


   formatCode() {
    this.codeMO = this.codeMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
  }

  formatDesc() {
    this.descMO = this.descMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  setTax_Exempt(){
    if(this.campoAct == true){
      this.campoAct = false;
    } else {
      this.campoAct = true;
    }
  }

   newObject(resp) {
    this.CatalogoInventarioEntradaService.resp= resp;
  }


   editObject(resp) {
    this.CatalogoInventarioEntradaService.code = this.codeMO;
    this.CatalogoInventarioEntradaService.description = this.descMO;
    this.CatalogoInventarioEntradaService.status = this.campoAct;

    this.CatalogoInventarioEntradaService.resp= resp;         
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }

    }
    
  }

}
