import { Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent,
} from '@stripe/stripe-js';
import { PaymentGatewaysService } from '../../payment-gateways.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrlBase } from 'src/app/core/ajax/url';
import { ListUrl } from 'src/app/core/constantes/request';

@Injectable({
  providedIn: 'root'
})
export class StripeServices {

  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es',
  };

  constructor(public stripeService: StripeService, private http: HttpClient,
  	public PaymentGatewaysService: PaymentGatewaysService, public auth: AuthService, 
    private listUrl: UrListService) {
  }

  setCardStyle(style) {
  	//this.cardOptions: StripeCardElementOptions = { style };
  }

  setElementsOptions(elements) {
  	//this.elementsOptions: StripeElementsOptions = { elements };
  }

  pay(card: StripeCardComponent): void {
  	this.card = card;
    this.createPaymentIntent(this.PaymentGatewaysService.total)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: this.PaymentGatewaysService.name,
                },
              },
            })
          ),
         catchError((error: HttpErrorResponse) => {

           let errorMessage = '';
           if (error.error instanceof ErrorEvent) {
             // client-side error
             errorMessage = `Error: ${error.error.message}`;
             this.PaymentGatewaysService.transactionResult('No se ha podido establecer una conexión con el servicio de Stripe, intente nuevamente...', 'Oops!');

           } else {
             // server-side error
             errorMessage = `${error.status}`;
             this.PaymentGatewaysService.transactionResult('No se ha podido establecer una conexión con el servicio de Stripe, intente nuevamente...', 'Código de error '+errorMessage);
           }
           return throwError(errorMessage);
         })
        ).subscribe((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.PaymentGatewaysService.transactionResult(result.error.message, 'Transacción fallida!...');
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              this.PaymentGatewaysService.transactionResult(result.paymentIntent.status, 'Transacción realizada!...');
              this.PaymentGatewaysService.createPaymentBill('stripe');
            }
          }
        });
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `http://localhost:8000/payment_gateway/stripe/payment_intents`,
      { 'amount': this.PaymentGatewaysService.total*100, 'currency': this.PaymentGatewaysService.currency, 'payment_method_types[]': 'card' }
    ).pipe(catchError((error: HttpErrorResponse) => {

         let errorMessage = '';
         this.PaymentGatewaysService.transactionResult('Ha ocurrido un error inesperado, intente nuevamente...', 'Oops!');
         if (error.error instanceof ErrorEvent) {

           // client-side error
           errorMessage = `Error: ${error.error.message}`;

         } else {
           // server-side error
           errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
         }
         return throwError(errorMessage);

       }));
  }
}