import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CierreCajaComponent } from 'src/app/cliente/puntodeventa/cierre-caja/cierre-caja.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

 
  modalRegister: BsModalRef;

  constructor(private auth: AuthService, private modalService: BsModalService) { }

  ngOnInit() {

  }

  getId() {
  	return 'BSSDBIU00001';
  }

  openModalRegister() {
    const initialState = {
      title: 'Cierre Parcial'
    }
    this.modalRegister = this.modalService.show(CierreCajaComponent, {initialState});
    this.modalRegister.setClass('modal-xl');
  }

}
