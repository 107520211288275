import { Injectable } from '@angular/core';
import { ObjectsEquipos, ObjectEquipos } from './objectEquipos';
import { Url } from 'src/app/core/ajax/url';


@Injectable({
  providedIn: 'root'
})

export class CatalogoEquiposService {

  pass: number | string; 
  confirmar: boolean;
  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  Modal: any
  closeModal: any;
  pk: number | string;
  newMU: boolean;
  typeModal;
  urlSave: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  habilitaBtn = false;
  valuesObject: ObjectsEquipos  | any;
  valuesObjects: ObjectsEquipos | any;
  valuesObjectsBase: ObjectsEquipos | any;
  viewCode: string;
  editCode: string;
  addCode: string;
  preguntaDelete: string | null;
  urlDelete: Url | null;
  strDelete?: string;
  strConecDelete?: string;
  resp: any;
  object: ObjectsEquipos;

  status: boolean;
  code: string;
  description: string;
  capacidad: number;
  cajas: boolean;
  unidad: boolean;
  
  constructor() { 

  }
}