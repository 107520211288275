import { EventEmitter, Injectable } from '@angular/core';
import { Supplier, Suppliers } from './suppliers';
import { GeoSelects } from '../modal-proveedor/select-geo';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  ambos: boolean;
  create: boolean;
  new: boolean = true;
  urlreturn: boolean | string;
  habilitaBtn: boolean = false;
  regresa: string | boolean;
  valuesObjects: Suppliers;
  valuesObjectsBase: Suppliers;
  pk: number | string;
  closeModal: any;
  SelectCountry: GeoSelects;
  SelectState: GeoSelects;
  SelectCity: GeoSelects;
  modalType: any = 'catalogo';
  country: number;
  state:  number;
  city: number | string | null;
  modelo: string | null;
  modeloBase: string | null;
  codeId = 'ADMCAIU00074';
  object: Supplier;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
