import { Component, OnInit } from '@angular/core';
import { InventaryProductService } from '../../../services/inventary-product.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';

@Component({
  selector: 'app-expirations',
  templateUrl: './expirations.component.html',
  styleUrls: ['./expirations.component.css']
})
export class ExpirationsComponent implements OnInit {

  ProductsExpira = [];
  heveExpira = false;
  loadData = false;

  constructor(
    public ProductService: InventaryProductService,
    public auth: AuthService,
    private listUrl: UrListService
  ) { }

  ngOnInit() {
    this.loadExpirations(this.ProductService.pk)
  }

  getId() {
    return 'ADMCAIU00050';
  }

  loadExpirations(pk) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk
      },
      url: this.listUrl.url('list_expirations_product')
    };
    const configAjax: Config = {
      visible: true,
      titleSuccess: '',
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.loadData = true;
        this.ProductsExpira = resp.lista;
        if (this.ProductsExpira.length > 0) {
          this.heveExpira = true;
        }
      }
    });
  }

  closeModal() {
    this.ProductService.closeModalExpira.hide();
  }

}
