import { Injectable } from '@angular/core';
import { PaymentIntentComponent } from 'src/app/cliente/administrativo/payment-intent/payment-intent.component';
import { ListUrl } from 'src/app/core/constantes/request';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrlBase } from 'src/app/core/ajax/url';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewaysService {

  currency: string;
  total: any;
  listProducts = [];
  confirmationUrl: string;
  email: string;
  testMode = false;
  name: string;
  tax: any;
  taxReturnBase: any;
  referenceCode: string;
  PaymentIntentComponent: PaymentIntentComponent;

  constructor(public auth: AuthService, 
    private listUrl: UrListService) {
    this.total = 0;
  }

  addProduct(name, value, description, category, quantity) {
    this.listProducts.push({'name': name, 'value': value.toString(),
      'description': description, 'category': category, 'quantity': quantity.toString(),
      'unit_amount': { currency_code: this.currency, 'value': value.toString() }
    });
    value = value*quantity;
    this.total = this.total+value;
  }

  destroyList() {
    this.listProducts = [];
    this.total = 0;
  }

  paymentDetails(currency, tax, taxReturnBase, testMode, referenceCode) {
    this.currency = currency;
    this.testMode = testMode;
    this.tax = tax;
    this.taxReturnBase = taxReturnBase;
    this.referenceCode = referenceCode;
  }

  generateToken(gateway) {
    if(gateway == 'paypal'){

    } else if(gateway == 'stripe'){

    }
  }

  createPaymentBill(payment_method) {
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('payment_bill'),
        auth: this.auth,
        body: {
          email: this.email,
          units: this.listProducts.length,
          monto: this.total,
          payment_method: payment_method,
          search: [{'code':'asd','quantity':1, 'weight':0}],
          referencia: this.referenceCode,
          detalle: ''
        }
      };const optionsalertaSuccess: SweetAlertOptions = {
        icon: 'success',
        title: 'Operación completada!',
        text: 'Configuración guardada!',
        showConfirmButton: true,
        showCancelButton: false,
        showCloseButton: false,
        timer: 15000,
        confirmButtonText: 'Entendido',
      }
      const configAjax: Config = {
        titleLoading: 'Validando información, por favor espere...',
        alertaSuccess: Swal.mixin(optionsalertaSuccess),
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
      }).catch(error => {
        return false;
      });
    
  }

  transactionResult(result, title) {
    this.PaymentIntentComponent.transactionResult(result, title);
  }
}