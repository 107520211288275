import { Component, OnInit } from '@angular/core';
import { InventaryProductService } from '../../../services/inventary-product.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  formSearch: FormGroup;
  listYears = [];
  listMonths = [
    {'id': '', 'code': 'MES'},
    {'id': '01', 'code': 'ENERO'},
    {'id': '02', 'code': 'FEBRERO'},
    {'id': '03', 'code': 'MARZO'},
    {'id': '04', 'code': 'ABRIL'},
    {'id': '05', 'code': 'MAYO'},
    {'id': '06', 'code': 'JUNIO'},
    {'id': '07', 'code': 'JULIO'},
    {'id': '08', 'code': 'AGOSTO'},
    {'id': '09', 'code': 'SEPTIEMBRE'},
    {'id': '10', 'code': 'OCTUBRE'},
    {'id': '11', 'code': 'NOVIEMBRE'},
    {'id': '12', 'code': 'DICIEMBRE'}
  ];
  Movements = [];
  errors = [];
  haveYears = false;
  haveSearch = true;
  loadData = false;
  validDates = false;
  formData: any;

  constructor(
    public ProductService: InventaryProductService,
    public auth: AuthService,
    private listUrl: UrListService,
    private FormBuilder: FormBuilder
  ) {
    this.formSearch = this.FormBuilder.group({
      dates: this.FormBuilder.array([])
    });
  }

  ngOnInit() {
    this.loadYears();
  }

  getId() {
    return 'ADMCAIU00047';
  }

  get dates(): FormArray {
    return this.formSearch.get('dates') as FormArray;
  }

  loadYears() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('check_years')
    };
    const configAjax: Config = {
      visible: true,
      titleSuccess: ''
    };

    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.loadData = true;
        this.listYears = resp.years;
        if (this.listYears.length > 1) {
          this.haveYears = true;
          this.addItem();
        }
      }
    });
  }

  addItem() {
    if (this.dates.length <= 1) {
      const dates_movement =this.FormBuilder.group({
        selectmonth: new FormControl('', [
          Validators.required
        ]),
        selectyear: new FormControl('', [
          Validators.required
        ])
      });
      this.errors.push({'error_month': false, 'txt_month': '', 'error_year': false, 'txt_year': ''});
      this.dates.push(dates_movement);
    }
  }

  deleteItem(index: number) {
    let validErrors = false;
    if (this.dates.length !== 1) { 
      this.dates.removeAt(index);
      this.errors.splice(index, 1);
    }
    for (const key in this.errors) {
      if (Object.prototype.hasOwnProperty.call(this.errors, key)) {
        const err = this.errors[key];
        if (err.error_month || err.error_year) {
          validErrors = true;
          break;
        }
      }
    }
    this.validDates = validErrors;
  }

  changeDate(index) {
    let month = this.dates.at(index).value.selectmonth;
    let year = this.dates.at(index).value.selectyear;
    let validMonth = false;
    let validYear = false;
    let validErrors = false;
    this.errors[index].error_month = false;
    this.errors[index].txt_month = '';
    this.errors[index].error_year = false;
    this.errors[index].txt_year = '';
    for (const i in this.listMonths) {
      if (Object.prototype.hasOwnProperty.call(this.listMonths, i)) {
        const elem = this.listMonths[i];
        if (elem.id == month) {
          validMonth = true;
          break;
        }
      }
    }
    for (const i in this.listYears) {
      if (Object.prototype.hasOwnProperty.call(this.listYears, i)) {
        const elem = this.listYears[i];
        if (elem.id == year) {
          validYear = true;
          break;
        }
      }
    }
    if (!validMonth) {
      this.errors[index].error_month = true;
      this.errors[index].txt_month = 'Valor no permitido.';
    } else if (!validYear) {
      this.errors[index].error_year = true;
      this.errors[index].txt_year = 'Valor no permitido.';
    } else {
      for (let i = 0; i < this.dates.length; i++) {
        if (i != index) {
          if (this.dates.at(i).value.selectmonth && this.dates.at(i).value.selectyear) {
            if (this.dates.at(i).value.selectmonth == month && this.dates.at(i).value.selectyear == year) {
              this.errors[index].error_month = true;
              this.errors[index].txt_month = 'Mes y Año ya estan en uso.';
              this.errors[index].error_year = true;
              this.errors[index].txt_year = 'Mes y Año ya estan en uso.';
            }
          }
        }
      }
    }
    for (const key in this.errors) {
      if (Object.prototype.hasOwnProperty.call(this.errors, key)) {
        const err = this.errors[key];
        if (err.error_month || err.error_year) {
          validErrors = true;
          break;
        }
      }
    }
    this.validDates = validErrors;
  }

  searchStatistics(): void {
    if (this.formSearch.invalid == false && this.validDates == false) {
      var iter = 0;
      this.formData = new FormData();
      this.Movements = [];
      for (let i = 0; i < this.dates.length; i++) {
        iter = iter+1;
        this.formData.append('month_'+iter, this.dates.at(i).value.selectmonth);
        this.formData.append('year_'+iter, this.dates.at(i).value.selectyear);
      }
      this.formData.append('cycle', iter);
      this.formData.append('product', this.ProductService.pk);

      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('search_statistics'),
        auth: this.auth,
        body: this.formData
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: 'Buscando Estadísticas.',
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          this.Movements = resp.objects;
          if (this.Movements.length == 0) {
            this.haveSearch = false;
          } else {
            this.haveSearch = true;
          }
        }
      }).catch(error => {
        return error;
      });
    }
  }

  closeModal() {
    this.ProductService.closeModalStatistics.hide();
  }

}
