import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  customerList = [];
  closeModal: any;
  pk = -1;
  customerForm = new FormGroup({
    trade_name: new FormControl(),
    address: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
    nit: new FormControl(),
  });
  enviaForm = {
    city: {
      city_id: '',
      city_name: ''
    },
    state: {
      state_id: '',
      state_name: ''
    },
    country: {
      country_id: '',
      country_name: '',
      phone_code: '',
      short_name: ''
    },
  };
  countryList = [];

  constructor(private formBuilder: FormBuilder) {
  	this.customerForm = this.formBuilder.group({
	      trade_name: ['', [Validators.required, Validators.maxLength(120)]],
	      address: ['', [Validators.required, Validators.maxLength(255)]],
	      email: ['', [Validators.required, Validators.email, Validators.maxLength(70)]],
	      phone: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(1), Validators.pattern('^([0-9]+)$')]],
	      nit: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^([0-9]+)$')]],
	    });
  }
}
