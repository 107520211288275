import { Component, OnInit } from '@angular/core';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Search } from 'src/app/search';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InvitationModalComponent } from '../invitation-modal/invitation-modal.component';
import { UsersListBase, UserBase } from 'src/app/core/auth/user';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {

  bsModalRef: BsModalRef;
  usersInvitadosBase: UsersListBase;
  usersInvitados: UsersListBase;
  usersSearch: UsersListBase;
  inputSearch: string | null;
  inputSearchLocal: string | null;
  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText:
      'Cancelar',
    cancelButtonAriaLabel: 'Cancelar'
  });

  constructor(public auth: AuthService,
              private listUrl: UrListService,
              private modalService: BsModalService
  ) {
    this.inputSearch = null;
    this.inputSearchLocal = null;
  }

  ngOnInit() {
    this.loadUsersInvitados();
  }

  openModal() {
    const initialState = {
      componentParent: this
    };
    this.bsModalRef = this.modalService.show(InvitationModalComponent, {initialState});
    this.bsModalRef.setClass('modal-dialog-scrollable modal-dialog-centered');
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  searchCall() {
    if (this.inputSearch && this.inputSearch !== '') {
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('invitationSearch'),
        auth: this.auth,
        body: {
          username: this.inputSearch
        }
      };
      const configAjax: Config = {
        titleLoading: 'Buscando Usuarios...',
        titleSuccess: '',
        textErrorNormal: `No se encontro ${this.inputSearch}`,
        titleErrorNormal: 'Error al Buscar Usurio',
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(response => {
        if (response.success) {
          this.usersSearch = response.users;
        }
      }).catch(error => {
      });
    }
  }

  loadUsersInvitados() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('users_company')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.usersInvitados = resp.users;
        this.usersInvitadosBase = resp.users;
      }
    }).catch(error => {
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  confirSendInvitation(user: UserBase) {
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.sendInvitation(user);
      });
    }, 'Enviar Invitacion?',
    `Realmente quiere enviar una invitacion a <strong>${user.first_name} ${user.last_name}</strong>`);
  }

  confirRemove(user: UserBase) {
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.removeUserCompany(user);
      });
    }, 'Eliminar Usuario?',
    `Realmente eliminar a <strong>${user.first_name} ${user.last_name}</strong>`);
  }

  searchComapanyUsers() {
    if (typeof this.usersInvitadosBase !== 'undefined' && this.usersInvitadosBase.length) {
      this.usersInvitados = Search.filter(this.usersInvitadosBase, (x, e) => {
          return Search.any([
            Search.search(x.username, e),
            Search.search(`${x.first_name} ${x.last_name}`, e)
          ]);
      }, this.inputSearchLocal);
    }
  }

  sendInvitation(user: UserBase) {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('sendInvitation'),
      auth: this.auth,
      body: {
        user: user.user_pk
      }
    };
    const configAjax: Config = {
      titleLoading: 'Enviando Invitacion...',
      titleSuccess: '',
      textErrorNormal: `No se logro enviar la Invitacion a ${user.first_name} ${user.last_name}`,
      titleErrorNormal: 'Error al Enviar Invitacion',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              }),
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const filter = (x: UserBase, e: string) => {
      return x.user_pk === e;
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then((resp: any) => {
      if (resp.success) {
        this.usersInvitadosBase.push(user);
        this.usersSearch = this.usersSearch.splice(Search.position(this.usersSearch, filter, user.user_pk) + 1);
      }
    }).catch(error => {
    });
  }

  removeUserCompany(user: UserBase) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        user: user.user_pk
      },
      url: this.listUrl.url('removeUserCompany')
    };
    const configAjax: Config = {
      titleLoading: 'Removiendo Usuario...',
      titleSuccess: '',
      textErrorNormal: `No se logro enviar la invitacion <strong>${user.first_name} ${user.last_name}</strong>`,
      titleErrorNormal: 'Error al Enviar Invitacion',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        const filter = (x: UserBase, e: string) => {
          return x.user_pk === e;
        };
        this.usersInvitadosBase.splice(Search.position(this.usersInvitadosBase, filter, user.user_pk), 1);
      }
    }).catch( error => {
    });
  }

}
