import { Component, OnInit } from '@angular/core';
import { SetControlsService } from '../services/set-controls.service';
import { ErrorsSetControlsService } from '../services/errors-set-controls.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Router } from '@angular/router';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-set-controls',
  templateUrl: './set-controls.component.html',
  styleUrls: ['./set-controls.component.css']
})
export class SetControlsComponent implements OnInit {

  formSetctrl: FormGroup;

  dis_fact = true;
  dis_devo = true;
  dis_cred = true;
  dis_debi = true;

  req_fact = null;
  req_devo = null;
  req_cred = null;
  req_debi = null;

  constructor(
    public SetCtrlSrvcs: SetControlsService,
    public Errors: ErrorsSetControlsService,
    public auth: AuthService,
    private listUrl: UrListService,
    private router: Router,
  ) {
    this.formSetctrl = new FormGroup({
      desde: new FormControl(this.SetCtrlSrvcs.desde, [
        Validators.min(1),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      hasta: new FormControl(this.SetCtrlSrvcs.hasta, [
        Validators.min(1),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      ctrl_desde: new FormControl(this.SetCtrlSrvcs.ctrl_desde, [
        Validators.min(1),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      ctrl_hasta: new FormControl(this.SetCtrlSrvcs.ctrl_hasta, [
        Validators.min(1),
        Validators.max(99999999999), // 11 digitos
        // numValidators
      ]),
      fact: new FormControl(this.SetCtrlSrvcs.fact),
      devo: new FormControl(this.SetCtrlSrvcs.devo),
      cred: new FormControl(this.SetCtrlSrvcs.cred),
      debi: new FormControl(this.SetCtrlSrvcs.debi)
    });
  }

  ngOnInit() {
  }

  get fact() { return this.formSetctrl.get('fact'); }
  get devo() { return this.formSetctrl.get('devo'); }
  get cred() { return this.formSetctrl.get('cred'); }
  get debi() { return this.formSetctrl.get('debi'); }
  get desde() { return this.formSetctrl.get('desde'); }
  get hasta() { return this.formSetctrl.get('hasta'); }
  get ctrl_desde() { return this.formSetctrl.get('ctrl_desde'); }
  get ctrl_hasta() { return this.formSetctrl.get('ctrl_hasta'); }

  resetDisaRequi() {
    this.formSetctrl.reset();
    this.dis_fact = true;
    this.dis_devo = true;
    this.dis_cred = true;
    this.dis_debi = true;
    this.req_fact = null;
    this.req_devo = null;
    this.req_cred = null;
    this.req_debi = null;
  }

  resetAll() {
    this.resetDisaRequi();
    this.formSetctrl.reset();
  }

  habemusDisaRequi(val, key) {
    // Comprueba que un checkbox este activo para habilitar las opciones dependientes.
    this.resetDisaRequi();
    if (key == 'fact') {
      this.SetCtrlSrvcs.fact = val;
      this.dis_fact = val ? null : true;
      this.req_fact = val ? true : null;
    }
    if (key == 'devo') {
      this.SetCtrlSrvcs.devo = val;
      this.dis_devo = val ? null : true;
      this.req_devo = val ? true : null;
    }
    if (key == 'cred') {
      this.SetCtrlSrvcs.cred = val;
      this.dis_cred = val ? null : true;
      this.req_cred = val ? true : null;
    }
    if (key == 'debi') {
      this.SetCtrlSrvcs.debi = val;
      this.dis_debi = val ? null : true;
      this.req_debi = val ? true : null;
    }
  }

  saveObject(formsave) {
    this.Errors.error_general = false;
    this.Errors.txt_error_general = '';
    let values = formsave.value;
    if (formsave.valid) {
      if (this.Errors.error_list) {
        this.Errors.error_list.forEach((v, k) => {
          this.Errors['error_' + v] = false;
          this.Errors['txt_' + v] = '';
        });
      }
      this.Errors.error_list = [];
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('saveset_controls'),
        auth: this.auth,
        body: {
          fact: values.fact,
          devo: values.devo,
          cred: values.cred,
          debi: values.debi,
          desde: values.desde,
          hasta: values.hasta,
          ctrl_desde: values.ctrl_desde,
          ctrl_hasta: values.ctrl_hasta,
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: 'Estableciendo controles',
        titleSuccess: 'Controles cambiados correctamente',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax ) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Campos inválidos',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        this.resetAll();
      }).catch(error => {
        this.viewError(error);
      });
    }
  }

  viewError(error) {
    // Cargar los campos que tienen errores y su mensaje de error.
    // crear una unica variable de error y mostrarlo con el if dentro de los inputs, limpiar al cambiar o al enviar?
    // this.Errors.error_list = [];
    // for (const k in error) {
    //   if (Object.prototype.hasOwnProperty.call(error, k)) {
    //     const va = error[k];
    //     if (!va.valid && va.valid != undefined) {
    //       this.Errors.error_list.push(k);
    //       this.Errors['error_' + k] = true;
    //       this.Errors['txt_' + k] = va.error;
    //     }
    //   }
    // }
    this.Errors.error_general = true;
    this.Errors.txt_error_general = error.error;
  }

}
