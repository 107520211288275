import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { Cache } from '../../cache';
import { session, session_p } from '../constantes/sessiones';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent implements OnInit {

  title = 'Signin';
  username: string | null = null;
  password: string | null = null;
  remember = false;
  return = '';

  constructor(private titleService: Title,
              public auth: AuthService,
              private router: Router,
              private route: ActivatedRoute
    ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    if (Cache.getLocal(session_p.rememberUser)) {
      this.remember = Cache.getLocal(session_p.rememberUser);
      this.username = Cache.getLocal(session_p.usernameSession);
    }
    this.route.queryParams.subscribe(params => this.return = params.return || '/');
  }

  async onSubmit() {
    if (this.username && this.password && this.username !== '' && this.password !== '') {
      try {
        const login = await this.auth.login(this.password, this.username, this.remember);
        if (login) {
          this.router.navigateByUrl(this.return);
        }
      } catch (error) {
      }
    }
  }

  getId() {
    return 'BSSACIU00001';
  }
}
