import { Component, OnInit, ViewChild, ɵConsole, Input, EventEmitter, Output, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { promise } from 'protractor';

import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';

import { StripeServices } from '../../../core/payment-gateways/payment-methods/stripe/stripe.service';
import { PaymentGatewaysService } from '../../../core/payment-gateways/payment-gateways.service'
import { PayuService } from '../../../core/payment-gateways/payment-methods/payu/payu.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';

@Component({
  selector: 'app-payment-intent',
  templateUrl: './payment-intent.component.html',
  styleUrls: ['./payment-intent.component.css']
})
export class PaymentIntentComponent implements OnInit {
  
  @Output() propagar: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  showErrors = false;
  sw = Swal;
  error: boolean = false;
  gatewaysAvailable: any = [];
  nogateways: boolean = false;
  paymentForm: FormGroup;
  loaded: boolean = false;
  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  paymentList = [{id: 1, name: 'bank', description: 'Transferencia Bancaria', icon: '/assets/img/payment-methods/bank.jpg', selected: true, active: false},
                {id: 2, name: 'stripe', description: 'Stripe', icon: '/assets/img/payment-methods/stripe.jpg', selected: false, active: false},
                {id: 3, name: 'payu', description: 'PayU', icon: '/assets/img/payment-methods/payu.jpg', selected: false, active: false},
                {id: 4, name: 'paypal', description: 'PayPal', icon: '/assets/img/payment-methods/paypal.jpg', selected: false, active: false}];
  constructor(
    public auth: AuthService,
    private listUrl: UrListService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    public stripeService: StripeServices,
    public PaymentGatewaysService: PaymentGatewaysService,
    public PayuService: PayuService
  ) {}

  async ngOnInit() {
    await this.loadingGateways();
    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
    this.PaymentGatewaysService = this.data;
    this.PaymentGatewaysService.PaymentIntentComponent = this;
  }

  selectPayment(id) {
    for(var i = 0; i < this.paymentList.length; i++) {
      this.paymentList[i].selected = false;
    }
    this.paymentList.find(x => x.id == id);
    this.paymentList[id-1].selected = true;
  }

  modifyInput(input) {
    if(input == 'name') {
      this.PaymentGatewaysService.name = this.paymentForm.value.name;
    } else if(input == 'email') {
      this.PaymentGatewaysService.email = this.paymentForm.value.email;
    }
  }

  transactionResult(result, title) {

    this.sw.close();
    if(result == 'succeeded') {
     const oe: SweetAlertOptions = {
       title: title,
       text: "Se procederá con la operación solicitada...",
       icon: 'success',
       confirmButtonText: 'Entendido',
       showConfirmButton: true,
       preConfirm: () => {
         setTimeout(()=>{this.propagar.emit({'event':'done'});},500)
       },
     };
     const alerer = Swal.fire(oe);
    } else {

      Swal.fire({
        icon: 'error',
        title: title,
        text: result
      })
    }
  }

  async pay(method) {

    if(this.paymentForm.valid){
      const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Todo listo!',
        text: "¿Desea proceder con la transacción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
        }).then((result) => {
          if(result.value == true){
            this.showErrors = false;
            if (method == 'stripe'){
              this.stripeService.pay(this.card);
              this.showLoadingModal();
            } else if(method == 'payU'){
              this.PayuService.pay();
              this.showLoadingModal();
            }  else if(method == 'bank'){
              this.transactionResult('succeeded', 'Transacción realizada!...')
            }
          }
        })
    }
    else {
      this.showErrors = true;
    }
  }

  showLoadingModal() {
    this.sw.fire({
      title: 'Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });this.sw.showLoading();
  }

  
  loadingGateways() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_gateways'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        if (respo.payment_gateways.filter(x => x.active).length == 0) {
          this.nogateways = true;
        } else {
          for (let pg of this.paymentList) {
            if (pg.id == 1) {
              pg.active = respo.payment_gateways.find(x => x.id == 1).active;
            }
            else if (pg.id == 2) {
              pg.active = respo.payment_gateways.find(x => x.id == 2).active;
            }
            else if (pg.id == 3) {
              pg.active = respo.payment_gateways.find(x => x.id == 3).active;
            }
            else if (pg.id == 4) {
              pg.active = respo.payment_gateways.find(x => x.id == 4).active;
            }
            for (let pv of this.paymentList) {
              if (pv.active) {
                pv.selected = true;
                break;
              }
            }
          }
        }
      } else {
        this.error = true;
        return false;
      }this.loaded = true;
    }).catch(error => {
      this.loaded = true;
      this.error = true;
      return false;
    });
  }
}
