import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvitationComponent } from '../invitation/invitation.component';

@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.css']
})
export class InvitationModalComponent implements OnInit {

  componentParent: InvitationComponent;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  getId() {
  	return 'BSSGUIU00004';
  }

}
