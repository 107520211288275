import { Injectable } from '@angular/core';
import { ObjectsAsistente } from './objectasistente';
import { Url } from 'src/app/core/ajax/url';

@Injectable({
  providedIn: 'root'
})

export class CatalogoFuerzadeventaAsistenteService {
  pass: number | string; 
  confirmar: boolean;
  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  Modal: any
  closeModal: any;
  pk: number | string;
  newMU: boolean;
  typeModal;
  urlSave: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  habilitaBtn = false;
  valuesObject: ObjectsAsistente;
  valuesObjects: ObjectsAsistente | any;
  valuesObjectsBase: ObjectsAsistente | any;
  viewCode: string;
  editCode: string;
  addCode: string;
  preguntaDelete: string | null;
  urlDelete: Url | null;
  strDelete?: string;
  strConecDelete?: string;
  status: boolean;
  constructor() { 



  }
}
