import { Init } from '../ajax/init-ajax';
import { Config, Ajax } from '../ajax/ajax';
import { Search } from 'src/app/search';
import Swal from 'sweetalert2';
import { promise } from 'protractor';


export class Confir {

    confirmar = Swal.mixin({
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'Confirmar',
        confirmButtonAriaLabel: 'Cancelar',
        cancelButtonText:
          'Cancelar',
        cancelButtonAriaLabel: 'Cancelar'
      });

    con(action: () => void, title: string, templa: string, icon: any) {
        this.confirmar.fire({
          icon,
          preConfirm: action,
          html: templa,
          title: `<strong>${title}</strong>`
        });
    }
    
    close_confirmar(): Promise<boolean> {
        return new Promise((success, error) => {
          let checkAlert = setInterval(() => {
            if (!this.confirmar.isVisible()) {
              success(true);
              clearInterval(checkAlert);
              checkAlert = null;
            }
          }, 500);
        });
      }

    confir(html: string, title: string, icon = 'question'): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.con(() => {
                this.close_confirmar().then(() => {
                    resolve(true);
                });
            }, title, html, icon);
        });
    }

};

export class Utiliti {

    v_empty(txt: string):Promise<string> {
        return new Promise((rl, rj) => {
            if (typeof txt != 'undefined') {
                let v = txt.replace("&nbsp;", "");
                v = typeof v == 'undefined' ? "" : v;
                if (!v || 0 === v.trim().length) {
                    rj('');
                }
                else {
                    rl(v);
                }
            } else {
                rj('');
            }
        });
    }

}

export class ViewLoader extends Utiliti {

    item_show: Array<any>; // items que se muestran
    item_base: Array<any>; // items bases siempre son fijos hasta que se cargan nuevos
    item_search: Array<any>; // items para buscar, se sumplantan con cada busqueda
    of = 0; // numero de inicio para traer registros
    to = 500; // numero final para el limite de registros
    numItem = 500; // numero maximo de registros por busqueda
    search = ''; // input search

    initLoader: Init; // init del ajax para la carga inicial
    configLoader: Config; // config del ajax para la carga inicial
    initSearch: Init; // init del ajax para la busqueda
    configSearch: Config; // config del ajax para la busqueda
    initScrolin: Init; // init del ajax para cuando dan scroll
    confiScrolin: Config; // config del ajax para cuando dan scroll

    resp_field: string; // campo que respondera los ajax cuando busquen en el back
    to_field = 'to'; // campo que se envia al back para decir el inicio de los registros
    of_field = 'of'; // campo que se envia al back para decir el fin de los registros
    search_field = 'search'; // campo que se envia el back para buscar
    field_norepeat: string | Function;
    lmi = true;
    li = true;
    sb = true;
    uli = true;
    create: boolean; // Campo usado para saber si tiene o no permiso de crear en los catalogos
    regresa: boolean; // Campo usado para saber si el catalogo debe regresar a otra URL
    msg_search: string | null = null; // para Guardar el texto a ser mostrado cuando busque y no encuentre nada
    msg_init: string | null = null; // para guardar texto inicial al querer mostrar items y no tenga registros
    msg_show: string | null = null; //Para guardar el texto a mostrar
    resp_back: any; //Campo para guardar la respuesta del back
    fields_search: Array<string>; // campos que se usaran para la busqueda rapida

    constructor() {
        super();
        this.item_base = [];
        this.item_search = [];
        this.item_show = [];
    }

    add_body(init: Init, to: number, of: number): Promise<Init> {
        return new Promise((rs, rj) => {
            if (typeof init.body === 'undefined') {
                init.body = { 
                    [this.to_field]: to,
                    [this.of_field]: of
                };
                if (this.search != '') {
                    init.body[this.search_field] = this.search;
                }
                rs(init);
            } else {
                init.body[this.to_field] = to;
                init.body[this.of_field] = of;
                if (this.search != '') {
                    init.body[this.search_field] = this.search;
                }
                rs(init);
            }
        });
    }

    load_items() {
        if (this.li) {
            this.li = false;
            this.add_body(this.initLoader, this.to, this.of).then( rp => {
                const ajax = new Ajax(rp, this.configLoader);
                ajax.call().then(resp => {
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') {
                            this.item_show = [].concat(resp[this.resp_field]);
                            this.item_search = [].concat(resp[this.resp_field]);
                            this.item_base = [].concat(resp[this.resp_field]);
                        }
                        if (typeof resp['create'] != 'undefined') {
                            this.create = resp['create'];
                        }
                        if (typeof resp['regresa'] != 'undefined') {
                            this.regresa = resp['regresa'];
                        }
                        this.resp_back = resp;
                    }
                    this.li = true;
                  }).catch(error => {
                    this.li = true;
                  });
            });
        }
    }

    fielnorepeat(obj): number {
        if (typeof this.field_norepeat == 'function') {
            return this.field_norepeat(obj);
        } else {
            return obj[this.field_norepeat];
        }
    }

    update_load_items() {
        if (this.uli) {
            this.uli = false;
            const of = 0;
            const to = this.item_base.length;
            this.add_body(this.initLoader, to, of).then( rp => {
                const ajax = new Ajax(rp, this.configLoader);
                ajax.call().then(resp => {
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') { 
                            this.item_base = [].concat(resp[this.resp_field]);
                            this.no_repeat_object(this.item_search, resp[this.resp_field]).then(elementos => {
                                this.item_search = elementos;
                                if (this.search != '') {
                                    this.search_speed({keyCode: 12});
                                } else {
                                    this.item_show = [].concat(resp[this.resp_field]);
                                }
                            });
                        }
                    }
                    this.uli = true;
                  }).catch(error => {
                    this.uli = true;
                  });
            });
        }
    }

    no_repeat_object(bs: Array<any>, nv: Array<any>):Promise<Array<any>> {
        return new Promise((rl, rj) => {
            Search.forEachAll(nv, (element, key, result, next) => {
                let position = Search.position(bs, (ele, value)=>{
                    return Search.igual(this.fielnorepeat(ele), value);
                }, this.fielnorepeat(element));
                if ( position != -1) {
                    bs[position] = element;
                    next();
                } else {
                    bs.push(element);
                    next();
                }
            }, (all) => {
                rl(bs);
            }, true);
        });
    }

    load_more_item() {
        if (this.lmi) {
            this.lmi = false;
            const of = this.item_show.length;
            const to = of + this.numItem;
            this.add_body(this.initScrolin, to, of).then( rp => {
                const ajax = new Ajax(rp, this.confiScrolin);
                ajax.call().then(resp => {
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') {
                            if (this.search == '') {
                                if (typeof resp[this.resp_field] != 'undefined') {
                                    this.no_repeat_object(this.item_base, resp[this.resp_field]).then(elementos => {
                                        this.item_base = elementos;
                                        this.item_show = [].concat(this.item_base);
                                        this.item_search = [].concat(this.item_base);
                                    });
                                }
                            } else {
                                if (typeof resp[this.resp_field] != 'undefined') {
                                    this.no_repeat_object(this.item_search, resp[this.resp_field]).then(elementos => {
                                        this.item_search = elementos;
                                        this.item_show = [].concat(this.item_search);
                                    });
                                }
                            }
                        }
                    }
                    this.lmi = true;
                  }).catch(error => {
                    this.lmi = true;
                  });
            });
        }
    }

    search_speed(even: any) {
        this.v_empty(this.search).then( rp => {
            if (typeof this.item_search !== 'undefined' && this.item_search.length && this.search != '') {
                this.item_show = Search.filter(this.item_search, (x, e) => {
                    return Search.any(this.fields_search.map( c => {
                        return Search.search(x[c], e);
                    }));
                }, rp);
                if (even.keyCode == 13) {
                    this.search_back();
                }
                this.msg_show = this.msg_search;
            }
        }).catch( er => {
            this.item_show = [].concat(this.item_base);
            this.item_search = [].concat(this.item_base);
            this.msg_show = this.msg_init;
        });
    }

    search_back() {
        if (this.sb) {
            this.sb = false;
            this.add_body(this.initSearch, this.to, this.of).then( rp => {
                const ajax = new Ajax(rp, this.configSearch);
                ajax.call().then(resp => {
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') {
                            this.item_search = [].concat(resp[this.resp_field]);
                            this.item_show = [].concat(this.item_search);
                        }
                    }
                    this.sb = true;
                });
            }).catch(error => {
                this.sb = true;
            });
        }
    }

}