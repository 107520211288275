import { JwtHelperService } from '@auth0/angular-jwt';
import { Cache } from '../../cache';
import { session } from '../constantes/sessiones';

export class Token {

    public _token: null | string;
    tokenObj: any;
    type: string;
    name: string;
    helper = new JwtHelperService();

    constructor(token: string) {
        this._token = null;
        this.proceso(token);
    }

    proceso(token: string) {
        this.type = session.tokenAcTp;
        this.name = session.tokenAc;
        Cache.setLocal(this.name, token);
        this.token = token;
        if (this.token) {
            this.tokenObj = this.getTokenObj();
            this.validateToken();
            this.changeCookieToken();
        }
    }

    set token(tk) {
        this._token = tk;
    }

    get token() {
        return this._token;
    }

    // createTimerefres() {
    //     /**
    //      * Crea un settimeup para enviar a refrescar el token antes de que llegue a vencer
    //      */
    //     if(typeof timeRefresToken !== 'undefined'){
    //         if(!timeRefresToken){
    //             try {
    //                 this.validateAllTokenBa(Cache.getItem('tokenrefres'));
    //                 timeRefresToken = setTimeout(() => {
    //                     try {
    //                         // Cache.getItem('refresActive');
    //                         this.callRefres(false, true).then(resp=>{
    //                         }).catch(errer=>{
    //                         });
    //                     } catch (error) {}
    //                 }, this.getTimeRefres());
    //             } catch (error) {
    //             }
    //         }
    //     }
    // }

    getTimeRefres(minutes: number = 1): number {
        const time_ini = new Date(this.tokenObj.exp * 1000).getTime();
        const time_fin = new Date().getTime();
        const m_miliseg = ((minutes * 60) * 1000);
        const finaltime = ((time_ini - time_fin) - m_miliseg);
        if (finaltime <= 0) {
            return 0;
        } else {
            return finaltime;
        }
    }

    isExpire(tk: string): boolean {
        const token = this.helper.decodeToken(tk);
        const time_ini = new Date(token.exp * 1000).getTime();
        const time_fin = new Date().getTime();
        const finaltime = ((time_ini - time_fin));
        if (finaltime <= 0) {
            return false;
        } else {
            return true;
        }
    }

    getToken(): Promise<string> {
        return new Promise((success, error) => {
            if (this.token) {
                this.validateAllToken();
                this.changeCookieToken();
                success(this.token);
            } else {
                error(false);

            }
                // this.callRefres().then(resp => {
                //     success(resp);
                // }).catch(erre => {
                //     error(erre);
                // });
        });
    }


    getTokenObj(): object {
        return this.helper.decodeToken(this.token);
    }

    validateAllToken(): boolean | string | object {
        return this.validateAllTokenBa(this.token);
    }

    validateAllTokenBa(tk: string): boolean | string | object {
        try {
            if (tk) {
                if (this.isExpire(tk)) {
                    return true;
                } else {
                    return false;
                }
            }
        } catch (error) {
            return false;
        }
    }

    changeCookieToken() {
        Cache.changeCookie(this.type, this.token);
    }

    validateToken(): boolean | string | object {
        return this.validateAllToken();
    }
}

export class TokenCompany extends Token {

    name: string;
    type: string;

    proceso(token: string) {
        this.type = session.tokenCompanyTp;
        this.name = session.tokenCompany;
        Cache.setSession(this.name, token);
        this.token = token;
        if (this.token) {
            this.tokenObj = this.getTokenObj();
            this.validateToken();
            this.changeCookieToken();
        }
    }

    set token(tk) {
        this._token = tk;
    }

    get token() {
        return this._token;
    }
}

export class TokenRefres extends Token {

    type: string;
    name: string;

    proceso(token: string) {
        this.type = session.refreshTokenTp;
        this.name = session.refreshToken;
        Cache.setLocal(this.name, token);
        this.token = token;
        if (this.token) {
            this.tokenObj = this.getTokenObj();
            this.validateToken();
            this.changeCookieToken();
        }
    }

    set token(tk) {
        this._token = tk;
    }

    get token() {
        return this._token;
    }
}
