import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrativoRoutingModule } from './administrativo-routing.module';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingComponent } from './setting/setting.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InventarioComponent } from './inventario/inventario.component';
import { SupervisorComponent } from './supervisor/supervisor.component';
import { InvitationComponent } from './invitation/invitation.component';
import { SetControlsComponent } from './set-controls/set-controls.component';
import { StationConfigComponent } from './station-config/station-config.component';
import { FiscalPrinterComponent } from './fiscal-printer/fiscal-printer.component';
import { HttpClientModule } from '@angular/common/http';
import { LaboralDaysComponent } from './laboral-days/laboral-days.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PaymentIntentComponent } from './payment-intent/payment-intent.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ModalMotivoComponent, ModalNoVisitaComponent, ModalNoCompraComponent } from './modals/modal-motivo/modal-motivo.component'; 
import { ModalEquiposComponent,ModalEquipoComponent } from './modals/modal-equipos/modal-equipos.component';
import { ModalPromotionalItemsComponent, ModalArticuloPromocion } from './modals/modal-promotional-items/modal-promotional-items.component';
import { ModalInventoryPromotionalItesmsComponent, ModalEntradaArticuloPromocion } from './modals/modal-inventory-promotional-itesms/modal-inventory-promotional-itesms.component';
import { ModalReasonRejectionComponent,ModalMotivoRetiroComponent} from './modals/others/modal-reason-rejection/modal-reason-rejection.component';
import { ModalPaySoonComponent, ModalProntoPagoComponent } from './modals/others/modal-pay-soon/modal-pay-soon.component';
import { ModalMsjFacturacionComponent, ModalFacturacionComponent } from './modals/others/modal-msj-facturacion/modal-msj-facturacion.component';
import { ModalEntradaComponent } from './modals/inventario/modal-entrada/modal-entrada.component';
import { ModalSalidaComponent } from './modals/inventario/modal-salida/modal-salida.component';
import { ModalClientesComponent, ModalClientesDebitoComponent, ModalClientesCreditoComponent } from './modals/tipos-de-notas/modal-clientes/modal-clientes.component';

@NgModule({
  declarations: [CatalogoComponent, SettingComponent, InventarioComponent, SupervisorComponent,
  InvitationComponent,
  SetControlsComponent,
  StationConfigComponent,
  FiscalPrinterComponent,
  LaboralDaysComponent,
  PaymentIntentComponent,
  ModalMotivoComponent, 
  ModalNoVisitaComponent, 
  ModalNoCompraComponent,
  ModalEquiposComponent,
  ModalEquipoComponent,
  ModalPromotionalItemsComponent, 
  ModalArticuloPromocion,
  ModalInventoryPromotionalItesmsComponent, 
  ModalEntradaArticuloPromocion,
  ModalMotivoComponent,
  ModalNoVisitaComponent,
  ModalNoCompraComponent,
  ModalReasonRejectionComponent ,
  ModalMotivoRetiroComponent,
  ModalPaySoonComponent,
  ModalProntoPagoComponent,
  ModalMsjFacturacionComponent,
  ModalFacturacionComponent,
  ModalEntradaComponent,
  ModalSalidaComponent,
  ModalClientesComponent,
  ModalClientesDebitoComponent, 
  ModalClientesCreditoComponent, 
],
  imports: [
    CommonModule,
    AdministrativoRoutingModule,
    FormsModule,
    HttpClientModule,
    TabsModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_51HcWqmIKOQfW16Wbzdk1VvXxFHQKz22Eh6NdP3T9GsobGewu49q9krLuZILmeizKZVPvDRHxI4v2O8uuLklOrv5m00mAUia9n4'),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    PaginationModule
  ],
  exports: [
    PaymentIntentComponent
  ],
  providers: [ 
    ModalMotivoComponent, 
    ModalNoVisitaComponent, 
    ModalNoCompraComponent,
    ModalEquiposComponent,
    ModalEquipoComponent,
    ModalPromotionalItemsComponent, 
    ModalArticuloPromocion,
    ModalInventoryPromotionalItesmsComponent, 
    ModalEntradaArticuloPromocion,
    ModalMotivoComponent,
    ModalNoVisitaComponent,
    ModalNoCompraComponent,
    ModalReasonRejectionComponent ,
    ModalMotivoRetiroComponent,
    ModalPaySoonComponent,
    ModalProntoPagoComponent,
    ModalMsjFacturacionComponent,
    ModalFacturacionComponent,
    ModalEntradaComponent,
    ModalSalidaComponent,
    ModalClientesComponent,
    ModalClientesDebitoComponent, 
    ModalClientesCreditoComponent,
  ],

  entryComponents: [
    ModalMotivoComponent, 
    ModalNoVisitaComponent, 
    ModalNoCompraComponent,
    ModalEquiposComponent,
    ModalEquipoComponent,
    ModalPromotionalItemsComponent, 
    ModalArticuloPromocion,
    ModalInventoryPromotionalItesmsComponent, 
    ModalEntradaArticuloPromocion,
    ModalMotivoComponent,
    ModalNoVisitaComponent,
    ModalNoCompraComponent,
    ModalReasonRejectionComponent ,
    ModalMotivoRetiroComponent,
    ModalPaySoonComponent,
    ModalProntoPagoComponent,
    ModalMsjFacturacionComponent,
    ModalFacturacionComponent,
    ModalEntradaComponent,
    ModalSalidaComponent,
    ModalClientesComponent,
    ModalClientesDebitoComponent, 
    ModalClientesCreditoComponent,
  ]
})
export class AdministrativoModule { }
