import { Component, OnInit, Input, EventEmitter, Output, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { ListUrl } from 'src/app/core/constantes/request';
import { Init } from 'src/app/core/ajax/init-ajax';
import { HttpParams } from '@angular/common/http';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { UrlBase } from 'src/app/core/ajax/url';
import { AuthService } from 'src/app/core/auth/auth.service';
import { City, State, Country } from 'src/app/core/auth/user';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';


export interface CustomerFm extends Object {
  // Interface creada para el formulario y sus campos
  code_customers?: any;
  trade_name?: any;
  legal_name?: any;
  name_owner?: any;
  name_manager?: any;
  address?: any;
  nit?: any;
  contac?: any;
  email?: any;
  phone?: any;
  city?: City;
  state?: State;
  country?: Country;
  post_code?: any;
  customers_type?: any;
  customers_group?: any;
  locked?: any;
  special_credit?: any;
  tax_exempt?: any;
  distributor?: any;
  customers_subgroup?: any;
  final_consumer?: any;
  retention_agent?: any;
}


@Component({
  selector: 'app-modal-createcustomer',
  templateUrl: './modal-createcustomer.component.html',
  styleUrls: ['./modal-createcustomer.component.css','./style.scss']
})
export class ModalCreatecustomerComponent implements OnInit {

  @Output() propagar: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  customer: any;
  co_ind: any;
  st_ind: any;
  dat: any;
  ci_ind: any;
  tax_exempt: boolean;
  special_credit: boolean;
  distributor: boolean;
  locked: boolean;
  retention_agent: boolean;
  final_consumer: boolean;
  phone_code = '+00';
  post_code: number | null = null;
  type_desc: any;
  countrys: any;
  states: any;
  citys: any;
  groups: any;
  subgroups: any;
  subgroups_temp: any;
  group_selected: any;
  types: any;
  city_selected: any;
  subgroup_selected: any;
  type_selected: any;
  enviando: any;
  group_desc: any;
  subgroup_desc: any;
  tabset: boolean;
  groupaux_description: any;
  groupaux_selected: any;

  codeId = 'ADMCAIU00005';

  idfType = [{name:'Cc',id:0,code:'CC'},{name:'Nit',id:1,code:'NIT'},{name:'Pasaporte',id:2,code:'PASAPORTE'},
  {name:'Rc',id:3,code:'RC'},{name:'Ti',id:4,code:'TI'},{name:'Te',id:5,code:'TE'},{name:'Ce',id:6,code:'CE'},
  {name:'Ie',id:7,code:'IE'},{name:'Nit de otro país',id:8,code:'NIT_OTRO_PAIS'}];
  customerType = [{name:'Natural',id:0,code:'PERSONA_NATURAL'},{name:'Jurídico',id:1,code:'PERSONA_JURIDICA'}];
  tax_level = [{name:'Responsable de Iva',id:0,code:'RESPONSABLE_DE_IVA'},{name:'No responsable de Iva',id:1,code:'NO_RESPONSABLE_DE_IVA'}];
  regimen = [{name:'Simple',id:0,code:'SIMPLE'},{name:'Ordinario',id:1,code:'ORDINARIO'},{name:'Gran contribuyente',id:2,code:'GRAN_CONTRIBUYENTE'},
  {name:'Autor retenedor',id:3,code:'AUTORRETENEDOR'},{name:'Agente de retención Iva',id:4,code:'AGENTE_RETENCION_IVA'}];
  isNatural = true;
  textId = 'Cc';
  codeTax_level = 'RESPONSABLE_DE_IVA';
  codeRegimen = 'SIMPLE';
  codecustomerType = 'PERSONA_NATURAL';
  codeidfType = 'CC';
  first_name = '';
  last_name = '';
  // Objeto con los campos que se envian al servidor
  enviaForm: CustomerFm = {
    code_customers: null,
    trade_name: null,
    legal_name: null,
    name_owner: null,
    name_manager: null,
    address: null,
    nit: null,
    contac: null,
    email: null,
    phone: null,
    city: {
      city_id: '',
      city_name: ''
    },
    state: {
      state_id: '',
      state_name: ''
    },
    country: {
      country_id: '',
      country_name: '',
      phone_code: '',
      short_name: ''
    },
    post_code: null,
    customers_type: null,
    customers_group: null,
    locked: null,
    special_credit: null,
    tax_exempt: null,
    distributor: null,
    customers_subgroup: null,
    final_consumer: null,
    retention_agent: null,
  };


  maxl: CustomerFm = {
    code_customers: 12,
    trade_name: 120,
    legal_name: 120,
    name_owner: 80,
    name_manager: 80,
    address: 255,
    nit: 20,
    contac: 20,
    email: 70,
    phone: 50,
    post_code: 6,
    customers_type: 3,
    customers_group: 3,
    customers_subgroup: 3,
  };
  minl: CustomerFm = {

  };

  showErrors = false;

  typeForm = new FormGroup({
    type_customer_code: new FormControl(),
    type_customer_description: new FormControl(),
  });

  groupForm = new FormGroup({
    group_code: new FormControl(),
    group_description: new FormControl(),
  });

  subgroupForm = new FormGroup({
    subgroup_code: new FormControl(),
    subgroup_description: new FormControl(),
  });

  customerForm = new FormGroup({
    code_customers: new FormControl(),
    trade_name: new FormControl(),
    legal_name: new FormControl(),
    name_owner: new FormControl(),
    name_manager: new FormControl(),
    address: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
    post_code: new FormControl(),
    nit: new FormControl(),
  });

  constructor(private titleService: Title,
    public auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private listUrl: UrListService) {
    this.typeForm = this.formBuilder.group({
        type_customer_code: ['', [Validators.required, Validators.maxLength(10)]],
        type_customer_description: ['', [Validators.required, Validators.maxLength(80)]],
    });
    this.groupForm = this.formBuilder.group({
      group_code: ['', [Validators.required, Validators.maxLength(10)]],
      group_description: ['', [Validators.required, Validators.maxLength(80)]],
    });
    this.subgroupForm = this.formBuilder.group({
      subgroup_code: ['', [Validators.required, Validators.maxLength(10)]],
      subgroup_description: ['', [Validators.required, Validators.maxLength(80)]],
    });
    this.tabset = false;
    this.groupaux_selected = -1;
    this.groupaux_description = 'DESCRIPCIÓN GRUPO';
    this.type_desc = "--------------------------";
    this.group_desc = "--------------------------";
    this.subgroup_desc = "--------------------------";
    this.tax_exempt = false;
    this.special_credit = false;
    this.distributor = false;
    this.locked = false;
    this.retention_agent = false;
    this.final_consumer = false;
    this.city_selected = -1;
    this.enviando = false;
    this.subgroup_selected = -1;
    this.group_selected = -1;
    this.type_selected = -1;
    this.customerForm = this.formBuilder.group({
      code_customers: ['', [Validators.required, Validators.maxLength(this.maxl.code_customers)]],
      trade_name: ['', [Validators.required, Validators.maxLength(this.maxl.trade_name)]],
      legal_name: ['', [Validators.required, Validators.maxLength(this.maxl.legal_name)]],
      name_owner: ['', [Validators.maxLength(this.maxl.name_owner)]],
      name_manager: ['', [Validators.maxLength(this.maxl.name_manager)]],
      address: ['', [Validators.required, Validators.maxLength(this.maxl.address)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(this.maxl.email)]],
      phone: ['', [Validators.required, Validators.maxLength(this.maxl.phone), Validators.minLength(1), Validators.pattern('^([0-9]+)$')]],
      post_code: ['', [Validators.maxLength(this.maxl.post_code), Validators.pattern('^([0-9]+)$')]],
      nit: ['', [Validators.required, Validators.maxLength(this.maxl.nit), Validators.pattern('^([0-9]+)$')]],
    });
    this.getCountries();
    this.getGroups();
    this.getSubGroups();
    this.getTypes();
   }

  selectData(e){
    switch (e.target.name) {
      case 'customerType':
        if(e.target.value == 0){
          this.isNatural = true;
        } else {
          this.isNatural = false;
        }
        this.codecustomerType = this.customerType.find(x => x.id == e.target.value).code;
        break;
      case 'idfType':
        this.textId = this.idfType.find(x => x.id == e.target.value).name;
        this.codeidfType = this.idfType.find(x => x.id == e.target.value).code;
        break;
      case 'tax_level':
        this.codeTax_level = this.tax_level.find(x => x.id == e.target.value).code;
        break;
      case 'regimen':
        this.codeRegimen = this.regimen.find(x => x.id == e.target.value).code;
        break;
      case 'first_name':
        this.first_name = e.target.value;
        this.unirNombres();
        break;
      case 'last_name':
        this.last_name = e.target.value;
        this.unirNombres();
        break;
    }
  }

  unirNombres(){
    this.customerForm.get('trade_name').setValue(this.first_name + " " + this.last_name);
  }

   async onSubmit() {
     if(this.customerForm.value.legal_name == '') {
        this.customerForm.get('legal_name').setValue(this.customerForm.value.trade_name);
     }
     if(this.customerForm.valid){
       this.showErrors = false;
      if(!this.enviando) {
        if(this.data != null) {
          this.enviando = true;
        if(this.isNatural && (this.first_name == '' || this.last_name == '')){
         Swal.fire({
              icon: 'warning',
              title: 'Operación rechazada!...',
              text: 'Por favor introduzca los campos de nombre y apellido...'
            });
         return;
       }
          const initAjax: Init = {
            method: 'put',
            url: this.listUrl.url('edit_customer'),
            auth: this.auth,
            body: {
              code_customers: this.customerForm.value.code_customers,
              trade_name: this.customerForm.value.trade_name,
              legal_name: this.customerForm.value.legal_name,
              first_name: this.first_name,
              last_name: this.last_name,
              name_owner: this.customerForm.value.name_owner,
              name_manager: this.customerForm.value.name_manager,
              address: this.customerForm.value.address,
              email: this.customerForm.value.email,
              phone: this.customerForm.value.phone,
              city: this.city_selected,
              post_code: this.customerForm.value.post_code,
              customers_type: this.type_selected,
              locked: this.locked,
              special_credit: this.special_credit,
              tax_exempt: this.tax_exempt,
              distributor: this.distributor,
              final_consumer: this.final_consumer,
              retention_agent: this.retention_agent,
              customers_subgroup: this.subgroup_selected,
              nit: this.customerForm.value.nit,
              contac: 'null',
              id_customer: this.customer.id_customers,
              party_type: this.codecustomerType,
              party_identification_type: this.codeidfType,
              tax_level_code: this.codeTax_level,
              regimen: this.codeRegimen
            }
          };const optionsalertaSuccess: SweetAlertOptions = {
            icon: 'success',
            title: 'Operación completada!',
            text: 'El cliente ha sido actualizado exitosamente',
            showConfirmButton: true,
            showCancelButton: false,
            showCloseButton: false,
            timer: 15000,
            confirmButtonText: 'Entendido',
          }
          const configAjax: Config = {
            titleLoading: 'Validando información, por favor espere...',
            alertaSuccess: Swal.mixin(optionsalertaSuccess),
          };
          const ajax = new Ajax(initAjax, configAjax);
          ajax.call().then(respo => {
            this.enviando = false;
            if (respo.success) {
              if (typeof respo.customer != 'undefined') {
                this.propagar.emit({'event':'edit', 'data':respo.customer});
              }
            }
          }).catch(error => {
            this.enviando = false;
            return false;
          });

        } else {
          this.enviando = true;
          const initAjax: Init = {
            method: 'put',
            url: this.listUrl.url('register_customer'),
            auth: this.auth,
            body: {
              code_customers: this.customerForm.value.code_customers,
              trade_name: this.customerForm.value.trade_name,
              legal_name: this.customerForm.value.legal_name,
              first_name: this.first_name,
              last_name: this.last_name,
              name_owner: this.customerForm.value.name_owner,
              name_manager: this.customerForm.value.name_manager,
              address: this.customerForm.value.address,
              email: this.customerForm.value.email,
              phone: this.customerForm.value.phone,
              city: this.city_selected,
              post_code: this.customerForm.value.post_code,
              customers_type: this.type_selected,
              locked: this.locked,
              special_credit: this.special_credit,
              tax_exempt: this.tax_exempt,
              distributor: this.distributor,
              final_consumer: this.final_consumer,
              retention_agent: this.retention_agent,
              customers_subgroup: this.subgroup_selected,
              nit: this.customerForm.value.nit,
              contac: 'null',
              party_type: this.codecustomerType,
              party_identification_type: this.codeidfType,
              tax_level_code: this.codeTax_level,
              regimen: this.codeRegimen
            }
          };const optionsalertaSuccess: SweetAlertOptions = {
            icon: 'success',
            title: 'Operación completada!',
            text: 'El cliente ha sido creado exitosamente',
            showConfirmButton: true,
            showCancelButton: false,
            showCloseButton: false,
            timer: 15000,
            confirmButtonText: 'Entendido',
          }
          const configAjax: Config = {
            titleLoading: 'Validando información, por favor espere...',
            alertaSuccess: Swal.mixin(optionsalertaSuccess),
          };
          const ajax = new Ajax(initAjax, configAjax);
          ajax.call().then(respo => {
            this.enviando = false;
            if (respo.success) {
              this.dat = {id_customer: respo.id_customers};
              if (typeof respo.customer != 'undefined') {
                this.propagar.emit({'event':'add', 'data':respo.customer});
              }
            } else {
              return false;
            }
          }).catch(error => {
            this.enviando = false;
            return false;
          });
        }
      }
     } else {
       this.showErrors = true;
     }
    }

    
  getCountries() {
    const initAjax: Init = {
      method: 'put',
      url: new UrlBase(ListUrl.iniregister[1]),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false,
      forceNormal: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.countrys = respo.dat.count;
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  getGroups() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_groups'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.groups = respo.dat;
        if(this.data) {
          this.group_desc = this.groups.find(x => x.id_group == this.data.subgroup_p.id_subgroup).group_description;
        }
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  getSubGroups(){
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_subgroups'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.subgroups_temp = respo.dat;
        if(this.data){
          this.subgroups =  this.subgroups_temp.filter(x => x.group_id == this.data.group_p.id_group);
          this.subgroup_desc = this.subgroups_temp.find(x => x.id_subgroup == this.data.group_p.id_group).subgroup_description;
        }
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  getTypes() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_types'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.types = respo.dat;
        if(this.data) {
          this.type_desc = this.types.find(x => x.id_type_customer == this.data.customers_type).type_customer_description
        }
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  ngOnInit() {
    if(this.data != null) {
      this.codeId = 'ADMCAIU00006';
      this.customer = this.data;
      this.customerForm.get('code_customers').setValue(this.customer.code_customers);
      this.customerForm.get('trade_name').setValue(this.customer.trade_name);
      this.customerForm.get('legal_name').setValue(this.customer.legal_name);
      this.customerForm.get('name_owner').setValue(this.customer.name_owner);
      this.customerForm.get('name_manager').setValue(this.customer.name_manager);
      this.customerForm.get('address').setValue(this.customer.address);
      this.customerForm.get('email').setValue(this.customer.email);
      this.customerForm.get('phone').setValue(this.customer.phone);
      this.customerForm.get('post_code').setValue(this.customer.post_code);
      this.customerForm.get('nit').setValue(this.customer.nit);
      this.tax_exempt = this.data.tax_exempt;
      this.special_credit = this.data.special_credit;
      this.distributor = this.data.distributor;
      this.locked = this.data.locked;
      this.retention_agent = this.data.retention_agent;
      this.final_consumer = this.data.final_consumer;
      this.city_selected = this.data.city_p.city_id;
      this.phone_code = '+'+this.data.country_p.phone_code;
      this.group_selected = this.data.group_p.id_group;
      this.searchGeo({country: this.data.country_p.country_id});
      this.searchGeo({state: this.data.state_p.state_id});
      this.searchGeo({city: this.data.city_p.city_id});
      this.getSubGroups();
      if(this.data.subgroup_p.id_subgroup == undefined){
        this.subgroup_selected = -1;
        this.group_selected = -1;
      } else{
        this.subgroup_selected = this.data.subgroup_p.id_subgroup;
      } if(this.data.customers_type == undefined){
        this.type_selected = -1;
      } else {
        this.type_selected = this.data.customers_type;
      }
      this.codeTax_level = this.data.tax_level_code;
      this.codeRegimen = this.data.regimen;
      this.codecustomerType = this.data.party_type;
      this.codeidfType = this.data.party_identification_type;
      if(this.codecustomerType == 'PERSONA_NATURAL') {
        this.isNatural = true;
        this.first_name = this.customer.first_name;
        this.last_name = this.customer.last_name;
        this.unirNombres();
      } else {
        this.isNatural = false;
        this.customerForm.get('trade_name').setValue(this.customer.trade_name);
      }
    } else {
      this.codeId = 'ADMCAIU00005';
    }
  }

  dividirCadena(cadenaADividir,separador) {
     var arrayDeCadenas = cadenaADividir.split(separador);
     this.first_name = arrayDeCadenas[0];
     this.last_name = arrayDeCadenas[1];
  }

  getId() {
    return this.codeId;
  }

  searchGeo(camp) {
    // Carga los datos de los "select" de la geolocalizacion
    // if (this.inputSearch && this.inputSearch !== '') {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.searchgeo[1]),
        auth: this.auth,
        body: camp
      };
      const configAjax: Config = {
        visible: false,
        forceNormal: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(response => {
        if (response.success) {
          switch (response.nam) {
            case 'country':
              this.countrys = response.values;
              break;
            case 'state':
              this.states = response.values;
              break;
            case 'city':
              this.citys = response.values;
              break;
          }
        } else {
          return false;
        }
      }).catch(error => {
        return false;
      });
  }

  detectType(e) {
    switch (e.target.name) {
      case 'customers_type':
        if(isNaN(e.target.value)){
          this.type_desc = "--------------------------";
        } else {
          this.type_selected = e.target.value;
          this.type_desc = this.types.find(x => x.id_type_customer == e.target.value).type_customer_description
        }
        break;
    }
  }

  setEmitter(){
    if(this.data!= null){
      this.dat={id_customer: this.customer.id_customers};
    }
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_customer'),
      auth: this.auth,
      body: this.dat
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        if(this.data!=null){
           this.propagar.emit({'event':'edit', 'data':respo.customers});
        } else {
          this.propagar.emit({'event':'add', 'data':respo.customers});
        }
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  async register_type() {
    if(!this.enviando && this.typeForm.valid){
      this.enviando = true;
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('register_type'),
        auth: this.auth,
        body: {
          type_customer_code: this.typeForm.value.type_customer_code,
          type_customer_description: this.typeForm.value.type_customer_description,
        }
      };const optionsalertaSuccess: SweetAlertOptions = {
        icon: 'success',
        title: 'Operación completada!',
        text: 'Tipo de cliente agregado!',
        showConfirmButton: true,
        showCancelButton: false,
        showCloseButton: false,
        timer: 15000,
        confirmButtonText: 'Entendido',
      }
      const configAjax: Config = {
        titleLoading: 'Validando información, por favor espere...',
        alertaSuccess: Swal.mixin(optionsalertaSuccess),
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
        this.enviando = false;
        if (respo.success) {
          this.enviando = false;
          if(this.types.length != 0){
            this.types.push(respo.type_customer);
          } else {
            this.types = [respo.type_customer];
          }
        } else {
          this.enviando = false;
          return false;
        }
      }).catch(error => {
        this.enviando = false;
        return false;
      });
    }
  }

  async register_group() {
    if(!this.enviando && this.groupForm.valid){
      this.enviando = true;
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('register_group_customer'),
        auth: this.auth,
        body: {
          group_code: this.groupForm.value.group_code,
          group_description: this.groupForm.value.group_description,
        }
      };const optionsalertaSuccess: SweetAlertOptions = {
        icon: 'success',
        title: 'Operación completada!',
        text: 'Grupo agregado!',
        showConfirmButton: true,
        showCancelButton: false,
        showCloseButton: false,
        timer: 15000,
        confirmButtonText: 'Entendido',
      }
      const configAjax: Config = {
        titleLoading: 'Validando información, por favor espere...',
        alertaSuccess: Swal.mixin(optionsalertaSuccess),
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
        this.enviando = false;
        if (respo.success) {
          this.enviando = false;
          if(this.groups.length != 0){
            this.groups.push(respo.customer_group);
          } else {
            this.groups = [respo.customer_group];
          }
          
        } else {
          this.enviando = false;
          return false;
        }
      }).catch(error => {
        this.enviando = false;
        return false;
      });
    }
  }

  selectGroup(data) {
    switch (data.target.name) {
      case 'group':
        if (data.target.value != 'CÓDIGO GRUPO') {
          this.groupaux_selected = data.target.value;
          this.groupaux_description = this.groups.find(x => x.id_group == data.target.value).group_description;
        } else {
          this.groupaux_description = 'DESCRIPCIÓN GRUPO';
        }
    }
  }

  changeSubgroup(e) {
    switch (e.target.name) {
      case 'customers_group':
        if(isNaN(e.target.value)){
          this.group_selected = -1;
          this.subgroups = null;
          this.subgroup_desc = "--------------------------";
          this.group_desc = "--------------------------";
          this.subgroup_selected = -1;
        } else {
          this.group_selected = e.target.value;
          this.group_desc = this.groups.find(x => x.id_group == e.target.value).group_description;
          this.subgroups = this.subgroups_temp.filter(x => x.group_id == this.group_selected);
        }
        break;
      case 'customers_subgroup':
        if(isNaN(e.target.value)){
          this.subgroup_selected = -1;
          this.subgroup_desc = "--------------------------";
        } else {
          this.subgroup_selected = e.target.value;
          this.subgroup_desc = this.subgroups_temp.find(x => x.id_subgroup == e.target.value).subgroup_description;
        }
    }
  }

  changeGeo(e) {
    // Detecta cuando se elige un campo de uno de los valores del "select" (onchange) y captura su valor.
    switch (e.target.name) {
      case 'country':
        this.phone_code = e.target.selectedOptions[0].id;
        this.enviaForm.country.country_id = e.target.value;
        this.enviaForm.country.country_name = e.target.selectedOptions[0].text;
        this.co_ind = e.target.selectedIndex;
        this.citys = null;
        this.searchGeo({country: this.enviaForm.country.country_id});
        break;
      case 'state':
        this.enviaForm.state.state_id = e.target.value;
        this.enviaForm.state.state_name = e.target.selectedOptions[0].text;
        this.st_ind = e.target.selectedIndex;
        this.searchGeo({state: this.enviaForm.state.state_id});
        break;
      case 'city':
        this.enviaForm.city.city_id = e.target.value;
        this.enviaForm.city.city_name = e.target.selectedOptions[0].text;
        this.ci_ind = e.target.selectedIndex;
        this.city_selected = e.target.value;
        break;
    }
  }

  async register_subgroup() {
    if(!this.enviando && this.subgroupForm.valid){
      if(this.groupaux_selected != -1) {
        this.enviando = true;
        const initAjax: Init = {
          method: 'put',
          url: this.listUrl.url('register_subgroup_customer'),
          auth: this.auth,
          body: {
            subgroup_code: this.subgroupForm.value.subgroup_code,
            subgroup_description: this.subgroupForm.value.subgroup_description,
            id_group: this.groupaux_selected
          }
        };const optionsalertaSuccess: SweetAlertOptions = {
          icon: 'success',
          title: 'Operación completada!',
          text: 'Subgrupo agregado!',
          showConfirmButton: true,
          showCancelButton: false,
          showCloseButton: false,
          timer: 15000,
          confirmButtonText: 'Entendido',
        }
        const configAjax: Config = {
          titleLoading: 'Validando información, por favor espere...',
          alertaSuccess: Swal.mixin(optionsalertaSuccess),
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(respo => {
          this.enviando = false;
          if (respo.success) {
            this.enviando = false;
            if(this.subgroups_temp.length != 0){
              this.subgroups_temp.push(respo.customer_subgroup);
            } else {
              this.subgroups_temp = respo.customer_subgroup;
            }this.getGroups();
            this.getSubGroups();
          } else {
            this.enviando = false;
            return false;
          }
        }).catch(error => {
          this.enviando = false;
          return false;
        });
      }
    } else {
        Swal.fire({
        icon: 'error',
        title: 'Operación rechazada!...',
        text: 'Por favor seleccione un grupo antes de registrar un nuevo subgrupo...'
      });
    }
  }
  

  // Chequeador de checkboxes

  setTax_Exempt(){
    if(this.tax_exempt == true){
      this.tax_exempt = false;
    } else {
      this.tax_exempt = true;
    }
  }

  setSpecial_credit(){
    if(this.special_credit == true){
      this.special_credit = false;
    } else {
      this.special_credit = true;
    }
  }

  setDistributor(){
    if(this.distributor == true){
      this.distributor = false;
    } else {
      this.distributor = true;
    }
  }

  setLocked(){
    if(this.locked == true){
      this.locked = false;
    } else {
      this.locked = true;
    }
  }

  setRetention_agent(){
    if(this.retention_agent == true){
      this.retention_agent = false;
    } else {
      this.retention_agent = true;
    }
  }

  setFinal_consumer(){
    if(this.final_consumer == true){
      this.final_consumer = false;
    } else {
      this.final_consumer = true;
    }
  }

}

@NgModule({
  declarations: [ModalCreatecustomerComponent],
  imports: [
    CommonModule,
    FormsModule,
    CollapseModule.forRoot(),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ModalCreatecustomerComponent]
})
export class CreateCustomerModule { }