import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { AddPurchaseService } from '../../../services/forms/purchase/add-purchase.service';
import { AddOrderService } from '../../../services/forms/order/add-order.service';
import { ErrorCompraService } from '../../../services/errors/error-compra.service';
import { ErrorPedidoService } from '../../../services/errors/error-pedido.service';

@Component({
  selector: 'app-other-taxes',
  templateUrl: './other-taxes.component.html',
  styleUrls: ['./other-taxes.component.css']
})
export class OtherTaxesComponent implements OnInit {
  Compra = false;
  Pedido = false;
  titleMod: string;
  formCompra: FormGroup; //Formuario Agregar Compra
  formPedido: FormGroup; //Formuario Agregar Pedido

  constructor(
    public ViewService: EntriesExitsService,
    public formAddCompra: AddPurchaseService,
    public formAddPedido: AddOrderService,
    public ErrorCompra: ErrorCompraService,
    public ErrorPedido: ErrorPedidoService
  ) { }

  ngOnInit() {
    if (this.ViewService.nroTypeTrans == 0) { 
      this.Compra = true;
      this.titleMod = 'Otros Impuestos en Compra';
      this.formCompra = this.formAddCompra.formCompra;
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.Pedido = true;
      this.titleMod = 'Otros Impuestos en Pedido';
      this.formPedido = this.formAddPedido.formPedido;
    }
  }

  // Campos Formulario de Compra //
  get lisaeaco() { return this.formCompra.get('lisaeaco'); }
  get drinktco() { return this.formCompra.get('drinktco'); }
  // FIN Campos Formulario de Compra //
  // Campos Formulario de Pedido //
  get lisaeape() { return this.formPedido.get('lisaeape'); }
  get drinktpe() { return this.formPedido.get('drinktpe'); }
  // FIN Campos Formulario de Pedido //

  trunc (x, posiciones = 0) {
    if (x) {
      let s = x.toString()
      let l = s.length
      let decimalLength = s.indexOf('.') + 1
      if (l - decimalLength <= posiciones){
        return x
      }
      let isNeg  = x < 0
      let decimal =  x % 1
      let entera  = isNeg ? Math.ceil(x) : Math.floor(x)
      let decimalFormated = Math.floor(
        Math.abs(decimal) * Math.pow(10, posiciones)
      )
      let finalNum = entera + ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
      
      return finalNum
    }
  }

  getId() {
    return 'ADMINIU00006';
  }

  formatOT(input){
    if (this.ViewService.nroTypeTrans == 0) {
      if (input === 'lisaea') {
        if (typeof this.ViewService.lisaeaCo == 'undefined' || this.ViewService.lisaeaCo == null) {
          this.ViewService.lisaeaCo = 0;
          this.lisaeaco.setValue(this.ViewService.lisaeaCo);
        } else {
          if (this.ViewService.lisaeaCo == 0) {
            this.lisaeaco.setValue(this.ViewService.lisaeaCo);
          } else {
            this.lisaeaco.setValue(this.trunc(this.ViewService.lisaeaCo, 8));
          }
        }
        this.ViewService.totOtherTaxCo = this.ViewService.lisaeaCo + this.ViewService.drinkTaxCo;
        this.ViewService.totalCo = this.ViewService.totalBICo + this.ViewService.totalTaxCo + this.ViewService.totOtherTaxCo + this.ViewService.totalOTCo + this.ViewService.totalExemptCo;
        this.ViewService.differenceCo = this.ViewService.totalCo - this.ViewService.origTotalCo;
      } else if (input === 'drink') {
        if (typeof this.ViewService.drinkTaxCo == 'undefined' || this.ViewService.drinkTaxCo == null) {
          this.ViewService.drinkTaxCo = 0;
          this.drinktco.setValue(this.ViewService.drinkTaxCo);
        } else {
          if (this.ViewService.drinkTaxCo == 0) {
            this.drinktco.setValue(this.ViewService.drinkTaxCo);
          } else {
            this.drinktco.setValue(this.trunc(this.ViewService.drinkTaxCo, 8));
          }
        }
        this.ViewService.totOtherTaxCo = this.ViewService.lisaeaCo + this.ViewService.drinkTaxCo;
        this.ViewService.totalCo = this.ViewService.totalBICo + this.ViewService.totalTaxCo + this.ViewService.totOtherTaxCo + this.ViewService.totalOTCo + this.ViewService.totalExemptCo;
        this.ViewService.differenceCo = this.ViewService.totalCo - this.ViewService.origTotalCo;
      }
    } else if (this.ViewService.nroTypeTrans == 2) {
      if (input === 'lisaea') {
        if (typeof this.ViewService.lisaeaPe == 'undefined' || this.ViewService.lisaeaPe == null) {
          this.ViewService.lisaeaPe = 0;
          this.lisaeape.setValue(this.ViewService.lisaeaPe);
        } else {
          if (this.ViewService.lisaeaPe == 0) {
            this.lisaeape.setValue(this.ViewService.lisaeaPe);
          } else {
            this.lisaeape.setValue(this.trunc(this.ViewService.lisaeaPe, 8));
          }
        }
        this.ViewService.totOtherTaxPe = this.ViewService.lisaeaPe + this.ViewService.drinkTaxPe;
      } else if (input === 'drink') {
        if (typeof this.ViewService.drinkTaxPe == 'undefined' || this.ViewService.drinkTaxPe == null) {
          this.ViewService.drinkTaxPe = 0;
          this.drinktpe.setValue(this.ViewService.drinkTaxPe);
        } else {
          if (this.ViewService.drinkTaxPe == 0) {
            this.drinktpe.setValue(this.ViewService.drinkTaxPe);
          } else {
            this.drinktpe.setValue(this.trunc(this.ViewService.drinkTaxPe, 8));
          }
        }
        this.ViewService.totOtherTaxPe = this.ViewService.lisaeaPe + this.ViewService.drinkTaxPe;
      }
    }
  }

  closeModal() {
    this.ViewService.closeModalLoadOT.hide();
  }

}
