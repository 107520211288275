import { EventEmitter, Injectable } from '@angular/core';
import { OneSelect } from '../modals-double/one-select';
import { Supervisor, Supervisors } from './supervisor';

@Injectable({
  providedIn: 'root'
})
export class SupervisorsService {

  ambos: boolean;
  create: boolean;
  new: boolean;
  urlreturn: boolean | string;
  habilitaBtn = false;
  regresa: string | boolean;
  valuesObjects: Supervisors;
  valuesObjectsBase: Supervisors;
  pk: number | string;
  closeModal: any;
  modalType: any;
  isCatalog = true;
  list_user: OneSelect;
  selectID: boolean;
  first_name: string;
  last_name: string;
  viewCode: string = 'ADMCAIU00061';
  addCode: string = 'ADMCAIU00062';
  editCode: string = 'ADMCAIU00063';
  object: Supervisor;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();
  OneSelect: OneSelect;
  modelo: string | null;
  typeModal: any;
  selectCodeSF: number | null;
  selectDescSF: number | null;


  constructor() { }
}
