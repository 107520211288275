import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { numValidators } from '../../../setting/validators';
import { EntriesExitsService } from '../../entries-exits.service';

@Injectable({
  providedIn: 'root'
})
export class AddProductService {
  formAddProdCo: FormGroup; //Formuario Agregar Producto en Compra
  disabled_fields: boolean = true;

  constructor(public ViewService: EntriesExitsService) {
    this.formAddProdCo = new FormGroup({
      prodCoCtrl: new FormControl(this.ViewService.prodCompra, [
        Validators.required
      ]),
      descCoCtrl: new FormControl({value: this.ViewService.descCompra, disabled: true}, [
        Validators.required
      ]),
      boxCoCtrl: new FormControl(this.ViewService.boxCompra, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      unitCoCtrl: new FormControl(this.ViewService.unitCompra, [
        Validators.min(1),
        Validators.max(999999999999999999),
        numValidators
      ]),
      pesoCoCtrl: new FormControl(this.ViewService.pesoCompra, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      costCoCtrl: new FormControl(this.ViewService.costCompra, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      disc1CoCtrl: new FormControl(this.ViewService.disc1Compra, [
        Validators.min(0),
        Validators.max(99),
        numValidators
      ]),
      disc2CoCtrl: new FormControl(this.ViewService.disc2Compra, [
        Validators.min(0),
        Validators.max(99),
        numValidators
      ]),
      disc3CoCtrl: new FormControl(this.ViewService.disc3Compra, [
        Validators.min(0),
        Validators.max(99),
        numValidators
      ]),
      taxCoCtrl: new FormControl(this.ViewService.taxCompra, [
        Validators.required
      ])
    });
  }
}
