import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuntodeventaRoutingModule } from './puntodeventa-routing.module';
import { CashOpeningComponent } from './cash-opening/cash-opening.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BillingComponent } from './billing/billing.component';
import { BuyProductModalComponent } from './buy-product-modal/buy-product-modal.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CostumerListModalComponent } from './costumer-list-modal/costumer-list-modal.component';
import { CreateCustomerModule } from '../administrativo/modal-createcustomer/modal-createcustomer.component';
import { SearchDocumentComponent } from './search-document/search-document.component';
import { ModalBankComponent, ModalsFourthComponent } from './modal-bank/modal-bank.component';
import { FourthComponent, BankComponent } from './fourth/fourth.component';
import { CustomerPDVComponent } from './catalogo/customer/customer.component';
import { TotalizarComponent } from './totalizar/totalizar.component';
import { ModalCreatecustomerPdvComponent } from './catalogo/customer/modal-createcustomer-pdv/modal-createcustomer-pdv.component';
import { ModalCierreComponent } from './modal-cierre/modal-cierre.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxCurrencyModule } from 'ngx-currency';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [CashOpeningComponent,
     BillingComponent,
     BuyProductModalComponent,
     CostumerListModalComponent,
     SearchDocumentComponent,
     FourthComponent,
     ModalBankComponent,
     BankComponent,
     ModalsFourthComponent,
     CustomerPDVComponent,
     TotalizarComponent,
     ModalCreatecustomerPdvComponent,
     ModalCierreComponent,
    ],
  imports: [
    CommonModule,
    FormsModule,
    PuntodeventaRoutingModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    CreateCustomerModule,
    NgSelectModule,
    PopoverModule.forRoot(),
    NgxCurrencyModule
  ],
  entryComponents: [BuyProductModalComponent,
    CostumerListModalComponent,
    SearchDocumentComponent,
    ModalBankComponent,
    ModalsFourthComponent,
    TotalizarComponent,
    ModalCreatecustomerPdvComponent,
    CustomerPDVComponent,
    ModalCierreComponent
  ],
  exports: [BuyProductModalComponent,
    CostumerListModalComponent,
    SearchDocumentComponent
  ],
  providers: [BsModalRef]
})

export class PuntodeventaModule { }
