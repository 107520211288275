import { Injectable } from '@angular/core';
import { OneSelect } from '../modals-double/one-select';
import { Products } from './products';
import { ProductsExpiration } from './products-expiration';
import { ProductsTable1 } from './products-table';
import { OthersTaxes } from './other-tax';

@Injectable({
  providedIn: 'root'
})
export class EntriesExitsService {

  DepositSelect: OneSelect; //Objeto para listar Depositos en select
  depositCo: number | null = null; //Id de Deposito en Compra
  depositEn: number | null | string = ''; //Id de Deposito en Entrada
  depositPe: number | null | string = ''; //Id de Deposito en Pedido
  depositSa: number | null | string = ''; //Id de Deposito en Salida
  orderCo: string; //Nro de pedido en Compra
  orderPe: string; //Nro de pedido en Compra
  SupplierSelect: OneSelect; //Objeto para listar Proveedores en select
  supplierCo: number | null = null; //Id del Proveedor en Compra
  supplierPe: number | null | string = ''; //Id del Proveedor en Pedido
  descSupplierCo: number | null = null; //Descripcion de Proveedor en Compra
  descSupplierPe: string; //Descripcion de Proveedor en Pedido
  CurrencySelect: OneSelect; //Objeto para listar Monedas en select
  currencyCo: number | null = null; //Id de Moneda en Compra
  currencyPe: number | null | string = ''; //Id de Moneda en Pedido
  billCo: string; //Nro de Factura en Compra
  controlCo: string; //Control en Compra
  dateDocCo: Date | string;; //Fecha de documento en Compra
  dateDocEn: Date; //Fecha de documento en Entrada
  dateDocPe: Date; //Fecha de documento en Pedido
  dateDocSa: Date; //Fecha de documento en Salida
  dateArrivalCo: Date | string;; //Fecha de llegada de Factura en Compra
  observationsCo: string = ''; //Observaciones en Compra
  lisaeaCo: number = 0; //LISAEA de Compra
  lisaeaPe: number = 0; //LISAEA de Pedido
  drinkTaxCo: number = 0; // Impuesto Licor Compra
  drinkTaxPe: number = 0; // Impuesto Licor Pedido
  origTotalCo: number = 0; //Total Original en Compra
  origTotalPe: number = 0; //Total Original en Pedido
  totOtherTaxCo: number = 0; //Total de otros impuestos en Compra
  totOtherTaxPe: number = 0; //Total de otros impuestos en Pedido
  ProductSelect: Products; //Objeto con todos los Productos
  productlists: any; //Para guardar los productos que seran utilizados en el observable
  TaxSelect: OneSelect; //Objeto Para listar Impuestos
  taxDescPe: string; //Descripcion de Impuesto
  expProdCompra: boolean = false; // Para saber si el producto seleccionado expira
  minProfitProd: number; // Para saber la utilidad minima del producto seleccionado
  statusMinProfit: boolean; // Para saber si la utilidad minima del producto ya ha sido agregada "True" o "False si es igual a null"
  prodTableCompra: ProductsTable1; // listar todos los productos expiren o no de Compra
  prodTableEntrada: ProductsTable1; // listar todos los productos expiren o no de Entrada
  prodTablePedido: ProductsTable1; // listar todos los productos expiren o no de Pedido
  prodTableSalida: ProductsTable1; // listar todos los productos expiren o no de Salida
  allProductsCompra: ProductsTable1 // Todos los productos que seran enviados al Backend Para guardar

  prodIdCo: number | null; //Id de producto Seleccionado en Compra
  prodIdEn: number | null; //Id de producto Seleccionado en Entrada
  prodIdPe: number | null; //Id de producto Seleccionado en Pedido
  prodIdSa: number | null; //Id de producto Seleccionado en Salida

  prodDescCo: string; //Descripcion de producto Seleccionado en Compra
  prodDescEn: string; //Descripcion de producto Seleccionado en Entrada
  prodDescPe: string; //Descripcion de producto Seleccionado en Pedido
  prodDescSa: string; //Descripcion de producto Seleccionado en Salida

  prodUnitBoxCo: number | null; //Unidades por Caja de producto Seleccionado en Compra
  prodUnitBoxEn: number | null; //Unidades por Caja de producto Seleccionado en Entrada
  prodUnitBoxPe: number | null; //Unidades por Caja de producto Seleccionado en Pedido
  prodUnitBoxSa: number | null; //Unidades por Caja de producto Seleccionado en Salida

  prodCompra: string; //Codigo de Producto seleccionado en Compra
  descCompra: string; //Descripcion de Producto seleccionado en Compra
  boxCompra: number = 0; //Nro de Cajas de Producto Cargadas en Compra
  unitCompra: number = 1; //Nro de unidades de Producto Cargada en Compra
  pesoCompra: number = 0; //Peso del producto Cargado en Compra
  costCompra: number = 0; //Costo del Producto Cargado en Compra
  disc1Compra: number = 0; //Descuento 1 del Producto cargado en Compra 
  disc2Compra: number = 0; //Descuento 2 del Producto cargado en Compra
  disc3Compra: number = 0; //Descuento 3 del Producto cargado en Compra
  taxCompra: number | null; //Id del Impuesto Seleccionado del Producto cargado en Compra
  taxDescCo: string; //Descripcion del impuesto Seleccionado del Producto cargado en Compra
  totalBICo: number = 0; //Total Base Imponible en Compra
  totalBIEn: number = 0; //Total Base Imponible en Entrada
  totalBIPe: number = 0; //Total Base Imponible en Pedido
  totalBISa: number = 0; //Total Base Imponible en Salida
  totalTaxCo: number = 0; //Total de Impuesto en Compra
  totalTaxEn: number = 0; //Total de Impuesto en Entrada
  totalTaxPe: number = 0; //Total de Impuesto en Pedido
  totalTaxSa: number = 0; //Total de Impuesto en Salida
  totalExemptCo: number = 0; //Total de Exento en Compra
  totalExemptEn: number = 0; //Total de Exento en Entrada
  totalExemptPe: number = 0; //Total de Exento en Pedido
  totalExemptSa: number = 0; //Total de Exento en Salida
  totalCo: number = 0; //Total de Compra
  totalEn: number = 0; //Total de Entrada
  totalPe: number = 0; //Total de Pedido
  totalSa: number = 0; //Total de Salida
  differenceCo: number = 0; //Diferencia en Compra
  differencePe: number = 0; //Diferencia en Pedido

  // Variables Utilizadas En Modal Expira y Modal de Precios //
  unitProd: number; //Unidades del Producto
  codeProd: string; //Codigo del Producto
  descProd: string; //Descripcion del Producto
  costProd: number; //Costo del Producto
  unitBox: number; //Unidades por Caja del Producto
  // FIN Variables Utilizadas En Modal Expira y Modal de Precios //

  closeModalExpira: any; //Para cerrar Modal Expiracion
  closeModalPrices: any; //Para cerrar Modal Precios
  closeModalViewExpira: any; //Para cerrar Modal View Expiration Products
  closeModalViewReturn: any; //Para cerrar Modal View Retornable Products
  closeModaAggReturn: any; //Para cerrar Modal Agregar Retornable Products
  closeModalDetailPro: any; //Para cerrar Modal de Detalle del producto
  closeModalLoadPro: any; //Para cerrar Modal de Cargar producto
  closeModalLoadOG: any; //Para cerrar Modal de Cargar otros Gastos
  closeModalLoadOT: any; //Para cerrar Modal de Cargar otros Impuestos
  objectsExpiration: any; //Objeto que tendra n cantidad de items cargados en Modal Expiracion
  boolClosePrices: boolean; //Para saber si cerrar Modal Expira al Cerrar Modal Prices
  boolCloseLoadProd: boolean; //Para saber si cerrar Modal Cargar Producto al Cerrar Modal Prices o Expiracion

  nroTypeTrans= 0; // Tipo de Transaccion (0=Compra, 1=Entrada, 2=Pedido, 3=Salida)
  viewExpProdCo: boolean = false; //Dato para habilitar o no boton de Ver productos con expiracion en Compra
  viewExpProdEn: boolean = false; //Dato para habilitar o no boton de Ver productos con expiracion en Entrada
  viewExpProdPe: boolean = false; //Dato para habilitar o no boton de Ver productos con expiracion en Pedido
  viewExpProdSa: boolean = false; //Dato para habilitar o no boton de Ver productos con expiracion en Salida

  viewReturnCo: boolean = false; //Dato para habilitar o no boton de Ver Retornable en Compra
  viewReturnPe: boolean = false; //Dato para habilitar o no boton de Ver Retornable en Pedido

  prodExpiCompra: ProductsExpiration; // Listar productos con expiracion en Compra
  prodExpiEntrada: ProductsExpiration; // Listar productos con expiracion en Entrada
  prodExpiPedido: ProductsExpiration; // Listar productos con expiracion en Pedido
  prodExpiSalida: ProductsExpiration; // Listar productos con expiracion en Salida

  idTableView: number; //Id del producto en tabla para ver datalles

  codeOTCo: string; //Codigo de Otro Impuesto en Compra
  descOTCo: string; //Descripcion de Otro Impuesto en Compra
  montOTCo: number = 0 //Monto de Otro Impuesto en Compra
  taxOTCo: number = 0; //Valor de Otro Impuesto en Compra

  codeOTPe : string; //Codigo de Otro Impuesto en Pedido
  descOTPe : string; //Descripcion de Otro Impuesto en Pedido
  montOTPe : number = 0 //Monto de Otro Impuesto en Pedido
  taxOTPe: number = 0; //Valor de Otro Impuesto en Pedido

  OtherTaxCo: OthersTaxes = []; // Objeto con los Otros Impuestos de Compra
  OtherTaxPe: OthersTaxes = []; // Objeto con los Otros Impuestos de Pedido
  totalOTCo: number = 0; //Total de otros Gastos en Compra
  totalOTPe: number = 0; //Total de otros Gastos en Pedido

  typeDetail: string; //Tipo de detalle para usar en el Detalle ya sea de Producto u Otros Impuestos
  editExpenses: boolean = false; //Para saber si la expensa se Edita o No desde la tabla
  pkExpense: number | null; //id de Expensa a editar
  editProduct: boolean = false; //Para saber si el producto se Edita o No desde la tabla
  pkProduct: number | null; //id de Producto a editar
  pkReturn: number | null; //id del Producto al cargar Retornable

  constructor() { }
}
