import { Component, OnInit } from '@angular/core';
import { SupervisorsService } from '../services/supervisors.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CatalogoOneService } from '../services/catalogo-one.service';
import { ModalSalesForceComponent } from '../modals-one/modals-one.component';
import { SellersService } from '../services/sellers.service';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';

@Component({
  selector: 'app-modal-supervisor',
  templateUrl: './modal-supervisor.component.html',
  styleUrls: ['./modal-supervisor.component.css']
})
export class ModalSupervisorComponent implements OnInit {
  formSupervisor: FormGroup;
  codeSuper: string;
  descriptionSuper: string;
  statusSuper = true;
  selectedOne: boolean;
  selectedTwo: boolean;
  error_id: boolean;
  txt_id: string;
  error_code: boolean;
  txt_code: string;
  error_descrip: boolean;
  txt_descrip: string;
  loadData = false;

  error_idprinc = false;
  txt_idprinc: string | null;
  error_idsecun = false;
  txt_idsecun: string | null;
  error_iduser = false;
  txt_iduser: string | null;
  typeSelectOne = 'supervisor';
  codeTwoMD: string | null;
  descTwoMD: string | null;
  descOneInput: string;
  descUserInput: string;
  salesf_disabled = true;
  users_disabled = true;
  codeId: string;
  selectCodeUS: number | null;
  selectDescUS: number | null;

  constructor(
    public SupervisorsService: SupervisorsService,
    public auth: AuthService,
    private listUrl: UrListService,
    private modalService: BsModalService,
    private modalRef: BsModalRef,
    public CatalogoOneService: CatalogoOneService,
    public SellersService: SellersService,
    public ResolvingService: ResolvingService
  ) {
    this.formSupervisor = new FormGroup({
      codesf: new FormControl(this.SupervisorsService.selectCodeSF, [
        Validators.required
      ]),
      descsf: new FormControl(this.SupervisorsService.selectDescSF, [
        Validators.required
      ]),
      codeus: new FormControl(this.selectCodeUS, [
        Validators.required
      ]),
      descus: new FormControl(this.selectDescUS, [
        Validators.required
      ]),
      suppcode: new FormControl(this.codeSuper, [
        Validators.required,
        Validators.maxLength(3)
      ]),
      suppdescription: new FormControl(this.descriptionSuper, [
        Validators.required,
        Validators.maxLength(100)
      ]),
    });
  }

  getId(){
    return this.codeId;
  }

  ngOnInit() {
    this.descOneInput = 'DESCRIPCION FUERZA DE VENTA';
    this.descUserInput = 'USERNAME';
    this.loadObjSelect();
    this.loadUserSelect();
    this.SupervisorsService.selectID = false;
    this.SupervisorsService.first_name = '';
    this.SupervisorsService.last_name = '';
    

    if (typeof this.SupervisorsService.new === 'undefined' || this.SupervisorsService.new) {
      this.codeId = this.SupervisorsService.addCode;
      this.selectedOne = false;
      this.selectedTwo = false;
      this.SupervisorsService.selectCodeSF = null;
      this.SupervisorsService.selectDescSF = null;
      this.selectCodeUS = null;
      this.selectDescUS = null;
    } else {
      this.codeId = this.SupervisorsService.editCode;
      this.selectedOne = true;
      this.selectedTwo = true;
      this.codeSuper = this.SupervisorsService.object.code;
      this.descriptionSuper = this.SupervisorsService.object.description;
      this.statusSuper = this.SupervisorsService.object.active;
      this.selectCodeUS = this.SupervisorsService.object.user;
      this.selectDescUS = this.SupervisorsService.object.user;
      this.SupervisorsService.selectCodeSF = this.SupervisorsService.object.sales_f_id;
      this.SupervisorsService.selectDescSF = this.SupervisorsService.object.sales_f_id;
    }
  }

  saveSupervisor(values) {
    let id_obj: any;
    if (this.SupervisorsService.isCatalog) {
      this.SupervisorsService.modalType = 'catalogo';
    }
    if (this.SupervisorsService.new) {
      id_obj = '';
    } else {
      id_obj = this.SupervisorsService.pk;
    }
    this.SupervisorsService.new = true;
    if (values.codesuper.trim() && values.descriptionsuper.trim()) {
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('savesupervisor'),
        auth: this.auth,
        body: {
          id_obj,
          code: values.codesuper.toUpperCase().trim(),
          description: values.descriptionsuper.toUpperCase().trim(),
          active: values.status,
          user: this.selectCodeUS,
          sales_f: this.SupervisorsService.selectCodeSF,
          tipo: this.SupervisorsService.modalType
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: 'Guardando Supervisor',
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if (resp.new) {
            if (resp.tipo == 'catalogo') {
              this.SupervisorsService.continuar = true;
              this.SupervisorsService.evenAdd.emit(resp);
            } else {
              if(this.SupervisorsService.modalType === 'Seller'){
                this.modalSeller(resp);
              } else if (this.SupervisorsService.modalType === 'conflicto') {
                this.ResolvingService.seguir = true;
                this.ResolvingService.loadselect = true;
                this.ResolvingService.evenAdd.emit({'new':resp.objeto});
              }
            }
          } else {
            this.SupervisorsService.continuar = true;
            this.SupervisorsService.evenAdd.emit(resp);
          }
          this.SupervisorsService.closeModal.hide();
          this.resetModalform();
        }
      }).catch(error => {
        this.viewError(error);
      });
    } else {
      this.codeSuper = this.codeSuper.trim();
      this.descriptionSuper = this.descriptionSuper.trim();
    }
  }

  modalSeller(resp) {
    this.SellersService.SupervisorSelect = resp.objeto
    if (resp.active) {
      for (const key in this.SellersService.SupervisorSelect) {
        if (Object.prototype.hasOwnProperty.call(this.SellersService.SupervisorSelect, key)) {
          const supervisor = this.SellersService.SupervisorSelect[key];
          if (supervisor.id == resp.pk) {
            this.SellersService.CodeSupe = supervisor.id;
            this.SellersService.DescSupe = supervisor.desc;
          }
        }
      }
    }
  }

  viewError(error) {
    if (error.error_id != 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code != 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_description != 'undefined') {
      if (error.error_description) {
        this.error_descrip = error.error_description;
        this.txt_descrip = error.txt_description;
      }
    }
    if (error.error_user != 'undefined') {
      if (error.error_user) {
        this.error_iduser = error.error_user;
        this.txt_iduser = error.txt_user;
      }
    }
    if (error.error_salesf != 'undefined') {
      if (error.error_salesf) {
        this.error_idprinc = error.error_salesf;
        this.txt_idprinc = error.txt_salesf;
      }
    }
  }

  closeModal() {
    this.SupervisorsService.closeModal.hide();
    this.resetModalform();
  }

  formatCode() {
    this.codeSuper = this.codeSuper.toUpperCase().trim();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
    if (this.codeSuper) {
      while (this.codeSuper.length < 3) {
        this.codeSuper = '0'+this.codeSuper;
      } 
    }
  }

  formatDescription() {
    this.descriptionSuper = this.descriptionSuper.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_descrip = false;
    this.txt_descrip = '';
  }

  // Aqui modal de salesforce
  async loadObjSelect() {
    this.salesf_disabled = true;
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('list_modal_salesforce'),
      body: {
        pk: this.SupervisorsService.pk
      }
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    await ajax.call().then(resp => {
      if (resp.success) {
        this.SupervisorsService.OneSelect = resp.objects;
      }
    });
    this.salesf_disabled = false;
  }

  async loadUserSelect() {
    this.users_disabled = true;
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('list_modal_superus'),
      body: {
        pk: this.SupervisorsService.pk
      }
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    await ajax.call().then(resp => {
      if (resp.success) {
        this.SupervisorsService.list_user = resp.objects;
      }
    });
    this.users_disabled = false;
  }

  changeCodeSF() {
    this.error_idprinc = false;
    this.error_id = false;
    this.txt_idprinc = '';
    this.txt_id = '';
    this.SupervisorsService.selectDescSF = null;
    for (const key in this.SupervisorsService.OneSelect) {
      if (this.SupervisorsService.OneSelect.hasOwnProperty(key)) {
        const element = this.SupervisorsService.OneSelect[key];
        if (this.SupervisorsService.selectCodeSF == element.id) {
          this.SupervisorsService.selectDescSF = element.id;
          break;
        }
      }
    }
  }

  changeDescSF() {
    this.error_idprinc = false;
    this.error_id = false;
    this.txt_idprinc = '';
    this.txt_id = '';
    this.SupervisorsService.selectCodeSF = null;
    for (const key in this.SupervisorsService.OneSelect) {
      if (this.SupervisorsService.OneSelect.hasOwnProperty(key)) {
        const element = this.SupervisorsService.OneSelect[key];
        if (this.SupervisorsService.selectDescSF == element.id) {
          this.SupervisorsService.selectCodeSF = element.id;
          break;
        }
      }
    }
  }

  changeCodeUser() {
    this.error_id = false;
    this.error_iduser = false;
    this.txt_id = '';
    this.txt_iduser = '';
    this.selectDescUS = null;
    for (const key in this.SupervisorsService.list_user) {
      if (this.SupervisorsService.list_user.hasOwnProperty(key)) {
        const element = this.SupervisorsService.list_user[key];
        if (this.selectCodeUS == element.id) {
          this.selectDescUS = element.id;
          break;
        }
      }
    }
  }

  changeDescUser() {
    this.error_id = false;
    this.error_iduser = false;
    this.txt_id = '';
    this.txt_iduser = '';
    this.selectCodeUS = null;
    for (const key in this.SupervisorsService.list_user) {
      if (this.SupervisorsService.list_user.hasOwnProperty(key)) {
        const element = this.SupervisorsService.list_user[key];
        if (this.selectDescUS == element.id) {
          this.selectCodeUS = element.id;
          break;
        }
      }
    }
  }

  resetModalform() {
    this.selectCodeUS = null;
    this.selectDescUS = null;
    this.SupervisorsService.first_name = null;
    this.SupervisorsService.last_name = null;
    this.SupervisorsService.selectID = false;
    this.SupervisorsService.selectCodeSF = null;
    this.SupervisorsService.selectDescSF = null;
  }

  openModalPrinct(tipo, model) {
    this.SupervisorsService.typeModal = tipo;
    this.SupervisorsService.modelo = model;
    this.modalRef = this.modalService.show(ModalSalesForceComponent);
    this.modalRef.setClass('modal-dialog-centered zindex-one');
    this.CatalogoOneService.closeModal = this.modalRef;
  }

}
