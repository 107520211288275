import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Confir } from 'src/app/core/viewLoader/viewLoader';
import { CierreCajaComponent } from '../cierre-caja/cierre-caja.component';

@Component({
  selector: 'app-modal-cierre',
  inputs: ['cashing'],
  outputs: ['eventAction'],
  templateUrl: './modal-cierre.component.html',
  styleUrls: ['./modal-cierre.component.css']
})
export class ModalCierreComponent extends Confir implements OnInit {

  eventAction: EventEmitter<any> = new EventEmitter();
  cashing: any;
  modalRegister: BsModalRef;

  constructor(private mybsModalRef: BsModalRef, public auth: AuthService,
    public listUrl: UrListService, private modalService: BsModalService) {
      super();
     }

  ngOnInit() {
  }

  cierre_parcial() {
    this.promise_parcial().then(resp => {
      if (resp) {
        this.eventAction.emit(resp);
        this.mybsModalRef.hide();
      }
    }).catch(err => {

    });
  }

  cierre_total() {
    this.confir('Necesita hacer un cierre parcial antes', 'Cierre parcial').then(resp => {
      this.promise_parcial().then(resp => {
        if (resp) {
          const config: Config = {
            titleLoading: 'Verificando facturas'
          };
          const init: Init = {
            url: this.listUrl.url('calculate_total'),
            auth: this.auth,
            method: 'put',
            body: {
              station: this.cashing.station_id
            }
          };
          const ajax = new Ajax(init, config);
          ajax.call().then(resp => {
            const initialState = {
              cierre: resp,
              title: 'Cierre Final',
              monto_apertura: this.cashing.opening_amount,
              estacion: this.cashing.code_station,
              id_cashing: this.cashing.id_opening,
              station: this.cashing.station_id,
              action: 'total'
            };
            this.modalRegister = this.modalService.show(CierreCajaComponent, {initialState});
            this.modalRegister.content.even_success.subscribe(resp2 => {
              this.eventAction.emit(resp2);
              this.mybsModalRef.hide();
            });
            this.modalRegister.content.even_cancel.subscribe(resp3 => {
              this.eventAction.emit(resp);
            });
            this.modalRegister.setClass('modal-xl');
          }).catch(err => {
          });
        }
      }).catch(err => {
  
      });
    }).catch();
  }

  close_modal() {
    this.mybsModalRef.hide();
  }

  
  cierre_caja() {
    const config: Config = {
      titleLoading: 'Cerrando Caja'
    };
    const init: Init = {
      url: this.listUrl.url('cerrar_caja'),
      auth: this.auth,
      method: 'put',
      body: {
        cashing: this.cashing.id_opening
      }
    };
    const ajax = new Ajax(init, config);
    ajax.call().then(resp => {
      this.eventAction.emit(true);
      this.mybsModalRef.hide();
    }).catch(err => {
    });
  }

  promise_parcial(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const config: Config = {
        titleLoading: 'Verificando facturas'
      };
      const init: Init = {
        url: this.listUrl.url('calculate_parcial'),
        auth: this.auth,
        method: 'put',
        body: {
          cashing: this.cashing.id_opening
        }
      };
      const ajax = new Ajax(init, config);
      ajax.call().then(resp => {
        const initialState = {
          cierre: resp,
          title: 'Cierre Parcial',
          monto_apertura: this.cashing.opening_amount,
          estacion: this.cashing.code_station,
          id_cashing: this.cashing.id_opening,
          station: this.cashing.station_id,
          action: 'parcial'
        };
        this.modalRegister = this.modalService.show(CierreCajaComponent, {initialState});
        this.modalRegister.content.even_success.subscribe(resp => {
          resolve(resp);
        });
        this.modalRegister.setClass('modal-xl');
      }).catch(err => {
        reject(err);
      });
    });
  }

}
