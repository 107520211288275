import { EventEmitter, Injectable } from '@angular/core';
import { Especie, Especies } from './especie';
import { Url } from 'src/app/core/ajax/url';

@Injectable({
  providedIn: 'root'
})
export class EspecieServiceService {
  ambos: boolean;
  create: boolean;
  closeModal: any;
  habilitaBtn = false;
  newMU: boolean;
  modelo: string | null;
  modeloBase: string | null;
  pk: number | string;
  preguntaDelete: string | null;
  regresa: string | boolean;
  strConecDelete?: string;
  strDelete?: string;
  urlDelete: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  urlSave: Url | null;
  valuesObjects: Especies | any;
  valuesObjectsBase: Especies | any;
  viewModal: any;
  typeModal: string | null;
  viewCode: string;
  editCode: string;
  addCode: string;
  object: Especie;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { 
    
  }
}
