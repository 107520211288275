import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, Company, Permisos, Guis, Permiso } from './user';
import { Cache } from '../../cache';
import { session, session_p } from '../constantes/sessiones';
import { Ajax, Config } from '../ajax/ajax';
import { Init } from '../ajax/init-ajax';
import { Token, TokenCompany, TokenRefres } from './token';
import { ListUrl } from '../constantes/request';
import { Router } from '@angular/router';
import { Search } from '../../search';
import { SocketService } from '../ajax/socket/socket';
import { UrlBase } from '../ajax/url';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: User | null;
  token: Token | null;
  tokenRefres: TokenRefres | null;
  timeRefresToken: any = null;
  socket: SocketService | null;
  private _activeWaiter: boolean;
  statusSocket: boolean;
  private conectSocke$ = new BehaviorSubject<boolean>(false);
  private isCompany$ = new BehaviorSubject<boolean>(false);
  intervalSocket: any;
  timeout = 15000;
  intent = 1;
  intentmax = 100;
  intentAument = 5;
  seg_sum = 5000;
  guis: Guis | null;
  block_return = '/';

  constructor(public http: HttpClient, private router: Router) {

    if (Cache.getLocal(session.activeWaiter)) {
      this.activeWaiter = !(Cache.getLocal(session.activeWaiter) === 'false');
    } else {
      this.activeWaiter = false;
    }
    this.guis = null;
    if (Cache.getLocal(session.guis)) {
      this.guis = Cache.getLocal(session.guis, true);
    }
    this.socket = null;
    this.statusSocket = false;
    Cache.setLocal(session.sendRefrest, true);
    try {
      if (Cache.getLocal(session.user)) {
        this.user = Cache.getLocal(session.user, true) as User;
        this.user.company = this.getCompany();
      } else {
        this.user = null;
      }
      if (Cache.getLocal(session.refreshToken)) {
        this.tokenRefres = new TokenRefres(Cache.getLocal(session.refreshToken));
      } else {
        this.tokenRefres = null;
      }
      if (Cache.getLocal(session.tokenAc)) {
        this.token = new Token(Cache.getLocal(session.tokenAc));
        this.createTimerefres();
      } else {
        this.token = null;
      }
      if (this.isLogin()) {
        this.connecSocket();
      }
    } catch (error) {
      this.logout();
    }
    if (Cache.getLocal(session.block_url)) {
      this.block_return = Cache.getLocal(session.block_url);
    }
  }

  get_token(): string | null {
    if (this.token) {
      return this.token.token;
    } else {
      return null;
    }
  }

  set activeWaiter(value: boolean) {
    this._activeWaiter = value;
    if (value) {
      this.connecSocket();
    } else {
      this.disconect_socket();
    }
  }

  get activeWaiter() {
    return this._activeWaiter;
  }

  onSocket(): Observable<boolean> {
    return this.conectSocke$.asObservable();
  }

  connecSocket() {
    if (this.activeWaiter) {
      this.statusSocket = false;
      this.conectSocke$.next(false);
      this.socket = new SocketService(this);
      this.socket.connect();
      this.socket.on('connect', () => {
        this.statusSocket = true;
        this.conectSocke$.next(true);
      });
      this.socket.on('disconnect', () => {
        this.statusSocket = false;
        this.conectSocke$.next(false);
      });
      this.socket.on('connect_error', (error) => {
        this.disconect_socket();
        this.reconect_socket();
      });
    }
  }

  disconect_socket() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
 
  checkPermView(view: string) {
    if (this.guis && this.guis.length && typeof this.guis[view] != 'undefined') {
      const permisos = this.guis[view].filter(element => {
        if (this.user.permisos) {
          const perm = Search.filter(this.user.permisos, (x: Permiso, e) => {
            // tslint:disable-next-line: triple-equals
            return x.pk == e;
          }, element.srv_id);
          if (perm.length) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (permisos.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  reconect_socket() {
    if (this.activeWaiter) {
      this.intervalSocket = setTimeout(() => {
        if (this.intent <= this.intentmax) {
          if (this.intent === this.intentAument) {
            this.intentAument += this.intentAument;
            this.timeout += this.seg_sum;
            this.seg_sum += this.seg_sum;
          }
          this.connecSocket();
          this.intent += 1;
        }
      }, this.timeout);
    }
  }

  set user(us: User) {
    Cache.setLocal(session.user, us);
    this._user = us;
  }

  get user(): User {
    return this._user;
  }

  login(pass: string, user: string, remember: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.login_url),
        auth: this,
        body: {
          username: user,
          password: pass
        }
      };
      const configAjax: Config = {
        forceNormal: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then((reponse) => {
        if (reponse.success) {
          Cache.removeItem(session.pname);
          this.token = new Token(reponse.access);
          this.tokenRefres = new TokenRefres(reponse.refresh);
          this.user = reponse.user as User;
          this.guis = reponse.guis_perms;
          Cache.setLocal(session.guis, reponse.guis_perms);
          if (remember) {
            Cache.setLocal(session_p.rememberUser, remember);
            Cache.setLocal(session_p.usernameSession, user);
          } else {
            Cache.removeLocalItem(session_p.rememberUser);
            Cache.removeLocalItem(session_p.usernameSession);
          }
          this.createTimerefres();
          this.connecSocket();
          resolve(this.user);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  callRefres() {
    return new Promise((resolve, reject) => {
      try {
        Cache.getItem('sendRefresf');
        Cache.removeItem('sendRefresf');
        const initAjax: Init = {
          method: 'post',
          url: new UrlBase(`${ListUrl.baseUrl}${this.token.tokenObj.url}`),
          body: {refresh: this.tokenRefres.token},
          auth: this,
        };
        const configAjax: Config = {
          visible: false,
          autoReNew: false,
          forceNormal: true
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then((reponse) => {
          if (typeof reponse.success !== 'undefined' && reponse.success) {
            if (typeof reponse.code !== 'undefined' && reponse.code === 'token_not_valid') {
              this.logout();
              reject(false);
            } else {
              this.token.proceso(reponse.access);
              this.tokenRefres.proceso(reponse.refresh);
              this.timeRefresToken = null;
              this.createTimerefres();
              if (this.socket) {
                this.socket.emit('update_token', {tk: this.get_token()}, (cb) => {});
              }
              resolve(reponse.access);
              Cache.setLocal(session.sendRefrest, true);
            }
          } else {
            this.logout();
          }
        }).catch(error => {
          reject(error);
          this.logout();
          Cache.setLocal(session.sendRefrest, true);
        });
    } catch (error) {
    }
    });
  }

  logout(paramsReturn: string | boolean = false) {
    this.token = null;
    this.tokenRefres = null;
    this.timeRefresToken = null;
    this._user = null;
    for (const key in session) {
      if (Object.prototype.hasOwnProperty.call(session, key)) {
        Cache.removeItem(session[key]);
      }
    }
    Cache.setLocal(session.sendRefrest, true);
    this.disconect_socket();
    if (paramsReturn) {
      // redirect con parametos
      this.router.navigate(['/signin'], {
        queryParams: {
          return: paramsReturn
        }
      });
    } else {
      this.router.navigate(['/signin']);
    }
  }

  createTimerefres() {
    if (!this.timeRefresToken && this.token && this.tokenRefres) {
      if (this.tokenRefres.validateAllToken()) {
        this.timeRefresToken = setTimeout(() => {
          try {
            this.callRefres().then(resp => {}).catch(errer => {});
          } catch (error) {}
        }, this.token.getTimeRefres());
      }
    }
  }

  isLogin(): boolean {
    if (this.user && this.token && this.tokenRefres) {
      if (this.token.validateAllToken() && this.tokenRefres.validateAllToken()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isBlock(): boolean {
    if (this.user.block) {
      return true;
    } else {
      return false;
    }
  }

  block(bloc = true) {
    let uss = this.user;
    uss.block = bloc;
    this.user = uss;
    return true;
  }

  block_url(urlret) {
    this.block_return = urlret;
    Cache.setLocal(session.block_url, urlret);
    return true;
  }

  desblock(pass: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.desblock[1]),
        auth: this,
        body: {
          username: this.user.username,
          password: pass
        }
      };
      const configAjax: Config = {
        autoReNew: false
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then((res) => {
        if (res.success) {
          if (res.isanonymus) {
            this.logout();
          } else {
            this.block(false);
          }
          resolve(true);
        }
      }).catch(error => {
        reject(false);
      });
    });
  }

  onCompany(): Observable<boolean> {
    return this.isCompany$.asObservable();
  }

  isCompany(): boolean {
    if (Cache.getSession(session.tokenCompany)) {
      return true;
    } else {
      return false;
    }
  }

  getCompanyToken(): TokenCompany {
    return new TokenCompany(Cache.getSession(session.tokenCompany));
  }

  getCompany(): Company | null {
    if (this.isCompany()) {
      const tokenCompany =  this.getCompanyToken();
      const company_select = Search.filter(this.user.companys, (x: Company, e) => {
        // tslint:disable-next-line: triple-equals
        return x.company_id == e;
      }, tokenCompany.tokenObj.pk);
      if (company_select.length) {
        this.isCompany$.next(true);
        return company_select[0];
      } else {
        this.isCompany$.next(false);
        return null;
      }
    } else {
      this.isCompany$.next(false);
      return null;
    }
  }

  set_perms_company(token_company: string, perm: Permisos, perm_user: Permisos): Promise<any> {
    return new Promise((resolve, reject) => {
      const tk = new TokenCompany(token_company);
      const position = Search.position(this.user.companys, (x: Company, e) => {
        // tslint:disable-next-line: triple-equals
        return x.company_id == e;
      }, tk.tokenObj.pk);
      try {
        this._user.permisos = perm_user;
        this._user.companys[position].permisos = perm;
        this.user = this._user;
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  }

  setCompany(comp: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'put',
        url: new UrlBase(ListUrl.select_company),
        auth: this,
        body: {
          company: comp,
        }
      };
      const ajax = new Ajax(initAjax);
      ajax.call().then( async (reponse) => {
        if (reponse.success) {
          const rep = await this.set_perms_company(reponse.company, reponse.perm_company, reponse.perm_user);
          if (this.socket) {
            this.socket.emit('update_token_company', {tk: reponse.company}, (cb) => {});
          }
          Cache.setSession(session.tokenCompany, reponse.company);
          this.user.company = this.getCompany();
          this.isCompany$.next(true);
          resolve(true);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }
}
