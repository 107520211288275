import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-select-company-modal',
  templateUrl: './select-company-modal.component.html',
  styleUrls: ['./select-company-modal.component.css']
})
export class SelectCompanyModalComponent implements OnInit {

  modal: any;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {

  }

}
