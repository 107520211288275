import { Component, OnInit } from '@angular/core';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { InventaryProductService } from '../../../services/inventary-product.service';


@Component({
  selector: 'app-existence',
  templateUrl: './existence.component.html',
  styleUrls: ['./existence.component.css']
})
export class ExistenceComponent implements OnInit {

  loadData = false;
  heveExistence = false;
  ProductsExistences = [];
  total_cajas = 0;
  total_und = 0;

  constructor(
    public ProductService: InventaryProductService,
    public auth: AuthService,
    private listUrl: UrListService
  ) { }

  ngOnInit() {
    this.loadExistences(this.ProductService.pk);
  }

  loadExistences(pk) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk
      },
      url: this.listUrl.url('list_existence_product')
    };
    const configAjax: Config = {
      visible: true,
      titleSuccess: '',
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.loadData = true;
        this.ProductsExistences = resp.lista;
        this.total_cajas = resp.total_cajas;
        this.total_und = resp.total_und;
        if (this.ProductsExistences.length > 0) {
          this.heveExistence = true;
        }
      }
    });
  }

  closeModal() {
    this.ProductService.closeModalExistence.hide();
  }

}
