import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-menu-movil-modal',
  templateUrl: './menu-movil-modal.component.html',
  styleUrls: ['./menu-movil-modal.component.css']
})
export class MenuMovilModalComponent implements OnInit {
  modal: any;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {

  }


}
