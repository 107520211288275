import { Component, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { InventaryProductService } from '../services/inventary-product.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { trigger, state, style } from '@angular/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorsProductService } from '../services/errors-product.service';
import { Subscription, combineLatest } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CatalogoOneService } from '../services/catalogo-one.service';
import { ModalTypeProductComponent, ModalPresentationComponent, ModalShopComponent, ModalLocationComponent, ModalGroupComponent } from '../modals-one/modals-one.component';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { ModalSubgroupComponent } from '../modals-double/modals-double.component';
import { CatalogotwoService } from '../services/catalogotwo.service';
import { ModalsLineComponent } from '../modals-triple/modals-triple.component';
import { CatalogoThreeService } from '../services/catalogo-three.service';
import { SupplierService } from '../services/supplier.service';
import { ModalProveedorComponent } from '../modal-proveedor/modal-proveedor.component';
import { numValidators } from '../setting/validators';
import Swal from 'sweetalert2';
import { ExpirationsComponent } from '../modals/products/expirations/expirations.component';
import { StatisticsComponent } from '../modals/products/statistics/statistics.component';
import { ExistenceComponent } from '../modals/products/existence/existence.component';

@Component({
  selector: 'app-modal-producto',
  templateUrl: './modal-producto.component.html',
  styleUrls: ['./modal-producto.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({
        opacity: 1
      })),
      state('on', style({
        display: 'none'
      }))
    ])
  ]
})
export class ModalProductoComponent implements OnInit {
  optionsProduct: boolean = true;
  optionsStatus: boolean = true;
  optionsPriceOrder: boolean = false;
  optionPrintLabel: boolean = false;
  impuestoCheck: boolean;
  optionsModals: boolean = false; //Para ocultar las opciones de los modales "Movimientos, Ventas y Seriales" no estaran estas opciones actualmente
  btnModalExpira: boolean = false;
  style1: boolean;
  style2: boolean;
  style3: boolean;
  style4: boolean;
  formProduct: FormGroup;
  formPrices: FormGroup;
  codeProd: string;
  descProd: string;
  rubroProd: number = 0;
  barcoBoxP: string = '';
  barcoDisP: string = '';
  barcoUniP: string = '';
  idProdSupp: string = '';
  descBoxProd: string = '';
  descUnitProd: string = '';
  events: Subscription[] = [];
  typeProd= [{id: 0, valor: 'Producto'},{id: 1, valor: 'Servicio'}];
  prodType: number | boolean = false;
  nroTypeProd: number = 0;
  unitPalProd: number = 0;
  unitBoxProd: number = 0;
  contUnitProd: number = 0;
  volBoxProd: number = 0;
  pesBrBoxProd: number = 0;
  invMinProd: number = 0;
  invMaxProd: number = 0;
  ordMinBoxProd: number = 0;
  dayMinInvProd: number = 0;
  optLocked: boolean = false;
  optBlock_in: boolean = false;
  optBlock_exits: boolean = false;
  optionsExpira: boolean = false;
  optBlock_move: boolean = false;
  optProductRegulation: boolean = false;
  notEditVenta1: boolean = true;
  notEditVenta2: boolean = true;
  venMinUndProd: number = 0;
  venMinBoxProd: number = 0;
  viewNot: boolean = true;
  loaded: boolean = false;
  imageSrc: string = '';
  viewImg: boolean = false;
  viewNewImg: boolean = true;
  formData: any;
  deleteImagee: boolean = false; //Variable para saber si la imagen fue borrada
  bringImage: boolean = false; //Variable para saber si el producto trae imagen
  haveImg: boolean = false; //Variable para saber si tiene imagen o no
  totalImg: number = 0; // Variable para saber cuantas imagenes tiene el producto
  typeOp: boolean = true;
  lotes_precios = [];
  list_lots = [];
  selectLot: number | string;
  UltCCPrice: number = 0;
  UltCUPrice: number = 0;
  UtiMinPrice: number = 0;
  UtiMinPriceOri: number = 0;
  pctBase: number = 0;
  pctA: number = 0;
  pctB: number = 0;
  pctC: number = 0;
  pctD: number = 0;
  pctE: number = 0;
  lastCostBase: number = 0;
  lastCostA: number = 0;
  lastCostB: number = 0;
  lastCostC: number = 0;
  lastCostD: number = 0;
  lastCostE: number = 0;
  utilityBase: number = 0;
  utilityA: number = 0;
  utilityB: number = 0;
  utilityC: number = 0;
  utilityD: number = 0;
  utilityE: number = 0;
  pvBase: number = 0;
  pvA: number = 0;
  pvB: number = 0;
  pvC: number = 0;
  pvD: number = 0;
  pvE: number = 0;
  pviBase: number = 0;
  pviA: number = 0;
  pviB: number = 0;
  pviC: number = 0;
  pviD: number = 0;
  pviE: number = 0;
  notSelectLot: boolean = true;
  validLotPrice = false;
  validLots = false;
  validLotsBase = true;
  validLotsA = true;
  validLotsB = true;
  validLotsC = true;
  validLotsD = true;
  validLotsE = true;
  validLotspvBase = true;
  validLotspvA = true;
  validLotspvB = true;
  validLotspvC = true;
  validLotspvD = true;
  validLotspvE = true;
  arrayErrorLots = [];
  ErrorsTablesShow = false;
  ErrorPrices = false;
  ErrorPreciosTxt= '';
  ErrorsTablesProd = [];
  codeId: string;

  constructor(
    public ProductService: InventaryProductService,
    public ErrorsProduct: ErrorsProductService,
    public CatalogoOneService: CatalogoOneService,
    public CatalogoTwoService: CatalogotwoService,
    public auth: AuthService,
    public CatalogoThreeService: CatalogoThreeService,
    public SupplierService: SupplierService,
    private listUrl: UrListService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private modalRef: BsModalRef,
    private elmRef: ElementRef
  ){
    this.formProduct = new FormGroup({
      groupselect: new FormControl(this.ProductService.selectGroup,[
        Validators.required
      ]),
      groupdesc: new FormControl(this.ProductService.descGroup,[
        Validators.required
      ]),
      subgroupselect: new FormControl(this.ProductService.selectSubgroup,[
        Validators.required
      ]),
      subgroupdesc: new FormControl(this.ProductService.descSubgroup,[
        Validators.required
      ]),
      kindselect: new FormControl(this.ProductService.selectKind,[
        Validators.required
      ]),
      kinddesc: new FormControl(this.ProductService.descKind,[
        Validators.required
      ]),
      typeselect: new FormControl(this.ProductService.selectType,[
        Validators.required
      ]),
      typedesc: new FormControl(this.ProductService.descType,[
        Validators.required
      ]),
      presentationselect: new FormControl(this.ProductService.selectPresentation,[
        Validators.required
      ]),
      presentationdesc: new FormControl(this.ProductService.descPresentation,[
        Validators.required
      ]),
      shopselect: new FormControl(this.ProductService.selectShop),
      shopdesc: new FormControl(this.ProductService.descShop),
      locationselect: new FormControl(this.ProductService.selectLocation),
      locationdesc: new FormControl(this.ProductService.descLocation),
      taxselect: new FormControl(this.ProductService.selectTax,[
        Validators.required
      ]),
      suppselect: new FormControl(this.ProductService.selectSupplier),
      suppdesc: new FormControl(this.ProductService.descSupplier),
      prodcode: new FormControl(this.codeProd,[
        Validators.required,
        Validators.maxLength(10)
      ]),
      proddesc: new FormControl(this.descProd,[
        Validators.required,
        Validators.maxLength(120)
      ]),
      prodrubro: new FormControl(this.rubroProd,[
        Validators.min(0),
        Validators.max(999),
        numValidators
      ]),
      prodbarcobox: new FormControl(this.barcoBoxP,[
        Validators.maxLength(60)
      ]),
      prodbarcodis: new FormControl(this.barcoDisP,[
        Validators.maxLength(60)
      ]),
      prodbarcouni: new FormControl(this.barcoUniP,[
        Validators.maxLength(60)
      ]),
      prodidprodsupp: new FormControl(this.idProdSupp,[
        Validators.maxLength(20)
      ]),
      typeproduct: new FormControl(this.nroTypeProd,[
        Validators.required
      ]),
      produnitpaleta: new FormControl(this.unitPalProd,[
        Validators.min(0),
        Validators.max(999999),
        numValidators
      ]),
      produnitbox: new FormControl(this.unitBoxProd,[
        Validators.min(0),
        Validators.max(999999),
        numValidators
      ]),
      prodcontunit: new FormControl(this.contUnitProd,[
        Validators.min(0),
        Validators.max(999999),
        numValidators
      ]),
      prodvolbox: new FormControl(this.volBoxProd,[
        Validators.min(0),
        Validators.max(999999),
        numValidators
      ]),
      prodpesbrubox: new FormControl(this.pesBrBoxProd,[
        Validators.min(0),
        Validators.max(999999),
        numValidators
      ]),
      proddescbox: new FormControl(this.descBoxProd,[
        Validators.maxLength(9)
      ]),
      proddescunit: new FormControl(this.descUnitProd,[
        Validators.maxLength(9)
      ]),
      prodinvmin: new FormControl(this.invMinProd,[
        Validators.min(0),
        Validators.max(9999),
        numValidators
      ]),
      prodinvmax: new FormControl(this.invMaxProd,[
        Validators.min(0),
        Validators.max(9999),
        numValidators
      ]),
      prodorminbox: new FormControl(this.ordMinBoxProd,[
        Validators.min(0),
        Validators.max(9999),
        numValidators
      ]),
      proddaymininv: new FormControl(this.dayMinInvProd,[
        Validators.min(0),
        Validators.max(999),
        numValidators
      ]), 
      prodlocked: new FormControl(this.optLocked),
      prodblockin: new FormControl(this.optBlock_in),
      prodblockex: new FormControl(this.optBlock_exits),
      prodblockmove: new FormControl(this.optBlock_move),
      prodregulation: new FormControl(this.optProductRegulation),
      prodactive: new FormControl(this.optionsStatus),
      prodexpira: new FormControl(this.optionsExpira),
      prodvenminund: new FormControl(this.venMinUndProd,[
        Validators.min(0),
        Validators.max(999999999),
        numValidators
      ]),
      prodvenminbox: new FormControl(this.venMinBoxProd,[
        Validators.min(0),
        Validators.max(999999999),
        numValidators
      ])
    });
    this.formPrices = new FormGroup({
      typeoperation: new FormControl(this.typeOp),
      lotsselect: new FormControl(this.selectLot),
      priceorder: new FormControl(this.optionsPriceOrder),
      printlabel: new FormControl(this.optionPrintLabel),
      priceultcc: new FormControl({value: this.UltCCPrice, disabled: true},[
        Validators.min(0),
        numValidators
      ]),
      priceultcu: new FormControl({value: this.UltCUPrice, disabled: true},[
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      utimin: new FormControl(this.UtiMinPrice,[
        Validators.min(-9999999),
        Validators.max(99999999)
      ]),
      base_base: new FormControl(this.pctBase,[
        Validators.max(999)
      ]),
      base_a: new FormControl(this.pctA,[
        Validators.max(999)
      ]),
      base_b: new FormControl(this.pctB,[
        Validators.max(999)
      ]),
      base_c: new FormControl(this.pctC,[
        Validators.max(999)
      ]),
      base_d: new FormControl(this.pctD,[
        Validators.max(999)
      ]),
      base_e: new FormControl(this.pctE,[
        Validators.max(999)
      ]),
      last_cost_base: new FormControl(this.lastCostBase,[
        Validators.max(999)
      ]),
      last_cost_a: new FormControl(this.lastCostA,[
        Validators.max(999)
      ]),
      last_cost_b: new FormControl(this.lastCostB,[
        Validators.max(999)
      ]),
      last_cost_c: new FormControl(this.lastCostC,[
        Validators.max(999)
      ]),
      last_cost_d: new FormControl(this.lastCostD,[
        Validators.max(999)
      ]),
      last_cost_e: new FormControl(this.lastCostE,[
        Validators.max(999)
      ]),
      utility_base: new FormControl(this.utilityBase,[
        Validators.max(999)
      ]),
      utility_a: new FormControl(this.utilityA,[
        Validators.max(999)
      ]),
      utility_b: new FormControl(this.utilityB,[
        Validators.max(999)
      ]),
      utility_c: new FormControl(this.utilityC,[
        Validators.max(999)
      ]),
      utility_d: new FormControl(this.utilityD,[
        Validators.max(999)
      ]),
      utility_e: new FormControl(this.utilityE,[
        Validators.max(999)
      ]),
      pv_base: new FormControl(this.pvBase,[
        Validators.max(99999999),
        Validators.min(0),
        numValidators
      ]),
      pv_a: new FormControl(this.pvA,[
        Validators.max(99999999),
        Validators.min(0),
        numValidators
      ]),
      pv_b: new FormControl(this.pvB,[
        Validators.max(99999999),
        Validators.min(0),
        numValidators
      ]),
      pv_c: new FormControl(this.pvC,[
        Validators.max(99999999),
        Validators.min(0),
        numValidators
      ]),
      pv_d: new FormControl(this.pvD,[
        Validators.max(99999999),
        Validators.min(0),
        numValidators
      ]),
      pv_e: new FormControl(this.pvE,[
        Validators.max(99999999),
        Validators.min(0),
        numValidators
      ]),
      pvi_base: new FormControl(this.pviBase,[
        Validators.min(0),
        numValidators
      ]),
      pvi_a: new FormControl(this.pviA,[
        Validators.min(0),
        numValidators
      ]),
      pvi_b: new FormControl(this.pviB,[
        Validators.min(0),
        numValidators
      ]),
      pvi_c: new FormControl(this.pviC,[
        Validators.min(0),
        numValidators
      ]),
      pvi_d: new FormControl(this.pviD,[
        Validators.min(0),
        numValidators
      ]),
      pvi_e: new FormControl(this.pviE,[
        Validators.min(0),
        numValidators
      ]),
    });
  }

  ngOnInit() {
    this.formData = new FormData();
    if (this.ProductService.new) {
      this.codeId = 'ADMCAIU00041';
      this.ProductService.selectIdGroup = false;
      this.ProductService.selectGroup = null;
      this.ProductService.descGroup = null;
      this.ProductService.selectedGroup = false;
      this.ProductService.descSubgroup = null;
      this.ProductService.selectedSubGroup = false;
      this.ProductService.descKind = null;
      this.ProductService.selectIdShop = false;
      this.shopselect.setValue('');
      this.ProductService.selectIdLocation = false;
      this.locationselect.setValue('');
      this.ProductService.selectIdSupplier = false;
      this.suppselect.setValue('');
      this.taxselect.setValue(0);
      if (!this.ProductService.requeridLine) {
        this.kindselect.setValue(0);
      }
      if (!this.ProductService.requeridTipo) {
        this.typeselect.setValue(0);
      } else {
        this.ProductService.selectIdType = false;
        this.typeselect.setValue('');
      }
      if (!this.ProductService.requeridPres) {
        this.presentationselect.setValue(0);
      } else {
        this.ProductService.selectIdPresentation = false;
        this.presentationselect.setValue('');
      }
      this.optionsProduct = false;
      this.impuestoCheck = false;
      this.ProductService.selectSupplier = null;
      this.ProductService.descSupplier = null;
      this.ProductService.selectType = null;
      this.ProductService.descType = null;
      this.ProductService.selectPresentation = null;
      this.ProductService.descPresentation = null;
      this.ProductService.selectShop = null;
      this.ProductService.descShop = null;
      this.ProductService.selectLocation = null;
      this.ProductService.descLocation = null;
    } else {
      this.codeId = 'ADMCAIU00045';
      this.codeProd = this.ProductService.object.product_code;
      this.descProd = this.ProductService.object.product_description;
      this.optionsStatus = this.ProductService.object.active;
      if (this.ProductService.object.img) {
        this.imageSrc = String(this.listUrl.url('baseUrl').elemet)+this.ProductService.object.img
        this.viewImg = true;
        this.viewNewImg = false;
        this.bringImage = true;
      }
      if (this.ProductService.object.tax != null) {
        this.ProductService.selectIdTax = true;
        this.impuestoCheck = true;
        this.ProductService.selectTax = this.ProductService.object.tax;
      } else {
        this.impuestoCheck = false;
        this.taxselect.setValue(0);
      }
      if (this.ProductService.object.grupo_pk) {
        this.ProductService.selectIdGroup = true;
        this.ProductService.selectGroup = this.ProductService.object.grupo_pk;
        this.changeGroup();
      }
      if (this.ProductService.object.subgroup_id) {
        this.ProductService.selectedGroup = true;
        this.ProductService.selectIdSubgroup = true;
        this.ProductService.selectSubgroup = this.ProductService.object.subgroup_id;
        this.changeSubgroup();
        this.ProductService.descSubgroup = this.ProductService.object.subgroup_id;
      }
      if (this.ProductService.requeridLine) {
        if (this.ProductService.object.kind) {
          this.ProductService.selectedSubGroup = true;
          this.ProductService.selectIdKind = true;
          this.ProductService.selectKind = this.ProductService.object.kind;
          this.changeKind(this.ProductService.selectKind);
          this.ProductService.descKind = this.ProductService.object.kind;
        }
      } else {
        this.kindselect.setValue(0);
      }
      if (this.ProductService.requeridTipo) {
        if (this.ProductService.object.type_product) {
          this.ProductService.selectIdType = true;
          this.ProductService.selectType = this.ProductService.object.type_product;
          this.changeType(this.ProductService.selectType);
        }
      } else {
        this.typeselect.setValue(0);
      }
      if (this.ProductService.requeridPres) {
        if (this.ProductService.object.presentation) {
          this.ProductService.selectIdPresentation = true;
          this.ProductService.selectPresentation = this.ProductService.object.presentation;
          this.changePresentation(this.ProductService.selectPresentation);
        }
      } else {
        this.presentationselect.setValue(0);
      }
      if (this.ProductService.object.shop) {
        this.ProductService.selectIdShop = true;
        this.ProductService.selectShop = this.ProductService.object.shop;
        this.changeShop(this.ProductService.selectShop);
      }
      if (this.ProductService.object.location) {
        this.ProductService.selectIdLocation = true;
        this.ProductService.selectLocation = this.ProductService.object.location;
        this.changeLocation(this.ProductService.selectLocation);
      }
      this.rubroProd = this.ProductService.object.product_control;
      if (this.ProductService.object.type_product_two) {
        this.prodType = true;
        this.nroTypeProd = 1;
      } else {
        this.prodType = false;
        this.nroTypeProd = 0;
      }
      if (this.ProductService.object.supplier) {
        this.ProductService.selectIdSupplier = true;
        this.ProductService.selectSupplier = this.ProductService.object.supplier;
        this.changeSupplier(this.ProductService.selectSupplier);
      }
      this.idProdSupp = this.ProductService.object.id_product_proveedor;
      this.barcoBoxP = this.ProductService.object.barcode_box;
      this.barcoDisP = this.ProductService.object.barcode_code_display;
      this.barcoUniP = this.ProductService.object.barcode_unit;
      this.unitPalProd = this.ProductService.object.stock_boxes;
      this.unitBoxProd = this.ProductService.object.unit_boxes;
      this.contUnitProd = this.ProductService.object.contained_unit;
      this.volBoxProd = this.ProductService.object.product_volume;
      this.pesBrBoxProd = this.ProductService.object.unit_weight;
      this.descBoxProd = this.ProductService.object.expresion_boxes;
      this.descUnitProd = this.ProductService.object.expresion_unit;
      this.invMinProd = this.ProductService.object.inventory_minimun;
      this.invMaxProd = this.ProductService.object.inventory_maximun;
      this.dayMinInvProd = this.ProductService.object.inventory_minimun_day;
      this.ordMinBoxProd = this.ProductService.object.order_minumun_boxes;
      this.optionsExpira = this.ProductService.object.expiration;

      if (this.ProductService.object.expiration) {
        this.btnModalExpira = true;
      }

      if(this.unitBoxProd){
        this.notEditVenta1 = false;
        this.notEditVenta2 = false;
        this.viewNot = false;
      }else{
        this.notEditVenta1 = true;
        this.notEditVenta2 = true;
        this.viewNot = true;
      }
      this.venMinUndProd = this.ProductService.object.minimun_sales_units;
      this.changeVenMinUnd();
      this.optLocked = this.ProductService.object.locked;
      this.optBlock_in = this.ProductService.object.block_in;
      this.optBlock_exits = this.ProductService.object.block_exits;
      this.optBlock_move = this.ProductService.object.block_move;
      this.optProductRegulation = this.ProductService.object.product_regulation;
      this.loadLots(this.ProductService.pk);
      this.optionsPriceOrder = this.ProductService.object.price_order;
      this.optionPrintLabel = this.ProductService.object.print_label;
      if (this.ProductService.object.minumun_profit == null) {
        this.UtiMinPrice = 0;
      } else {
        this.UtiMinPrice = this.ProductService.object.minumun_profit;
      }
      
      this.UtiMinPriceOri = this.UtiMinPrice;
    }
    if (screen.width >= 768) {
      this.style1 = true;
      this.style2 = true;
      this.style3 = true;
      this.style4 = true;
    } else {
      this.style1 = true;
      this.style2 = false;
      this.style3 = false;
      this.style4 = false;
    }
  }

  get groupselect() { return this.formProduct.get('groupselect'); }
  get groupdesc() { return this.formProduct.get('groupdesc'); }
  get subgroupselect() { return this.formProduct.get('subgroupselect'); }
  get subgroupdesc() { return this.formProduct.get('subgroupdesc'); }
  get kindselect() { return this.formProduct.get('kindselect'); }
  get kinddesc() { return this.formProduct.get('kinddesc'); }
  get typeselect() { return this.formProduct.get('typeselect'); }
  get typedesc() { return this.formProduct.get('typedesc'); }
  get presentationselect() { return this.formProduct.get('presentationselect'); }
  get presentationdesc() { return this.formProduct.get('presentationdesc'); }
  get shopselect() { return this.formProduct.get('shopselect'); }
  get shopdesc() { return this.formProduct.get('shopdesc'); }
  get locationselect() { return this.formProduct.get('locationselect'); }
  get locationdesc() { return this.formProduct.get('locationdesc'); }
  get taxselect() { return this.formProduct.get('taxselect'); }
  get suppselect() { return this.formProduct.get('suppselect'); }
  get suppdesc() { return this.formProduct.get('suppdesc'); }
  get prodcode() { return this.formProduct.get('prodcode'); }
  get proddesc() { return this.formProduct.get('proddesc'); }
  get prodrubro() { return this.formProduct.get('prodrubro'); }
  get prodbarcobox() { return this.formProduct.get('prodbarcobox'); }
  get prodbarcodis() { return this.formProduct.get('prodbarcodis'); }
  get prodbarcouni() { return this.formProduct.get('prodbarcouni'); }
  get prodidprodsupp() { return this.formProduct.get('prodidprodsupp'); }
  get typeproduct() { return this.formProduct.get('typeproduct'); }
  get produnitpaleta() { return this.formProduct.get('produnitpaleta'); }
  get produnitbox() { return this.formProduct.get('produnitbox'); }
  get prodcontunit() { return this.formProduct.get('prodcontunit'); }
  get prodvolbox() { return this.formProduct.get('prodvolbox'); }
  get prodpesbrubox() { return this.formProduct.get('prodpesbrubox'); }
  get proddescbox() { return this.formProduct.get('proddescbox'); }
  get proddescunit() { return this.formProduct.get('proddescunit'); }
  get prodinvmin() { return this.formProduct.get('prodinvmin'); }
  get prodinvmax() { return this.formProduct.get('prodinvmax'); }
  get prodorminbox() { return this.formProduct.get('prodorminbox'); }
  get proddaymininv() { return this.formProduct.get('proddaymininv'); }
  get prodlocked() { return this.formProduct.get('prodlocked'); }
  get prodblockin() { return this.formProduct.get('prodblockin'); }
  get prodblockex() { return this.formProduct.get('prodblockex'); }
  get prodblockmove() { return this.formProduct.get('prodblockmove'); }
  get prodregulation() { return this.formProduct.get('prodregulation'); }
  get prodvenminund() { return this.formProduct.get('prodvenminund'); }
  get prodvenminbox() { return this.formProduct.get('prodvenminbox'); }
  get prodexpira() { return this.formProduct.get('prodexpira'); }
  get prodactive() { return this.formProduct.get('prodactive'); }

  get typeoperation() { return this.formPrices.get('typeoperation'); }
  get lotsselect() { return this.formPrices.get('lotsselect'); }
  get priceorder() { return this.formPrices.get('priceorder'); }
  get printlabel() { return this.formPrices.get('printlabel'); }
  get priceultcc() { return this.formPrices.get('priceultcc'); }
  get priceultcu() { return this.formPrices.get('priceultcu'); }
  get utimin() { return this.formPrices.get('utimin'); }
  get base_base() { return this.formPrices.get('base_base'); }
  get base_a() { return this.formPrices.get('base_a'); }
  get base_b() { return this.formPrices.get('base_b'); }
  get base_c() { return this.formPrices.get('base_c'); }
  get base_d() { return this.formPrices.get('base_d'); }
  get base_e() { return this.formPrices.get('base_e'); }
  get last_cost_base() { return this.formPrices.get('last_cost_base'); }
  get last_cost_a() { return this.formPrices.get('last_cost_a'); }
  get last_cost_b() { return this.formPrices.get('last_cost_b'); }
  get last_cost_c() { return this.formPrices.get('last_cost_c'); }
  get last_cost_d() { return this.formPrices.get('last_cost_d'); }
  get last_cost_e() { return this.formPrices.get('last_cost_e'); }
  get utility_base() { return this.formPrices.get('utility_base'); }
  get utility_a() { return this.formPrices.get('utility_a'); }
  get utility_b() { return this.formPrices.get('utility_b'); }
  get utility_c() { return this.formPrices.get('utility_c'); }
  get utility_d() { return this.formPrices.get('utility_d'); }
  get utility_e() { return this.formPrices.get('utility_e'); }
  get pv_base() { return this.formPrices.get('pv_base'); }
  get pv_a() { return this.formPrices.get('pv_a'); }
  get pv_b() { return this.formPrices.get('pv_b'); }
  get pv_c() { return this.formPrices.get('pv_c'); }
  get pv_d() { return this.formPrices.get('pv_d'); }
  get pv_e() { return this.formPrices.get('pv_e'); }
  get pvi_base() { return this.formPrices.get('pvi_base'); }
  get pvi_a() { return this.formPrices.get('pvi_a'); }
  get pvi_b() { return this.formPrices.get('pvi_b'); }
  get pvi_c() { return this.formPrices.get('pvi_c'); }
  get pvi_d() { return this.formPrices.get('pvi_d'); }
  get pvi_e() { return this.formPrices.get('pvi_e'); }

      
  pageChanged(event: PageChangedEvent): void {
    let page = event.page;
    if(page === 1){
      this.style1 = true;
      this.style2 = false;
      this.style3 = false;
      this.style4 = false;
    }else if(page === 2){
      this.style1 = false;
      this.style2 = true;
      this.style3 = false;
      this.style4 = false;
    }else if(page === 3){
      this.style1 = false;
      this.style2 = false;
      this.style3 = true;
      this.style4 = false;
    }else if(page === 4){
      this.style1 = false;
      this.style2 = false;
      this.style3 = false;
      this.style4 = true;
    }
  }

  getId(){
    return this.codeId;
  }

  changeCodeId(newCode){
    this.codeId = newCode;
  }

  saveProduct(values) {
    let valid = true; 
    let id_obj: any;
    if (this.ProductService.new) {
      id_obj = '';
    } else {
      id_obj = this.ProductService.pk;
    }
    if (values.prodcode.trim() && values.proddesc.trim()) {
      if (values.groupselect && values.subgroupselect) {
        if (values.typeproduct == 0 || values.typeproduct == 1) {
          values.typeproduct = values.typeproduct;
        } else {
          this.typeProd = [{id: 0, valor: 'Producto'},{id: 1, valor: 'Servicio'}];
          this.formProduct.get('typeproduct').setValue(0);
          this.nroTypeProd = 0;
          values.typeproduct = 0;
        }
        if (this.ProductService.requeridLine) {
          if (values.kindselect == 0 || (values.kindselect % 1 != 0)) {
            valid = false;
            this.restLine();
          }
        } else {
          if (values.kindselect % 1 != 0) {
            valid = false;
            this.restLine();
          }
        }
        if (this.ProductService.requeridTipo) {
          if (values.typeselect == 0 || (values.typeselect % 1 != 0)) {
            valid = false;
            this.restTipo();
          }
        } else {
          if (values.typeselect % 1 != 0) {
            valid = false;
            this.restTipo();
          }
        }
        if (this.ProductService.requeridPres) {
          if (values.presentationselect == 0 || (values.presentationselect % 1 != 0)) {
            valid = false;
            this.restPres();
          }
        } else {
          if (values.presentationselect % 1 != 0) {
            valid = false;
            this.restPres();
          }
        }
        if (this.impuestoCheck) {
          if (values.taxselect == 0 || (values.taxselect % 1 != 0)) {
            valid = false;
            this.restTax();
          }
        } else {
          if (values.taxselect % 1 != 0) {
            valid = false;
            this.restTax();
          }
        }
        if (typeof values.shopselect  == 'undefined') {
          values.shopselect = '';
        }
        if (typeof values.locationselect  == 'undefined') {
          values.locationselect = '';
        }
        if (typeof values.suppselect == 'undefined') {
          values.suppselect = '';
        }
        if (valid) {
          var iter = 0;
          for (const key in this.lotes_precios) {
            if (Object.prototype.hasOwnProperty.call(this.lotes_precios, key)) {
              const elem = this.lotes_precios[key];
              if (elem.edited) {
                iter = iter+1;
                this.formData.append('lots_pk_'+iter, elem.pk);
                this.formData.append('lots_lote_'+iter, elem.lote);
                this.formData.append('lots_utilidad_'+iter, elem.utilidad);
                this.formData.append('lots_base_'+iter, elem.base);
                this.formData.append('lots_a_'+iter, elem.a);
                this.formData.append('lots_b_'+iter, elem.b);
                this.formData.append('lots_c_'+iter, elem.c);
                this.formData.append('lots_d_'+iter, elem.d);
                this.formData.append('lots_e_'+iter, elem.e);
              }
            }
          }
          this.formData.append('id_obj', id_obj); // Id_Producto
          this.formData.append('code', values.prodcode);
          this.formData.append('description', values.proddesc);
          this.formData.append('status', values.prodactive);
          this.formData.append('tax', values.taxselect);
          this.formData.append('group', values.groupselect);
          this.formData.append('subgroup', values.subgroupselect);
          this.formData.append('kind', values.kindselect);
          this.formData.append('type_product', values.typeselect);
          this.formData.append('presentation', values.presentationselect);
          this.formData.append('shop', values.shopselect);
          this.formData.append('location', values.locationselect);
          this.formData.append('product_control', values.prodrubro);
          this.formData.append('type_product_two', values.typeproduct);
          this.formData.append('supplier', values.suppselect);
          this.formData.append('id_prod_supp', values.prodidprodsupp);
          this.formData.append('barcode_box', values.prodbarcobox);
          this.formData.append('barcode_dispaly', values.prodbarcodis);
          this.formData.append('barcode_unit', values.prodbarcouni);
          this.formData.append('stock_boxes', values.produnitpaleta);
          this.formData.append('unit_boxes', values.produnitbox);
          this.formData.append('contained_unit', values.prodcontunit);
          this.formData.append('product_volume', values.prodvolbox);
          this.formData.append('unit_weight', values.prodpesbrubox);
          this.formData.append('expresion_boxes', values.proddescbox);
          this.formData.append('expresion_unit', values.proddescunit);
          this.formData.append('inven_min', values.prodinvmin);
          this.formData.append('inven_max', values.prodinvmax);
          this.formData.append('inven_min_day', values.proddaymininv);
          this.formData.append('order_min_boxes', values.prodorminbox);
          this.formData.append('min_sales_unit', values.prodvenminund);
          this.formData.append('locked', values.prodlocked);
          this.formData.append('expira', values.prodexpira);
          this.formData.append('block_in', values.prodblockin);
          this.formData.append('block_exits', values.prodblockex);
          this.formData.append('block_move', values.prodblockmove);
          this.formData.append('product_regulation', values.prodregulation);
          this.formData.append('req_kind', this.ProductService.requeridLine); // Comprobar estatus de Linea
          this.formData.append('req_type', this.ProductService.requeridTipo); // Comprobar estatus de Tipo
          this.formData.append('req_pres', this.ProductService.requeridPres); // Comprobar estatus de Presentacion
          this.formData.append('req_tax', this.impuestoCheck); // Comprobar estatus de Activo
          this.formData.append('profit_min', this.UtiMinPrice); // Utilidad Minima
          this.formData.append('price_order', this.optionsPriceOrder); // Pide precio en pedidos y Facturas
          this.formData.append('print_label', this.optionPrintLabel); // Imprimir Etiquetas
          this.formData.append('tipo', 'catalogo'); // Tipo de Formulario
          this.formData.append('have_img', this.haveImg); //Para saber si tiene imagen
          this.formData.append('bring_img', this.bringImage); //Para saber si el producto a editar tiene o no imagen
          this.formData.append('delete_img', this.deleteImagee); //Para saber si el producto a editar se le elimino o no la imagen
          this.formData.append('nro_lots', iter); // Objeto con los Lotes de Precios
          this.formData.append('total_img', this.totalImg); //Total de imagenes

          const initAjax: Init = {
            method: 'put',
            url: this.listUrl.url('saveproduct'),
            auth: this.auth,
            body: this.formData
          };
          const configAjax: Config = {
            numInten : 1,
            titleLoading: 'Guardando Producto',
            titleSuccess: '',
            titleErrorNormal: 'Error',
            textErrorNormal: 'Ha ocurrido un Error.',
            confirmButtonTextError: 'Cerrar',
            validatejson: (resp: any, obj: Ajax) => {
              return new Promise((success, error) => {
                if (resp.success === false) {
                  obj.declareConfig({
                    alertaError : Swal.mixin({
                      icon: 'error',
                      title: 'Ocurrio un error',
                      text: resp.message,
                      showConfirmButton: false,
                      confirmButtonText: 'Cerrar',
                    })
                  });
                  error(resp);
                } else {
                  success(resp);
                }
              });
            }
          };
          const ajax = new Ajax(initAjax, configAjax);
          ajax.call().then(resp => {
            if (resp.success) {
              if (resp.new) {
                if (resp.tipo == 'catalogo') {
                  this.ProductService.continuar = true;
                  this.ProductService.evenAdd.emit(resp);
                }
              } else {
                this.ProductService.continuar = true;
                this.ProductService.evenAdd.emit(resp);
              }
              this.ProductService.closeModal.hide();
            }
          }).catch(error => {
            this.viewError(error);
          });
        }
      } else {
        this.formProduct.get('groupselect').setValue('');
        this.formProduct.get('subgroupselect').setValue(null);
        this.ProductService.descGroup = null;
        this.ProductService.selectedGroup = false;
        this.ProductService.selectIdSubgroup = false;
        this.ProductService.selectSubgroup = null;
        this.ProductService.descSubgroup = null;
        if (this.ProductService.requeridLine) {
          this.ProductService.selectKind = null;
          this.ProductService.descKind = null;
          this.formProduct.get('kindselect').setValue(null);
        }
      }
    } else {
      this.formProduct.get('prodcode').setValue(values.prodcode.trim());
      this.formProduct.get('proddesc').setValue(values.proddesc.trim());
    }
  }

  viewError(error) {
    if (typeof error.error_id !== 'undefined') {
      if (error.error_id) {
        this.ErrorsProduct.error_id = error.error_id;
        this.ErrorsProduct.txt_id = error.txt_id;
      }
    }
    if (typeof error.error_codep !== 'undefined') {
      if (error.error_codep) {
        this.ErrorsProduct.error_codep = error.error_codep;
        this.ErrorsProduct.txt_codep = error.txt_codep;
      }
    }
    if (typeof error.error_descp !== 'undefined') {
      if (error.error_descp) {
        this.ErrorsProduct.error_descp = error.error_descp;
        this.ErrorsProduct.txt_descp = error.txt_descp;
      }
    }
    if (typeof error.error_cababo !== 'undefined') {
      if (error.error_cababo) {
        this.ErrorsProduct.error_cababo = error.error_cababo;
        this.ErrorsProduct.txt_cababo = error.txt_cababo;
      }
    }
    if (typeof error.error_cabadi !== 'undefined') {
      if (error.error_cabadi) {
        this.ErrorsProduct.error_cabadi = error.error_cabadi;
        this.ErrorsProduct.txt_cabadi = error.txt_cabadi;
      }
    }
    if (typeof error.error_cabaun !== 'undefined') {
      if (error.error_cabaun) {
        this.ErrorsProduct.error_cabaun = error.error_cabaun;
        this.ErrorsProduct.txt_cabaun = error.txt_cabaun;
      }
    }
    if (typeof error.error_idpssu !== 'undefined') {
      if (error.error_idpssu) {
        this.ErrorsProduct.error_idpssu = error.error_idpssu;
        this.ErrorsProduct.txt_idpssu = error.txt_idpssu;
      }
    }
    if (typeof error.error_rubrp !== 'undefined') {
      if (error.error_rubrp) {
        this.ErrorsProduct.error_rubrp = error.error_rubrp;
        this.ErrorsProduct.txt_rubrp = error.txt_rubrp;
      }
    }
    if (typeof error.error_unpal !== 'undefined') {
      if (error.error_unpal) {
        this.ErrorsProduct.error_unpal = error.error_unpal;
        this.ErrorsProduct.txt_unpal = error.txt_unpal;
      }
    }
    if (typeof error.error_unbox !== 'undefined') {
      if (error.error_unbox) {
        this.ErrorsProduct.error_unbox = error.error_unbox;
        this.ErrorsProduct.txt_unbox = error.txt_unbox;
      }
    }
    if (typeof error.error_descbox !== 'undefined') {
      if (error.error_descbox) {
        this.ErrorsProduct.error_descbox = error.error_descbox;
        this.ErrorsProduct.txt_descbox = error.txt_descbox;
      }
    }
    if (typeof error.error_descunit !== 'undefined') {
      if (error.error_descunit) {
        this.ErrorsProduct.error_descunit = error.error_descunit;
        this.ErrorsProduct.txt_descunit = error.txt_descunit;
      }
    }
    if (typeof error.error_invmin !== 'undefined') {
      if (error.error_invmin) {
        this.ErrorsProduct.error_invmin = error.error_invmin;
        this.ErrorsProduct.txt_invmin = error.txt_invmin;
      }
    }
    if (typeof error.error_invmax !== 'undefined') {
      if (error.error_invmax) {
        this.ErrorsProduct.error_invmax = error.error_invmax;
        this.ErrorsProduct.txt_invmax = error.txt_invmax;
      }
    }
    if (typeof error.error_daminv !== 'undefined') {
      if (error.error_daminv) {
        this.ErrorsProduct.error_daminv = error.error_daminv;
        this.ErrorsProduct.txt_daminv = error.txt_daminv;
      }
    }
    if (typeof error.error_ormibo !== 'undefined') {
      if (error.error_ormibo) {
        this.ErrorsProduct.error_ormibo = error.error_ormibo;
        this.ErrorsProduct.txt_ormibo = error.txt_ormibo;
      }
    }
    if (typeof error.error_vemiun !== 'undefined') {
      if (error.error_vemiun) {
        this.ErrorsProduct.error_vemiun = error.error_vemiun;
        this.ErrorsProduct.txt_vemiun = error.txt_vemiun;
      }
    }
    if (typeof error.error_contunit !== 'undefined') {
      if (error.error_contunit) {
        this.ErrorsProduct.error_contunit = error.error_contunit;
        this.ErrorsProduct.txt_contunit = error.txt_contunit;
      }
    }
    if (typeof error.error_utimin !== 'undefined') {
      if (error.error_utimin) {
        this.ErrorsProduct.error_utimin = error.error_utimin;
        this.ErrorsProduct.text_utimin = error.text_utimin;
      }
    }
    if (typeof error.error_volbox !== 'undefined') {
      if (error.error_volbox) {
        this.ErrorsProduct.error_volbox = error.error_volbox;
        this.ErrorsProduct.txt_volbox = error.txt_volbox;
      }
    }
    if (typeof error.error_pesbrbox !== 'undefined') {
      if (error.error_pesbrbox) {
        this.ErrorsProduct.error_pesbrbox = error.error_pesbrbox;
        this.ErrorsProduct.txt_pesbrbox = error.txt_pesbrbox;
      }
    }
    if (typeof error.error_group !== 'undefined') {
      if (error.error_group) {
        this.ErrorsProduct.error_group = error.error_group;
        this.ErrorsProduct.txt_group = error.txt_group;
      }
    }
    if (typeof error.error_subgroup !== 'undefined') {
      if (error.error_subgroup) {
        this.ErrorsProduct.error_subgroup = error.error_subgroup;
        this.ErrorsProduct.txt_subgroup = error.txt_subgroup;
      }
    }
    if (typeof error.error_kind !== 'undefined') {
      if (error.error_kind) {
        this.ErrorsProduct.error_kind = error.error_kind;
        this.ErrorsProduct.txt_kind = error.txt_kind;
      }
    }
    if (typeof error.error_type !== 'undefined') {
      if (error.error_type) {
        this.ErrorsProduct.error_type = error.error_type;
        this.ErrorsProduct.txt_type = error.txt_type;
      }
    }
    if (typeof error.error_presentation !== 'undefined') {
      if (error.error_presentation) {
        this.ErrorsProduct.error_presentation = error.error_presentation;
        this.ErrorsProduct.txt_presentation = error.txt_presentation;
      }
    }
    if (typeof error.error_tax !== 'undefined') {
      if (error.error_tax) {
        this.ErrorsProduct.error_tax = error.error_tax;
        this.ErrorsProduct.txt_tax = error.txt_tax;
      }
    }
    if (typeof error.error_shop !== 'undefined') {
      if (error.error_shop) {
        this.ErrorsProduct.error_shop = error.error_shop;
        this.ErrorsProduct.txt_shop = error.txt_shop;
      }
    }
    if (typeof error.error_location !== 'undefined') {
      if (error.error_location) {
        this.ErrorsProduct.error_location = error.error_location;
        this.ErrorsProduct.txt_location = error.txt_location;
      }
    }
    if (typeof error.error_supplier !== 'undefined') {
      if (error.error_supplier) {
        this.ErrorsProduct.error_supplier = error.error_supplier;
        this.ErrorsProduct.txt_supplier = error.txt_supplier;
      }
    }
    if (typeof error.error_changeimg !== 'undefined') {
      if (error.error_changeimg) {
        this.ErrorsProduct.error_changeimg = error.error_changeimg;
        this.ErrorsProduct.txt_changeimg = error.txt_changeimg;
      }
    }
    if (typeof error.lotes !== 'undefined') {
      this.ErrorsTablesShow = true;
      this.ErrorPrices = true;
      this.ErrorPreciosTxt = 'Lo siento, los siguientes Lotes presentan errores:';
      for (const key in error.lotes) {
        let obj_error = new Object();
        if (Object.prototype.hasOwnProperty.call(error.lotes, key)) {
          const elem = error.lotes[key];
          if (typeof elem.error_utibase !== 'undefined' && elem.error_utibase) {
            obj_error['error_utibase'] = true;
            obj_error['txt_utibase'] = elem.text_utibase;
            this.ErrorsTablesProd.push({error: '%Utilidad de Precio Base en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_utibase, 'pk': key, 'tipo': 'base'});
          }
          if (typeof elem.error_pvbase !== 'undefined' && elem.error_pvbase) {
            obj_error['error_pvbase'] = true;
            obj_error['text_pvbase'] = elem.text_pvbase;
            this.ErrorsTablesProd.push({error: 'Precio de Venta, de Precio Base en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_pvbase, 'pk': key, 'tipo': 'pvbase'});
          }
          if (typeof elem.error_pva !== 'undefined' && elem.error_pva) {
            obj_error['error_pva'] = true;
            obj_error['text_pva'] = elem.text_pva;
            this.ErrorsTablesProd.push({error: 'Precio de Venta, de Precio A en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_pva, 'pk': key, 'tipo': 'pva'});
          }
          if (typeof elem.error_pvb !== 'undefined' && elem.error_pvb) {
            obj_error['error_pvb'] = true;
            obj_error['text_pvb'] = elem.text_pvb;
            this.ErrorsTablesProd.push({error: 'Precio de Venta, de Precio B en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_pvb, 'pk': key, 'tipo': 'pvb'});
          }
          if (typeof elem.error_pvc !== 'undefined' && elem.error_pvc) {
            obj_error['error_pvc'] = true;
            obj_error['text_pvc'] = elem.text_pvc;
            this.ErrorsTablesProd.push({error: 'Precio de Venta, de Precio C en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_pvc, 'pk': key, 'tipo': 'pvc'});
          }
          if (typeof elem.error_pvd !== 'undefined' && elem.error_pvd) {
            obj_error['error_pvd'] = true;
            obj_error['text_pvd'] = elem.text_pvd;
            this.ErrorsTablesProd.push({error: 'Precio de Venta, de Precio D en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_pvd, 'pk': key, 'tipo': 'pvd'});
          }
          if (typeof elem.error_pve !== 'undefined' && elem.error_pve) {
            obj_error['error_pve'] = true;
            obj_error['text_pve'] = elem.text_pve;
            this.ErrorsTablesProd.push({error: 'Precio de Venta, de Precio E en Lote <strong>'+elem.error_lote+'</strong>, '+elem.text_pve, 'pk': key, 'tipo': 'pve'});
          }

          if (Object.keys(obj_error).length > 0) {
            obj_error['pk'] = key;
            obj_error['lote'] = elem.error_lote;
            this.arrayErrorLots.push(obj_error);
          }
        }
      }
      
    }
  }

  loadSubgroup(pk_group) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk_group
      },
      url: this.listUrl.url('list_modal_subgroup')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.ProductService.SubgroupSelect = resp.objects;
        this.ProductService.selectedGroup = true;
      }
    });
  }

  loadKind(pk_sub) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk_sub
      },
      url: this.listUrl.url('list_modal_kind')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.ProductService.KindSelect = resp.objects;
        this.ProductService.selectedSubGroup = true;
      }
    });
  }

  restLine() {
    this.ProductService.selectedGroup = false;
    this.ProductService.selectedSubGroup = false;
    this.ProductService.selectSubgroup = null;
    this.ProductService.selectKind = null;
    this.ProductService.descSubgroup = null;
    this.ProductService.descKind = null;
    this.formProduct.get('subgroupselect').setValue(null);
    this.formProduct.get('kindselect').setValue(null);
  }

  restTipo() {
    this.ProductService.selectIdType = false;
    this.ProductService.selectType = null;
    this.ProductService.descType = null;
    this.formProduct.get('typeselect').setValue(null);
  }

  restPres() {
    this.ProductService.selectIdPresentation = false;
    this.ProductService.selectPresentation = null;
    this.ProductService.descPresentation = null;
    this.formProduct.get('presentationselect').setValue(null);
  }

  restTax() {
    this.ProductService.selectIdTax = false;
    this.ProductService.selectTax = null;
    this.formProduct.get('taxselect').setValue(null);
  }

  changeTaxCheck() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    if (!this.impuestoCheck) {
      this.formProduct.get('taxselect').setValue(0);
      this.ProductService.selectIdTax = false;
      this.ProductService.selectTax = null;
    }
  }

  formatCodeP() {
    this.codeProd = this.codeProd.toUpperCase().trim();
    this.ErrorsProduct.error_codep = false;
    this.ErrorsProduct.txt_codep = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    if (this.codeProd) {
      while (this.codeProd.length < 10) {
        this.codeProd = '0'+this.codeProd;
      } 
    }
  }

  formatDescP() {
    this.descProd = this.descProd.toUpperCase();
    this.ErrorsProduct.error_descp = false;
    this.ErrorsProduct.txt_descp = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  changeCheck() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  changeNro(error) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    if(error === 'error_rubrp'){
      if(this.ErrorsProduct.error_rubrp){
        this.ErrorsProduct.error_rubrp = false;
        this.ErrorsProduct.txt_rubrp = '';
      }
    }else if (error === 'error_unpal') {
      if(this.ErrorsProduct.error_unpal){
        this.ErrorsProduct.error_unpal = false;
        this.ErrorsProduct.txt_unpal = '';
      }
    }else if (error === 'error_unbox') {
      if(this.ErrorsProduct.error_unbox){
        this.ErrorsProduct.error_unbox = false;
        this.ErrorsProduct.txt_unbox = '';
      }
    }else if (error === 'error_contunit') {
      if(this.ErrorsProduct.error_contunit){
        this.ErrorsProduct.error_contunit = false;
        this.ErrorsProduct.txt_contunit = '';
      }
    }else if (error === 'error_volbox') {
      if(this.ErrorsProduct.error_volbox){
        this.ErrorsProduct.error_volbox = false;
        this.ErrorsProduct.txt_volbox = '';
      }
    }else if (error === 'error_pesbrbox') {
      if(this.ErrorsProduct.error_pesbrbox){
        this.ErrorsProduct.error_pesbrbox = false;
        this.ErrorsProduct.txt_pesbrbox = '';
      }
    }else if (error === 'error_descbox') {
      if(this.ErrorsProduct.error_descbox){
        this.ErrorsProduct.error_descbox = false;
        this.ErrorsProduct.txt_descbox = '';
      }
    }else if (error === 'error_descunit') {
      if(this.ErrorsProduct.error_descunit){
        this.ErrorsProduct.error_descunit = false;
        this.ErrorsProduct.txt_descunit = '';
      }
    }else if (error === 'error_invmin') {
      if(this.ErrorsProduct.error_invmin){
        this.ErrorsProduct.error_invmin = false;
        this.ErrorsProduct.txt_invmin = '';
      }
    }else if (error === 'error_invmax') {
      if(this.ErrorsProduct.error_invmax){
        this.ErrorsProduct.error_invmax = false;
        this.ErrorsProduct.txt_invmax = '';
      }
    }else if (error === 'error_daminv') {
      if(this.ErrorsProduct.error_daminv){
        this.ErrorsProduct.error_daminv = false;
        this.ErrorsProduct.txt_daminv = '';
      }
    }else if (error === 'error_ormibo') {
      if(this.ErrorsProduct.error_ormibo){
        this.ErrorsProduct.error_ormibo = false;
        this.ErrorsProduct.txt_ormibo = '';
      }
    }
    
  }

  changeTax() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_tax = false;
    this.ErrorsProduct.txt_tax = '';
  }

  changeGroup() {
    this.clearErrorsGroup();
    if(this.ProductService.selectGroup){
      this.loadSubgroup(this.ProductService.selectGroup);
      for (const key in this.ProductService.GroupSelect) {
        if (this.ProductService.GroupSelect.hasOwnProperty(key)) {
          const element = this.ProductService.GroupSelect[key];
          if(this.ProductService.selectGroup == element.id){
            this.ProductService.descGroup = element.id;
            break;
          }
        }
      }
    }else{
      this.ProductService.descGroup = null;
      this.ProductService.selectedGroup = false;
    }
  }

  changeGroupDesc() {
    this.clearErrorsGroup();
    if(this.ProductService.descGroup){
      this.loadSubgroup(this.ProductService.descGroup);
      for (const key in this.ProductService.GroupSelect) {
        if (this.ProductService.GroupSelect.hasOwnProperty(key)) {
          const element = this.ProductService.GroupSelect[key];
          if(this.ProductService.descGroup == element.id){
            this.ProductService.selectGroup = element.id;
            break;
          }
        }
      }
    }else{
      this.ProductService.selectGroup = null;
      this.ProductService.selectedGroup = false;
    }
  }

  clearErrorsGroup() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_group = false;
    this.ErrorsProduct.txt_group = '';
    this.ProductService.selectSubgroup = null;
    this.ProductService.descSubgroup = null;
    this.ErrorsProduct.error_subgroup = false;
    this.ErrorsProduct.txt_subgroup = '';
    this.ErrorsProduct.error_kind = false;
    this.ErrorsProduct.txt_kind = '';
    if (this.ProductService.requeridLine) {
      this.ProductService.selectKind = null;
    }
    this.ProductService.selectedSubGroup = false;
    this.ProductService.descKind = null;
  }

  changeSubgroup() {
    this.clearErrorsSubgroup();
    if(this.ProductService.selectSubgroup){
      this.loadKind(this.ProductService.selectSubgroup);
      for (const key in this.ProductService.SubgroupSelect) {
        if (this.ProductService.SubgroupSelect.hasOwnProperty(key)) {
          const element = this.ProductService.SubgroupSelect[key];
          if(this.ProductService.selectSubgroup == element.id){
            this.ProductService.descSubgroup = element.id;
            break;
          }
        }
      }
      this.ProductService.selectedSubGroup = true;
    }else{
      this.ProductService.descSubgroup = null;
      this.ProductService.selectedSubGroup = false;
      this.ProductService.descKind = null;
    }
  }

  changeSubgroupDesc() {
    this.clearErrorsSubgroup();
    if(this.ProductService.descSubgroup){
      this.loadKind(this.ProductService.descSubgroup);
      for (const key in this.ProductService.SubgroupSelect) {
        if (this.ProductService.SubgroupSelect.hasOwnProperty(key)) {
          const element = this.ProductService.SubgroupSelect[key];
          if(this.ProductService.descSubgroup == element.id){
            this.ProductService.selectSubgroup = element.id;
            break;
          }
        }
      }
      this.ProductService.selectedSubGroup = true;
    }else{
      this.ProductService.selectSubgroup = null;
      this.ProductService.selectedSubGroup = false;
      this.ProductService.descKind = null;
    }
  }

  clearErrorsSubgroup() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_subgroup = false;
    this.ErrorsProduct.txt_subgroup = '';
    this.ErrorsProduct.error_kind = false;
    this.ErrorsProduct.txt_kind = '';
    if (this.ProductService.requeridLine) {
      this.ProductService.selectKind = null;
      this.ProductService.descKind = null;
    }
  }

  changeKind(value) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_kind = false;
    this.ErrorsProduct.txt_kind = '';
    if (value) {
      for (const key in this.ProductService.KindSelect) {
        if (this.ProductService.KindSelect.hasOwnProperty(key)) {
          const element = this.ProductService.KindSelect[key];
          if (value == element.id) {
            this.ProductService.selectKind = element.id;
            this.ProductService.descKind = element.id;
            break;
          }
        }
      } 
    } else {
      this.ProductService.selectKind = null;
      this.ProductService.descKind = null;
    }
  }

  changeType(value) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_type = false;
    this.ErrorsProduct.txt_type = '';
    if (value) {
      for (const key in this.ProductService.TypeSelect) {
        if (this.ProductService.TypeSelect.hasOwnProperty(key)) {
          const element = this.ProductService.TypeSelect[key];
          if(value == element.id){
            this.ProductService.selectType = element.id;
            this.ProductService.descType = element.id;
            break;
          }
        }
      }  
    } else {
      this.ProductService.selectType = null;
      this.ProductService.descType = null;
    }
  }

  changePresentation(value) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_presentation = false;
    this.ErrorsProduct.txt_presentation = '';
    if (value) {
      for (const key in this.ProductService.PresentationSelect) {
        if (this.ProductService.PresentationSelect.hasOwnProperty(key)) {
          const element = this.ProductService.PresentationSelect[key];
          if(value == element.id){
            this.ProductService.selectPresentation = element.id;
            this.ProductService.descPresentation = element.id;
            break;
          }
        }
      }
    } else {
      this.ProductService.selectPresentation = null;
      this.ProductService.descPresentation = null;
    }
  }

  changeShop(value) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_shop = false;
    this.ErrorsProduct.txt_shop = '';
    if (value) {
      for (const key in this.ProductService.ShopSelect) {
        if (this.ProductService.ShopSelect.hasOwnProperty(key)) {
          const element = this.ProductService.ShopSelect[key];
          if(value == element.id){
            this.ProductService.selectShop = element.id;
            this.ProductService.descShop = element.id;
            break;
          }
        }
      }
    } else {
      this.ProductService.selectShop = null;
      this.ProductService.descShop = null;
    }
  }

  changeLocation(value) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_location = false;
    this.ErrorsProduct.txt_location = '';
    if (value) {
      for (const key in this.ProductService.LocationSelect) {
        if (this.ProductService.LocationSelect.hasOwnProperty(key)) {
          const element = this.ProductService.LocationSelect[key];
          if (value == element.id) {
            this.ProductService.selectLocation = element.id;
            this.ProductService.descLocation = element.id;
            break;
          }
        }
      }
    } else {
      this.ProductService.selectLocation = null;
      this.ProductService.descLocation = null;
    }
  }

  changeSupplier(value) {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_supplier = false;
    this.ErrorsProduct.txt_supplier = '';
    if (value) {
      for (const key in this.ProductService.SupplierSelect) {
        if (this.ProductService.SupplierSelect.hasOwnProperty(key)) {
          const element = this.ProductService.SupplierSelect[key];
          if(value == element.id){
            this.ProductService.selectSupplier = element.id;
            this.ProductService.descSupplier = element.id;
          }
        }
      }
    } else {
      this.ProductService.selectSupplier = null;
      this.ProductService.descSupplier = null;
    }
  }

  formatIdProSup() {
    this.idProdSupp = this.idProdSupp.toUpperCase();
    this.ErrorsProduct.error_idpssu = false;
    this.ErrorsProduct.txt_idpssu = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  formatBarcoBoxP() {
    this.barcoBoxP = this.barcoBoxP.toUpperCase();
    this.ErrorsProduct.error_cababo = false;
    this.ErrorsProduct.txt_cababo = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  formatBarcoDisP() {
    this.barcoDisP = this.barcoDisP.toUpperCase();
    this.ErrorsProduct.error_cabadi = false;
    this.ErrorsProduct.txt_cabadi = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  formatBarcoUniP() {
    this.barcoUniP = this.barcoUniP.toUpperCase();
    this.ErrorsProduct.error_cabaun = false;
    this.ErrorsProduct.txt_cabaun = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  formatDescBox() {
    this.descBoxProd = this.descBoxProd.toUpperCase();
    this.ErrorsProduct.error_descbox = false;
    this.ErrorsProduct.txt_descbox = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  formatDescUnit() {
    this.descUnitProd = this.descUnitProd.toUpperCase();
    this.ErrorsProduct.error_descunit = false;
    this.ErrorsProduct.txt_descunit = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
  }

  changeTypeProd() {
    if(this.nroTypeProd == 0){
      this.prodType = false;
      this.nroTypeProd = 0;
    }else{
      this.prodType = true;
      this.nroTypeProd = 1;
    }
  }

  undBox() {
    if(this.unitBoxProd){
      this.notEditVenta1 = false;
      this.notEditVenta2 = false;
      this.viewNot = false;
    }else{
      this.notEditVenta1 = true;
      this.notEditVenta2 = true;
      this.viewNot = true;
    }
    this.ErrorsProduct.error_unbox = false;
    this.ErrorsProduct.txt_unbox = '';
    this.venMinUndProd = 0;
    this.venMinBoxProd = 0;
  }

  focusUnd() {
    this.notEditVenta2 = true;
  }

  blurUnd() {
    this.notEditVenta2 = false;
  }

  changeVenMinBox() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_vemiun = false;
    this.ErrorsProduct.txt_vemiun = '';
    this.venMinUndProd = parseInt(String(this.venMinBoxProd * this.unitBoxProd));
  }

  focusBox() {
    this.notEditVenta1 = true;
  }

  blurBox() {
    this.notEditVenta1 = false;
  }

  changeVenMinUnd() {
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    this.ErrorsProduct.error_vemiun = false;
    this.ErrorsProduct.txt_vemiun = '';
    this.venMinBoxProd = this.venMinUndProd / this.unitBoxProd;
  }

  cargandoImagen(e) {
    var files = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.ErrorsProduct.error_changeimg = false;
    this.ErrorsProduct.txt_changeimg = '';
    this.ErrorsProduct.error_id = false;
    this.ErrorsProduct.txt_id = '';
    if (typeof files != 'undefined') {
      let valext = this.validatorExt(files.name);
      if (valext === true) {
        let valsize = this.validatorSize(files.size);
        if (valsize === true) {
          var reader = new FileReader();
          this.loaded = false;
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsDataURL(files);
          this.viewImg = true;
          this.viewNewImg = false;          
          this.formData.append('img', files, files.name);
          this.haveImg = true;
          for (var entrie of this.formData.entries()) {
            var images = entrie[0].split('-');
            if (images.length == 2 && images[0] == 'img') {
              this.totalImg++;
            }
          }
        } else {
          this.formData = new FormData();
          this.haveImg = false;
          this.totalImg = 0;
          this.ErrorsProduct.error_changeimg = true;
          this.ErrorsProduct.txt_changeimg = valsize;
        }
      } else {
        this.formData = new FormData();
        this.haveImg = false;
        this.totalImg = 0;
        this.ErrorsProduct.error_changeimg = true;
        this.ErrorsProduct.txt_changeimg = valext;
      }
    } else {
      this.formData = new FormData();
      this.haveImg = false;
      this.totalImg = 0;
      this.viewImg = false;
      this.viewNewImg = true;
    }
  }

  _handleReaderLoaded(e) {
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }

  delImg() {
    this.imageSrc = '';
    this.loaded = true;
    this.viewImg = false;
    this.viewNewImg = true;
    this.formData = new FormData();
    this.haveImg = false;
    this.totalImg = 0;
    this.deleteImagee = true;
    this.elmRef.nativeElement.querySelector('#file-upload').value = '';
  }
  
  validatorExt(name) {
    let extensionesValidas = ".png, .jfif, .jpeg, .jpg";
    let extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    let extensionValida = extensionesValidas.indexOf(extension);
    if(extensionValida < 0) {
      return 'Extensión no es válida Su archivo tiene de extensión: .' + extension;
    } else {
      return true;
    }
  }

  validatorSize(size) {
    let pesoFichero: any;
    let pesoPermitido = 512;
    pesoFichero = Number((size/1024).toFixed(2));
    if(pesoFichero > pesoPermitido){
      return 'Peso maximo permitido: ' + pesoPermitido + ' KBs Su archivo pesa: ' + pesoFichero +' KBs';
    }else{
      return true;
    }
    
  }

  closeModal() {
    this.formData = new FormData();
    this.haveImg = false;
    this.totalImg = 0;
    this.ProductService.closeModal.hide();
  }

  openModalOne(tipo, model, one = true, three = false, supplier = false) {
    let show_modal: any;
    let styleModal: any;
    let service: any;
    this.ProductService.typeModal = tipo;
    this.ProductService.modelo = model;
    if (one == false) {
      if (three) {
        if (supplier) {
          service = this.SupplierService;
          styleModal = 'modal-dialog-centered modal-xl mod_tab';
        } else {
          service = this.CatalogoThreeService;
          styleModal = 'modal-dialog-centered';
        }
      } else {
        service = this.CatalogoTwoService;
        styleModal = 'modal-dialog-centered';
      }
    } else {
      service = this.CatalogoOneService;
      styleModal = 'modal-dialog-centered zindex-one';
    }

    if (tipo === 'tipo') {
      show_modal = ModalTypeProductComponent;
    } else if (tipo === 'presentacion') {
      show_modal = ModalPresentationComponent;
    } else if (tipo === 'shop') {
      show_modal = ModalShopComponent;
    } else if (tipo === 'location') {
      show_modal = ModalLocationComponent;
    } else if (tipo === 'grupo') {
      show_modal = ModalGroupComponent;
    } else if (tipo === 'subgrupo') {
      show_modal = ModalSubgroupComponent;
    } else if (tipo === 'kind') {
      show_modal = ModalsLineComponent;
    } else if (tipo === 'supplier') {
      show_modal = ModalProveedorComponent;
    }

    const _combine = combineLatest(
      this.modalService.onHide
    ).subscribe(() => this.changeDetection.markForCheck());

    this.events.push(
      this.modalService.onHide.subscribe(() => {
        this.ProductService.typeModal = this.ProductService.typeModalBase;
        this.unsubscribe();
      })
    );
    
    this.events.push(_combine);

    this.modalRef = this.modalService.show(show_modal);
    this.modalRef.setClass(styleModal);
    service.closeModal = this.modalRef;
  }

  openExpirations() {
    this.modalRef = this.modalService.show(ExpirationsComponent);
    this.modalRef.setClass('modal-dialog-centered modal-xl mod-exp-prod');
    this.ProductService.closeModalExpira = this.modalRef;
  }

  openStatistics() {
    this.modalRef = this.modalService.show(StatisticsComponent);
    this.modalRef.setClass('modal-dialog-centered modal-lg mod-exp-prod');
    this.ProductService.closeModalStatistics = this.modalRef;
  }

  openExistence() {
    this.modalRef = this.modalService.show(ExistenceComponent);
    this.modalRef.setClass('modal-dialog-centered modal-lg mod-exp-prod');
    this.ProductService.closeModalExistence = this.modalRef;
  }

  unsubscribe() {
    this.events.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.events = [];
  }
  
  loadLots(pk) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: pk
      },
      url: this.listUrl.url('lots_price_producto')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.lotes_precios = resp.objects;
        this.list_lots = resp.lista;
      }
    });
  }

  changeOperation() {
    if (this.unitBoxProd > 0) {
      if (this.typeOp) {
        this.pvBase = parseFloat((this.pvBase / this.unitBoxProd).toFixed(2));
        this.pvA = parseFloat((this.pvA / this.unitBoxProd).toFixed(2));
        this.pvB = parseFloat((this.pvB / this.unitBoxProd).toFixed(2));
        this.pvC = parseFloat((this.pvC / this.unitBoxProd).toFixed(2));
        this.pvD = parseFloat((this.pvD / this.unitBoxProd).toFixed(2));
        this.pvE = parseFloat((this.pvE / this.unitBoxProd).toFixed(2));
        this.pviBase = parseFloat((this.pviBase / this.unitBoxProd).toFixed(2));
        this.pviA = parseFloat((this.pviA / this.unitBoxProd).toFixed(2));
        this.pviB = parseFloat((this.pviB / this.unitBoxProd).toFixed(2));
        this.pviC = parseFloat((this.pviC / this.unitBoxProd).toFixed(2));
        this.pviD = parseFloat((this.pviD / this.unitBoxProd).toFixed(2));
        this.pviE = parseFloat((this.pviE / this.unitBoxProd).toFixed(2));
      } else {
        this.pvBase = parseFloat((this.pvBase * this.unitBoxProd).toFixed(2));
        this.pvA = parseFloat((this.pvA * this.unitBoxProd).toFixed(2));
        this.pvB = parseFloat((this.pvB * this.unitBoxProd).toFixed(2));
        this.pvC = parseFloat((this.pvC * this.unitBoxProd).toFixed(2));
        this.pvD = parseFloat((this.pvD * this.unitBoxProd).toFixed(2));
        this.pvE = parseFloat((this.pvE * this.unitBoxProd).toFixed(2));
        this.pviBase = parseFloat((this.pviBase * this.unitBoxProd).toFixed(2));
        this.pviA = parseFloat((this.pviA * this.unitBoxProd).toFixed(2));
        this.pviB = parseFloat((this.pviB * this.unitBoxProd).toFixed(2));
        this.pviC = parseFloat((this.pviC * this.unitBoxProd).toFixed(2));
        this.pviD = parseFloat((this.pviD * this.unitBoxProd).toFixed(2));
        this.pviE = parseFloat((this.pviE * this.unitBoxProd).toFixed(2));
      }
    }
  }

  changeLot() {
    this.ErrorsProduct.error_utibase = false;
    this.ErrorsProduct.text_utibase = '';
    this.ErrorsProduct.error_utia = false;
    this.ErrorsProduct.text_utia = '';
    this.ErrorsProduct.error_utib = false;
    this.ErrorsProduct.text_utib = '';
    this.ErrorsProduct.error_utic = false;
    this.ErrorsProduct.text_utic = '';
    this.ErrorsProduct.error_utid = false;
    this.ErrorsProduct.text_utid = '';
    this.ErrorsProduct.error_utie = false;
    this.ErrorsProduct.text_utie = '';
    if (this.selectLot) {
      let i: any;
      this.typeOp = true;
      this.notSelectLot = false;
      for (const key in this.lotes_precios) {
        if (Object.prototype.hasOwnProperty.call(this.lotes_precios, key)) {
          const elem = this.lotes_precios[key];
          if (elem.pk == this.selectLot) {
            i = key;
            break;
          }
        }
      }
      this.validLotsBase = true;
      this.validLotsA = true;
      this.validLotsB = true;
      this.validLotsC = true;
      this.validLotsD = true;
      this.validLotsE = true;
      this.validLotspvBase = true;
      this.validLotspvA = true;
      this.validLotspvB = true;
      this.validLotspvC = true;
      this.validLotspvD = true;
      this.validLotspvE = true;
      for (const key in this.arrayErrorLots) {
        if (Object.prototype.hasOwnProperty.call(this.arrayErrorLots, key)) {
          const elem = this.arrayErrorLots[key];
          if (elem.pk == this.selectLot) {
            if (elem.error_utibase) {
              this.ErrorsProduct.error_utibase = true;
              this.ErrorsProduct.text_utibase = elem.txt_utibase;
              this.validLotsBase = false;
            }
            if (elem.error_utia) {
              this.ErrorsProduct.error_utia = true;
              this.ErrorsProduct.text_utia = elem.txt_utia;
              this.validLotsA = false;
            }
            if (elem.error_utib) {
              this.ErrorsProduct.error_utib = true;
              this.ErrorsProduct.text_utib = elem.txt_utib;
              this.validLotsB = false;
            }
            if (elem.error_utic) {
              this.ErrorsProduct.error_utic = true;
              this.ErrorsProduct.text_utic = elem.txt_utic;
              this.validLotsC = false;
            }
            if (elem.error_utid) {
              this.ErrorsProduct.error_utid = true;
              this.ErrorsProduct.text_utid = elem.txt_utid;
              this.validLotsD = false;
            }
            if (elem.error_utie) {
              this.ErrorsProduct.error_utie = true;
              this.ErrorsProduct.text_utie = elem.txt_utie;
              this.validLotsE = false;
            }

            if (elem.error_pvbase) {
              this.ErrorsProduct.error_pvbase = true;
              this.ErrorsProduct.text_pvbase = elem.text_pvbase;
              this.validLotspvBase = false;
            }
            if (elem.error_pva) {
              this.ErrorsProduct.error_pva = true;
              this.ErrorsProduct.text_pva = elem.text_pva;
              this.validLotspvA = false;
            }
            if (elem.error_pvb) {
              this.ErrorsProduct.error_pvb = true;
              this.ErrorsProduct.text_pvb = elem.text_pvb;
              this.validLotspvB = false;
            }
            if (elem.error_pvc) {
              this.ErrorsProduct.error_pvc = true;
              this.ErrorsProduct.text_pvc = elem.text_pvc;
              this.validLotspvC = false;
            }
            if (elem.error_pvd) {
              this.ErrorsProduct.error_pvd = true;
              this.ErrorsProduct.text_pvd = elem.text_pvd;
              this.validLotspvD = false;
            }
            if (elem.error_pve) {
              this.ErrorsProduct.error_pve = true;
              this.ErrorsProduct.text_pve = elem.text_pve;
              this.validLotspvE = false;
            }
            break;
          }
        }
      }
      this.UltCUPrice = this.lotes_precios[i].costo;
      if (this.unitBoxProd > 0) {
        this.UltCCPrice = this.unitBoxProd * this.UltCUPrice;
      } else {
        this.UltCCPrice = this.UltCUPrice;
      }
      
      this.utilityBase = this.lotes_precios[i].utilidad;
      this.pvBase = this.lotes_precios[i].base;
      const PriceVI = (this.pvBase*(this.utilityBase/100))+this.pvBase;
      this.pviBase = parseFloat(PriceVI.toFixed(2));

      if (this.lotes_precios[i].a == null) {
        this.pvA = this.pvBase;
        this.utilityA = this.utilityBase;
        this.pviA = this.pviBase;
      } else {
        this.pvA = this.lotes_precios[i].a;
        const utA = (((this.pvA-this.UltCUPrice)/this.UltCUPrice)*100);
        this.utilityA = parseFloat(utA.toFixed(2));
        const PriceAVI = (this.pvA*(utA/100))+this.pvA;
        this.pviA = parseFloat(PriceAVI.toFixed(2));
      }

      if (this.lotes_precios[i].b == null) {
        this.pvB = this.pvBase;
        this.utilityB = this.utilityBase;
        this.pviB = this.pviBase;
      } else {
        this.pvB = this.lotes_precios[i].b;
        const utB = (((this.pvB-this.UltCUPrice)/this.UltCUPrice)*100);
        this.utilityB = parseFloat(utB.toFixed(2));
        const PriceBVI = (this.pvB*(utB/100))+this.pvB;
        this.pviB = parseFloat(PriceBVI.toFixed(2));
      }

      if (this.lotes_precios[i].c == null) {
        this.pvC = this.pvBase;
        this.utilityC = this.utilityBase;
        this.pviC = this.pviBase;
      } else {
        this.pvC = this.lotes_precios[i].c;
        const utC = (((this.pvC-this.UltCUPrice)/this.UltCUPrice)*100);
        this.utilityC = parseFloat(utC.toFixed(2));
        const PriceCVI = (this.pvC*(utC/100))+this.pvC;
        this.pviC = parseFloat(PriceCVI.toFixed(2));
      }

      if (this.lotes_precios[i].d == null) {
        this.pvD = this.pvBase;
        this.utilityD = this.utilityBase;
        this.pviD = this.pviBase;
      } else {
        this.pvD = this.lotes_precios[i].d;
        const utD = (((this.pvD-this.UltCUPrice)/this.UltCUPrice)*100);
        this.utilityD = parseFloat(utD.toFixed(2));
        const PriceDVI = (this.pvD*(utD/100))+this.pvD;
        this.pviD = parseFloat(PriceDVI.toFixed(2));
      }

      if (this.lotes_precios[i].e == null) {
        this.pvE = this.pvBase;
        this.utilityE = this.utilityBase;
        this.pviE = this.pviBase;
      } else {
        this.pvE = this.lotes_precios[i].e;
        const utE = (((this.pvE-this.UltCUPrice)/this.UltCUPrice)*100);
        this.utilityE = parseFloat(utE.toFixed(2));
        const PriceEVI = (this.pvE*(utE/100))+this.pvE;
        this.pviE = parseFloat(PriceEVI.toFixed(2));
      }
      
      if (this.validLotsBase && this.validLotsA && this.validLotsB && this.validLotsC && this.validLotsD && this.validLotsE && this.validLotspvBase && this.validLotspvA && this.validLotspvB && this.validLotspvC && this.validLotspvD && this.validLotspvE) {
        this.validLots = false;
      } else {
        this.validLots = true;
      }
    } else {
      this.cleanLot();
    }
  }

  cleanLot(lot=true) {
    if (!lot) {
      this.selectLot = '';
    }
    this.notSelectLot = true;
    this.typeOp = true;
    this.UltCCPrice = 0;
    this.UltCUPrice = 0;
    this.pctBase = 0;
    this.pctA = 0;
    this.pctB = 0;
    this.pctC = 0;
    this.pctD = 0;
    this.pctE = 0;
    this.lastCostBase = 0;
    this.lastCostA = 0;
    this.lastCostB = 0;
    this.lastCostC = 0;
    this.lastCostD = 0;
    this.lastCostE = 0;
    this.utilityBase = 0;
    this.utilityA = 0;
    this.utilityB = 0;
    this.utilityC = 0;
    this.utilityD = 0;
    this.utilityE = 0;
    this.pvBase = 0;
    this.pvA = 0;
    this.pvB = 0;
    this.pvC = 0;
    this.pvD = 0;
    this.pvE = 0;
    this.pviBase = 0;
    this.pviA = 0;
    this.pviB = 0;
    this.pviC = 0;
    this.pviD = 0;
    this.pviE = 0;
  }

  changeProfit(tipo) {
    let Cost: number;
    if (this.typeOp) {
      Cost = this.UltCUPrice;
    } else {
      Cost = this.UltCCPrice;
    }
    if (tipo === 'base') {
      this.ErrorsProduct.error_utibase = false;
      this.validLotsBase = true;
      if (this.UtiMinPrice > this.utilityBase) {
        this.validLotsBase = false;
        this.validLots = true;
        this.ErrorsProduct.error_utibase = true;
        this.ErrorsProduct.text_utibase = 'Valor Minimo "'+this.UtiMinPrice+'".';
      }
      const PV = Cost+(Cost*(this.utilityBase/100))
      this.pvBase = parseFloat(PV.toFixed(2));
      const PVI = (this.pvBase*(this.utilityBase/100))+this.pvBase;
      this.pviBase = parseFloat(PVI.toFixed(2));
    } else if (tipo === 'a') {
      this.ErrorsProduct.error_utia = false;
      this.validLotsA = true;
      if (this.UtiMinPrice > this.utilityA) {
        this.validLotsA = false;
        this.validLots = true;
        this.ErrorsProduct.error_utia = true;
        this.ErrorsProduct.text_utia = 'Valor Minimo "'+this.UtiMinPrice+'".';
      }
      const PV = Cost+(Cost*(this.utilityA/100))
      this.pvA = parseFloat(PV.toFixed(2));
      const PVI = (this.pvA*(this.utilityA/100))+this.pvA;
      this.pviA = parseFloat(PVI.toFixed(2));
    } else if (tipo === 'b') {
      this.ErrorsProduct.error_utib = false;
      this.validLotsB = true;
      if (this.UtiMinPrice > this.utilityB) {
        this.validLotsB = false;
        this.validLots = true;
        this.ErrorsProduct.error_utib = true;
        this.ErrorsProduct.text_utib = 'Valor Minimo "'+this.UtiMinPrice+'".';
      }
      const PV = Cost+(Cost*(this.utilityB/100))
      this.pvB = parseFloat(PV.toFixed(2));
      const PVI = (this.pvB*(this.utilityB/100))+this.pvB;
      this.pviB = parseFloat(PVI.toFixed(2));
    } else if (tipo === 'c') {
      this.ErrorsProduct.error_utic = false;
      this.validLotsC = true;
      if (this.UtiMinPrice > this.utilityC) {
        this.validLotsC = false;
        this.validLots = true;
        this.ErrorsProduct.error_utic = true;
        this.ErrorsProduct.text_utic = 'Valor Minimo "'+this.UtiMinPrice+'".';
      }
      const PV = Cost+(Cost*(this.utilityC/100))
      this.pvC = parseFloat(PV.toFixed(2));
      const PVI = (this.pvC*(this.utilityC/100))+this.pvC;
      this.pviC = parseFloat(PVI.toFixed(2));
    } else if (tipo === 'd') {
      this.ErrorsProduct.error_utid = false;
      this.validLotsD = true;
      if (this.UtiMinPrice > this.utilityD) {
        this.validLotsD = false;
        this.validLots = true;
        this.ErrorsProduct.error_utid = true;
        this.ErrorsProduct.text_utid = 'Valor Minimo "'+this.UtiMinPrice+'".';
      }
      const PV = Cost+(Cost*(this.utilityD/100))
      this.pvD = parseFloat(PV.toFixed(2));
      const PVI = (this.pvD*(this.utilityD/100))+this.pvD;
      this.pviD = parseFloat(PVI.toFixed(2));
    } else if (tipo === 'e') {
      this.ErrorsProduct.error_utie = false;
      this.validLotsE = true;
      if (this.UtiMinPrice > this.utilityE) {
        this.validLotsE = false;
        this.validLots = true;
        this.ErrorsProduct.error_utie = true;
        this.ErrorsProduct.text_utie = 'Valor Minimo "'+this.UtiMinPrice+'".';
      }
      const PV = Cost+(Cost*(this.utilityE/100))
      this.pvE = parseFloat(PV.toFixed(2));
      const PVI = (this.pvE*(this.utilityE/100))+this.pvE;
      this.pviE = parseFloat(PVI.toFixed(2));
    }
    if (this.validLotsBase && this.validLotsA && this.validLotsB && this.validLotsC && this.validLotsD && this.validLotsE && this.validLotspvBase && this.validLotspvA && this.validLotspvB && this.validLotspvC && this.validLotspvD && this.validLotspvE) {
      this.validLots = false;
    }
  }

  changePV(tipo) {
    if (tipo === 'base') {
      const PVI = (this.pvBase*(this.utilityBase/100))+this.pvBase;
      this.validLotspvBase = true;
      this.pviBase = parseFloat(PVI.toFixed(2));
      this.ErrorsProduct.error_pvbase = false;
      this.ErrorsProduct.text_pvbase = '';
    } else if (tipo === 'a') {
      const PVI = (this.pvA*(this.utilityA/100))+this.pvA;
      this.validLotspvA = true;
      this.pviA = parseFloat(PVI.toFixed(2));
      this.ErrorsProduct.error_pva = false;
      this.ErrorsProduct.text_pva = '';
    } else if (tipo === 'b') {
      const PVI = (this.pvB*(this.utilityB/100))+this.pvB;
      this.validLotspvB = true;
      this.pviB = parseFloat(PVI.toFixed(2));
      this.ErrorsProduct.error_pvb = false;
      this.ErrorsProduct.text_pvb = '';
    } else if (tipo === 'c') {
      const PVI = (this.pvC*(this.utilityC/100))+this.pvC;
      this.validLotspvC = true;
      this.pviC = parseFloat(PVI.toFixed(2));
      this.ErrorsProduct.error_pvc = false;
      this.ErrorsProduct.text_pvc = '';
    } else if (tipo === 'd') {
      const PVI = (this.pvD*(this.utilityD/100))+this.pvD;
      this.validLotspvD = true;
      this.pviD = parseFloat(PVI.toFixed(2));
      this.ErrorsProduct.error_pvd = false;
      this.ErrorsProduct.text_pvd = '';
    } else if (tipo === 'e') {
      const PVI = (this.pvE*(this.utilityE/100))+this.pvE;
      this.validLotspvE = true;
      this.pviE = parseFloat(PVI.toFixed(2));
      this.ErrorsProduct.error_pve = false;
      this.ErrorsProduct.text_pve = '';
    }
    if (this.validLotsBase && this.validLotsA && this.validLotsB && this.validLotsC && this.validLotsD && this.validLotsE && this.validLotspvBase && this.validLotspvA && this.validLotspvB && this.validLotspvC && this.validLotspvD && this.validLotspvE) {
      this.validLots = false;
    }
  }

  changePVI(tipo) {
    if (tipo === 'base') {
      const PV = (this.pviBase/(1+(this.utilityBase/100)));
      this.validLotspvBase = true;
      this.pvBase = parseFloat(PV.toFixed(2));
      this.ErrorsProduct.error_pvbase = false;
      this.ErrorsProduct.text_pvbase = '';
    } else if (tipo === 'a') {
      const PV = (this.pviA/(1+(this.utilityA/100)));
      this.validLotspvA = true;
      this.pvA = parseFloat(PV.toFixed(2));
      this.ErrorsProduct.error_pva = false;
      this.ErrorsProduct.text_pva = '';
    } else if (tipo === 'b') {
      const PV = (this.pviB/(1+(this.utilityB/100)));
      this.validLotspvB = true;
      this.pvB = parseFloat(PV.toFixed(2));
      this.ErrorsProduct.error_pvb = false;
      this.ErrorsProduct.text_pvb = '';
    } else if (tipo === 'c') {
      const PV = (this.pviC/(1+(this.utilityC/100)));
      this.validLotspvC = true;
      this.pvC = parseFloat(PV.toFixed(2));
      this.ErrorsProduct.error_pvc = false;
      this.ErrorsProduct.text_pvc = '';
    } else if (tipo === 'd') {
      const PV = (this.pviD/(1+(this.utilityD/100)));
      this.validLotspvD = true;
      this.pvD = parseFloat(PV.toFixed(2));
      this.ErrorsProduct.error_pvd = false;
      this.ErrorsProduct.text_pvd = '';
    } else if (tipo === 'e') {
      const PV = (this.pviE/(1+(this.utilityE/100)));
      this.validLotspvE = true;
      this.pvE = parseFloat(PV.toFixed(2));
      this.ErrorsProduct.error_pve = false;
      this.ErrorsProduct.text_pve = '';
    }
    if (this.validLotsBase && this.validLotsA && this.validLotsB && this.validLotsC && this.validLotsD && this.validLotsE && this.validLotspvBase && this.validLotspvA && this.validLotspvB && this.validLotspvC && this.validLotspvD && this.validLotspvE) {
      this.validLots = false;
    }
  }

  changeUtiMin() {
    this.ErrorPrices = false;
    this.ErrorPreciosTxt = '';
    this.ErrorsTablesShow = false;
    this.arrayErrorLots = [];
    this.ErrorsTablesProd = [];
    if (this.UtiMinPrice > this.UtiMinPriceOri) {
      for (const key in this.lotes_precios) {
        if (Object.prototype.hasOwnProperty.call(this.lotes_precios, key)) {
          const elem = this.lotes_precios[key];
          let obj_error = new Object();
          let cost = elem.costo;
          let price_a: number;
          let price_b: number;
          let price_c: number;
          let price_d: number;
          let price_e: number;
          let utiBase = elem.utilidad;
          if (elem.a == null) {
            price_a = elem.base;
          } else {
            price_a = elem.a
          }
          if (elem.b == null) {
            price_b = elem.base;
          } else {
            price_b = elem.b
          }
          if (elem.c == null) {
            price_c = elem.base;
          } else {
            price_c = elem.c
          }
          if (elem.d == null) {
            price_d = elem.base;
          } else {
            price_d = elem.d
          }
          if (elem.e == null) {
            price_e = elem.base;
          } else {
            price_e = elem.e
          }
          let utiA = parseFloat((((price_a-cost)/cost)*100).toFixed(2));
          let utiB = parseFloat((((price_b-cost)/cost)*100).toFixed(2));
          let utiC = parseFloat((((price_c-cost)/cost)*100).toFixed(2));
          let utiD = parseFloat((((price_d-cost)/cost)*100).toFixed(2));
          let utiE = parseFloat((((price_e-cost)/cost)*100).toFixed(2));
          
          if (utiBase < this.UtiMinPrice) {
            obj_error['error_utibase'] = true;
            obj_error['txt_utibase'] = 'Valor Minimo "'+this.UtiMinPrice+'".';
          }

          if (utiA < this.UtiMinPrice) {
            obj_error['error_utia'] = true;
            obj_error['txt_utia'] = 'Valor Minimo "'+this.UtiMinPrice+'".';
          }

          if (utiB < this.UtiMinPrice) {
            obj_error['error_utib'] = true;
            obj_error['txt_utib'] = 'Valor Minimo "'+this.UtiMinPrice+'".';
          }

          if (utiC < this.UtiMinPrice) {
            obj_error['error_utic'] = true;
            obj_error['txt_utic'] = 'Valor Minimo "'+this.UtiMinPrice+'".';
          }

          if (utiD < this.UtiMinPrice) {
            obj_error['error_utid'] = true;
            obj_error['txt_utid'] = 'Valor Minimo "'+this.UtiMinPrice+'".';
          }

          if (utiE < this.UtiMinPrice) {
            obj_error['error_utie'] = true;
            obj_error['txt_utie'] = 'Valor Minimo "'+this.UtiMinPrice+'".';
          }

          if (Object.keys(obj_error).length > 0) {
            obj_error['pk'] = elem.pk;
            obj_error['lote'] = elem.lote;
            this.arrayErrorLots.push(obj_error);
          }
        }
      }
      if (this.arrayErrorLots.length > 0) {
        this.addErrorLoteAlert();
        this.validLotPrice = true;
        this.ErrorsProduct.error_utimin = true;
        this.ErrorsProduct.text_utimin = 'Modifique % Utilidad de los tipos de precios.';
        if (this.selectLot) {
          for (const key in this.arrayErrorLots) {
            if (Object.prototype.hasOwnProperty.call(this.arrayErrorLots, key)) {
              const elem = this.arrayErrorLots[key];
              if (elem.pk == this.selectLot) {
                if (elem.error_utibase) {
                  this.ErrorsProduct.error_utibase = true;
                  this.ErrorsProduct.text_utibase = elem.txt_utibase;
                  this.validLotsBase = false;
                }
                if (elem.error_utia) {
                  this.ErrorsProduct.error_utia = true;
                  this.ErrorsProduct.text_utia = elem.txt_utia;
                  this.validLotsA = false;
                }
                if (elem.error_utib) {
                  this.ErrorsProduct.error_utib = true;
                  this.ErrorsProduct.text_utib = elem.txt_utib;
                  this.validLotsB = false;
                }
                if (elem.error_utic) {
                  this.ErrorsProduct.error_utic = true;
                  this.ErrorsProduct.text_utic = elem.txt_utic;
                  this.validLotsC = false;
                }
                if (elem.error_utid) {
                  this.ErrorsProduct.error_utid = true;
                  this.ErrorsProduct.text_utid = elem.txt_utid;
                  this.validLotsD = false;
                }
                if (elem.error_utie) {
                  this.ErrorsProduct.error_utie = true;
                  this.ErrorsProduct.text_utie = elem.txt_utie;
                  this.validLotsE = false;
                }
                if (this.validLotsBase && this.validLotsA && this.validLotsB && this.validLotsC && this.validLotsD && this.validLotsE ) {
                  this.validLots = false;
                } else {
                  this.validLots = true;
                }
                break;
              }
            }
          }
        }
      } else {
        this.validLotPrice = false;
        this.ErrorsProduct.error_utimin = false;
        this.ErrorsProduct.text_utimin = '';
      }
    } else if (this.UtiMinPrice <= this.UtiMinPriceOri) {
      this.validLotPrice = false;
      this.ErrorsProduct.error_utimin = false;
      this.ErrorsProduct.text_utimin = '';
      if (this.selectLot) {
        this.ErrorsProduct.error_utibase = false;
        this.ErrorsProduct.text_utibase = '';
        this.ErrorsProduct.error_utia = false;
        this.ErrorsProduct.text_utia = '';
        this.ErrorsProduct.error_utib = false;
        this.ErrorsProduct.text_utib = '';
        this.ErrorsProduct.error_utic = false;
        this.ErrorsProduct.text_utic = '';
        this.ErrorsProduct.error_utid = false;
        this.ErrorsProduct.text_utid = '';
        this.ErrorsProduct.error_utie = false;
        this.ErrorsProduct.text_utie = '';
      }
    }
  }

  addErrorLoteAlert() {
    this.ErrorPrices = true;
    this.ErrorPreciosTxt = 'Lo siento, el % Utilidad Mínima no puede ser mayor a las siguientes:';
    this.ErrorsTablesShow = true;
    for (const it of this.arrayErrorLots) {
      if (it.error_utibase) {
        this.ErrorsTablesProd.push({error: '%Utilidad de Precio Base en Lote <strong>'+it.lote+'</strong>', 'pk': it.pk, 'tipo': 'base'});
      }

      if (it.error_utia) {
        this.ErrorsTablesProd.push({error: '%Utilidad de Precio A en Lote <strong>'+it.lote+'</strong>', 'pk': it.pk, 'tipo': 'a'});
      }

      if (it.error_utib) {
        this.ErrorsTablesProd.push({error: '%Utilidad de Precio B en Lote <strong>'+it.lote+'</strong>', 'pk': it.pk, 'tipo': 'b'});
      }

      if (it.error_utic) {
        this.ErrorsTablesProd.push({error: '%Utilidad de Precio C en Lote <strong>'+it.lote+'</strong>', 'pk': it.pk, 'tipo': 'c'});
      }

      if (it.error_utid) {
        this.ErrorsTablesProd.push({error: '%Utilidad de Precio D en Lote <strong>'+it.lote+'</strong>', 'pk': it.pk, 'tipo': 'd'});
      }

      if (it.error_utie) {
        this.ErrorsTablesProd.push({error: '%Utilidad de Precio E en Lote <strong>'+it.lote+'</strong>', 'pk': it.pk, 'tipo': 'e'});
      }
    }
  }

  onClosedError() {
    this.ErrorsTablesShow = false;
  }

  viewErrorLot() {
    if (this.ErrorsTablesProd.length > 0) {
      this.ErrorsTablesShow = true;
    }
  }

  loadPrice() {
    if (!this.typeOp) {
      this.pvBase = parseFloat((this.pvBase / this.unitBoxProd).toFixed(2));
      this.pvA = parseFloat((this.pvA / this.unitBoxProd).toFixed(2));
      this.pvB = parseFloat((this.pvB / this.unitBoxProd).toFixed(2));
      this.pvC = parseFloat((this.pvC / this.unitBoxProd).toFixed(2));
      this.pvD = parseFloat((this.pvD / this.unitBoxProd).toFixed(2));
      this.pvE = parseFloat((this.pvE / this.unitBoxProd).toFixed(2));
    }
    for (const key in this.lotes_precios) {
      if (Object.prototype.hasOwnProperty.call(this.lotes_precios, key)) {
        const elem = this.lotes_precios[key];
        if (elem.pk == this.selectLot) {
          elem.utilidad = this.utilityBase;
          elem.base = this.pvBase;
          elem.a = this.pvA;
          elem.b = this.pvB;
          elem.c = this.pvC;
          elem.d = this.pvD;
          elem.e = this.pvE;
          elem.edited = true;
          break;
        }
      }
    }
    for (const key in this.arrayErrorLots) {
      if (Object.prototype.hasOwnProperty.call(this.arrayErrorLots, key)) {
        const elem = this.arrayErrorLots[key];
        if (elem.pk == this.selectLot) {
          this.arrayErrorLots.splice(Number(key), 1);
        }
      }
    }
    const long = this.ErrorsTablesProd.length;
    if (long > 0) {
      for (let i = long-1; i >= 0; i--) {
        const elem = this.ErrorsTablesProd[i];
        if (elem.pk == this.selectLot) {
          this.ErrorsTablesProd.splice(i, 1);
        }
      }
    }
    if (this.arrayErrorLots.length == 0 && this.ErrorsTablesProd.length == 0) {
      this.validLotPrice = false;
      this.ErrorsTablesShow = false;
      this.ErrorPrices = false;
      this.ErrorsProduct.error_utimin = false;
      this.ErrorsProduct.text_utimin = '';
      this.UtiMinPriceOri = this.UtiMinPrice;
    }
    this.cleanLot(false);
  }

}
