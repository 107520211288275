import { EventEmitter, Injectable } from '@angular/core';
import { Product, Products } from './products';
import { OneSelect } from '../modals-double/one-select';

@Injectable({
  providedIn: 'root'
})
export class InventaryProductService {

  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  urlreturn: boolean | string;
  habilitaBtn: boolean = false;
  new: boolean;
  pk: number | string;
  valuesObjects: Products;
  valuesObjectsBase: Products;
  closeModal: any;
  closeModalExpira: any;
  closeModalExistence: any;
  closeModalStatistics: any;
  typeModal: any;
  typeModalBase: any;
  modelo: string | null;
  modeloBase: string | null;
  GroupSelect: OneSelect;
  selectIdGroup: boolean = false;
  selectGroup: number | null = null;
  descGroup: number | null = null;
  TypeSelect: OneSelect;
  selectIdType: boolean = false;
  selectType: number | null = null;
  descType: number | null = null;
  PresentationSelect: OneSelect;
  selectIdPresentation: boolean = false;
  selectPresentation: number | null = null;
  descPresentation: number | null = null;
  ShopSelect: OneSelect;
  selectIdShop: boolean = false;
  selectShop: number | null = null;
  descShop: number | null = null;
  LocationSelect: OneSelect;
  selectIdLocation: boolean = false;
  selectLocation: number | null = null;
  descLocation: number | null = null;
  SubgroupSelect: OneSelect;
  selectIdSubgroup: boolean = false;
  selectSubgroup: number | null = null;
  descSubgroup: number | null = null;
  KindSelect: OneSelect;
  selectIdKind: boolean = false;
  selectKind: number | null = null;
  descKind: number | null = null;
  TaxSelect: OneSelect;
  selectIdTax: boolean = false;
  selectTax: number | null;
  SupplierSelect: OneSelect;
  selectIdSupplier: boolean = false;
  selectSupplier: number | null = null;
  descSupplier: number | null = null;
  selectedGroup: boolean = false;
  selectedSubGroup: boolean = false;
  requeridLine: boolean;
  requeridTipo: boolean;
  requeridPres: boolean;
  addCode: string;
  editCode: string;
  object: Product;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor(){ }
}
