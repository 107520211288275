import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Config } from "protractor";
import { Ajax } from "src/app/core/ajax/ajax";
import { Init } from "src/app/core/ajax/init-ajax";
import { AuthService } from "src/app/core/auth/auth.service";
import Swal from "sweetalert2";
import { CatalogoOneService } from "../../services/catalogo-one.service";
import { CatalogotwoService } from "../../services/catalogotwo.service";
import { CatalogoThreeService } from "../../services/catalogo-three.service";
import { CatalogoFourthService } from "../../../puntodeventa/services/catalogo-fourth.service";
import { isBoolean } from "util";

@Component({
  selector: "app-modal-vehicle",
  inputs: [
    "titleModal",
    "labelCode",
    "placeholderCode",
    "labelDesc",
    "placeholderDesc",
    "typeModal",
    "nameModal",
    "maxCode",
    "maxDesc",
    "titleSave",
    "typeInput",
  ],
  templateUrl: "./modal-vehicle.component.html",
  styleUrls: [
    "./modal-vehicle.component.css",
    "../../modals-one/modals-one.component.css",
  ],
})
export class ModalVehicleComponent implements OnInit {
  titleSave: string;
  controller: FormGroup;
  titleModal: string;
  fields: any[];
  error_id:string;
  txt_id:string;
  error_code:string;
  txt_code:string;
  error_desc:string;
  txt_desc:string;
  entity = {
    car_registration: {
      label: `Placa`,
      ph: `Placa`,
      amount: `4`,
      lengthMessage: `Ingrese maximo 20 caracteres.`,
    },
    description: {
      label: `Descripcion`,
      ph: `Descripcion de Vehiculo`,
      amount: `8`,
      lengthMessage: `Ingrese maximo 80 caracteres.`,
    },
    holder: {
      label: `Titular`,
      ph: ``,
      amount: `12`,
      lengthMessage: `Ingrese maximo 60 caracteres.`,
    },
    capacity: {
      label: `Capacidad`,
      ph: ``,
      amount: `12`,
      lengthMessage: `Ingrese maximo 18 digitos.`,
      pattern: `Ingrese solo numeros de hasta 8 decimales.`,
    },
  };

  constructor(
    public CatalogoTwoService: CatalogotwoService,
    public CatalogoThreeService: CatalogoThreeService,
    public CatalogoFourthService: CatalogoFourthService,
    public auth: AuthService,
    private CatalogoOneService: CatalogoOneService,
    private _FormBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.initController();
  }

  initController = () => {
    const value = this.CatalogoOneService.newMU?null:this.CatalogoOneService.object;
    this.fields = [];
    this.controller = new FormGroup({});
    let validators: ValidatorFn[];
    Object.keys(this.entity).forEach((field: string, i: number) => {
      validators = [];
      validators = this.retrieveValidators(field);
      this.controller.addControl(
        field,
        this._FormBuilder.control(value ? value[field] : null, validators)
      );
      const { label, ph, amount } = this.entity[field];
      if (i > 1) this.fields.push({ name: field, label, ph, amount });
    });
    this.controller.addControl(
      "id_vehicle_catalog",
      this._FormBuilder.control(value ? value[`id_vehicle_catalog`] : null)
    );
    this.controller.addControl(
      "active",
      this._FormBuilder.control(
        value ? value[`active`] : true,
        this.BooleanValidator
      )
    );
  };

  retrieveValidators = (field: string): ValidatorFn[] => {
    let validators: ValidatorFn[] = [];
    switch (field) {
      case "car_registration":
        validators.push(Validators.maxLength(20));
        break;
      case "description":
        validators.push(Validators.maxLength(80));
        break;
      case "holder":
        validators.push(Validators.maxLength(60));
        break;
      case "capacity":
        validators.push(Validators.pattern(/^\d*(?:[.]\d{1,15})?$/));
        validators.push(Validators.maxLength(18));
        validators.push(Validators.min(0));
        break;
    }
    return validators;
  };

  BooleanValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return isBoolean(value)
      ? null
      : {
          invalidBooleanField: true,
        };
  };

  saveObject() {
    this.controller.markAllAsTouched();
    if (this.controller.invalid) return;
    const value = this.controller.value;
    let id_obj: any;
    if (this.CatalogoOneService.newMU) {
      id_obj = "";
    } else {
      id_obj = this.CatalogoOneService.pk;
    }
    const initAjax: Init = {
      method: "post",
      url: this.CatalogoOneService.urlSave,
      auth: this.auth,
      body: { ...value },
    };
    const configAjax: Config = {
      numInten: 1,
      titleLoading: `Guardando ${this.titleSave}`,
      titleSuccess: "",
      titleErrorNormal: "Error",
      textErrorNormal: "Ha ocurrido un Error.",
      confirmButtonTextError: "Cerrar",
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError: Swal.mixin({
                icon: "error",
                title: "Ocurrio un error",
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: "Cerrar",
              }),
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      },
    };

    const ajax = new Ajax(initAjax, configAjax);
    ajax
      .call()
      .then((resp) => {
        if (resp.success) {
          this.CatalogoOneService.continuar = true;
          this.CatalogoOneService.evenAdd.emit(resp);
          this.CatalogoOneService.closeModal.hide();
        }
      })
      .catch((error) => {
        this.viewError(error);
      });
  }

  closeModal() {
    this.CatalogoOneService.closeModal.hide();
  }

  newObject(resp) {
    this.CatalogoOneService.valuesObjects.unshift(resp.objeto);
    this.CatalogoOneService.valuesObjectsBase = this.CatalogoOneService.valuesObjects;
    this.CatalogoOneService.habilitaBtn = true;
  }

  // editObject(result) {
  //   const values = this.CatalogoOneService.valuesObjects;
  //   for (let i = 0; i < values.length; i++) {
  //     if (
  //       values[i][`id_vehicle_catalog`] === result.objeto[`id_vehicle_catalog`]
  //     ) {
  //       values[i] = result.objeto;
  //     }
  //   }
  //   this.CatalogoOneService.valuesObjectsBase = this.CatalogoOneService.valuesObjects;
  // }
  
  viewError(error) {
    if (error.error_id !== "undefined") {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== "undefined") {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== "undefined") {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }
  }

  onFormError(field: string) {
    const control: AbstractControl = this.controller.get(field);
    const [error] = Object.keys(control.errors || {});
    if (!control.touched) return ``;
    const message = this.entity[field];
    switch (error) {
      case "required":
        return message["requiredMessage"] || "Campo es requerido.";
      case "maxlength":
        return message["lengthMessage"];
      case "min":
        return "El valor no puede ser menor a 0.";
      case "pattern":
        return message["pattern"] || `El valor ingresado es invalido`;
      default:
        return ``;
    }
  }
}
