import { Component, OnInit } from '@angular/core';
import { CatalogoFuerzadeventaAsistenteService } from '../../../services/catalogo-fuerzadeventa-asistente.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';


@Component({
  selector: 'app-modal-asistente',
  inputs: [
    'titleModal',
    'campoCode',
    'placeholderCode',
    'campoName',
    'placeholderNomb',
    'campoNit',
    'placeholderNit',
    'campoTlf',
    'placeholderTlf',
    'campoDir',
    'placeholderDir',
    'campoAct',
    'typeModal',
    'nameModal',
    'maxCode',
    'titleSave',
    'typeInput'],
  
  templateUrl: './modal-asistente.component.html',
  styleUrls: ['./modal-asistente.component.css','./style.scss']
})

export class ModalAsistenteComponent implements OnInit {
	titleModal = 'Agregar Asistente';
	campoCode = 'Codigo';
	placeholderCode = 'Codigo';
	campoName = 'Nombre';
	placeholderNomb = "Nombre";
	campoNit = 'Nit';
	placeholderNit = "Nit";
	campoTlf = 'Telefono';
	placeholderTlf = "Telefono";
	campoDir = 'Direccion';
	placeholderDir = "Direccion";
  	formAsistente: FormGroup;
	codeMO: string | null;
  	nombMO: string | null;
  	nitMO: string | null;
  	tlfMO: string | null;
	dirMO: string | null;
	campoAct: boolean = false;
	maxCode = 10;
 	maxNomb = 60;
 	maxNit = 20;
 	maxPhone = 50;
 	maxDir = 255;
 	error_id = false;
  	txt_id: string | null;
  	error_code = false;
  	txt_code: string | null;
  	error_nomb = false;
  	txt_nomb: string | null;
  	error_tlf = false;
  	txt_tlf: string | null;
  	error_nit = false;
  	txt_nit: string | null;
  	error_dir = false;
  	txt_dir: string | null;
  	titleSave = "Fuerza de Venta-Asistente";
  	typeInput: boolean = true;
  	inputType: string;
  	codeId: string;
    y: number | string



  constructor(public CatalogoFuerzadeventaAsistenteService : CatalogoFuerzadeventaAsistenteService,
  		public auth: AuthService, 
   		private listUrl: UrListService
    ) {

  	this.formAsistente = new FormGroup({
      mocode: new FormControl(this.codeMO, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      monomb: new FormControl(this.nombMO, [
        Validators.required,
        Validators.maxLength(this.maxNomb)
      ]),
      monit: new FormControl(this.nitMO, [
        Validators.required,
        Validators.maxLength(this.maxNit)
      ]),
      motlf: new FormControl(this.tlfMO, [
        Validators.required,
        Validators.maxLength(this.maxPhone)
      ]),
      modir: new FormControl(this.dirMO, [
        Validators.required,
        Validators.maxLength(this.maxDir)
      ])
    });
   }

  

  ngOnInit() {
  if(this.typeInput === true){
      this.inputType = 'text';
    }else{
      this.inputType = 'number';
    }

    
    if (!this.CatalogoFuerzadeventaAsistenteService.newMU) {
      this.codeId = this.CatalogoFuerzadeventaAsistenteService.editCode;
      const values = this.CatalogoFuerzadeventaAsistenteService.valuesObjects;

      if (typeof values !== 'undefined') {
        for (let i = 0; i < values.length; i++) {
          const element = values[i];
          if (element.code == this.CatalogoFuerzadeventaAsistenteService.pk) {
            this.codeMO = element.code;
            this.nombMO = element.id;
            this.nitMO = element.ni;
            this.tlfMO = element.phon;
            this.dirMO = element.addres;
            this.campoAct = element.statu;
          }
        }
      }
    } else {
      this.codeId = this.CatalogoFuerzadeventaAsistenteService.addCode;
    }

  this.CatalogoFuerzadeventaAsistenteService.status = this.campoAct;
  this.CatalogoFuerzadeventaAsistenteService.urlSave = this.listUrl.url('savesalesforceasistente');
  this.CatalogoFuerzadeventaAsistenteService.typeModal = 'catalogo';
  }

  setTax_Exempt(){
    if(this.campoAct == true){
      this.campoAct = false;
    } else {
      this.campoAct = true;
    }
  }


  closeModal() {
    this.CatalogoFuerzadeventaAsistenteService.closeModal.hide();
   }

   
   formatCode() {
    this.codeMO = this.codeMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
  }

  formatnomb() {
    this.nombMO = this.nombMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_nomb = false;
    this.txt_nomb = '';
  }

  formatnit() {
    this.nitMO = this.nitMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_nit = false;
    this.txt_nit = '';
  }

  formattlf() {
    this.tlfMO = this.tlfMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_tlf = false;
    this.txt_tlf = '';
  }

  formatdir() {
    this.dirMO = this.dirMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_dir = false;
    this.txt_dir = '';
  }




  saveObject(){
    let id_obj: any;
    if (this.CatalogoFuerzadeventaAsistenteService.newMU) {
      id_obj = '';
      this.CatalogoFuerzadeventaAsistenteService.confirmar = false;
    } else {
      id_obj = this.CatalogoFuerzadeventaAsistenteService.pk;
      this.CatalogoFuerzadeventaAsistenteService.confirmar = true;
    }


    if (this.codeMO.toUpperCase().trim() && this.nombMO.toUpperCase().trim() && this.nitMO.toUpperCase().trim() && this.tlfMO.toUpperCase().trim() && this.dirMO.toUpperCase().trim()) {

    const initAjax: Init = {
        method: 'put',
        url: this.CatalogoFuerzadeventaAsistenteService.urlSave,
        auth: this.auth,
        body: {
          id : this.CatalogoFuerzadeventaAsistenteService.pass,
          mod: this.CatalogoFuerzadeventaAsistenteService.newMU,
          code: this.codeMO.toUpperCase().trim(),
          nomb: this.nombMO.toUpperCase().trim(),
          nit: this.nitMO.toUpperCase().trim(),
          tlf: this.tlfMO.toUpperCase().trim(),
          dire: this.dirMO.toUpperCase().trim(),
          status: this.campoAct,
          nuevo : this.CatalogoFuerzadeventaAsistenteService.confirmar
        }
      };

        const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
              this.CatalogoFuerzadeventaAsistenteService.closeModal.hide();
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        console.log('resp: ',resp);
        if (resp.success) {
            
            if (resp.nuevo) {
            this.newObject(resp);

            } 
           else {            
             this.editObject(resp);
          }     
        }
      }).catch(error => {
        console.log('error: ',error);
        this.viewError(error);
      });
    }

    else{
        this.error_dir = true;
        this.txt_dir = 'Favor debe de llenar los campos correctamente';
    }	
  }

  newObject(resp) {

    this.CatalogoFuerzadeventaAsistenteService.valuesObjects.unshift(resp.objeto);
    this.CatalogoFuerzadeventaAsistenteService.valuesObjectsBase = this.CatalogoFuerzadeventaAsistenteService.valuesObjects;
    this.CatalogoFuerzadeventaAsistenteService.habilitaBtn = true;


  }


  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_nomb !== 'undefined') {
      if (error.error_nomb) {
        this.error_nomb = error.error_nomb;
        this.txt_nomb = error.txt_nomb;
      }

    }
    if (error.error_nit !== 'undefined') {
      if (error.error_nit) {
        this.error_nit = error.error_nit;
        this.txt_nit = error.txt_nit;
      }
      
    }
    if (error.error_dir !== 'undefined') {
      if (error.error_dir) {
        this.error_dir = error.error_dir;
        this.txt_dir = error.txt_dir;
      }
      
    }


    if (error.error_tlf !== 'undefined') {
      if (error.error_tlf) {
        this.error_tlf = error.error_tlf;
        this.txt_tlf = error.txt_dir;
      }
      
    }
  }


  editObject(resp) {
            const values = this.CatalogoFuerzadeventaAsistenteService.valuesObjects;
            for (let i = 0; i < values.length; i++) {
            const element = values[i];
            if (values[i].code == resp.ids) {
              values[i].llave = resp.new;
              values[i].code = this.codeMO;
              values[i].id = this.nombMO;
              values[i].ni = this.nitMO;
              values[i].phon = this.tlfMO;
              values[i].addres = this.dirMO;
              values[i].statu = this.campoAct;
             }
            }

            this.CatalogoFuerzadeventaAsistenteService.valuesObjects = values;
            this.CatalogoFuerzadeventaAsistenteService.valuesObjectsBase = this.CatalogoFuerzadeventaAsistenteService.valuesObjects;
            this.CatalogoFuerzadeventaAsistenteService.habilitaBtn = true;


  }
























}
