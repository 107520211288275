import { AbstractControl } from '@angular/forms'

export function numValidators(control:AbstractControl) {
    if (control &&( control.value !== null || control.value != undefined)){
        const regex = new RegExp('^[^+-]*$');
        if (!regex.test(control.value)) {
            return {
                notFormat: true
            };
        }
    }else if(control.value == null || control.value == undefined){
        return {
            notFormat: true
        };
    }
    return null;
}