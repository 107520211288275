import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { Ajax, Config } from "src/app/core/ajax/ajax";
import { Init } from "src/app/core/ajax/init-ajax";
import { UrListService } from "src/app/core/ajax/ur-list.service";
import { Url, UrlBase } from 'src/app/core/ajax/url';
import { AuthService } from "src/app/core/auth/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-laboral-days",
  templateUrl: "./laboral-days.component.html",
  styleUrls: ["./laboral-days.component.css"],
})
export class LaboralDaysComponent implements OnInit {
  weekends: FormControl = new FormControl(true);
  controller: FormGroup;
  controllers: FormArray;
  list: any;
  days: any[] = [
    { day: 0, name: "Domingo" },
    { day: 1, name: "Lunes" },
    { day: 2, name: "Martes" },
    { day: 3, name: "Miercoles" },
    { day: 4, name: "Jueves" },
    { day: 5, name: "Viernes" },
    { day: 6, name: "Sabado" },
  ];
  months = [
    { name: "Enero", id: 1 },
    { name: "Febrero", id: 2 },
    { name: "Marzo", id: 3 },
    { name: "Abril", id: 4 },
    { name: "Mayo", id: 5 },
    { name: "Junio", id: 6 },
    { name: "Julio", id: 7 },
    { name: "Agosto", id: 8 },
    { name: "Septiembre", id: 9 },
    { name: "Octubre", id: 10 },
    { name: "Noviembre", id: 11 },
    { name: "Diciembre", id: 12 },
  ];
  current: number = new Date().getFullYear();
  years = [];
  entities: any;
  state: string;
  currentPage: number = 0;

  constructor(
    public auth: AuthService,
    private _UrListService: UrListService,
    private _FormBuilder: FormBuilder,
    private modalService: BsModalService // public CatalogotwoService: CatalogotwoService
  ) {}

  ngOnInit() {
    this.initController();
  }

  updateValue = (index: number) => {
    const value = this.controllers.at(index).get(`day_off`).value;
    this.controllers.at(index).get(`day_off`).setValue(!value);
    this.controllers.at(index).markAsTouched();
  };

  retrieveDays = (day: number) => {
    let list = [];
    this.controllers.controls.forEach(
      (controller: AbstractControl, index: number) => {
        if (
          new Date(controller.get(`day`).value.replace("-", "/")).getDay() ===
          day
        ) {
          list.push({ ...controller.value, index });
        }
      }
    );
    return list;
  };

  verify = (item: any) => {
    const day = new Date(item.day.replace("-", "/")).getDay();
    const dayNumber: number =
      item.day.split("-")[item.day.split("-").length - 1] - 1;
    const flag: boolean =
      dayNumber !== day && dayNumber >= day && dayNumber < 7;
    return flag;
  };

  initController = (): void => {
    let validators: ValidatorFn[] = [];
    let controller: FormGroup = new FormGroup({});
    const date = new Date();
    const ym = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;
    const list = ym.split(`-`);
    const [year] = list;
    const month = list[list.length - 1];
    controller.addControl(
      "year",
      this._FormBuilder.control({ value: +year, disabled: false }, validators)
    );
    controller.addControl(
      "month",
      this._FormBuilder.control({ value: +month, disabled: false }, validators)
    );
    controller.setValidators([this.validDate]);
    this.controller = controller;
    this.submit('get_laboral_days',"get");
  };

  searchButton = () => {
    this.submit('get_laboral_days',"get");
  }

  create = () => {
    this.submit('set_laboral_days',"post");
  }

  submit(url:string,method?: "post" | "put" | "get") {
    let value;
    if (this.controller.invalid) return;
    // if (this.controllers?!this.controllers.touched:false) return;
    switch (method) {
      case "post":
        this.controllers
          ? this.controllers.controls.filter((control: AbstractControl) => {
              if (!control.touched) {
                control.disable();
              }
            })
          : [];
        value = {
          year: this.controller.value[`year`],
          month: this.controller.value[`month`],
          weekends: this.weekends.value,
          values:
            this.controllers && this.controllers.touched
              ? this.controllers.value
              : [],
        };
        break;
      case "get":
        value = {
          year: this.controller.value[`year`],
          month: this.controller.value[`month`],
        };
        break;
    }
    const { initAjax, configAjax } = this.ajaxConfig(value,url, method);
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then((resp) => {
      this.initControllers(resp.entities);
    });
  }

  update = () => {
    this.confir(
      () => {
        this.close_confirmar().then(() => {
          const value = {
            year: this.controller.value[`year`],
            month: this.controller.value[`month`],
          };
          const { initAjax, configAjax } = this.ajaxConfig(value,'delete_laboral_days', "put");
          const ajax = new Ajax(initAjax, configAjax);
          ajax.call().then((resp) => {
            this.initControllers(resp.entities);
          });
        });
      },
      "Desea Eliminar?",
      `Se eliminaran todos los registros`
    );
  };

  ajaxConfig = (value: any,url:string, method?: "post" | "put" | "get") => {
    const initAjax: Init = {
      method,
      auth: this.auth,
      url: this._UrListService.url(url),
      body: value,
    };
    const configAjax: Config = {
      titleLoading: `Cargando`,
      titleSuccess: "Peticion efectuada correctamente",
      textErrorNormal: `No se logro cargar`,
      titleErrorNormal: `Error al cargar`,
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError: Swal.mixin({
                icon: "error",
                title: "Ocurrio un error",
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: "Cerrar",
              }),
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      },
    };
    return { initAjax, configAjax };
  };

  initControllers = (entities: any): void => {
    this.years = [];
    [0,1,2,3].forEach((i:number)=>this.years.push(this.current+i));
    this.weekends.setValue(true);
    let controllers: FormArray = new FormArray([]);
    let validators: ValidatorFn[];
    entities.forEach((item: any) => {
      let controller = new FormGroup({});
      Object.keys(item).forEach((field: string) => {
        controller.addControl(
          field,
          this._FormBuilder.control(item ? item[field] : null, validators)
        );
      });
      controllers.push(controller);
    });
    this.controllers = controllers;
  };

  validDate = (controller: FormGroup) => {
    // const date:Date = new Date();
    const month = controller.get(`month`).value;
    const year = controller.get(`year`).value;
    const sum = month + year;
    const flag: any =
      month < 1 || month > 12 || year === null
        ? {
            invalidDate: true,
          }
        : null;
    return flag;
  };

  confirmar = Swal.mixin({
    icon: "question",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "Confirmar",
    confirmButtonAriaLabel: "Cancelar",
    cancelButtonText: "Cancelar",
    cancelButtonAriaLabel: "Cancelar",
  });

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`,
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }
}
