import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './core/signin/signin.component';
import { SignupComponent } from './servicios/signup/signup.component';
import { ClienteComponent } from './cliente/cliente.component';
import { AuthAnonymoGuard } from './core/canActivate/auth-anonymo.guard';
import { SelectCompanyComponent } from './core/select-company/select-company.component';
import { AuthGuardService } from './core/canActivate/auth-guard-login';
import { HomeComponent } from './core/home/home.component';
import { ProveedorComponent } from './cliente/administrativo/proveedor/proveedor.component';
import { SelectCompanyGuard } from './core/canActivate/select-company.guard';
import { ServiciosComponent } from './servicios/servicios.component';
import { PasswordResetComponent } from './servicios/password-reset/password-reset.component';
import { BlockComponent } from './core/block/block.component';
import { BlockGuard } from './core/canActivate/block.guard';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'password_reset', component: PasswordResetComponent },
  { path: 'signin', component: SigninComponent, canActivate: [ AuthAnonymoGuard ] },
  { path: 'signup', component: SignupComponent, canActivate: [ AuthAnonymoGuard ] },
  { path: 'select_company', component: SelectCompanyComponent, canActivate: [ AuthGuardService ] },
  { path: 'cliente', component: ClienteComponent, loadChildren: './cliente/cliente.module#ClienteModule'},
  { path: 'cliente/adm/provider', component: ProveedorComponent, canActivate: [ AuthGuardService, SelectCompanyGuard]},
  { path: '',
    component: ServiciosComponent,
    canActivate: [ AuthGuardService ],
    loadChildren: './servicios/servicios.module#ServiciosModule'},
  { path: 'block', component: BlockComponent, canActivate: [ BlockGuard ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
