import { Component, OnInit } from '@angular/core';
import { SellerCustomerService } from '../../services/seller-customer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { numValidators } from '../../setting/validators';
import { SellersService } from '../../services/sellers.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoadSellerComponent } from '../load-seller/load-seller.component';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-load-seller-customer',
  templateUrl: './load-seller-customer.component.html',
  styleUrls: ['./load-seller-customer.component.css']
})
export class LoadSellerCustomerComponent implements OnInit {

  formSellerCustomer: FormGroup;
  modalRef: BsModalRef;

  error_id: boolean = false;
  txt_id: string | null;
  error_customer: boolean = false;
  txt_customer: string | null;
  error_seller: boolean = false;
  txt_seller: string | null;
  error_frecu: boolean = false;
  txt_frecu: string | null;
  error_deta: boolean = false;
  txt_deta: string | null;
  error_secu: boolean = false;
  txt_secu: string | null;
  error_pay: boolean = false;
  txt_pay: string | null;
  error_day: boolean = false;
  txt_day: string | null;
  error_limit: boolean = false;
  txt_limit: string | null;
  error_type: boolean = false;
  txt_type: string | null;
  codeId: string;

  constructor(
    public SellerCustomerService: SellerCustomerService,
    public SellersService: SellersService,
    public auth: AuthService,
    private listUrl: UrListService,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.formSellerCustomer = new FormGroup({
      code_customer: new FormControl(this.SellerCustomerService.CodeCustomer, [
        Validators.required
      ]),
      desc_customer: new FormControl(this.SellerCustomerService.DescCustomer, [
        Validators.required
      ]),
      code_seller: new FormControl(this.SellerCustomerService.CodeSeller, [
        Validators.required
      ]),
      desc_seller: new FormControl(this.SellerCustomerService.DescSeller, [
        Validators.required
      ]),
      status: new FormControl(this.SellerCustomerService.Status),
      frecuencia: new FormControl(this.SellerCustomerService.CodeFrecuency, [
        Validators.required
      ]),
      detalle: new FormControl(this.SellerCustomerService.CodeDetail, [
        Validators.required
      ]),
      secuency: new FormControl(this.SellerCustomerService.Secu, [
        Validators.min(0),
        Validators.max(999),
        numValidators
      ]),
      lunes: new FormControl(this.SellerCustomerService.Lun),
      martes: new FormControl(this.SellerCustomerService.Mar),
      miercoles: new FormControl(this.SellerCustomerService.Mier),
      jueves: new FormControl(this.SellerCustomerService.Jue),
      viernes: new FormControl(this.SellerCustomerService.Vie),
      sabado: new FormControl(this.SellerCustomerService.Sab),
      domingo: new FormControl(this.SellerCustomerService.Dom),
      payment: new FormControl(this.SellerCustomerService.CodePay, [
        Validators.required
      ]),
      day_credit: new FormControl(this.SellerCustomerService.DayCredit, [
        Validators.min(0),
        Validators.max(999),
        numValidators
      ]),
      limit_credit: new FormControl(this.SellerCustomerService.LimitCredit, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      type_price: new FormControl(this.SellerCustomerService.CodePrice, [
        Validators.required
      ])
    });
  }

  getId() {
    return this.codeId;
  }

  ngOnInit() {
    this.loadingSupervisors();
    this.loadingDeposits();
    this.loadingUsersCompany();
    if (this.SellerCustomerService.new) {
      this.codeId = 'ADMCAIU00069';
      this.formSellerCustomer.reset();
      this.SellerCustomerService.CodeCustomer = null;
      this.SellerCustomerService.DescCustomer = null;
      this.SellerCustomerService.CodeSeller = null;
      this.SellerCustomerService.DescSeller = null;
      this.SellerCustomerService.Status = true;
      this.SellerCustomerService.CodeFrecuency = '';
      this.SellerCustomerService.selectFrecu = false;
      this.SellerCustomerService.CodeDetail = '';
      this.SellerCustomerService.Secu = 0;
      this.SellerCustomerService.Lun = false;
      this.SellerCustomerService.Mar = false;
      this.SellerCustomerService.Mier = false;
      this.SellerCustomerService.Jue = false;
      this.SellerCustomerService.Vie = false;
      this.SellerCustomerService.Sab = false;
      this.SellerCustomerService.Dom = false;
      this.SellerCustomerService.CodePay = '';
      this.SellerCustomerService.selectPay = false;
      this.SellerCustomerService.DayCredit = 0;
      this.SellerCustomerService.LimitCredit = 0;
      this.SellerCustomerService.CodePrice = '';
    } else {
      this.codeId = 'ADMCAIU00070';
      this.SellerCustomerService.CodeCustomer = this.SellerCustomerService.object.id_customer;
      this.SellerCustomerService.DescCustomer = this.SellerCustomerService.object.id_customer;
      this.SellerCustomerService.CodeSeller = this.SellerCustomerService.object.id_seller;
      this.SellerCustomerService.DescSeller = this.SellerCustomerService.object.id_seller;
      this.SellerCustomerService.Status = this.SellerCustomerService.object.status;
      this.SellerCustomerService.Lun = this.SellerCustomerService.object.mon;
      this.SellerCustomerService.Mar = this.SellerCustomerService.object.tues;
      this.SellerCustomerService.Mier = this.SellerCustomerService.object.wed;
      this.SellerCustomerService.Jue = this.SellerCustomerService.object.thurs;
      this.SellerCustomerService.Vie = this.SellerCustomerService.object.fri;
      this.SellerCustomerService.Sab = this.SellerCustomerService.object.sat;
      this.SellerCustomerService.Dom = this.SellerCustomerService.object.sun;
      this.SellerCustomerService.CodeFrecuency = this.SellerCustomerService.object.frecu;
      if (this.SellerCustomerService.CodeFrecuency == '' || this.SellerCustomerService.CodeFrecuency == '1') {
        this.SellerCustomerService.selectFrecu = false;
      } else {
        this.SellerCustomerService.selectFrecu = true;
      }
      this.SellerCustomerService.CodeDetail = this.SellerCustomerService.object.frecu_detail;
      this.SellerCustomerService.Secu = this.SellerCustomerService.object.sequ;
      this.SellerCustomerService.CodePay = this.SellerCustomerService.object.pay_cond;
      if (this.SellerCustomerService.CodePay == '' || this.SellerCustomerService.CodePay == '1') {
        this.SellerCustomerService.selectPay = false;
        this.SellerCustomerService.DayCredit = 0;
        this.SellerCustomerService.LimitCredit = 0;
      } else {
        this.SellerCustomerService.selectPay = true;
        this.SellerCustomerService.DayCredit = this.SellerCustomerService.object.days_credit;
        this.SellerCustomerService.LimitCredit = this.SellerCustomerService.object.limit_credit;
      }
      this.SellerCustomerService.CodePrice = this.SellerCustomerService.object.type_price;
    }
  }

  get code_customer() { return this.formSellerCustomer.get('code_customer'); }
  get desc_customer() { return this.formSellerCustomer.get('desc_customer'); }
  get code_seller() { return this.formSellerCustomer.get('code_seller'); }
  get desc_seller() { return this.formSellerCustomer.get('desc_seller'); }
  get status() { return this.formSellerCustomer.get('status'); }
  get frecuencia() { return this.formSellerCustomer.get('frecuencia'); }
  get detalle() { return this.formSellerCustomer.get('detalle'); }
  get secuency() { return this.formSellerCustomer.get('secuency'); }
  get lunes() { return this.formSellerCustomer.get('lunes'); }
  get martes() { return this.formSellerCustomer.get('martes'); }
  get miercoles() { return this.formSellerCustomer.get('miercoles'); }
  get jueves() { return this.formSellerCustomer.get('jueves'); }
  get viernes() { return this.formSellerCustomer.get('viernes'); }
  get sabado() { return this.formSellerCustomer.get('sabado'); }
  get domingo() { return this.formSellerCustomer.get('domingo'); }
  get payment() { return this.formSellerCustomer.get('payment'); }
  get day_credit() { return this.formSellerCustomer.get('day_credit'); }
  get limit_credit() { return this.formSellerCustomer.get('limit_credit'); }
  get type_price() { return this.formSellerCustomer.get('type_price'); }

  closeModal() {
    this.SellerCustomerService.closeModal.hide();
  }

  loadingDeposits() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('select_deposits')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.retornar) {
          if (resp.retornar === 'location') {
            this.router.navigateByUrl("/cliente/adm/catalogo/productos/ubicacion?return="+this.router.url);
          }else if (resp.retornar === 'deposit') {
            this.router.navigateByUrl("/cliente/adm/catalogo/productos/deposito?return="+this.router.url);
          }
      }else if (resp.success) {
        this.SellersService.DepositSelect = resp.objects;
      }
    });
  }

  loadingUsersCompany() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('select_users_company')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.SellersService.UserCompanySelect = resp.objects;
      }
    });
  }

  loadingSupervisors() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('select_supervisors')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.SellersService.SupervisorSelect = resp.objects;
      }
    });
  }

  openModalSeller() {
    this.SellersService.new = true;
    this.SellersService.pk = '';
    this.SellersService.isCatalog = false;
    this.SellersService.modalType = 'seller-customer';
    this.modalRef = this.modalService.show(LoadSellerComponent);
    this.modalRef.setClass('modal-dialog-centered');
    this.SellersService.closeModal = this.modalRef;
  }

  changeCustomer() {
    this.error_id = false;
    this.txt_id = '';
    this.error_customer = false;
    this.txt_customer = '';
    this.SellerCustomerService.DescCustomer = null;
    for (const key in this.SellerCustomerService.CustomerSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.CustomerSelect, key)) {
        const customer = this.SellerCustomerService.CustomerSelect[key];
        if (customer.id == this.SellerCustomerService.CodeCustomer) {
          this.SellerCustomerService.DescCustomer = customer.id;
          break;
        }
      }
    }
  }

  changeDescCustomer() {
    this.error_id = false;
    this.txt_id = '';
    this.error_customer = false;
    this.txt_customer = '';
    this.SellerCustomerService.CodeCustomer = null;
    for (const key in this.SellerCustomerService.CustomerSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.CustomerSelect, key)) {
        const customer = this.SellerCustomerService.CustomerSelect[key];
        if (customer.id == this.SellerCustomerService.DescCustomer) {
          this.SellerCustomerService.CodeCustomer = customer.id;
          break;
        }
      }
    }
  }

  changeSeller() {
    this.error_id = false;
    this.txt_id = '';
    this.error_seller = false;
    this.txt_seller = '';
    this.SellerCustomerService.DescSeller = null;
    for (const key in this.SellerCustomerService.SellerSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.SellerSelect, key)) {
        const seller = this.SellerCustomerService.SellerSelect[key];
        if (seller.id == this.SellerCustomerService.CodeSeller) {
          this.SellerCustomerService.DescSeller = seller.id;
          break;
        }
      }
    }
  }

  changeDescSeller() {
    this.error_id = false;
    this.txt_id = '';
    this.error_seller = false;
    this.txt_seller = '';
    this.SellerCustomerService.CodeSeller = null;
    for (const key in this.SellerCustomerService.SellerSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.SellerSelect, key)) {
        const seller = this.SellerCustomerService.SellerSelect[key];
        if (seller.id == this.SellerCustomerService.DescSeller) {
          this.SellerCustomerService.CodeSeller = seller.id;
          break;
        }
      }
    }
  }

  changeFrecuency() {
    this.error_id = false;
    this.txt_id = '';
    this.error_frecu = false;
    this.txt_frecu = '';
    this.error_deta = false;
    this.txt_deta = '';
    if (this.SellerCustomerService.CodeFrecuency == '' || this.SellerCustomerService.CodeFrecuency == '1') {
      this.SellerCustomerService.selectFrecu = false;
      if (this.SellerCustomerService.CodeFrecuency == '') {
        this.SellerCustomerService.CodeDetail = '';
      } else {
        this.SellerCustomerService.CodeDetail = '5';
      }
    } else {
      this.SellerCustomerService.selectFrecu = true;
      this.SellerCustomerService.CodeDetail = '';
    }
  }

  changePay() {
    this.error_id = false;
    this.txt_id = '';
    this.error_pay = false;
    this.txt_pay = '';
    this.error_day = false;
    this.txt_day = '';
    this.error_limit = false;
    this.txt_limit = '';
    this.SellerCustomerService.DayCredit = 0;
    this.SellerCustomerService.LimitCredit = 0;
    if (this.SellerCustomerService.CodePay == '' || this.SellerCustomerService.CodePay == '1') {
      this.SellerCustomerService.selectPay = false;
    } else {
      this.SellerCustomerService.selectPay = true;
    }
  }

  changeDetail() {
    this.error_id = false;
    this.txt_id = '';
    this.error_deta = false;
    this.txt_deta = '';
  }

  changePrice() {
    this.error_id = false;
    this.txt_id = '';
    this.error_type = false;
    this.txt_type = '';
  }

  formatSecu() {
    this.error_id = false;
    this.txt_id = '';
    this.error_secu = false;
    this.txt_secu = '';
    if (this.secuency.valid && this.SellerCustomerService.Secu > 0) {
      this.SellerCustomerService.Secu = this.trunc(this.SellerCustomerService.Secu);
      this.secuency.setValue(this.SellerCustomerService.Secu);
    }
  }

  formatDay() {
    this.error_id = false;
    this.txt_id = '';
    this.error_day = false;
    this.txt_day = '';
    if (this.day_credit.valid && this.SellerCustomerService.DayCredit > 0) {
      this.SellerCustomerService.DayCredit = this.trunc(this.SellerCustomerService.DayCredit);
      this.day_credit.setValue(this.SellerCustomerService.DayCredit);
    }
  }

  formatLimit() {
    this.error_id = false;
    this.txt_id = '';
    this.error_limit = false;
    this.txt_limit = '';
  }

  trunc (x, posiciones = 0) {
    if (x) {
      let s = x.toString()
      let l = s.length
      let decimalLength = s.indexOf('.') + 1
      if (l - decimalLength <= posiciones){
        return x
      }
      let isNeg  = x < 0
      let decimal =  x % 1
      let entera  = isNeg ? Math.ceil(x) : Math.floor(x)
      let decimalFormated = Math.floor(
        Math.abs(decimal) * Math.pow(10, posiciones)
      )
      let finalNum = entera + ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
      
      return finalNum
    }
  }

  saveSellerCustomer() {
    if (this.formSellerCustomer.valid) {
      let values = this.formSellerCustomer.value;
      let validCustomer = false;
      let validSeller = false;
      let validFrecu = false;
      let validDetail = false;
      let validPay = false;
      let validDay = false;
      let validLimit = false;
      let validPrice = false;
      if (values.code_customer) {
        for (const key in this.SellerCustomerService.CustomerSelect) {
          if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.CustomerSelect, key)) {
            const customer = this.SellerCustomerService.CustomerSelect[key];
            if (customer.id == values.code_customer) {
              validCustomer = true;
              break;
            }
          }
        }
      }
      if (values.code_seller) {
        for (const key in this.SellerCustomerService.SellerSelect) {
          if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.SellerSelect, key)) {
            const seller = this.SellerCustomerService.SellerSelect[key];
            if (seller.id == values.code_seller) {
              validSeller = true;
              break;
            }
          }
        }
      }
      if (values.frecuencia) {
        for (const key in this.SellerCustomerService.FrecuencySelect) {
          if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.FrecuencySelect, key)) {
            const frecuency = this.SellerCustomerService.FrecuencySelect[key];
            if (frecuency.id == values.frecuencia) {
              validFrecu = true;
              break;
            }
          }
        }
        if (validFrecu) {
          if (values.frecuencia == '1' && values.detalle == '5') {
            validDetail = true;
          } else if (values.frecuencia != '1' && values.detalle) {
            for (const key in this.SellerCustomerService.DetailsSelect) {
              if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.DetailsSelect, key)) {
                const detail = this.SellerCustomerService.DetailsSelect[key];
                if (detail.id == values.detalle) {
                  validDetail = true;
                  break;
                }
              }
            }
          }
        }
      }
      if (values.payment) {
        for (const key in this.SellerCustomerService.PaymentSelect) {
          if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.PaymentSelect, key)) {
            const payment = this.SellerCustomerService.PaymentSelect[key];
            if (payment.id == values.payment) {
              validPay = true;
              break;
            }
          }
        }
        if (validPay) {
          if (values.payment == '1' && values.day_credit == 0 && values.limit_credit == 0) {
            validDay = true;
            validLimit = true;
          } else if (values.payment == '2') {
            validDay = true;
            validLimit = true;
          }
        }
      }
      if (values.type_price != '') {
        for (const key in this.SellerCustomerService.PricesSelect) {
          if (Object.prototype.hasOwnProperty.call(this.SellerCustomerService.PricesSelect, key)) {
            const price = this.SellerCustomerService.PricesSelect[key];
            if (price.id == values.type_price) {
              validPrice = true;
              break;
            }
          }
        }
      }
      if (validCustomer && validSeller && validFrecu && validDetail && validPay && validDay && validLimit && validPrice) {
        let id_obj: any;
        if(this.SellerCustomerService.new){
          id_obj = '';
        }else{
          id_obj = this.SellerCustomerService.pk;
        }
        if (this.SellerCustomerService.isCatalog) {
          this.SellerCustomerService.modalType = 'catalogo';
        }

        const initAjax: Init = {
          method: 'put',
          url: this.listUrl.url('saveseller_customer'),
          auth: this.auth,
          body: {
            id_obj: id_obj,
            tipo: this.SellerCustomerService.modalType,
            customer: this.SellerCustomerService.CodeCustomer,
            seller: this.SellerCustomerService.CodeSeller,
            status: this.SellerCustomerService.Status,
            frecuency: this.SellerCustomerService.CodeFrecuency,
            detail: this.SellerCustomerService.CodeDetail,
            secuency: this.SellerCustomerService.Secu,
            lunes: this.SellerCustomerService.Lun,
            martes: this.SellerCustomerService.Mar,
            miercoles: this.SellerCustomerService.Mier,
            jueves: this.SellerCustomerService.Jue,
            viernes: this.SellerCustomerService.Vie,
            sabado: this.SellerCustomerService.Sab,
            domingo: this.SellerCustomerService.Dom,
            payment: this.SellerCustomerService.CodePay,
            days: this.SellerCustomerService.DayCredit,
            limit: this.SellerCustomerService.LimitCredit,
            price: this.SellerCustomerService.CodePrice
          }
        };
        const configAjax: Config = {
          numInten : 1,
          titleLoading: 'Guardando Ruta de Cliente',
          titleSuccess: '',
          titleErrorNormal: 'Error',
          textErrorNormal: 'Ha ocurrido un Error.',
          confirmButtonTextError: 'Cerrar',
          validatejson: (resp: any, obj: Ajax) => {
            return new Promise((success, error) => {
              if (resp.success === false) {
                obj.declareConfig({
                  alertaError : Swal.mixin({
                    icon: 'error',
                    title: 'Ocurrio un error',
                    text: resp.message,
                    showConfirmButton: false,
                    confirmButtonText: 'Cerrar',
                  })
                });
                error(resp);
              } else {
                success(resp);
              }
            });
          }
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(resp => {
          if (resp.success) {
            if(resp.new){
              if(resp.tipo == 'catalogo'){
                this.SellerCustomerService.continuar = true;
                this.SellerCustomerService.evenAdd.emit(resp);
              }
            }else{
              this.SellerCustomerService.continuar = true;
              this.SellerCustomerService.evenAdd.emit(resp);
            }
            this.SellerCustomerService.closeModal.hide();
          }
        }).catch(error => {
          this.viewError(error);
        });
      } else {
        if (!validCustomer) {
          this.error_customer = true;
          this.txt_customer = 'Valor no permitido.';
        }
        if (!validSeller) {
          this.error_seller = true;
          this.txt_seller = 'Valor no permitido.';
        }
        if (!validFrecu) {
          this.error_frecu = true;
          this.txt_frecu = 'Valor no permitido.';
        }
        if (!validDetail) {
          this.error_deta = true;
          this.txt_deta = 'Valor no permitido.';
        }
        if (!validPay) {
          this.error_pay = true;
          this.txt_pay = 'Valor no permitido.';
        }
        if (!validDay) {
          this.error_day = true;
          this.txt_day = 'Valor no permitido.';
        }
        if (!validLimit) {
          this.error_limit = true;
          this.txt_limit = 'Valor no permitido.';
        }
        if (!validPrice) {
          this.error_type = true;
          this.txt_type = 'Valor no permitido.';
        }
      }
    }
  }

  viewError(error) {
    if (typeof error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (typeof error.error_customer !== 'undefined') {
      if (error.error_customer) {
        this.error_customer = error.error_customer;
        this.txt_customer = error.txt_customer;
      }
    }
    if (typeof error.error_seller !== 'undefined') {
      if (error.error_seller) {
        this.error_seller = error.error_seller;
        this.txt_seller = error.txt_seller;
      }
    }
    if (typeof error.error_frecu !== 'undefined') {
      if (error.error_frecu) {
        this.error_frecu = error.error_frecu;
        this.txt_frecu = error.txt_frecu;
      }
    }
    if (typeof error.error_deta !== 'undefined') {
      if (error.error_deta) {
        this.error_deta = error.error_deta;
        this.txt_deta = error.txt_deta;
      }
    }
    if (typeof error.error_secu !== 'undefined') {
      if (error.error_secu) {
        this.error_secu = error.error_secu;
        this.txt_secu = error.txt_secu;
      }
    }
    if (typeof error.error_pay !== 'undefined') {
      if (error.error_pay) {
        this.error_pay = error.error_pay;
        this.txt_pay = error.txt_pay;
      }
    }
    if (typeof error.error_day !== 'undefined') {
      if (error.error_day) {
        this.error_day = error.error_day;
        this.txt_day = error.txt_day;
      }
    }
    if (typeof error.error_limit !== 'undefined') {
      if (error.error_limit) {
        this.error_limit = error.error_limit;
        this.txt_limit = error.txt_limit;
      }
    }
    if (typeof error.error_type !== 'undefined') {
      if (error.error_type) {
        this.error_type = error.error_type;
        this.txt_type = error.txt_type;
      }
    }
  }

}
