import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EntriesExitsService } from '../../entries-exits.service';
import { numValidators } from '../../../setting/validators';

@Injectable({
  providedIn: 'root'
})
export class AddOrderService {

  formPedido: FormGroup; //Formuario Agregar Pedido

  constructor(
    public ViewService: EntriesExitsService
  ) {
    this.formPedido = new FormGroup({
      orderpe: new FormControl(this.ViewService.orderPe, [
        Validators.required,
        Validators.maxLength(10)
      ]),
      typetranspe: new FormControl(this.ViewService.nroTypeTrans,[
        Validators.required
      ]),
      depositpe: new FormControl(this.ViewService.depositPe,[
        Validators.required
      ]),
      supplierpe: new FormControl(this.ViewService.supplierPe,[
        Validators.required
      ]),
      currencype: new FormControl(this.ViewService.currencyPe,[
        Validators.required
      ]),
      datedope: new FormControl(this.ViewService.dateDocPe, [
        Validators.required
      ]),
      lisaeape: new FormControl(this.ViewService.lisaeaPe, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      drinktpe: new FormControl(this.ViewService.drinkTaxPe, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      origtotalpe: new FormControl(this.ViewService.origTotalPe, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ])
    });
  }
}
