import { EventEmitter, Injectable } from '@angular/core';
import { Url } from 'src/app/core/ajax/url';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CatalogoFourthService {

  typeModal: any;
  modelo: any;
  ambos: boolean;
  create: boolean;
  closeModal: any;
  habilitaBtn = false;
  newMU: boolean;
  modeloBase: string | null;
  pk: number | string;
  preguntaDelete: string | null;
  regresa: string | boolean;
  strConecDelete?: string;
  strDelete?: string;
  urlDelete: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  urlSave: Url | null;
  urlEdit: Url | null;
  viewModal: any;
  objectList: any;
  currList: any;
  countrys: any;
  datForm = new FormGroup({
    name: new FormControl(),
    desc: new FormControl(),
    addrs: new FormControl(),
  });
  viewCode: string;
  addCode: string;
  editCode: string;
  object: ObjectFourth;
  curr_selected: string | null | number = null;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder) { 
    this.datForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      desc: ['', [Validators.required, Validators.maxLength(100)]],
      addrs: ['', [Validators.required, Validators.maxLength(100)]],
    });
    this.objectList = [];
    this.currList = [];
  }
}

export interface ObjectFourth {    ​​​
  active: boolean;
  address: string | null;
  country: number | string | null;
  country_p: ObjectCountry;
  currency_id: number | string | null;
  currency_p: ObjectCurrency;
  delete: boolean;
  description: string;
  id: number | null;
  id_banks: number | null;
  name: string;
  update: boolean;
}

export interface ObjectCountry {    ​​​
  active: boolean;
  country_id: number | string | null;
  country_name: string;
  phone_code: number | string | null;
  short_name: string;
}

export interface ObjectCurrency {    ​​​
  active: boolean;
  id_currency: number | string | null;
  currency_name: string;
  currency_simbol: string;
}

export interface ObjectsFourth extends Array<ObjectFourth> {
  [key: number]: ObjectFourth;
}
