import { UrListService } from './ur-list.service';

export class Url {

    elemet: Array<string>;

    constructor(elemet: Array<string>, public urlList: UrListService) {
      this.elemet = elemet;
    }

    url(): string {
        if (this.urlList.activeWaiter) {
            if (this.urlList.auth.statusSocket) {
                return this.elemet[0];
            } else {
                return this.elemet[1];
            }
        } else {
            return this.elemet[1];
        }
    }

}

export class UrlBase {

    url_base: string;
    urlList = {
        activeWaiter: false,
    };

    constructor(url: string) { this.url_base = url; }

    url() {
        return this.url_base;
    }
}
