import { UsersListBase, UserBase } from '../auth/user';
import { Search } from 'src/app/search';
import { Cache } from 'src/app/cache';
import { SocketService } from '../ajax/socket/socket';

export interface Chat {
    chat_id: number;
    chat_type_id: string;
    company_id: number;
    creation_date: any;
    finish_date: any;
    owner_chat_id: number;
    status: any;
    users: UsersListBase;
    my_id: number;
    msgs: any;
}

interface Chats extends Array<ChatControl> {
    [key: number]: ChatControl;
}

export class ChatsList {

    private _chats: Chats;
    private _select: ChatControl | null;
    private _pendiente: Array<number>;
    private _chat_select: ChatControl | null;
    tipo: string;

    constructor(tipo: string) {
        this._chats = [];
        this._select = null;
        this.tipo = tipo;
        // this.templateOne = null;
        this._pendiente = [];
    }

    set chats(chats: Chats) {
        chats.forEach((chat: ChatControl) => {
            this.chat = chat;
        });
    }

    get chats(): Chats {
        return this._chats;
    }

    get is_company() {
        return this.tipo === 'company';
    }

    get_templatemsg() {
        if (this.is_company) {
            // return this.templateOne;
        }
    }

    desactivate_select() {
        this.chat_select.desactivate();
    }

    remove_chat_select() {
        this.desactivate_select();
        this._chat_select = null;
    }

    set chat_select(chat: ChatControl) {
        if (this.chat_select) {
            this.remove_chat_select();
        }
        chat.active_chat();
        this._select = chat;
    }

    get chat_select() {
        return this._select;
    }

    set chat(chat: ChatControl | any) {
        this._chats.push(chat);
    }

    get chat() {
        return this._chats.length;
    }

    activate_selet_chat() {
        if (this.chat_select == null && this.chats.length !== 0) {
            this.chat_select = this.chats[0];
        } else {
            this.chat_select = this.chat_select;
        }
    }


    set pendiente(pen: number) {
        const pendientes = this._pendiente.filter(x => x === pen );
        if (pendientes.length === 0) {
            this._pendiente.push(pen);
        }
        if (this.pendiente >= 1) {
            if (this.is_company) {
                // $('.numbercompanychat .companychatnumber').text(this.pendiente);
                // $('.numbercompanychat').removeClass('dispalnone');
            }
        }
    }

    get pendiente() {
        return this._pendiente.length;
    }

    removePendiente(pen) {
        this._pendiente.splice(this._pendiente.indexOf(pen), 1 );
        if (this.pendiente === 0) {
            if (this.is_company) {
                // $('.numbercompanychat .companychatnumber').text(this.pendiente);
                // $('.numbercompanychat').addClass('dispalnone');
            }
        }
    }

    get_chat(pk: any): Promise<ChatControl> {
        return new Promise((success, error) => {
            const chat = Search.filter(this.chats, (element, valor) => {
                if (element) {
                    return Search.any([
                        Search.igual(element.chat_id, valor),
                    ]);
                } else {
                    return false;
                }}, pk);
            if (chat.length) {
                success(chat[0]);
            } else {
                error();
            }
        });
    }

    // unionhtml(element) {
    //     let html = '';
    //     element.forEach(val => {
    //         html += `${val.html}`;
    //     });
    //     return html;
    // }

    search_company(seatextch: string) {
        const chats = Search.filter(this.chats, (element, valor) => {
            if (element) {
                return Search.any([
                    Search.search(element.users[1].data_user.username, valor),
                    Search.search(`${element.users[1].data_user.first_name} ${element.users[1].data_user.last_name}`, valor),
                ]);
            } else {
                return false;
            }
        }, seatextch);
        // $('#usersConect').html(' ');
        // $('.mensajes_company').html('');
        if (chats.length) {
            // $('#usersConect').html(this.unionhtml(chats));
            this.chat_select = chats[0];
        } else {
            // message de no chats
        }
    }

}


export class ChatControl {

    chat_id: number;
    active: boolean;
    chat_type_id: string;
    company_id: number;
    creation_date: any;
    finish_date: any;
    owner_chat_id: number;
    status: boolean;
    users: UsersListBase;
    my_id: number;
    private _msgs: any;
    private _owner_chat: any;
    private _pendiente: number;

    constructor(data: Chat, private st: SocketService) {
        this._pendiente = 0;
        this.save_data(data);
    }

    save_data(data: Chat) {
        this.chat_id = data.chat_id;
        this.active = false;
        this.chat_type_id = data.chat_type_id;
        this.company_id = data.company_id;
        this.creation_date = data.creation_date;
        this.finish_date = data.finish_date;
        this.owner_chat_id = data.owner_chat_id;
        this.status = data.status;
        this.users = data.users;
        this.owner_chat = data.owner_chat_id;
        this.my_id = data.my_id;
        // data.users.filter();
        // this.print_chat();
        this._msgs = [];
        this.msgs = data.msgs.reverse();
    }

    // print_chat(){
    //     if($(`#display_${this.chat_id}`).length == 0){
    //         if(this.isCustomer){
    //             $('#usersConect').append(this.html);
    //         }
    //     }
    // }

    // printMsg(msg){
    //     if(this.isCustomer){
    //         if($(`#mensajes_${this.chat_id}`).length){
    //             if($(`#msjcompany_${msg.message_id}`).length == 0){
    //                 if(typeof msg.ishistori !== 'undefined' && msg.ishistori){
    //                     $(`#mensajes_${this.chat_id}`).prepend(msg.html);
    //                 }else{
    //                     $(`#mensajes_${this.chat_id}`).append(msg.html);
    //                 }
    //             }else{
    //                 if(typeof msg.temp !== 'undefined' && msg.temp.message_id !== 'undefined'){
    //                     mensaje
    //                 }
    //             }
    //         }
    //     }
    //     this.scrollbotton();
    // }

    scrollbotton() {
        if (this.isCustomer) {
            // $('.mensajes_company').scrollTop($('.mensajes_company')[0].scrollHeight - $('.mensajes_company').height());
        }
    }

    // printMsgs(){
    //     this.msgs.forEach(msg=>{
    //         if($(`#mensajes_${this.chat_id}`).length){
    //             this.printMsg(msg);
    //         }
    //     });
    // }

    // removeMsgs(){
    //     if(this.isCustomer){
    //         $(`#mensajes_${this.chat_id}`).html(' ');
    //     }
    // }

    // create_temp_msg(msg){
    //     let template = chats_company.get_templatemsg()
    //     chats_company.msgmaldito = msg;
    //     return template.replace(
    //         "{msg_id}", `msjcompany_${msg.message_id}`
    //         ).replace(
    //             "{msg_content}", msg.message
    //         ).replace(
    //             "{msg_date}", msg.creation_date
    //         ).replace("{msg_photo}", msg.photo);
    // }

    desactivate() {
        if (this.isCustomer) {
            // $(`.${this.chat_id}_active`).removeClass('active_chat');
            // $('.mensajes_company').attr('id', ` `);
            // $('.sendcompanymsg').data('chat', `null`);
            this.active = false;
        }
    }

    active_chat() {
        if (this.isCustomer) {
            // $(`.${this.chat_id}_active`).addClass('active_chat');
            // $('.mensajes_company').attr('id', `mensajes_${this.chat_id}`);
            // $('.sendcompanymsg').data('chat', `${this.chat_id}`);
            this.active = true;
            // this.removeMsgs();
            // this.printMsgs();
        }
    }

    search_msg(msgid) {
        return new Promise((success, error) => {
            const msgs = Search.filter(this.msgs, (element, valor) => {
                if (element) {
                    return Search.any([
                        Search.igual(element.message_id, valor)
                    ]);
                } else {
                    return false;
                }
            }, msgid);
            if (msgs.length) {
                success(msgs[0]);
            } else {
                error(false);
            }
        });
    }

    sendMsg(menssage) {
        const idmsg = Cache.makeid(64);
        const msg_object = {
            message_id: idmsg,
            message: menssage,
            creation_date: new Date(),
            photo: ''
        };
        this.st.emit('sendmsg', {msg: menssage, pk: this.chat_id, temp: msg_object}, (callback) => {
            msg_object.creation_date = callback;
            // msg_object.html = this.create_temp_msg(msg_object);
            this.msg = msg_object;
        });
        if (this.active) {
            this.chatIsRead();
        }
    }

    filtermsg(msg, temp) {
        if (this.isCustomer) {
            temp.temp = msg;
            this.msg = temp;
        }
    }

    focus() {
        if (this.isCustomer) {
            // $('.sendcompanymsg').focus();
        }
        this.chatIsRead();
    }

    chatIsRead() {
        this.st.emit('readchat', {pk: this.chat_id});
        this.readMsg();
    }

    readMsg() {
        this.msgs.forEach((element, key) => {
            if (element.user_id !== this.my_id) {
                this.msgs[key].is_readed = true;
                this.removePendiente(1);
            }
        });
    }

    removePendiente(pen) {
        if (this.pendiente > 0) {
            this._pendiente -= pen;
        }
        if (this.pendiente === 0) {
            if (this.isCustomer) {
                // chats_company.removePendiente(this.chat_id);
            }
        }
    }

    notification() {
        // document.getElementById("audionotification").play();
    }

    get isCustomer() {
        return this.chat_type_id === 'user_customer';
    }

    set msgs(msgs) {
        msgs.forEach(msg => {
            this.msg = msg;
        });
    }

    get msgs() {
        return this._msgs;
    }

    set msg(msg) {
        this.search_msg(msg.message_id).then(resp => {
            const position = Search.position(this.msgs, (element, value) => {
                return Search.igual(element.message_id, value);
            }, msg.message_id);
            this.msgs[position] = msg.temp;
            // this.printMsg(msg);
        }).catch(err => {
            if (typeof msg.ishistori !== 'undefined' && msg.ishistori) {
                this._msgs.unshift(msg);
                // this.printMsg(msg);
            } else {
                this._msgs.push(msg);
                // this.printMsg(msg);
                if (this.my_id !== msg.user_id) {
                    if (msg.is_readed === false) {
                        this.pendiente = 1;
                        if (this.active) {
                            this.chatIsRead();
                        } else {
                            // que se escuche un sonido
                        }
                    }
                }
            }
        });
    }

    get msg() {
        return this._msgs.length;
    }

    histori_msgs(msgs) {
        msgs.forEach(elemet => {
            this.histori_msg(elemet);
        });
    }

    histori_msg(msg) {
        msg.ishistori = true;
        this.msg = msg;
    }

    get owner_chat() {
        return this._owner_chat;
    }

    set owner_chat(owner_id) {
        const usuario_chat = Search.filter(this.users, (element, valor) => {
            if (element) {
                return Search.any([
                    Search.igual(element.user_id, valor),
                ]);
            } else {
                return false;
            }}, owner_id);
        if (usuario_chat.length) {
            this._owner_chat = usuario_chat[0];
        }
    }

    set pendiente(pen) {
        if (this.isCustomer) {
            // chats_company.pendiente = this.chat_id;
        }
        this._pendiente += pen;
    }

    get pendiente() {
        return this._pendiente;
    }

}
