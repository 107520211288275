import { Injectable } from '@angular/core';
import { GeoSelects } from '../modal-proveedor/select-geo';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  newGnrl: boolean = true;
  pkGnrl: number | string;
  SelectCountry: GeoSelects;
  SelectState: GeoSelects;
  SelectCity: GeoSelects;
  SelectCurrency: GeoSelects;
  legalName: string;
  tradeName: string;
  Nit: string;
  Address: string;
  Phone: string;
  nameOwner: string;
  specialLicense: string = '';
  capiSoci: string = '';
  idCountry: number | null;
  idState: number | null;
  idCity: number | null;
  postCode: string;
  productDiscount: number = 0;
  globalDiscount: number = 0;
  creditDate: number = 0;
  creditLimit: number = 0;
  customersStart: number = 0;
  customerFinal: number = 0;
  idCurrency: number | null;
  redoPrice: number = 0;
  diasAnul: number = 0;
  iva: number = 0;
  priceBase: boolean = false;
  priceIva: boolean = false;
  priceViewExist: boolean = false;
  priceA: boolean = false;
  priceB: boolean = false;
  priceC: boolean = false;
  priceD: boolean = false;
  priceE: boolean = false;
  kind: boolean = false;
  type: boolean = false;
  presentation: boolean = false;

  selectedCountry: boolean;
  selectIdState: boolean;
  selectedState: boolean;
  selectIdCity: boolean;


  loadData: boolean = false;

  constructor() { }
}
