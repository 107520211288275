import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CatalogoInventoryItemPromotionalService } from '../../services/catalogo-inventory-ItemPromotional.service';
import { CatalogoItemPromotionalService } from '../../services/catalogo-ItemPromotional.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { InventaryProductService } from '../../services/inventary-product.service';


@Component({
  selector: 'app-modal-inventory-promotional-itesms',
  inputs: [
    'titleModal',
    'labelCode',
    'placeholderCode',
    'labelDesc',
    'placeholderDesc',
    'typeModal',
    'nameModal',
    'information1',
    'information2',
    'placeholderCampo',
    'placeholderCampo2',
    'campoAct',
    'campoCliente',
    'campoOficina',
    'campoVendedor',
    'maxCode',
    'maxDesc',
    'titleSave',
    'typeInput'],
  templateUrl: './modal-inventory-promotional-itesms.component.html',
  styleUrls: ['./modal-inventory-promotional-itesms.component.css']
})
export class ModalInventoryPromotionalItesmsComponent implements OnInit {
  titleModal: string;
  campoCode = 'Codigo:';
  placeholderCode = 'Codigo';
  campoDesc = 'NOMBRE:';
  placeholderDesc = 'Nombre';
  placeholderCampo: string;
  placeholderCampo2: string;
  typeModal = 'catalogo';
  nameModal: string;
  information1: string;
  information2: string;
  information3: string;
  formArticulos: FormGroup;
  codeMO: string | null;
  descMO: string | null;
  error_id = false;
  txt_id: string | null;
  error_code = false;
  txt_code: string | null;
  error_desc = false;
  txt_desc: string | null;
  error_precio = false;
  txt_precio: string | null;
  error_cantidad = false;
  txt_cantidad: string | null;
  maxCode = 10;
  maxDesc = 60;
  maxCapa = 18;
  titleSave: string;
  typeInput: boolean = true;
  inputType: string;
  codeId: string;
  precio: number | null;
  cantidad: number | null;

  constructor(public auth: AuthService,
    public ProductService: InventaryProductService,
    public CatalogoInventoryItemPromotionalService: CatalogoInventoryItemPromotionalService,
    public CatalogoItemPromotionalService: CatalogoItemPromotionalService,
    private listUrl: UrListService) {

    this.formArticulos = new FormGroup({
      mocode: new FormControl(this.codeMO, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      modesc: new FormControl(this.descMO, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ]),
      price: new FormControl(this.precio, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,10}(\.[0-9]{1,8})?$/)
      ]),
      quantity: new FormControl(this.cantidad, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,20}?$/)
      ]),  
    }); 
    }

  ngOnInit() {
  if (this.CatalogoInventoryItemPromotionalService.newMU) {
      this.codeId = this.CatalogoInventoryItemPromotionalService.editCode;
      this.codeMO = this.CatalogoInventoryItemPromotionalService.code;
      this.descMO = this.CatalogoInventoryItemPromotionalService.description;
      this.loadModal();
  
    }
    

  this.CatalogoInventoryItemPromotionalService.typeModal = 'catalogo';
  }

  getId() {
    return this.codeId;
  }

    loadModal(){

      const initAjax: Init = {
        method: 'put',
        url: this.CatalogoInventoryItemPromotionalService.urlLoading,
        auth: this.auth,
        body: { 
          code: this.CatalogoInventoryItemPromotionalService.code
        }
      };

      const configAjax: Config = {
        numInten : 1,
        titleLoading: `Obteniendo informacion del inventario`,
      };

      const ajax = new Ajax(initAjax,configAjax);
      ajax.call().then(resp => {
        console.log('resp: ',resp);
        if (resp.success) {
           if(resp.objeto != false){
              this.CatalogoInventoryItemPromotionalService.valuesObjects = resp.objeto;
              this.CatalogoInventoryItemPromotionalService.valuesObjects.precio = resp.objeto.precio;
              this.CatalogoInventoryItemPromotionalService.valuesObjects.cantidad = resp.objeto.cantidad;
              this.precio = resp.objeto.precio;
              this.cantidad = resp.objeto.cantidad;
           }
           else{
              this.precio = 0;
              this.cantidad = 0;
           }       
        }
      })
    }

  formatCode() {
    this.codeMO = this.codeMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
  }

  formatDesc() {
    this.descMO = this.descMO.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  formatPrecio() {
    this.error_id = false;
    this.txt_id = '';
    this.error_precio = false;
    this.txt_precio = '';
  }

  formatCantidad() {
    this.error_id = false;
    this.txt_id = '';
    this.error_cantidad = false;
    this.txt_cantidad = '';
  }

  saveObject(){
    let id_obj: any;
    if (this.CatalogoInventoryItemPromotionalService.newMU) {
      id_obj = '';
      this.CatalogoInventoryItemPromotionalService.confirmar = false;
    } else {
      id_obj = this.CatalogoInventoryItemPromotionalService.pk;
      this.CatalogoInventoryItemPromotionalService.confirmar = true;
    }


    if (this.codeMO.toUpperCase().trim() && this.descMO.toUpperCase().trim() ) {

    const initAjax: Init = {
        method: 'put',
        url: this.CatalogoInventoryItemPromotionalService.urlSave,
        auth: this.auth,
        body: {	
          code: this.codeMO.toUpperCase().trim(),
          precio: this.precio,
  		    cantidad: this.cantidad
        }
      };

        const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        console.log('resp: ',resp);
        if (resp.success) {
            
            if (resp.nuevo) {
            this.newObject(resp);
            } 
           else {            
             this.editObject(resp);
          }     
        }

        this.CatalogoInventoryItemPromotionalService.closeModal.hide();
      }).catch(error => {
        console.log('error: ',error);
        this.viewError(error);
      });
    }

    else{
        this.error_desc = true;
        this.txt_desc = 'Favor debe de llenar los campos correctamente';
    }	
  }

  newObject(resp) {
    this.CatalogoInventoryItemPromotionalService.resp= resp;
  }

  editObject(resp) {
    this.CatalogoInventoryItemPromotionalService.code = this.codeMO;
    this.CatalogoInventoryItemPromotionalService.description = this.descMO;
    this.CatalogoInventoryItemPromotionalService.precio = this.precio;
    this.CatalogoInventoryItemPromotionalService.cantidad = this.cantidad;

    this.CatalogoInventoryItemPromotionalService.resp= resp;         
  }

  closeModal() {
    this.CatalogoInventoryItemPromotionalService.closeModal.hide();
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }
    if (error.precio !== 'undefined') {
      if (error.precio) {
        this.error_precio = error.precio;
        this.txt_precio = error.txt_precio;
      }
    }
    if (error.cantidad !== 'undefined') {
      if (error.cantidad) {
        this.error_cantidad = error.cantidad;
        this.txt_cantidad = error.txt_cantidad;
      }
    }
    
  }
}

@Component({
  selector: 'app-modal-ArticuloPromocion-modal',
  template: `
    <app-modal-inventory-promotional-itesms
      titleModal="Entrada de Articulos de Promocion"
      information1="Precio"
  	  information2="Cantidad"
  	  placeholderCampo = "Precio"
  	  placeholderCampo2 = "Cantidad"
      nameModal="Entrada de Articulos de Promocion"
      titleSave="Catalogo Articulos de Promocion"
      >
    </app-modal-inventory-promotional-itesms>
  `
})


export class ModalEntradaArticuloPromocion implements OnInit {
  constructor(
    public CatalogoInventoryItemPromotionalService: CatalogoInventoryItemPromotionalService,
    public ProductService: InventaryProductService,
    private listUrl: UrListService
  ) {}

  ngOnInit() {
    this.CatalogoInventoryItemPromotionalService.urlSave = this.listUrl.url('saveinventorypromocion');
    this.CatalogoInventoryItemPromotionalService.urlLoading = this.listUrl.url('view_inventory_promociones');
    this.CatalogoInventoryItemPromotionalService.typeModal = this.ProductService.typeModal;
    this.CatalogoInventoryItemPromotionalService.typeModal = 'catalogo';
    
  }
}
