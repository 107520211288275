import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isLogin()) {
      if (this.auth.isBlock()) {
        this.router.navigate(['/block']);
        return false;
      } else {
        return true;
      }
    } else {
      this.auth.logout(state.url);
      return false;
    }
  }
}
