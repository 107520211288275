import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import Swal from 'sweetalert2';
import { StationService } from '../../services/station.service';
import { ErrorsStationService } from '../../services/errors-station.service';

@Component({
  selector: 'app-modal-station',
  templateUrl: './modal-station.component.html',
  styleUrls: ['./modal-station.component.css']
})
export class ModalStationComponent implements OnInit {
  titleModal: string;
  labelCode = 'Codigo:';
  placeholderCode = 'CODIGO';
  labelDesc = 'Descripcion:';
  placeholderDesc = 'DESCRIPCION';
  labelStatusa = 'Activo';
  labelStatusi = 'Inactivo';
  typeModal = 'catalogo';
  nameModal: string;
  formSta: FormGroup;
  maxCode = 3;
  maxDesc = 30;
  titleSave = 'Guardando Estacion';
  typeInput = true;
  urlsave: any;
  codeId: string;

  constructor(
    public auth: AuthService,
    private listUrl: UrListService,
    public StaSrv: StationService,
    public Errors: ErrorsStationService
  ) {
    this.urlsave = this.listUrl.url('save_station');
    this.formSta = new FormGroup({
      code: new FormControl(this.StaSrv.code, [
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      desc: new FormControl(this.StaSrv.desc, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ]),
      status: new FormControl(this.StaSrv.status, [])
    });
  }

  get code() { return this.formSta.get('code'); }
  get desc() { return this.formSta.get('desc'); }
  get status() { return this.formSta.get('status'); }

  getId(){
    return this.codeId;
  }

  ngOnInit() {
    this.titleModal = 'Añadir Estacion';
    this.StaSrv. typeModal = this.typeModal;
    if (!this.StaSrv.new) {
      this.codeId = 'ADMCAIU00078';
      this.titleModal = 'Actualizar Estacion';
      this.StaSrv.pk = this.StaSrv.object.id;
      this.StaSrv.code = this.StaSrv.object.code;
      this.StaSrv.desc = this.StaSrv.object.desc;
      this.StaSrv.status = this.StaSrv.object.status;
    } else {
      this.codeId = 'ADMCAIU00077';
      this.formSta.reset();
      this.StaSrv.pk = '';
      this.StaSrv.code = '';
      this.StaSrv.desc = '';
      this.StaSrv.status = true;
    }
  }

  formatCode() {
    this.StaSrv.code = this.StaSrv.code.toUpperCase().trim();
    this.Errors.error_id = false;
    this.Errors.txt_id = '';
    this.Errors.error_code = false;
    this.Errors.txt_code = '';
    if (this.StaSrv.code) {
      while (this.StaSrv.code.length < this.maxCode) {
        this.StaSrv.code = '0'+this.StaSrv.code;
      } 
    }
  }

  formatDesc() {
    this.StaSrv.desc = this.StaSrv.desc.toUpperCase();
    this.Errors.error_id = false;
    this.Errors.txt_id = '';
    this.Errors.error_desc = false;
    this.Errors.txt_desc = '';
  }

  saveObject() {
    let id_obj: any;
    if (this.StaSrv.new) {
      id_obj = '';
    } else {
      id_obj = this.StaSrv.pk;
    }
    if (this.StaSrv.code.toUpperCase().trim() && this.StaSrv.desc.toUpperCase().trim()) {
      const initAjax: Init = {
        method: 'put',
        url: this.urlsave,
        auth: this.auth,
        body: {
          id_obj,
          code: this.StaSrv.code.toUpperCase().trim(),
          desc: this.StaSrv.desc.toUpperCase().trim(),
          status: this.StaSrv.status,
          tipo: this.StaSrv.typeModal
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: this.titleSave,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };

      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          this.StaSrv.continuar = true;
          this.StaSrv.evenAdd.emit(resp);
        }
        this.StaSrv.closeModal.hide();
      }).catch(error => {
        this.viewError(error);
      });
    } else {
      this.StaSrv.code = this.StaSrv.code.trim();
      this.StaSrv.desc = this.StaSrv.desc.trim();
    }
  }

  closeModal() {
    this.StaSrv.closeModal.hide();
  }

  viewError(error) {
    if (error.error_id !== 'undefined') {
      if (error.error_id) {
        this.Errors.error_id = error.error_id;
        this.Errors.txt_id = error.txt_id;
      }
    }
    if (error.error_code !== 'undefined') {
      if (error.error_code) {
        this.Errors.error_code = error.error_code;
        this.Errors.txt_code = error.txt_code;
      }
    }
    if (error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.Errors.error_desc = error.error_desc;
        this.Errors.txt_desc = error.txt_desc;
      }
    }
  }
}
