import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Permiso } from 'src/app/core/auth/user';

export interface Permisof extends Permiso {
  // Interfaz que añade los campos que se enviaran por ajax
  atcreate?: boolean;
  atread?: boolean;
  atupdate?: boolean;
  atdelete?: boolean;
}

export interface Permisosf extends Array<Permisof> {
  [key: number]: Permisof;
}

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.css']
})

export class SupervisorComponent implements OnInit {

  users: any;
  rols: [{ // Roles disponibles
    rol_id: any,
    name: any,
    perms: Permisosf,
  }];
  rols_s: any; // Rol seleccionado

  perm_t: Permisosf; // Permisos totales (Todos los disponibles de la compañia)
  perm_r: Permisosf; // Permisos del rol selecconado
  perm_s: Permisosf; // permisos asignados
  perm_ns: Permisosf; // Permisos no asignados

  perm_s_a: Permisosf; // Permisos asignado nuevo  (añadido)
  perm_ns_a: Permisosf; // Permisos no asignado nuevo (quitado)


  infou = { // Ususario seleccionado
    user_pk: null,
    first_name: ``,
    last_name: ``,
    telephone: ``,
    email: ``,
    perms: [],
    rol: {}
  };

  codeId = 'BSSGUIU00001';

  constructor(
    private auth: AuthService,
    private listUrl: UrListService,
  ) {
    this.rols_s = {};
    this.perm_t = [];
    this.perm_r = [];
    this.perm_s = [];
    this.perm_ns = [];
    this.perm_s_a = [];
    this.perm_ns_a = [];
   }

  ngOnInit() {
    if (this.auth.user.company.permisos) {
      this.perm_t = this.auth.user.company.permisos.slice(0);
    }
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('inisupervisor'),
      auth: this.auth,
      body: {}
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(r => {
      this.users = r.users;
      this.rols = r.rols;
    }).catch(error => {
      return false;
    });
  }

  changeUsr(e) {
    // Al escoger usuario, se cambian los datos de usuario al que se selecciono,
    // incluyendo el rol y los permisos individuales que tenga el usuario con su crud
    if (e.target.value !== '') {
      this.infou = Object.assign({}, this.users[e.target.value]);
      this.rols_s = Object.assign({}, this.infou.rol);
      this.perm_s = this.infou.perms.slice(0);
      this.perm_ns = this.perm_t.slice(0);
      this.perm_s.forEach(( v, k ) => {
        this.perm_ns = this.perm_ns.filter(item => item.pk !== v.pk);
      });
    } else {
      this.infou = {
        user_pk: null,
        first_name: ``,
        last_name: ``,
        telephone: ``,
        email: ``,
        perms: [],
        rol: {}
      };
      this.rols_s = {};
      this.perm_s = [];
      this.perm_ns = [];
    }
  }

  changeRol(e) {
    // Al escoger rol, se vacia la plantilla de permisos que trae ese rol sin importar
    // que el usuario ya tenga permisos asignados, en ese caso debera escogerlos de nuevo.
    if (e.target.value !== '') {
      this.rols_s = Object.assign({}, this.rols[e.target.value]);
      this.perm_s = [];
      this.perm_ns = this.perm_t.slice(0);
      const rolsperm = this.rols_s.perms.slice(0);
      rolsperm.forEach(( v: any, k: any ) => {
        this.agregaPerm(v);
      });
    } else {
      this.rols_s = {};
      this.perm_s = [];
      this.perm_ns = [];
    }
  }

  isOne(p, o= 'r') {
    // Evita que un permiso se quede sin un atributo CRUD seleccionado
    o = o.toLowerCase();
    switch (o) {
      case 'c':
        return !p.atread && !p.atupdate && !p.atdelete;
      case 'r':
        return !p.atcreate && !p.atupdate && !p.atdelete;
      case 'u':
        return !p.atcreate && !p.atread && !p.atdelete;
      case 'd':
        return !p.atcreate && !p.atread && !p.atupdate;
    }
  }

  onlyOne(i, o= 'r') {
    // Devuelve el objeto de Permiso con solo un atributo CRUD selecionado
    // segun el parametro enviado.
    i.atcreate = false;
    i.atread = false;
    i.atupdate = false;
    i.atdelete = false;
    o = o.toLowerCase();
    switch (o) {
      case 'c':
        i.atcreate = true;
        break;
      case 'r':
        i.atread = true;
        break;
      case 'u':
        i.atupdate = true;
        break;
      case 'd':
        i.atdelete = true;
        break;
    }
    return i;
  }

  quitaPerm(i) {
    // Extrae un permiso especifico de la lista de permisos seleccionados para
    // añadirlo a la lista de permisos disponibles
    this.perm_s = this.perm_s.filter(item => item.pk !== i.pk);
    this.perm_ns.push(i);
  }

  agregaPerm(i) {
    // Extrae un permiso especifico de la lista de permisos disponibles para
    // añadirlo a la lista de permisos selecionados.
    // Adicionalmente añade el atributo R del permiso añadido o el atributo inmediato disponible
    // En caso de no tener ningun atributo CRUD, por defecto se establecera el R.
    this.perm_ns = this.perm_ns.filter(item => item.pk !== i.pk);
    if (i.read) {
      i = this.onlyOne(i);
    } else if (i.create) {
      i = this.onlyOne(i, 'c');
    } else if (i.update) {
      i = this.onlyOne(i, 'u');
    } else if (i.delete) {
      i = this.onlyOne(i, 'd');
    } else {
      i = this.onlyOne(i);
      i.read = true;
    }
    this.perm_s.push(i);
  }

  pass() {
    return this.perm_s.length && this.infou.user_pk !== null && this.rols_s.rol_id;
  }

  add_prefix_crud(p) {
    let prefix = '';
    if (p.atcreate && p.create) {
      prefix = `${prefix}C`;
    }
    if (p.atread && p.read) {
      prefix = `${prefix}R`;
    }
    if (p.atupdate && p.update) {
      prefix = `${prefix}U`;
    }
    if (p.atdelete && p.delete) {
      prefix = `${prefix}D`;
    }
    return `${p.pk}_${prefix}`;
  }

  guardar() {
    let permslist = [];
    this.perm_s.forEach(( v, k ) => {
      permslist.push( this.add_prefix_crud(v) );
    });
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('supervisor'),
      auth: this.auth,
      body: {
        permslist,
        user: this.infou,
        rols: this.rols_s
      }
    };
    const configAjax: Config = {
      visible: true,
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(r => {
    }).catch(error => {
      return false;
    });
    // Obtner permisos nuevos
    // se envian los permisos seleccionados
  }

  changeCodeId(data){
    this.codeId = data;
  }

  getId() {
    return this.codeId;
  }

}
