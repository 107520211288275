import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Product } from '../../interface/products';


@Component({
  selector: 'app-buy-product-modal',
  outputs: ['evenAdd'],
  inputs: ['product', 'title', 'action',],
  templateUrl: './buy-product-modal.component.html',
  styleUrls: ['./buy-product-modal.component.css']
})
export class BuyProductModalComponent implements OnInit {
  
  evenAdd: EventEmitter<any> = new EventEmitter();
  peso: number;
  cantidad: number;
  product: Product;
  title: string;
  action: 'rmv' | 'add';
  espera = false;
  espera2 = true;

  @ViewChild('canti', {static:false}) canti: ElementRef;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    if (this.product.unit_weight) {
      this.peso = this.product.unit_weight;
    } else {
      this.peso = 0;
    }
    this.cantidad = 1;
  }

  addItems() {
    if (this.cantidad >= 1 && this.cantidad <= (this.product.inventory_p.quantity - this.product.inventory_p.reserved)) {
      let product: Product = Object.assign({}, this.product);
      product.pedido = this.cantidad;
      product.pedido_item = this.cantidad;
      product.unit_weight_m = this.peso;
      product.loading = true;
      product.error = false;
      product.accion = 'add';
      product.errortxt = '';
      this.evenAdd.emit(product);
      this.closeModal();
    } else {
      // mostrar error de cantidad incorrecta en input y luego quitar alerta
      const oe: SweetAlertOptions = {
        icon: 'error',
        title: 'La cantidad introducida es incorrecta',
        text: '',
        showConfirmButton: true,
        confirmButtonText: 'Ok'
      };
      const alerer = Swal.fire(oe);
      this.espera = true;
    }
  }

  guardado() {
    console.log('guardo');
    if (this.espera) {
      console.log('guardo pase');
      this.espera = false;
      if (this.action == 'add') {
        this.addItems();
      } else {
        this.removeItems();
      }
    }
  }

  removeItems() {
    if (this.cantidad >= 1 && this.cantidad <= this.product.pedido) {
      let product: Product = Object.assign({}, this.product);
      product.pedido = this.cantidad;
      product.pedido_item = this.cantidad;
      product.unit_weight_m = this.peso;
      product.loading = true;
      product.error = false;
      product.accion = 'rmv';
      this.evenAdd.emit(product);
      this.closeModal();
    } else {
      // mostrar error de cantidad incorrecta en input y luego quitar alerta
      const oe: SweetAlertOptions = {
        icon: 'error',
        title: 'La cantidad introducida es incorrecta',
        text: '',
        showConfirmButton: true,
        confirmButtonText: 'Ok'
      };
      const alerer = Swal.fire(oe);
      this.espera = true;
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
