import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EntriesExitsService } from '../../entries-exits.service';
import { numValidators } from '../../../setting/validators';

@Injectable({
  providedIn: 'root'
})
export class AddPurchaseService {

  formCompra: FormGroup; //Formuario Agregar Compra

  constructor(
    public ViewService: EntriesExitsService
  ) {
    this.formCompra = new FormGroup({
      orderco: new FormControl(this.ViewService.orderCo, [
        Validators.maxLength(10)
      ]),
      typetransco: new FormControl(this.ViewService.nroTypeTrans,[
        Validators.required
      ]),
      depositco: new FormControl(this.ViewService.depositCo,[
        Validators.required
      ]),
      supplierco: new FormControl(this.ViewService.supplierCo,[
        Validators.required
      ]),
      suppliercodesc: new FormControl(this.ViewService.descSupplierCo),
      currencyco: new FormControl(this.ViewService.currencyCo,[
        Validators.required
      ]),
      billco: new FormControl(this.ViewService.billCo, [
        Validators.required,
        Validators.maxLength(10)
      ]),
      controlco: new FormControl(this.ViewService.controlCo, [
        Validators.required,
        Validators.maxLength(20)
      ]),
      datedoco: new FormControl(this.ViewService.dateDocCo, [
        Validators.required
      ]),
      datearrivalco: new FormControl(this.ViewService.dateArrivalCo, [
        Validators.required
      ]),
      observco: new FormControl(this.ViewService.observationsCo),
      lisaeaco: new FormControl(this.ViewService.lisaeaCo, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      drinktco: new FormControl(this.ViewService.drinkTaxCo, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      origtotalco: new FormControl(this.ViewService.origTotalCo, [
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ])
    });
  }
}
