import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvitationComponent } from '../administrativo/invitation/invitation.component';
import { AuthGuardService } from 'src/app/core/canActivate/auth-guard-login';
import { SelectCompanyGuard } from 'src/app/core/canActivate/select-company.guard';
import { ViewPermGuard } from 'src/app/core/canActivate/view-perm.guard';
import { CashOpeningComponent } from './cash-opening/cash-opening.component';
import { BillingComponent } from './billing/billing.component';
import { CashOpeningGuard } from './canActivate/cash-opening.guard';
import { BankComponent } from './fourth/fourth.component';
import { CustomerPDVComponent } from './catalogo/customer/customer.component';

const routes: Routes = [
  { path: 'cash_opening', component: CashOpeningComponent, canActivate: [ AuthGuardService, SelectCompanyGuard ]},
  { path: 'billing', component: BillingComponent, canActivate: [ AuthGuardService, SelectCompanyGuard, CashOpeningGuard ]},
  { path: 'banks', component: BankComponent, canActivate: [ AuthGuardService, SelectCompanyGuard ]},
  { path: 'customer', component: CustomerPDVComponent, canActivate: [ AuthGuardService, SelectCompanyGuard ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PuntodeventaRoutingModule { }
