import { Component, OnInit } from '@angular/core';
import { CatalogoFourthService } from '../services/catalogo-fourth.service';
import { CatalogoOneService } from '../../administrativo/services/catalogo-one.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import Swal from 'sweetalert2';
import { ModalCurrencyComponent } from '../../administrativo/modals-one/modals-one.component';

@Component({
  selector: 'app-modals-fourth',
  inputs: [
    'titleModal',
    'labelCode',
    'placeholderCode',
    'labelDesc',
    'placeholderDesc',
    'typeModal',
    'nameModal',
    'maxCode',
    'maxDesc',
    'titleSave',
    'typeInput'],
  templateUrl: './modal-bank.component.html',
  styleUrls: ['./modal-bank.component.css']
})
export class ModalsFourthComponent implements OnInit {
  cou_selected: string | null | number = null;
  curr_symbol: any;
  titleSave: string;
  enviando: boolean;
  datForm: any;
  bsModalRef: BsModalRef;
  codeId: string;

  constructor(
    public CatalogoFourthService: CatalogoFourthService,
    public CatalogoOneService: CatalogoOneService,
    public auth: AuthService, 
    private listUrl: UrListService,
    private modalService: BsModalService
  ) {
    this.CatalogoOneService.urlSave = this.listUrl.url('savecurrency');
    this.CatalogoOneService.viewModal = ModalCurrencyComponent;
  	this.curr_symbol = '---';
  	this.datForm = this.CatalogoFourthService.datForm;
  	this.enviando = false;
  }

  ngOnInit() {
    if (this.CatalogoFourthService.newMU) {
      this.codeId = this.CatalogoFourthService.addCode;
      this.cou_selected = null;
      this.CatalogoFourthService.curr_selected = null;
      this.datForm.reset();
    } else {
      this.codeId = this.CatalogoFourthService.editCode;
      this.cou_selected = String(this.CatalogoFourthService.object.country);
      this.CatalogoFourthService.curr_selected = this.CatalogoFourthService.object.currency_id;
      this.curr_symbol = this.CatalogoFourthService.object.currency_p.currency_simbol;
      this.CatalogoFourthService.datForm.get('name').setValue(this.CatalogoFourthService.object.name);
      this.CatalogoFourthService.datForm.get('desc').setValue(this.CatalogoFourthService.object.description);
      this.CatalogoFourthService.datForm.get('addrs').setValue(this.CatalogoFourthService.object.address);
    }
  }

  getId() {
    return this.codeId;
  }

  saveObject(){
  	if(this.enviando == false) {
      if ((this.cou_selected == null || this.cou_selected == '') && (this.CatalogoFourthService.curr_selected == null || this.CatalogoFourthService.curr_selected == '')) {
        Swal.fire({
          icon: 'warning',
          title: 'Operación rechazada!...',
          text: 'Los campos de moneda y país no deben estar vacíos...'
        });
      } else {
        if(this.datForm.valid) {
          if(this.CatalogoFourthService.newMU == true){
            this.enviando = true;
              const initAjax: Init = {
                method: 'put',
                url: this.CatalogoFourthService.urlSave,
                auth: this.auth,
                body: {
                  name: this.datForm.value.name,
                  description: this.datForm.value.desc,
                  currency: this.CatalogoFourthService.curr_selected,
                  address: this.datForm.value.addrs,
                  country: this.cou_selected,
                }
              };
              const configAjax: Config = {
                numInten : 1,
                titleLoading: `Guardando ${this.titleSave}`,
                titleSuccess: '',
                titleErrorNormal: 'Error',
                textErrorNormal: 'Ha ocurrido un Error.',
                confirmButtonTextError: 'Cerrar',
                validatejson: (resp: any, obj: Ajax) => {
                  return new Promise((success, error) => {
                    if (resp.success === false) {
                      obj.declareConfig({
                        alertaError : Swal.mixin({
                          icon: 'error',
                          title: 'Ocurrió un error',
                          text: resp.message,
                          showConfirmButton: false,
                          confirmButtonText: 'Cerrar',
                        })
                      });
                      error(resp);
                    } else {
                      success(resp);
                    }
                  });
                }
              };const ajax = new Ajax(initAjax, configAjax);
              ajax.call().then(resp => {
                if (resp.success) {
                  resp.new = true;
                  this.CatalogoFourthService.continuar = true;
                  this.CatalogoFourthService.evenAdd.emit(resp);
                  this.enviando = false;
                  this.closeModal();
                }
              }).catch(error => {
                this.enviando = false;
              });
          } else {
             this.enviando = true;
              const initAjax: Init = {
                method: 'put',
                url: this.CatalogoFourthService.urlEdit,
                auth: this.auth,
                body: {
                  id_obj: this.CatalogoFourthService.pk,
                  name: this.datForm.value.name,
                  description: this.datForm.value.desc,
                  currency: this.CatalogoFourthService.curr_selected,
                  address: this.datForm.value.addrs,
                  country: this.cou_selected,
                }
              };
              const configAjax: Config = {
                numInten : 1,
                titleLoading: `Guardando ${this.titleSave}`,
                titleSuccess: '',
                titleErrorNormal: 'Error',
                textErrorNormal: 'Ha ocurrido un Error.',
                confirmButtonTextError: 'Cerrar',
                validatejson: (resp: any, obj: Ajax) => {
                  return new Promise((success, error) => {
                    if (resp.success === false) {
                      obj.declareConfig({
                        alertaError : Swal.mixin({
                          icon: 'error',
                          title: 'Ocurrió un error',
                          text: resp.message,
                          showConfirmButton: false,
                          confirmButtonText: 'Cerrar',
                        })
                      });
                      error(resp);
                    } else {
                      success(resp);
                    }
                  });
                }
              };const ajax = new Ajax(initAjax, configAjax);
              ajax.call().then(resp => {
                if (resp.success) {
                  resp.new = false;
                  this.CatalogoFourthService.continuar = true;
                  this.CatalogoFourthService.evenAdd.emit(resp);
                  this.enviando = false;
                  this.closeModal();
                }
              }).catch(error => {
                this.enviando = false;
              });
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Operación rechazada!...',
            text: 'Por favor revise cada uno de los campos introducidos...'
          });
        }
      }
    }
  }
  
  openModalCurrency() {
    this.CatalogoFourthService.typeModal = 'banks';
    this.CatalogoFourthService.modelo = 'Banks';
    this.CatalogoOneService.newMU = true;
    this.CatalogoOneService.pk = '';
    const initialState = {
      componentParent: this
    };
    this.bsModalRef = this.modalService.show(this.CatalogoOneService.viewModal, {initialState});
    this.bsModalRef.setClass('modal-dialog-centered zindex-one');
    this.CatalogoOneService.closeModal = this.bsModalRef;
  }

  closeModal() {
    this.CatalogoFourthService.pk = -1;
    this.CatalogoFourthService.closeModal.hide();
  }
}

@Component({
  selector: 'app-modal-bank',
  template: `
    <app-modals-fourth
      titleModal="Agregar Banco"
      nameModal="banco"
      titleSave="Banco"
      >
    </app-modals-fourth>
  `
})
export class ModalBankComponent implements OnInit {
  constructor(
    public CatalogoFourthService: CatalogoFourthService, 
    private listUrl: UrListService
  ) { }

  ngOnInit() {
  	this.CatalogoFourthService.urlSave = this.listUrl.url('register_bank');
    this.CatalogoFourthService.urlEdit = this.listUrl.url('edit_bank');
  }

}
