import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpHeaders } from '@angular/common/http';
import { Init } from './init-ajax';
import { Cache } from '../../cache';
import { positionElements } from 'ngx-bootstrap/positioning/public_api';

export interface Config extends Object {
    timecloseLoading?: number;
    numInten?: number;
    inten?: number;
    timeEachInten?: number;
    visible?: boolean;
    autoReNew?: boolean;
    autoReNewSlep?: number;
    titleLoading?: string;
    titleError?: string;
    titleSuccess?: string;
    textError?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    timeCloseSuccess?: number;
    validatejson?: any;
    htmlCargar?: string;
    showCancelButton?: boolean;
    showConfirmButton?: boolean;
    showCloseButton?: boolean;
    forceNormal?: boolean;
    titleErrorNormal?: string;
    textErrorNormal?: string;
    showConfirmButtonError?: boolean;
    confirmButtonTextError?: string;
    alertaLoading?: any;
    alertaError?: any;
    alertaReintentar?: any;
    alertaSuccess?: any;
    background?:string;
    
}

export class Ajax {

    config: Config = {
        timecloseLoading: 1000,
        numInten : 5,
        inten : 1,
        timeEachInten : 500,
        visible : true,
        autoReNew : false,
        autoReNewSlep : 10000,
        titleLoading : '',
        titleError : 'Oops...',
        titleSuccess : '<div class="">La Operacion Fue Exitosa</div>',
        textError : 'Algo Salio Mal, quieres reintentar?',
        confirmButtonText : 'Reintenter',
        cancelButtonText : 'Cancelar',
        timeCloseSuccess : 1500,
        validatejson : this.validatej,
        htmlCargar : `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
        showCancelButton : true,
        showConfirmButton : true,
        showCloseButton : false,
        forceNormal : false,
        titleErrorNormal : '',
        textErrorNormal : 'Ocurrio un Error',
        showConfirmButtonError : true,
        confirmButtonTextError : 'Cerrar',
        // background: 'transparent',
        
    };

    success_global: any;
    error_global: any;
    
    constructor(public init: Init, config?: Config) {
        if (typeof this.init.httpOptions === 'undefined') {
            this.init.httpOptions = {
                headers: new HttpHeaders()
            };
        } else {
            if (typeof this.init.httpOptions.headers === 'undefined') {
                this.init.httpOptions.headers = new HttpHeaders();
            }
        }
        this.declareConfig(config);
    }

    declareConfig(config: Config) {
        if (config) {
            if (typeof config.timecloseLoading !== 'undefined') {
                this.config.timecloseLoading = config.timecloseLoading;
            }
            if (typeof config.numInten !== 'undefined') {
                this.config.numInten = config.numInten;
            }
            if (typeof config.timeEachInten !== 'undefined') {
                this.config.timeEachInten = config.timeEachInten;
            }
            if (typeof config.titleLoading !== 'undefined') {
                this.config.titleLoading = config.titleLoading;
            }
            if (typeof config.titleError !== 'undefined') {
                this.config.titleError = config.titleError;
            }
            if (typeof config.titleSuccess !== 'undefined') {
                this.config.titleSuccess = config.titleSuccess;
            }
            if (typeof config.textError !== 'undefined') {
                this.config.textError = config.textError;
            }
            if (typeof config.confirmButtonText !== 'undefined') {
                this.config.confirmButtonText = config.confirmButtonText;
            }
            if (typeof config.cancelButtonText !== 'undefined') {
                this.config.cancelButtonText = config.cancelButtonText;
            }
            if (typeof config.timeCloseSuccess !== 'undefined') {
                this.config.timeCloseSuccess = config.timeCloseSuccess;
            }
            if (typeof config.htmlCargar !== 'undefined') {
                this.config.htmlCargar = config.htmlCargar;
            }
            if (typeof config.visible !== 'undefined') {
                this.config.visible = config.visible;
            }
            if (typeof config.autoReNew !== 'undefined') {
                this.config.autoReNew = config.autoReNew;
            }
            if (typeof config.autoReNewSlep !== 'undefined') {
                this.config.autoReNewSlep = config.autoReNewSlep;
            }
            if (typeof config.validatejson !== 'undefined') {
                this.config.validatejson = config.validatejson;
            }
            if (typeof config.showCancelButton !== 'undefined') {
                this.config.showCancelButton = config.showCancelButton;
            }
            if (typeof config.showConfirmButton !== 'undefined') {
                this.config.showConfirmButton = config.showConfirmButton;
            }
            if (typeof config.showCloseButton !== 'undefined') {
                this.config.showCloseButton = config.showCloseButton;
            }
            if (typeof config.forceNormal !== 'undefined') {
                this.config.forceNormal = config.forceNormal;
            }
            if (typeof config.titleErrorNormal !== 'undefined') {
                this.config.titleErrorNormal = config.titleErrorNormal;
            }
            if (typeof config.textErrorNormal !== 'undefined') {
                this.config.textErrorNormal = config.textErrorNormal;
            }
            if (typeof config.showConfirmButtonError !== 'undefined') {
                this.config.showConfirmButtonError = config.showConfirmButtonError;
            }
            if (typeof config.confirmButtonTextError !== 'undefined') {
                this.config.confirmButtonTextError = config.confirmButtonTextError;
            }
        }
        if (typeof this.config.alertaLoading === 'undefined') {
            this.config.alertaLoading = Swal.mixin({
                title: this.config.titleLoading,
                html: this.config.htmlCargar,
                allowOutsideClick: false,
                showConfirmButton: false,
                background: this.config.background
            });
        }
        if (typeof this.config.alertaError === 'undefined') {
            const optionsalertaError: SweetAlertOptions = {
                icon: 'error',
                title: this.config.titleErrorNormal,
                text: this.config.textErrorNormal,
                showConfirmButton: this.config.showConfirmButtonError,
                confirmButtonText: this.config.confirmButtonTextError,
            };
            this.config.alertaError = Swal.mixin(optionsalertaError);
        }
        if (typeof this.config.alertaReintentar === 'undefined') {
            const optionsalertaReintentar: SweetAlertOptions = {
                icon: 'error',
                title: this.config.titleError,
                text: this.config.textError,
                confirmButtonText: this.config.confirmButtonText,
                showConfirmButton: this.config.showConfirmButton,
                showCancelButton: this.config.showCancelButton,
                allowEscapeKey: false,
                allowEnterKey: false,
                cancelButtonText: this.config.cancelButtonText,
                showCloseButton: this.config.showCloseButton,
                preConfirm: (bton) => {
                    this.callReNew();
                }
            };
            this.config.alertaReintentar = Swal.mixin(optionsalertaReintentar);
        }
        if (typeof this.config.alertaSuccess === 'undefined') {
            const optionsalertaSuccess: SweetAlertOptions = {
                icon: 'success',
                title: this.config.titleSuccess,
                showConfirmButton: false,
                timer: this.config.timeCloseSuccess,
                background: this.config.background
                
                
            };
            this.config.alertaSuccess = Swal.mixin(optionsalertaSuccess);
        }

        if (config) {
            if (typeof config.alertaLoading !== 'undefined' && typeof config.alertaLoading === 'function') {
                this.config.alertaLoading = config.alertaLoading;
            }
            if (typeof config.alertaError !== 'undefined' && typeof config.alertaError === 'function') {
                this.config.alertaError = config.alertaError;
            }
            if (typeof config.alertaSuccess !== 'undefined' && typeof config.alertaSuccess === 'function') {
                this.config.alertaSuccess = config.alertaSuccess;
            }
            if (typeof config.alertaReintentar !== 'undefined' && typeof config.alertaReintentar === 'function') {
                this.config.alertaReintentar = config.alertaReintentar;
            }
        }
    }

    validatej(resp: any): Promise<any> {
        return new Promise((success, error) => {
            if (typeof resp.success !== 'undefined' && resp.success) {
                success(resp);
            } else {
                error(resp.message);
            }
        });
    }

    check() {
        if (typeof this.init.url === 'undefined') {
            throw new Error('La url no puede estar vacia');
        }
        if (!this.init.httpOptions.headers.has('X-CSRFToken')) {
            this.init.httpOptions.headers = this.init.httpOptions.headers.set('X-CSRFToken', Cache.getCookie('csrftoken'));
        }
    }

    defaultParams() {
        this.config.inten = 1;
    }

    callReNew() {
        /**
         * llama a las funciones para reintentar una peticion
         */
        this.defaultParams();
        //this.call();
        this.proceso(this.config);
    }

    call(config: null | Config = null): Promise<any> {
        return new Promise((success, error) => {
            this.success_global = success;
            this.error_global = error;
            this.proceso(config);
        });
    }

    proceso(config: null | Config) {
        this.declareConfig(config);
        this.open_loading();
        if (this.config.inten <= this.config.numInten) {
            let timeeach = this.config.timeEachInten;
            if (this.config.inten === 1) {
                timeeach = 0;
            }
            this.config.inten += 1;
            if (this.init.auth.isLogin() && this.config.forceNormal === false) {
                this.init.httpOptions.headers = this.init.httpOptions.headers.set('Authorization', this.init.auth.token.token);
                if (this.init.auth.isCompany()) {
                    this.init.httpOptions.headers = this.init.httpOptions.headers.set('Company', this.init.auth.getCompanyToken().token);
                }
            }
            setTimeout(() => {
                this.peticion().then((response: any) => {
                    this.config.validatejson(response, this).then((validate: any) => {
                        this.close_loading().then(() => {
                            this.open_success();
                            this.success_global(validate);
                        });
                    }).catch((errvalidate: any) => {
                        this.close_loading().then(() => {
                            if (typeof errvalidate === 'object') {
                                this.open_error(errvalidate.message);
                                this.error_global(errvalidate);
                            } else {
                                this.open_error(errvalidate);
                                this.error_global(errvalidate);
                            }
                        });
                    });
                }).catch(er => {
                    this.proceso(config);
                });
            }, timeeach);
        } else {
            this.close_loading().then(() => {
                this.open_error_reintentar();
                if (this.config.autoReNew && !this.config.visible) {
                    setTimeout(() => {
                        this.callReNew();
                    }, this.config.autoReNewSlep);
                } else {
                    //this.error_global(false);
                }
            });
        }
    }

    peticion(): Promise<any> {
        return new Promise((success, error) => {
            if (this.init.url.urlList.activeWaiter && this.init.auth.statusSocket) {
                this.socket(success, error);
            } else {
                if (this.init.method === 'post') {
                    this.post(success, error);
                } else if (this.init.method === 'put') {
                    this.put(success, error);
                } else if (this.init.method === 'get') {
                    this.get(success, error);
                }
            }
        });
    }

    socket(resolve: any, reject: any) {
        this.init.auth.socket.st.emit(this.init.url.url(), this.init.body, (resp) => {
            if (resp.success) {
                resolve(resp);
            } else {
                if (typeof resp.code === 'undefined') {
                    // ocurrio un error de mesero
                    reject(resp);
                } else {
                    reject(resp);
                }
            }
        });
    }

    serializeUrl(url: string): string {
        if (typeof this.init.body !== 'undefined') {
            let cont = 1;
            for (const key in this.init.body) {
                if (cont === 1) {
                    cont = cont + 1;
                    url = `${url}?${key}=${this.init.body[key]}`;
                } else {
                    cont = cont + 1;
                    url = `${url}&${key}=${this.init.body[key]}`;
                }
            }
        }
        return url;
    }

    get(success: any, error: any) {
        this.init.auth.http.get(this.serializeUrl(this.init.url.url()), this.init.httpOptions).subscribe(response => {
            success(response);
        }, err => {
            error(err);
        });
    }

    post(success: any, error: any) {
        this.init.auth.http.post(this.init.url.url(), this.init.body, this.init.httpOptions).subscribe(response => {
            success(response);
        }, err => {
            error(err);
        });
    }

    put(success: any, error: any) {
        this.init.auth.http.put(this.init.url.url(), this.init.body, this.init.httpOptions).subscribe(response => {
            success(response);
        }, err => {
            error(err);
        });
    }

    open_success() {
        if (!this.config.alertaSuccess.isVisible()) {
            if (this.config.visible) {
                this.config.alertaSuccess.fire();
            }
        }
    }

    close_success() {
        if (this.config.visible) {
            if (this.config.alertaSuccess.isVisible()) {
                this.config.alertaSuccess.close();
            }
        }
    }

    open_loading() {
        if (this.config.visible) {
            if (!this.config.alertaLoading.isVisible()) {
                this.config.alertaLoading.fire();
            }
        }
    }

    close_loading() {
        return new Promise((success, error) => {
            if (this.config.visible) {
                setTimeout(() => {
                    if (this.config.alertaSuccess.isVisible()) {
                        this.config.alertaLoading.close();
                    }
                    let checkAlert = setInterval(() => {
                        if (!this.config.alertaLoading.isVisible()) {
                            success(true);
                            clearInterval(checkAlert);
                            checkAlert = null;
                        }
                    }, 500);
                }, this.config.timecloseLoading);
            } else {
                success(true);
            }
        });
    }

    open_error_reintentar() {
        if (this.config.visible) {
            if (!this.config.alertaReintentar.isVisible()) {
                this.config.alertaReintentar.fire();
            }
        }
    }
    open_error(message = false) {
        if (this.config.visible) {
            if (!this.config.alertaError.isVisible()) {
                if (message) {
                    this.config.alertaError.fire({text: message});
                } else {
                    this.config.alertaError.fire();
                }
            }
        }
    }

    close_error() {
        if (this.config.visible) {
            if (this.config.alertaError.isVisible()) {
                this.config.alertaError.close();
            }
        }
    }

}
