import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsStationService {

  error_id = false;
  txt_id: string | null;
  error_code = false;
  txt_code: string | null;
  error_desc = false;
  txt_desc: string | null;

  constructor() { }
}
