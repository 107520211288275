import { Component, OnInit } from '@angular/core';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export interface Produ {
  id: number;
  code: string;
}

@Component({
  selector: 'app-change-returnable',
  templateUrl: './change-returnable.component.html',
  styleUrls: ['./change-returnable.component.css']
})
export class ChangeReturnableComponent implements OnInit {
  
  filteredProd: Observable<Produ[]>;
  filteredBoxs: Observable<Produ[]>;
  produsMod: Produ[] = [];
  formAddReturn: FormGroup;
  statusReturn: boolean;
  unitsReturn: string | null;
  boxReturn: string | null;
  error_units: boolean = false;
  txt_units: string = '';
  error_box: boolean = false;
  txt_box: string = '';
  titleMod: string = '';
  pk: number | null;
  lists: any[] = [];
  allList: any;

  constructor(
    public ViewService: EntriesExitsService
  ) {
    this.formAddReturn = new FormGroup({
      returnstatus: new FormControl(this.statusReturn),
      returnunits: new FormControl(this.unitsReturn),
      returnbox: new FormControl(this.boxReturn)
    });
  }

  getId(){
    return 'ADMINIU00010';
  }

  ngOnInit() {
    if (this.ViewService.nroTypeTrans == 0) {
      this.allList = this.ViewService.prodTableCompra;
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.allList = this.ViewService.prodTablePedido;
    }
    this.pk = this.ViewService.pkReturn;
    for (const i in this.ViewService.productlists) {
      if (Object.prototype.hasOwnProperty.call(this.ViewService.productlists, i)) {
        const elem = this.ViewService.productlists[i];
        if (elem.id != this.pk) {
          this.lists.push(elem);
        } else {
          this.titleMod = 'Retornable de Producto '+elem.code;
        }
      }
    }
    for (const key in this.allList) {
      if (Object.prototype.hasOwnProperty.call(this.allList, key)) {
        const element = this.allList[key];
        if (element.id_product == this.pk) {
          this.statusReturn = element.returnable;
          this.unitsReturn = element.return_units;
          this.boxReturn = element.return_box;
        } else {
          if (element.return_pk_units != null) {
            for (const x in this.lists) {
              if (Object.prototype.hasOwnProperty.call(this.lists, x)) {
                const pr = this.lists[x];
                if (pr.id == element.return_pk_units) {
                  this.lists.splice(Number(x), 1);
                  break;
                }
              }
            }
          }
          if (element.return_pk_box != null) {
            for (const x in this.lists) {
              if (Object.prototype.hasOwnProperty.call(this.lists, x)) {
                const pr = this.lists[x];
                if (pr.id == element.return_pk_box) {
                  this.lists.splice(Number(x), 1);
                  break;
                }
              }
            }
          }
        }
      }
    }
    for (const i in this.ViewService.ProductSelect) {
      if (Object.prototype.hasOwnProperty.call(this.ViewService.ProductSelect, i)) {
        const prod = this.ViewService.ProductSelect[i];
        if (prod.id_product != this.pk) {
          if (prod.returnable_code_bottle != null) {
            for (const x in this.lists) {
              if (Object.prototype.hasOwnProperty.call(this.lists, x)) {
                const pr = this.lists[x];
                if (pr.id == prod.returnable_code_bottle) {
                  this.lists.splice(Number(x), 1);
                  break;
                }
              }
            }
          }
          if (prod.returnable_code_box != null) {
            for (const x in this.lists) {
              if (Object.prototype.hasOwnProperty.call(this.lists, x)) {
                const pr = this.lists[x];
                if (pr.id == prod.returnable_code_box) {
                  this.lists.splice(Number(x), 1);
                  break;
                }
              }
            }
          }
        }
      }
    }
    this.produsMod = this.lists
    this.filteredProd = this.returnunits.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
    this.filteredBoxs = this.returnbox.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
  }

  get returnstatus() { return this.formAddReturn.get('returnstatus'); }
  get returnunits() { return this.formAddReturn.get('returnunits'); }
  get returnbox() { return this.formAddReturn.get('returnbox'); }

  private _filter(value: string): Produ[] {
    if (typeof value != 'undefined' && value != null) {
      const filterValue = value.toLowerCase();
      return this.produsMod.filter(produ => produ.code.toLowerCase().includes(filterValue));
    }
  }

  closeModalAdd() {
    this.ViewService.closeModaAggReturn.hide();
  }

  checkProd(ev, value) {
    if (ev.relatedTarget && ev.relatedTarget.id.indexOf('mat-option') > -1) {
      ev.preventDefault();
    }else{
      if (value === 'units' && this.unitsReturn) {
        this.changeProd(this.unitsReturn.toUpperCase(), value);
      } else if (value === 'box' && this.boxReturn) {
        this.changeProd(this.boxReturn.toUpperCase(), value);
      }
      
    }
  }

  onSelectProd(ev, value) {
    this.changeProd(ev.option.value.toUpperCase(), value);
  }

  changeProd(value, tipo) {
    let not_exist = true;
    let msg: string;
    for (const key in this.produsMod) {
      if (Object.prototype.hasOwnProperty.call(this.produsMod, key)) {
        const elem = this.produsMod[key];
        if (elem.code === value) {
          not_exist = false;
          break;
        }
      }
    }
    if (not_exist) {
      msg = 'El codigo: '+value+' no existe.';
      if (tipo == 'units') {
        this.error_units = true;
        this.txt_units = msg;
        this.unitsReturn = '';
      } else if (tipo == 'box') {
        this.error_box = true;
        this.txt_box = msg;
        this.boxReturn = '';
      }
    } else {
      if (tipo === 'units') {
        this.error_units = false;
        this.txt_units = '';
      } else if (tipo === 'box') {
        this.error_box = false;
        this.txt_box = '';
      }
    }
  }

  loadReturn() {
    let valid = true;
    let not_units = true;
    let not_box = true;
    let pkunit: number | null;
    let pkbox: number | null;
    let i: any;
    if (this.statusReturn) {
      if (this.unitsReturn) {
        for (const key in this.produsMod) {
          if (Object.prototype.hasOwnProperty.call(this.produsMod, key)) {
            const elem = this.produsMod[key];
            if (elem.code === this.unitsReturn) {
              pkunit = Number(elem.id);
              not_units = false;
              break;
            }
          }
        }
        if (not_units) {
          valid = false;
          this.error_units = true;
          this.txt_units = 'El codigo: '+this.unitsReturn+' no existe.';;
        }
      } else {
        valid = false;
        this.error_units = true;
        this.txt_units = 'Campo requerido.';
      }

      if (this.boxReturn) {
        for (const key in this.produsMod) {
          if (Object.prototype.hasOwnProperty.call(this.produsMod, key)) {
            const elem = this.produsMod[key];
            if (elem.code === this.boxReturn) {
              pkbox = Number(elem.id);
              not_box = false;
              break;
            }
          }
        }
        if (not_box) {
          valid = false;
          this.error_box = true;
          this.txt_box = 'El codigo: '+this.boxReturn+' no existe.';;
        }
      } else {
        valid = false;
        this.error_box = true;
        this.txt_box = 'Campo requerido.';
      }
    } else {
      this.unitsReturn = '';
      this.boxReturn = '';
      pkunit = null;
      pkbox = null;
    }
    if (valid) {
      if (this.ViewService.nroTypeTrans == 0) {
        for (const key in this.ViewService.prodTableCompra) {
          if (Object.prototype.hasOwnProperty.call(this.ViewService.prodTableCompra, key)) {
            const element = this.ViewService.prodTableCompra[key];
            if (element.id_product == this.pk) {
              i = key;
              break;
            }
          }
        }
        this.ViewService.prodTableCompra[i].returnable = this.statusReturn;
        if (this.statusReturn) {
          this.ViewService.prodTableCompra[i].return_units = this.unitsReturn;
          this.ViewService.prodTableCompra[i].return_pk_units = pkunit;
          this.ViewService.prodTableCompra[i].return_box = this.boxReturn;
          this.ViewService.prodTableCompra[i].return_pk_box = pkbox;
        } else {
          this.ViewService.prodTableCompra[i].return_units = '';
          this.ViewService.prodTableCompra[i].return_pk_units = null;
          this.ViewService.prodTableCompra[i].return_box = '';
          this.ViewService.prodTableCompra[i].return_pk_box = null;
        }
      } else if (this.ViewService.nroTypeTrans == 2) {
        for (const key in this.ViewService.prodTablePedido) {
          if (Object.prototype.hasOwnProperty.call(this.ViewService.prodTablePedido, key)) {
            const element = this.ViewService.prodTablePedido[key];
            if (element.id_product == this.pk) {
              i = key;
              break;
            }
          }
        }
        this.ViewService.prodTablePedido[i].returnable = this.statusReturn;
        if (this.statusReturn) {
          this.ViewService.prodTablePedido[i].return_units = this.unitsReturn;
          this.ViewService.prodTablePedido[i].return_pk_units = pkunit;
          this.ViewService.prodTablePedido[i].return_box = this.boxReturn;
          this.ViewService.prodTablePedido[i].return_pk_box = pkbox;
        } else {
          this.ViewService.prodTablePedido[i].return_units = '';
          this.ViewService.prodTablePedido[i].return_pk_units = null;
          this.ViewService.prodTablePedido[i].return_box = '';
          this.ViewService.prodTablePedido[i].return_pk_box = null;
        }
      }
      this.ViewService.closeModaAggReturn.hide();
    }
  }

}
