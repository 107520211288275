import { Component, OnInit, EventEmitter } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { CustomerService } from './customer-service/customer-service.service';
import { ModalCreatecustomerPdvComponent } from './modal-createcustomer-pdv/modal-createcustomer-pdv.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Customer } from 'src/app/cliente/interface/customer';
import { isArray } from 'util';

@Component({
  selector: 'app-customer-pdv',
  inputs: ['search_cus', 'customerlist'],
  outputs: ['customer'],
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerPDVComponent implements OnInit {
  // delete_dispatch_areas y register_dispatch_areas
  collapsed: any;
  title = 'Lista de clientes';
  bsModalRef: BsModalRef;
  confirmar = Swal.mixin({
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Confirmar',
      confirmButtonAriaLabel: 'Cancelar',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Cancelar'
  });
  search_cus: string;
  resp: any = {search: ''};
  even_customer: EventEmitter<any> = new EventEmitter();
  customerlist: any;

  constructor(
    private titleService: Title,
    public auth: AuthService,
    public router: Router,
    public listUrl: UrListService,
    public CustomerService: CustomerService,
    public modalService: BsModalService,
    public mybsModalRef: BsModalRef) {
      this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    if (this.customerlist != undefined && isArray(this.customerlist) && this.customerlist.length >= 1) {
      this.CustomerService.customerList = this.customerlist;
    } else {
      this.getCostumers();
    }
  }

  selectCustomer(customer: Customer) {
    this.resp.customer = customer;
    this.closeModal();
  }

  closeModal() {
    this.resp.search = this.search_cus;
    this.even_customer.emit(this.resp);
    this.mybsModalRef.hide();
  }

  getId() {
    return 'ESTPVIU00006';
  }

  openModal(id) {
    this.CustomerService.pk = id;
    if (id != -1) {
      this.CustomerService.customerForm.get('trade_name').setValue(this.CustomerService.customerList.find(x => x.id_customers == id).trade_name);
      this.CustomerService.customerForm.get('address').setValue(this.CustomerService.customerList.find(x => x.id_customers == id).address);
      this.CustomerService.customerForm.get('email').setValue(this.CustomerService.customerList.find(x => x.id_customers == id).email);
      this.CustomerService.customerForm.get('phone').setValue(this.CustomerService.customerList.find(x => x.id_customers == id).phone);
      this.CustomerService.customerForm.get('nit').setValue(this.CustomerService.customerList.find(x => x.id_customers == id).nit);
    } else {
      this.CustomerService.customerForm.get('trade_name').setValue('');
      this.CustomerService.customerForm.get('address').setValue('');
      this.CustomerService.customerForm.get('email').setValue('');
      this.CustomerService.customerForm.get('phone').setValue('');
      this.CustomerService.customerForm.get('nit').setValue('');
    }
    const initialState = {
      componentParent: this
    };
    this.bsModalRef = this.modalService.show(ModalCreatecustomerPdvComponent, {initialState});
    this.bsModalRef.content.even_add.subscribe(resp => {
      if (this.CustomerService.pk == -1) {
        this.CustomerService.customerList.push(resp);
      } else {
        this.CustomerService.customerList = this.CustomerService.customerList.filter(x => x.id_customers != this.CustomerService.pk);
        this.CustomerService.customerList.push(resp);
      }
    });
    this.bsModalRef.setClass('modal-lg');
    this.CustomerService.closeModal = this.bsModalRef;
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>Eliminar cliente</strong>`
    });
  }

  deletevalObj(pk) {
    const confirDelete = `Desea eliminar?`;
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.removeObj(pk);
      });
    }, 'Desea Eliminar?',
    confirDelete);
  }

  removeObj(id) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        id_customers: id
      },
      url: this.listUrl.url('delete_customer')
    };
    const configAjax: Config = {
      titleLoading: `Eliminando del catálogo...`,
      titleSuccess: 'El cliente fue eliminado',
      textErrorNormal: `No se logró eliminar el cliente`,
      titleErrorNormal: `Error al Eliminar el cliente`,
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.CustomerService.customerList = this.CustomerService.customerList.filter(x => x.id_customers != id);
      }
    }).catch( error => {
      return error;
    });
  }

  getCostumers() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_customers'),
      auth: this.auth,
      body: {
        search: this.search_cus
      },
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.CustomerService.customerList = respo.customers;
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }


}
