import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SelectCompanyGuard } from 'src/app/core/canActivate/select-company.guard';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingService } from '../services/setting.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { ErrorsSettingService } from '../services/errors-setting.service';
import { numValidators } from './validators';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  inputs: ['SettingService.productDiscount: number'],
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  formGeneral: FormGroup;
  return = '';
  retornar: boolean | string = false;
  title = 'Bonaventure :. Configuración';
  codeId = 'BSSCFIU00001';
  r_station = false;

  // tslint:disable-next-line: no-shadowed-variable
  constructor(public SettingService: SettingService, public Errors: ErrorsSettingService,
              public auth: AuthService, public company: SelectCompanyGuard, private listUrl: UrListService,
              private router: Router, private route: ActivatedRoute, private titleService: Title
  ){
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(params => {
      this.return = params.return || '';
      this.r_station = params.station || false;
    });
    this.formGeneral = new FormGroup({
      namelegal: new FormControl(this.SettingService.legalName, [
        Validators.required,
        Validators.maxLength(120)
      ]),
      nametrade: new FormControl(this.SettingService.tradeName, [
        Validators.required,
        Validators.maxLength(120)
      ]),
      rif: new FormControl(this.SettingService.Nit, [
        Validators.required,
        Validators.maxLength(20)
      ]),
      direccion: new FormControl(this.SettingService.Address, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      telefono: new FormControl(this.SettingService.Phone, [
        Validators.required,
        Validators.maxLength(40)
      ]),
      gerente: new FormControl(this.SettingService.nameOwner, [
        Validators.required,
        Validators.maxLength(120)
      ]),
      licencia: new FormControl(this.SettingService.specialLicense, [
        Validators.maxLength(35)
      ]),
      socicapi: new FormControl(this.SettingService.capiSoci, [
        Validators.maxLength(80)
      ]),
      country: new FormControl(this.SettingService.idCountry, [
        Validators.required
      ]),
      state: new FormControl(this.SettingService.idState, [
        Validators.required
      ]),
      city: new FormControl(this.SettingService.idCity, [
        Validators.required
      ]),
      codepost: new FormControl(this.SettingService.postCode, [
        Validators.required,
        Validators.maxLength(10)
      ]),
      discount: new FormControl(this.SettingService.productDiscount, [
        Validators.min(0),
        Validators.max(100),
        numValidators
      ]),
      discglobal: new FormControl(this.SettingService.globalDiscount, [
        Validators.min(0),
        Validators.max(100),
        numValidators
      ]),
      datecredit: new FormControl(this.SettingService.creditDate, [
        Validators.min(0),
        Validators.max(9999999999),
        numValidators
      ]),
      creditlimit: new FormControl(this.SettingService.creditLimit, [
        Validators.min(0),
        Validators.max(9999999999),
        numValidators
      ]),
      customersstart: new FormControl(this.SettingService.customersStart, [
        Validators.min(0),
        Validators.max(9999999999),
        numValidators
      ]),
      customerfinal: new FormControl(this.SettingService.customerFinal, [
        Validators.min(0),
        Validators.max(9999999999),
        numValidators
      ]),
      currency: new FormControl(this.SettingService.idCurrency, [
        Validators.required
      ]),
      redoprec: new FormControl(this.SettingService.redoPrice, [
        Validators.min(0),
        Validators.max(99999999),
        numValidators
      ]),
      diasanul: new FormControl(this.SettingService.diasAnul, [
        Validators.min(0),
        Validators.max(9999999999),
        numValidators
      ]),
      iva: new FormControl(this.SettingService.iva, [
        Validators.min(0),
        Validators.max(1000),
        numValidators
      ]),
      price_base: new FormControl(this.SettingService.priceBase),
      price_iva: new FormControl(this.SettingService.priceIva),
      price_viewexist: new FormControl(this.SettingService.priceViewExist),
      price_a: new FormControl(this.SettingService.priceA),
      price_b: new FormControl(this.SettingService.priceB),
      price_c: new FormControl(this.SettingService.priceC),
      price_d: new FormControl(this.SettingService.priceD),
      price_e: new FormControl(this.SettingService.priceE),
      kind: new FormControl(this.SettingService.kind),
      type: new FormControl(this.SettingService.type),
      presentation: new FormControl(this.SettingService.presentation),
    });
  }

  get namelegal() { return this.formGeneral.get('namelegal'); }
  get nametrade() { return this.formGeneral.get('nametrade'); }
  get rif() { return this.formGeneral.get('rif'); }
  get direccion() { return this.formGeneral.get('direccion'); }
  get telefono() { return this.formGeneral.get('telefono'); }
  get gerente() { return this.formGeneral.get('gerente'); }
  get licencia() { return this.formGeneral.get('licencia'); }
  get socicapi() { return this.formGeneral.get('socicapi'); }
  get country() { return this.formGeneral.get('country'); }
  get state() { return this.formGeneral.get('state'); }
  get city() { return this.formGeneral.get('city'); }
  get codepost() { return this.formGeneral.get('codepost'); }
  get discount() { return this.formGeneral.get('discount'); }
  get discglobal() { return this.formGeneral.get('discglobal'); }
  get datecredit() { return this.formGeneral.get('datecredit'); }
  get creditlimit() { return this.formGeneral.get('creditlimit'); }
  get customersstart() { return this.formGeneral.get('customersstart'); }
  get customerfinal() { return this.formGeneral.get('customerfinal'); }
  get currency() { return this.formGeneral.get('currency'); }
  get redoprec() { return this.formGeneral.get('redoprec'); }
  get diasanul() { return this.formGeneral.get('diasanul'); }
  get iva() { return this.formGeneral.get('iva'); }
  get price_base() { return this.formGeneral.get('price_base'); }
  get price_iva() { return this.formGeneral.get('price_iva'); }
  get price_viewexist() { return this.formGeneral.get('price_viewexist'); }
  get price_a() { return this.formGeneral.get('price_a'); }
  get price_b() { return this.formGeneral.get('price_b'); }
  get price_c() { return this.formGeneral.get('price_c'); }
  get price_d() { return this.formGeneral.get('price_d'); }
  get price_e() { return this.formGeneral.get('price_e'); }
  get kind() { return this.formGeneral.get('kind'); }
  get type() { return this.formGeneral.get('type'); }
  get presentation() { return this.formGeneral.get('presentation'); }

  ngOnInit() {
    this.loadingCompany();
    this.loadingCurrency();
    this.loadingSetting();
    if (this.return) {
      this.retornar = this.return;
    }
  }

  loadingCompany() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('load_company')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.SettingService.legalName = resp.company.legal_name;
        this.SettingService.tradeName = resp.company.trade_name;
        this.SettingService.Nit = resp.company.nit;
        this.SettingService.Address = resp.company.address;
        this.SettingService.Phone = resp.company.telephone;
        this.SettingService.nameOwner = resp.company.name_owner;
        this.SettingService.postCode = resp.company.post_code;
        this.SettingService.idCountry = resp.company.country;
        this.SettingService.idState = resp.company.state;
        this.SettingService.idCity = resp.company.city;
        this.loadingGeo();
      }
    });
  }

  loadingGeo() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('selects_geo'),
      body: {
        country: this.SettingService.idCountry,
        state: this.SettingService.idState,
        city: this.SettingService.idCity
      }
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.SettingService.SelectCountry = resp.countries;
        this.SettingService.SelectState = resp.states;
        this.SettingService.SelectCity = resp.cities;
        this.SettingService.selectedCountry = true;
        this.SettingService.selectIdState = true;
        this.SettingService.selectedState = true;
        this.SettingService.selectIdCity = true;
      }
    });
  }

  loadingCurrency() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('select_currencies')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.retornar) {
        if (resp.retornar === 'currency') {
          this.router.navigateByUrl(`/cliente/adm/catalogo/moneda?return=${this.router.url}`);
        }
      } else {
        this.SettingService.SelectCurrency = resp.objects;
      }
    });
  }

  loadingSetting() {
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('loading_setting')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success && resp.config) {
        this.SettingService.productDiscount = resp.general.prod_discount;
        this.SettingService.globalDiscount = resp.general.glob_discount;
        this.SettingService.creditDate = resp.general.credi_date;
        this.SettingService.creditLimit = resp.general.credi_limit;
        this.SettingService.customersStart = resp.general.custo_start;
        this.SettingService.customerFinal = resp.general.custo_final;
        this.SettingService.idCurrency = resp.general.moneda;
        this.SettingService.redoPrice = resp.general.redon_price;
        this.SettingService.diasAnul = resp.general.dias_anul;
        this.SettingService.iva = resp.general.impuesto;
        this.SettingService.priceBase = resp.general.price_base;
        this.SettingService.priceIva = resp.general.price_iva;
        this.SettingService.priceViewExist = resp.general.price_viewexist;
        this.SettingService.priceA = resp.general.price_a;
        this.SettingService.priceB = resp.general.price_b;
        this.SettingService.priceC = resp.general.price_c;
        this.SettingService.priceD = resp.general.price_d;
        this.SettingService.priceE = resp.general.price_e;
        this.SettingService.kind = resp.general.kind;
        this.SettingService.type = resp.general.type;
        this.SettingService.presentation = resp.general.presentation;
        this.SettingService.specialLicense = resp.general.license_special;
        this.SettingService.capiSoci = resp.general.auth_special;
        this.SettingService.pkGnrl = resp.general.id;
        this.SettingService.newGnrl = false;
      }
    });
  }

  changeCountry() {
    this.Errors.error_country = false;
    this.Errors.error_state = false;
    this.Errors.error_city = false;
    if (this.SettingService.idCountry) {
      this.SettingService.loadData = true;
      const initAjax: Init = {
        method: 'put',
        auth: this.auth,
        url: this.listUrl.url('list_modal_state'),
        body: {
          country: this.SettingService.idCountry
        }
      };
      const configAjax: Config = {
        visible: false,
        autoReNew: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          this.SettingService.SelectState = resp.objects;
          this.SettingService.selectIdState = false;
          this.SettingService.selectedState = false;
          this.SettingService.selectedCountry = true;
          this.SettingService.loadData = false;
        }
      });
    } else {
      this.SettingService.selectedCountry = false;
      this.SettingService.selectedState = false;
    }
  }

  changeState() {
    this.Errors.error_country = false;
    this.Errors.error_state = false;
    this.Errors.error_city = false;
    if (this.SettingService.idState) {
      this.SettingService.loadData = true;
      this.SettingService.selectedState = true;
      this.SettingService.selectIdCity = false;
      const initAjax: Init = {
        method: 'put',
        auth: this.auth,
        url: this.listUrl.url('list_modal_city'),
        body: {
          state: this.SettingService.idState
        }
      };
      const configAjax: Config = {
        visible: false,
        autoReNew: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          this.SettingService.SelectCity = resp.objects;
          this.SettingService.loadData = false;
        }
      });
    } else {
      this.SettingService.selectedState = false;
    }
  }

  changeCity() {
    this.Errors.error_country = false;
    this.Errors.error_state = false;
    this.Errors.error_city = false;
  }

  formatUpper(elem, error) {
    elem.setValue(elem.value.toUpperCase());
    if (error === 'error_codep') {
      if (this.Errors.error_codep) {
        this.Errors.error_codep = false;
      }
    } else if (error === 'error_namel') {
      if (this.Errors.error_namel) {
        this.Errors.error_namel = false;
      }
    } else if (error === 'error_namet') {
      if(this.Errors.error_namet){
        this.Errors.error_namet = false;
      }
    }else if(error === 'error_nit'){
      if(this.Errors.error_nit){
        this.Errors.error_nit = false;
      }
    }else if(error === 'error_address'){
      if(this.Errors.error_address){
        this.Errors.error_address = false;
      }
    }else if(error === 'error_phone'){
      if(this.Errors.error_phone){
        this.Errors.error_phone = false;
      }
    }else if(error === 'error_owner'){
      if(this.Errors.error_owner){
        this.Errors.error_owner = false;
      }
    }else if(error === 'error_licencia'){
      if(this.Errors.error_licencia){
        this.Errors.error_licencia = false;
      }
    }else if(error === 'error_capisoci'){
      if(this.Errors.error_capisoci){
        this.Errors.error_capisoci = false;
      }
    }
  }

  saveObject(values){
    if(this.formGeneral.valid){
      if(values.namelegal.trim() && values.nametrade.trim() && values.rif.trim() && values.direccion.trim() && values.telefono.trim() && values.gerente.trim() && values.codepost.trim()){
        let id_gnrl: any;
        if(this.SettingService.newGnrl){
          id_gnrl = '';
        }else{
          id_gnrl = this.SettingService.pkGnrl;
        }
        const initAjax: Init = {
          method: 'put',
          url: this.listUrl.url('saveconfig_gnral'),
          auth: this.auth,
          body: {
            id_gnrl: id_gnrl,
            discount: values.discount,
            discglobal: values.discglobal,
            creditlimit: values.creditlimit,
            iva: values.iva,
            customersstart: values.customersstart,
            customerfinal: values.customerfinal,
            datecredit: values.datecredit,
            redoprec: values.redoprec,
            diasanul: values.diasanul,
            currency: values.currency,
            city: values.city,
            state: values.state,
            country: values.country,
            licencia: values.licencia.toUpperCase().trim(),
            socicapi: values.socicapi.toUpperCase().trim(),
            namelegal: values.namelegal.toUpperCase().trim(),
            nametrade: values.nametrade.toUpperCase().trim(),
            rif: values.rif.toUpperCase().trim(),
            direccion: values.direccion.toUpperCase().trim(),
            telefono: values.telefono.toUpperCase().trim(),
            gerente: values.gerente.toUpperCase().trim(),
            codepost: values.codepost.toUpperCase().trim(),
            kind: values.kind,
            type: values.type,
            presentation: values.presentation,
            price_iva: values.price_iva,
            price_viewexist: values.price_viewexist,
            price_base: values.price_base,
            price_a: values.price_a,
            price_b: values.price_b,
            price_c: values.price_c,
            price_d: values.price_d,
            price_e: values.price_e
          }
        };
        const configAjax: Config = {
          numInten : 1,
          titleLoading: 'Guardando Configuración General',
          titleSuccess: '',
          titleErrorNormal: 'Error',
          textErrorNormal: 'Ha ocurrido un Error.',
          confirmButtonTextError: 'Cerrar',
          validatejson: (resp: any, obj: Ajax) => {
            return new Promise((success, error) => {
              if (resp.success === false) {
                obj.declareConfig({
                  alertaError : Swal.mixin({
                    icon: 'error',
                    title: 'Ocurrio un error',
                    text: resp.message,
                    showConfirmButton: false,
                    confirmButtonText: 'Cerrar',
                  })
                });
                error(resp);
              } else {
                success(resp);
              }
            });
          }
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(resp => {
          if (resp.success) {
            this.auth.user.company.legal_name = values.namelegal.trim();
            this.auth.user.company.trade_name = values.nametrade.trim();
            this.auth.user.company.nit = values.rif.trim();
            this.auth.user.company.address = values.direccion.trim();
            this.auth.user.company.telephone = values.telefono.trim();
            this.auth.user.company.name_owner = values.gerente.trim();
            this.auth.user.company.post_code = values.codepost.trim();
          }
        }).catch(error => {
          this.viewError(error);
        });
      }else{
        this.namelegal.setValue(values.namelegal.trim());
        this.nametrade.setValue(values.nametrade.trim());
        this.rif.setValue(values.rif.trim());
        this.direccion.setValue(values.direccion.trim());
        this.telefono.setValue(values.telefono.trim());
        this.gerente.setValue(values.gerente.trim());
        this.codepost.setValue(values.codepost.trim());
      }
    }
  }

  viewError(error){
    if(error.error_prodisco != 'undefined'){
      if(error.error_prodisco){
        this.Errors.error_prodisco = error.error_prodisco;
        this.Errors.txt_prodisco = error.txt_prodisco;
      }
    }
    if(error.error_glodisco != 'undefined'){
      if(error.error_glodisco){
        this.Errors.error_glodisco = error.error_glodisco;
        this.Errors.txt_glodisco = error.txt_glodisco;
      }
    }
    if(error.error_limitcredit != 'undefined'){
      if(error.error_limitcredit){
        this.Errors.error_limitcredit = error.error_limitcredit;
        this.Errors.txt_limitcredit = error.txt_limitcredit;
      }
    }
    if(error.error_iva != 'undefined'){
      if(error.error_iva){
        this.Errors.error_iva = error.error_iva;
        this.Errors.txt_iva = error.txt_iva;
      }
    }
    if(error.error_custostart != 'undefined'){
      if(error.error_custostart){
        this.Errors.error_custostart = error.error_custostart;
        this.Errors.txt_custostart = error.txt_custostart;
      }
    }
    if(error.error_custofinal != 'undefined'){
      if(error.error_custofinal){
        this.Errors.error_custofinal = error.error_custofinal;
        this.Errors.txt_custofinal = error.txt_custofinal;
      }
    }
    if(error.error_datecredit != 'undefined'){
      if(error.error_datecredit){
        this.Errors.error_datecredit = error.error_datecredit;
        this.Errors.txt_datecredit = error.txt_datecredit;
      }
    }
    if(error.error_redoprec != 'undefined'){
      if(error.error_redoprec){
        this.Errors.error_redoprec = error.error_redoprec;
        this.Errors.txt_redoprec = error.txt_redoprec;
      }
    }
    if(error.error_diasanul != 'undefined'){
      if(error.error_diasanul){
        this.Errors.error_diasanul = error.error_diasanul;
        this.Errors.txt_diasanul = error.txt_diasanul;
      }
    }
    if(error.error_currency != 'undefined'){
      if(error.error_currency){
        this.Errors.error_currency = error.error_currency;
        this.Errors.txt_currency = error.txt_currency;
      }
    }
    if(error.error_licencia != 'undefined'){
      if(error.error_licencia){
        this.Errors.error_licencia = error.error_licencia;
        this.Errors.txt_licencia = error.txt_licencia;
      }
    }
    if(error.error_capisoci != 'undefined'){
      if(error.error_capisoci){
        this.Errors.error_capisoci = error.error_capisoci;
        this.Errors.txt_capisoci = error.txt_capisoci;
      }
    }
    if(error.error_namel != 'undefined'){
      if(error.error_namel){
        this.Errors.error_namel = error.error_namel;
        this.Errors.txt_namel = error.txt_namel;
      }
    }
    if(error.error_namet != 'undefined'){
      if(error.error_namet){
        this.Errors.error_namet = error.error_namet;
        this.Errors.txt_namet = error.txt_namet;
      }
    }
    if(error.error_nit != 'undefined'){
      if(error.error_nit){
        this.Errors.error_nit = error.error_nit;
        this.Errors.txt_nit = error.txt_nit;
      }
    }
    if(error.error_address != 'undefined'){
      if(error.error_address){
        this.Errors.error_address = error.error_address;
        this.Errors.txt_address = error.txt_address;
      }
    }
    if(error.error_phone != 'undefined'){
      if(error.error_phone){
        this.Errors.error_phone = error.error_phone;
        this.Errors.txt_phone = error.txt_phone;
      }
    }
    if(error.error_owner != 'undefined'){
      if(error.error_owner){
        this.Errors.error_owner = error.error_owner;
        this.Errors.txt_owner = error.txt_owner;
      }
    }
    if(error.error_codep != 'undefined'){
      if(error.error_codep){
        this.Errors.error_codep = error.error_codep;
        this.Errors.txt_codep = error.txt_codep;
      }
    }
    if(error.error_state != 'undefined'){
      if(error.error_state){
        this.Errors.error_state = error.error_state;
        this.Errors.txt_state = error.txt_state;
      }
    }
    if(error.error_country != 'undefined'){
      if(error.error_country){
        this.Errors.error_country = error.error_country;
        this.Errors.txt_country = error.txt_country;
      }
    }
    if(error.error_city != 'undefined'){
      if(error.error_city){
        this.Errors.error_city = error.error_city;
        this.Errors.txt_city = error.txt_city;
      }
    }
  }

  changeCodeId(data){
    this.codeId = data;
  }

  getId() {
    return this.codeId;
  }
}
