import { EventEmitter, Injectable } from '@angular/core';
import { ObjectsThree, ObjectThree } from './objectthree';
import { OneSelect } from '../modals-double/one-select';
import { Url } from 'src/app/core/ajax/url';

@Injectable({
  providedIn: 'root'
})
export class CatalogoThreeService {

  ambos: boolean;
  closeModal: any;
  create: boolean;
  descOneMT: string | null;
  descTwoMT: string | null;
  habilitaBtn: boolean = false;
  modelo: string | null;
  modeloBase: string | null;
  newMT: boolean = true;
  OneSelect: OneSelect;
  pkThird: number | string;
  preguntaDelete: string | null;
  princModal: any;
  regresa: string | boolean;
  secunModal: any;
  selectID: boolean = false;
  selectIDTwo: boolean = false;
  selectedOne: boolean = true;
  selectOneMT: number | null;
  selectTwoMT: number | string;
  strConecDelete?: string;
  strDelete?: string;
  typeModal: any;
  typeModalBase: any;
  TwoSelect: OneSelect;
  urlDelete: Url | null;
  urlLoading: Url | null;
  urlResolving: Url | null;
  urlreturn: boolean | string;
  urlSave: Url | null;
  urlSelect: Url | null;
  urlSelectTwo: Url | null;
  valuesObjects: ObjectsThree;
  valuesObjectsBase: ObjectsThree;
  viewModal: any;
  viewCode: string;
  editCode: string;
  addCode: string;
  object: ObjectThree;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
