import { EventEmitter, Injectable } from '@angular/core';
import { ObjectsTwo, ObjectTwo } from './objecttwo';
import { OneSelect } from '../modals-double/one-select';
import { Url } from 'src/app/core/ajax/url';

@Injectable({
  providedIn: 'root'
})
export class CatalogotwoService {

  ambos: boolean;
  closeModal: any;
  create: boolean;
  descOneMD: number | null = null
  habilitaBtn: boolean = false;
  modelo: string | null;
  modeloBase: string | null;
  newMD: boolean = true;
  OneSelect: OneSelect;
  pkSecun: number | string;
  preguntaDelete: string | null;
  princModal: any;
  regresa: string | boolean;
  selectID: boolean = false;
  selectOneMD: number | null = null;
  strConecDelete?: string;
  strDelete?: string;
  typeModal: any;
  typeModalBase: any;
  urlDelete: Url | null;
  urlResolving: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  urlSave: Url | null;
  urlSelect: Url | null;
  valuesObjects: ObjectsTwo;
  valuesObjectsBase: ObjectsTwo;
  viewModal: any;
  viewCode: string;
  editCode: string;
  addCode: string;
  resp: any;
  object: ObjectTwo;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();
  
  constructor() { }
}
