import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-chat-client',
  templateUrl: './chat-client.component.html',
  styleUrls: ['./chat-client.component.css']
})
export class ChatClientComponent implements OnInit {

  parent: MenuComponent;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
