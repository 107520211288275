import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SetControlsService {

  tdocu: string;
  fact: boolean;
  devo: boolean;
  debi: boolean;
  cred: boolean;

  desde: number;
  hasta: number;
  ctrl_desde: number;
  ctrl_hasta: number;

  vacio = 0;

  loadData = false;

  constructor() { }
}
