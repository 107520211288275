import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsStationConfigService {

  error_namet: boolean;
  txt_namet: string;

  error_n_control: boolean;
  txt_n_control: string;

  error_station: boolean;
  txt_station: string;

  error_n_factu: boolean;
  txt_n_factu: string;
  error_serie_factu: boolean;
  txt_serie_factu: string;
  error_ctrl_factu: boolean;
  txt_ctrl_factu: string;

  error_n_devol: boolean;
  txt_n_devol: string;
  error_serie_devol: boolean;
  txt_serie_devol: string;
  error_ctrl_devol: boolean;
  txt_ctrl_devol: string;

  error_n_cred: boolean;
  txt_n_cred: string;
  error_serie_cred: boolean;
  txt_serie_cred: string;
  error_ctrl_cred: boolean;
  txt_ctrl_cred: string;

  error_n_debit: boolean;
  txt_n_debit: string;
  error_serie_debit: boolean;
  txt_serie_debit: string;
  error_ctrl_debit: boolean;
  txt_ctrl_debit: string;

  error_n_pedido: boolean;
  txt_n_pedido: string;
  error_serie_pedido: boolean;
  txt_serie_pedido: string;

  // i_fiscal = false; // Impresora Fiscal
  error_i_serial: boolean;
  txt_i_serial: string;
  error_n_corte: boolean;
  txt_n_corte: string;

  error_depart: string;
  txt_depart: string;

  // toma_pedidos = false;
  // factu_pedidos = false;
  // select_deposi = false;
  // a_factu_sby = false; // autorizar facturas standby
  // des_key_manual = false; // Desactivar clave manual
  // vari_rutas = false;
  // print_sby = false; // imprimir standby

  error_selec_type_price: boolean;
  txt_selec_type_price: string;
  // priceBase = false;
  // priceA = false;
  // priceB = false;
  // priceC = false;
  // priceD = false;
  // priceE = false;

  // factu_negativo = false;
  // select_mo_extra = false; // Seleccionar Moneda extranjera

  error_moneda: boolean;
  txt_moneda: string;

  error_list: any;

  constructor() { }
}
