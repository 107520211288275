import { EventEmitter, Injectable } from '@angular/core';
import { Seller, Sellers } from './sellers';
import { OneSelect } from '../modals-double/one-select';

@Injectable({
  providedIn: 'root'
})
export class SellersService {

  ambos: boolean;
  closeModal: any;
  create: boolean;
  CodeDepo: number | null = null;
  DescDepo: number | null = null;
  CodeSupe: number | null = null;
  DescSupe: number | null = null;
  DepositSelect: OneSelect; //Objeto para listar Depositos en select
  UserCompanySelect: OneSelect; //Objeto para listar Depositos en select
  habilitaBtn = false;
  haveObjects = true;
  isCatalog = true;
  LocationSelect: OneSelect;
  new: boolean = true;
  modalType = '';
  modelo: string | null;
  pk: number | string;
  regresa: string | boolean;
  SupervisorSelect: OneSelect; //Objeto para listar Depositos en select
  typeModal: any;
  urlreturn: boolean | string;
  valuesObjects: Sellers | any;
  valuesObjectsBase: Sellers | any;
  object: Seller;
  continuar: boolean = false;
  evenAdd: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
