import { Component, OnInit } from '@angular/core';
import { EntriesExitsService } from '../../../services/entries-exits.service';

@Component({
  selector: 'app-view-detail-product',
  templateUrl: './view-detail-product.component.html',
  styleUrls: ['./view-detail-product.component.css']
})
export class ViewDetailProductComponent implements OnInit {

  ProductDetail: any;
  item = this.ViewService.idTableView;
  TableProducts: any;
  objOT: any;
  codeId: string;

  constructor(
    public ViewService: EntriesExitsService
  ) { }

  getId() {
    return this.codeId;
  }

  ngOnInit() {
    if (this.ViewService.nroTypeTrans == 0) {
      this.TableProducts = this.ViewService.prodTableCompra;
      this.objOT = this.ViewService.OtherTaxCo;
    } else if (this.ViewService.nroTypeTrans == 1) {
      this.TableProducts = this.ViewService.prodTableEntrada;
    } else if (this.ViewService.nroTypeTrans == 2) {
      this.TableProducts = this.ViewService.prodTablePedido;
    } else if (this.ViewService.nroTypeTrans == 3) {
      this.objOT = this.ViewService.OtherTaxCo;
      this.TableProducts = this.ViewService.prodTableSalida;
    }
    if (this.ViewService.typeDetail === 'PR') {
      this.codeId = 'ADMINIU00004';
      for (const i in this.TableProducts) {
        if (this.TableProducts.hasOwnProperty(i)) {
          const elem = this.TableProducts[i];
          if (elem.id_product == this.item) {
            this.ProductDetail = elem
            break;
          }
        }
      }
    } else if (this.ViewService.typeDetail === 'OT') {
      this.codeId = 'ADMINIU00008';
      for (const i in this.objOT) {
        if (this.objOT.hasOwnProperty(i)) {
          const elem = this.objOT[i];
          if (elem.id == this.item) {
            this.ProductDetail = elem
            break;
          }
        }
      }
    }
  }

  closeModal() {
    this.ViewService.closeModalDetailPro.hide();
  }

}
