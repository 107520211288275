import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorPedidoService {

  error_id: boolean;
  txt_id: string;
  error_typetrans: boolean;
  txt_typetrans: string;
  error_deposit: boolean;
  txt_deposit: string;
  error_supplier: boolean;
  txt_supplier: string;

  error_lisaea: boolean;
  txt_lisaea: string;
  error_drink: boolean;
  txt_drink: string;

  error_currency: boolean;
  txt_currency: string;
  
  constructor() { }
}
