import { Component, OnInit } from '@angular/core';
import { SellersService } from '../../services/sellers.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDepositComponent } from '../../modals-double/modals-double.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CatalogotwoService } from '../../services/catalogotwo.service';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import { SupervisorsService } from '../../services/supervisors.service';
import { ModalSupervisorComponent } from '../../modal-supervisor/modal-supervisor.component';
import Swal from 'sweetalert2';
import { SellerCustomerService } from '../../services/seller-customer.service';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';

@Component({
  selector: 'app-load-seller',
  templateUrl: './load-seller.component.html',
  styleUrls: ['./load-seller.component.css']
})
export class LoadSellerComponent implements OnInit {

  formSeller: FormGroup;
  CodeUser: number | null = null;
  DescUser: number | null = null;
  codeSell: string | null;
  descSell: string | null;
  error_id: boolean = false;
  txt_id: string | null;
  error_user: boolean = false;
  txt_user: string | null;
  error_depo: boolean = false;
  txt_depo: string | null;
  error_supe: boolean = false;
  txt_supe: string | null;
  error_code: boolean = false;
  txt_code: string | null;
  error_desc: boolean = false;
  txt_desc: string | null;
  codeId: string;

  constructor(
    public SellersService: SellersService,
    public SellerCustomerService: SellerCustomerService,
    public CatalogoTwoService: CatalogotwoService,
    public SupervisorsService: SupervisorsService,
    public ResolvingService: ResolvingService,
    public auth: AuthService,
    private listUrl: UrListService,
    private modalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    this.formSeller = new FormGroup({
      code_user: new FormControl(this.CodeUser, [
        Validators.required
      ]),
      desc_user: new FormControl(this.DescUser, [
        Validators.required
      ]),
      code_depo: new FormControl(this.SellersService.CodeDepo, [
        Validators.required
      ]),
      desc_depo: new FormControl(this.SellersService.DescDepo, [
        Validators.required
      ]),
      code_supe: new FormControl(this.SellersService.CodeSupe, [
        Validators.required
      ]),
      desc_supe: new FormControl(this.SellersService.DescSupe, [
        Validators.required
      ]),
      code_sell: new FormControl(this.codeSell,[
        Validators.required,
        Validators.maxLength(3)
      ]),
      desc_sell: new FormControl(this.descSell,[
        Validators.required,
        Validators.maxLength(80)
      ])
    });
  }

  getId() {
    return this.codeId;
  }

  ngOnInit() {
    this.loadingLocations();
    if (this.SellersService.new) {
      this.codeId = 'ADMCAIU00059';
      this.formSeller.reset();
      this.CodeUser = null;
      this.DescUser = null;
      this.SellersService.CodeDepo = null;
      this.SellersService.DescDepo = null;
      this.SellersService.CodeSupe = null;
      this.SellersService.DescSupe = null;
      this.codeSell = '';
      this.descSell = '';
    } else {
      this.codeId = 'ADMCAIU00060';
      this.CodeUser = this.SellersService.object.user_id;
      this.DescUser = this.SellersService.object.user_id;
      this.SellersService.CodeDepo = this.SellersService.object.id_deposit;
      this.SellersService.DescDepo = this.SellersService.object.id_deposit;
      this.SellersService.CodeSupe = this.SellersService.object.id_supervisor;
      this.SellersService.DescSupe = this.SellersService.object.id_supervisor;
      this.codeSell = this.SellersService.object.code;
      this.descSell = this.SellersService.object.desc;
    }
  }

  loadingLocations(){
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.listUrl.url('list_modal_location')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.SellersService.LocationSelect = resp.objects;
      }
    });
  }

  closeModal() {
    this.SellersService.closeModal.hide();
  }

  saveSeller() {
    if(this.codeSell.toUpperCase().trim() && this.descSell.toUpperCase().trim()){
      let id_obj: any;
      if(this.SellersService.new){
        id_obj = '';
      }else{
        id_obj = this.SellersService.pk;
      }
      if (this.SellersService.isCatalog) {
        this.SellersService.modalType = 'catalogo';
      }
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('saveseller'),
        auth: this.auth,
        body: {
          id_obj: id_obj,
          code: this.codeSell.toUpperCase().trim(),
          desc: this.descSell.toUpperCase().trim(),
          user: this.CodeUser,
          deposit: this.SellersService.CodeDepo,
          supervisor: this.SellersService.CodeSupe,
          tipo: this.SellersService.modalType
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: 'Guardando Ruta',
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if(resp.new){
            if(resp.tipo == 'catalogo'){
              this.SellersService.continuar = true;
              this.SellersService.evenAdd.emit(resp);
            }else{
              if(this.SellersService.modalType === 'seller-customer'){
                this.modalSellerCustomer(resp);
              } else if (this.SellersService.modelo === 'Conflicto') {
                this.ResolvingService.seguir = true;
                this.ResolvingService.loadselect = true;
                this.ResolvingService.evenAdd.emit({'new':resp.objeto});
              }
            }
          }else{
            this.SellersService.continuar = true;
            this.SellersService.evenAdd.emit(resp);
          }
          this.SellersService.closeModal.hide();
        }
      }).catch(error => {
        this.viewError(error);
      });
    } else {
      this.codeSell = this.codeSell.trim();
      this.descSell = this.descSell.trim();
    }
  }

  modalSellerCustomer(resp) {
    this.SellerCustomerService.SellerSelect = resp.objeto;
    this.SellerCustomerService.CodeSeller = resp.pk;
    this.SellerCustomerService.DescSeller = resp.desc;
  }

  viewError(error) {
    if (typeof error.error_id !== 'undefined') {
      if (error.error_id) {
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if (typeof error.error_user !== 'undefined') {
      if (error.error_user) {
        this.error_user = error.error_user;
        this.txt_user = error.txt_user;
      }
    }
    if (typeof error.error_depo !== 'undefined') {
      if (error.error_depo) {
        this.error_depo = error.error_depo;
        this.txt_depo = error.txt_depo;
      }
    }
    if (typeof error.error_supe !== 'undefined') {
      if (error.error_supe) {
        this.error_supe = error.error_supe;
        this.txt_supe = error.txt_supe;
      }
    }
    if (typeof error.error_code !== 'undefined') {
      if (error.error_code) {
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if (typeof error.error_desc !== 'undefined') {
      if (error.error_desc) {
        this.error_desc = error.error_desc;
        this.txt_desc = error.txt_desc;
      }
    }
  }

  openModalDeposit() {
    this.CatalogoTwoService.selectOneMD = null;
    this.CatalogoTwoService.OneSelect = this.SellersService.LocationSelect;
    this.SellersService.typeModal = 'seller';
    this.SellersService.modelo = 'Seller';
    const initialState = {
      componentParent: this
    };
    this.modalRef = this.modalService.show(ModalDepositComponent, {initialState});
    this.modalRef.setClass('modal-dialog-centered');
    this.CatalogoTwoService.closeModal = this.modalRef;
  }

  openModalSupervisor() {
    this.SupervisorsService.new = true;
    this.SupervisorsService.pk = 0;
    this.SupervisorsService.isCatalog = false;
    this.SupervisorsService.modalType = 'Seller';
    this.modalRef = this.modalService.show(ModalSupervisorComponent);
    this.modalRef.setClass('modal-dialog-centered modal-xl mod_tab');
    this.SupervisorsService.closeModal = this.modalRef;
  }

  changeUser() {
    this.error_id = false;
    this.txt_id = '';
    this.error_user = false;
    this.txt_user = '';
    this.DescUser = null;
    for (const key in this.SellersService.UserCompanySelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.UserCompanySelect, key)) {
        const user = this.SellersService.UserCompanySelect[key];
        if (user.id == this.CodeUser) {
          this.DescUser = user.id;
          break;
        }
      }
    }
  }

  changeDescUser() {
    this.error_id = false;
    this.txt_id = '';
    this.error_user = false;
    this.txt_user = '';
    this.CodeUser = null;
    for (const key in this.SellersService.UserCompanySelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.UserCompanySelect, key)) {
        const user = this.SellersService.UserCompanySelect[key];
        if (user.id == this.DescUser) {
          this.CodeUser = user.id;
          break;
        }
      }
    }
  }

  changeDeposit() {
    this.error_id = false;
    this.txt_id = '';
    this.error_depo = false;
    this.txt_depo = '';
    this.SellersService.DescDepo = null;
    for (const key in this.SellersService.DepositSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.DepositSelect, key)) {
        const deposit = this.SellersService.DepositSelect[key];
        if (deposit.id == this.SellersService.CodeDepo) {
          this.SellersService.DescDepo = deposit.id;
          break;
        }
      }
    }
  }

  changeDescDeposit() {
    this.error_id = false;
    this.txt_id = '';
    this.error_depo = false;
    this.txt_depo = '';
    this.SellersService.CodeDepo = null;
    for (const key in this.SellersService.DepositSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.DepositSelect, key)) {
        const deposit = this.SellersService.DepositSelect[key];
        if (deposit.id == this.SellersService.DescDepo) {
          this.SellersService.CodeDepo = deposit.id;
          break;
        }
      }
    }
  }

  changeSupervisor() {
    this.error_id = false;
    this.txt_id = '';
    this.error_supe = false;
    this.txt_supe = '';
    this.SellersService.DescSupe = null;
    for (const key in this.SellersService.SupervisorSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.SupervisorSelect, key)) {
        const supervisor = this.SellersService.SupervisorSelect[key];
        if (supervisor.id == this.SellersService.CodeSupe) {
          this.SellersService.DescSupe = supervisor.id;
          break;
        }
      }
    }
  }

  changeDescSupervisor() {
    this.error_id = false;
    this.txt_id = '';
    this.error_supe = false;
    this.txt_supe = '';
    this.SellersService.CodeSupe = null;
    for (const key in this.SellersService.SupervisorSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.SupervisorSelect, key)) {
        const supervisor = this.SellersService.SupervisorSelect[key];
        if (supervisor.id == this.SellersService.DescSupe) {
          this.SellersService.CodeSupe = supervisor.id;
          break;
        }
      }
    }
  }

  formatCode() {
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
    this.codeSell = this.codeSell.toUpperCase().trim();
    if (this.codeSell) {
      while (this.codeSell.length < 3) {
        this.codeSell = '0'+this.codeSell;
      } 
    }
  }

  formatDesc() {
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
    this.descSell = this.descSell.toUpperCase();
  }

}
