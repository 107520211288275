import { Component, EventEmitter, OnInit } from '@angular/core';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import Swal from 'sweetalert2';
import { CatalogoFourthService, ObjectFourth } from '../services/catalogo-fourth.service';
import { Title } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalBankComponent } from '../modal-bank/modal-bank.component';
import { ActivatedRoute } from '@angular/router';
import { ViewLoader } from 'src/app/core/viewLoader/viewLoader';

@Component({
  selector: 'app-fourth',
  inputs: ['placeholderSearch', 'titleAddModal', 'faIcon', 'not_item', 'not_search'],
  templateUrl: './fourth.component.html',
  styleUrls: ['./fourth.component.css']
})
export class FourthComponent  extends ViewLoader implements OnInit {
  placeholderSearch: string;
  titleAddModal: string;
  faIcon: string;
  searchUrl: boolean | string = false;
  return = '';
  bsModalRef: BsModalRef;
  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar',
    cancelButtonColor: '#c82333'
  });
  resp_field = 'objects';
  fields_search = ['name', 'description'];
  field_norepeat = (resp: ObjectFourth) => {
		return resp.id;
	};
  desde: number = 0;
  hasta: number = 500;
  totalItems: number = 500;
  seguir = true;
  not_item: string;
  not_search: string;

  constructor(
    public CatalogoFourthService: CatalogoFourthService,
    public auth: AuthService,
    private listUrl: UrListService,
    private modalService: BsModalService, 
    private route: ActivatedRoute
  ) {
    super();
    this.route.queryParams.subscribe(params => CatalogoFourthService.urlreturn = params.return || '');
  }

  ngOnInit() {
    this.of = Number(this.desde);
    this.to = Number(this.hasta);
    this.numItem = Number(this.totalItems);
    this.msg_init = this.not_item;
    this.msg_search = this.not_search;
    this.msg_show = this.msg_init;
    this.initLoader = {
      method: 'put',
      auth: this.auth,
      url: this.CatalogoFourthService.urlLoading
    };
    this.configLoader = {visible: false, autoReNew: true};
    this.initScrolin = Object.assign({}, this.initLoader);
    this.initSearch = Object.assign({}, this.initLoader);
    this.configSearch = {titleSuccess: '', visible: true};
		this.confiScrolin = Object.assign({}, this.configLoader);
    this.load_items();
    setInterval(() => {
      if (this.CatalogoFourthService.create != this.create) {
        this.load_options();
      }
    }, 1000);
    this.getCountries();
  	this.getCurrencies();
  }

  load_options() {
    if (this.return) {
      this.searchUrl = this.return;
    }
    if (this.item_show.length > 0) {
      this.CatalogoFourthService.habilitaBtn = true;
    }
    if (this.create && this.searchUrl) {
      this.CatalogoFourthService.ambos = true;
      this.CatalogoFourthService.create = false;
      this.CatalogoFourthService.regresa = false;
      this.CatalogoFourthService.urlreturn = this.searchUrl;
    } else {
      if (this.create) {
        this.CatalogoFourthService.create = this.create;
      }
      if (this.regresa) {
        this.CatalogoFourthService.regresa = this.regresa;
        this.CatalogoFourthService.urlreturn = this.searchUrl;
      }
    }
  }

  getId() {
    return this.CatalogoFourthService.viewCode;
  }

  openModalAdd(newOne, pkObj) {
  	this.CatalogoFourthService.pk = pkObj;
    this.CatalogoFourthService.newMU = newOne;
    if (!newOne) {
      for (const key in this.item_show) {
        if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
          const element = this.item_show[key];
          if (element.id == this.CatalogoFourthService.pk) {
            this.CatalogoFourthService.object = element;
            break;
          }
        }
      }
    }
    const initialState = {
      componentParent: this
    };
    this.bsModalRef = this.modalService.show(this.CatalogoFourthService.viewModal, {initialState});
    this.CatalogoFourthService.evenAdd.subscribe(resp => {
      if (this.CatalogoFourthService.continuar) {
        this.objCall(resp, (call) => {
          this.CatalogoFourthService.continuar = call;
        })
      }
		});
    this.bsModalRef.setClass('modal-dialog-centered zindex-one');
    this.CatalogoFourthService.closeModal = this.bsModalRef;
  }

  getCountries() {
  	const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('iniregister'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false,
      forceNormal: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.CatalogoFourthService.countrys = respo.dat.count;
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  getCurrencies() {
  	const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_currencies'),
      auth: this.auth,
      body: ''
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.CatalogoFourthService.currList = respo.dat;
      } else {
        return false;
      }
    }).catch(error => {
      return false;
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  deletevalObj(pk, name) {
    const confirDelete = `${this.CatalogoFourthService.preguntaDelete} ${name}`;
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.removeObj(pk);
      });
    }, 'Desea Eliminar?',
    confirDelete);
  }

  removeObj(id) {
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: id
      },
      url: this.CatalogoFourthService.urlDelete
    };
    const configAjax: Config = {
      titleLoading: `Eliminando del ${this.CatalogoFourthService.strDelete}...`,
      titleSuccess: '',
      textErrorNormal: `No se logro eliminar ${this.CatalogoFourthService.strConecDelete}`,
      titleErrorNormal: `Error al Eliminar ${this.CatalogoFourthService.strDelete}`,
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        for (const key in this.item_base) {
          if (Object.prototype.hasOwnProperty.call(this.item_base, key)) {
            const element = this.item_base[key];
            if (element.id_banks == id) {
              this.item_base.splice(Number(key), 1);
              break;
            }
          }
        }
        for (const key in this.item_show) {
          if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
            const element = this.item_show[key];
            if (element.id_banks == id) {
              this.item_show.splice(Number(key), 1);
              break;
            }
          }
        }
        for (const key in this.item_search) {
          if (Object.prototype.hasOwnProperty.call(this.item_search, key)) {
            const element = this.item_search[key];
            if (element.id_banks == id) {
              this.item_search.splice(Number(key), 1);
              break;
            }
          }
        }
        if(this.item_base.length === 0 && this.CatalogoFourthService.habilitaBtn){
          this.CatalogoFourthService.habilitaBtn = false;
        }
      }
    }).catch( error => {
      return error;
    });
  }

  onscroll(event: any) {
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
			this.load_more_item();
		}
	}

  objCall(resp, callback: any = false) {
    if (resp.new) {
      this.newObject(resp);
    } else {
      this.editObject(resp);
    }
    callback(false);
  }

  newObject(resp) {
    this.item_base.unshift(resp.objects);
    this.item_show.unshift(resp.objects);
    this.CatalogoFourthService.habilitaBtn = true;
    this.CatalogoFourthService.evenAdd = new EventEmitter();
  }

  editObject(resp) {
    for (const key in this.item_show) {
      if (Object.prototype.hasOwnProperty.call(this.item_show, key)) {
        const element = this.item_show[key];
        if (element.id_banks == resp.objects.id_banks) {
          this.item_show[key] = resp.objects;
          break;
        }
      }
    }
    for (const key in this.item_base) {
      if (Object.prototype.hasOwnProperty.call(this.item_base, key)) {
        const element = this.item_base[key];
        if (element.id_banks == resp.objects.id_banks) {
          this.item_base[key] = resp.objects;
          break;
        }
      }
    }
    for (const key in this.item_search) {
      if (Object.prototype.hasOwnProperty.call(this.item_search, key)) {
        const element = this.item_search[key];
        if (element.id_banks == resp.objects.id_banks) {
          this.item_search[key] = resp.objects;
          break;
        }
      }
    }
    this.CatalogoFourthService.evenAdd = new EventEmitter();
  }
}

@Component({
  selector: 'app-bank',
  template: `
    <app-fourth
      placeholderSearch="Bancos"
      titleAddModal="Agregar Banco"
      faIcon="fas fa-user"
      not_item = "No hay Bancos registrados actualmente."
      not_search= "No se encontraron resultados de bancos para tu busqueda."
      >
    </app-fourth>
  `
})
export class BankComponent implements OnInit {
  constructor(
    public CatalogoFourthService: CatalogoFourthService,
    public auth: AuthService, 
  	private listUrl: UrListService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Bonaventure :. Catálogo de Bancos');
  }

  ngOnInit() {
  	this.CatalogoFourthService.urlLoading = this.listUrl.url('get_banks');
    this.CatalogoFourthService.viewModal = ModalBankComponent;
    this.CatalogoFourthService.preguntaDelete = 'Realmente quiere eliminar el banco:';
    this.CatalogoFourthService.urlDelete = this.listUrl.url('delete_bank');
    this.CatalogoFourthService.strDelete = 'Catálogo de bancos';
    this.CatalogoFourthService.strConecDelete = 'el Banco';
    this.CatalogoFourthService.viewCode = 'ADMCAIU00001';
    this.CatalogoFourthService.addCode = 'ADMCAIU00002';
    this.CatalogoFourthService.editCode = 'ADMCAIU00003';
  }
}
