import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { SettingComponent } from './setting/setting.component';
import { AuthGuardService } from 'src/app/core/canActivate/auth-guard-login';
import { SelectCompanyGuard } from 'src/app/core/canActivate/select-company.guard';
import { InventarioComponent } from './inventario/inventario.component';
import { SupervisorComponent } from './supervisor/supervisor.component';
import { InvitationComponent } from './invitation/invitation.component';
import { FiscalPrinterComponent } from './fiscal-printer/fiscal-printer.component';
import { LaboralDaysComponent } from './laboral-days/laboral-days.component';
import { PaymentIntentComponent } from './payment-intent/payment-intent.component';

const routes: Routes = [
  { path: 'catalogo', component: CatalogoComponent, loadChildren: './catalogo/catalogo.module#CatalogoModule'},
  { path: 'inventario', component: InventarioComponent, loadChildren: './inventario/inventario.module#InventarioModule'},
  { path: 'config', component: SettingComponent, canActivate: [ AuthGuardService, SelectCompanyGuard ]},
  { path: 'roles_permisos', component: SupervisorComponent, canActivate: [ AuthGuardService, SelectCompanyGuard ]},
  { path: 'invitation', component: InvitationComponent, canActivate: [ AuthGuardService, SelectCompanyGuard ]},
  { path: 'fiscalprinter', component: FiscalPrinterComponent },
  { path: 'dias-laborales', component: LaboralDaysComponent, canActivate: [ AuthGuardService, SelectCompanyGuard]},
  { path: 'payment-intent', component: PaymentIntentComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrativoRoutingModule { }
