export const base_url = 'https://apidev.bon-aventure.com';
const socket_base = 'http://localhost:3000';
export const ListUrl = {
    baseUrl: base_url,
    baseUrlSt: socket_base,
    login_url: `${base_url}/inicio/api/login`,
    select_company: `${base_url}/inicio/api/select-company`,
    load_company: ['load_company', `${base_url}/inicio/api/load_company`],
    invitationSearch: ['invitationSearch', `${base_url}/puntodeventa/api/search-user`],
    users_company: ['usersCompany', `${base_url}/puntodeventa/api/users-company`],
    list_modal_group: ['list_modal_group', `${base_url}/administrativo/api/catalog/products/list_modal_group`],
    views_subgroups: ['viewSubgroup', `${base_url}/administrativo/api/catalog/products/view_subgroup`],
    removeUserCompany: ['removeUserCompany', `${base_url}/puntodeventa/api/remove-users`],
    sendInvitation: ['sendInvitation', `${base_url}/puntodeventa/api/send-invitation`],
    list_modal_subgroup: ['list_modal_subgroup', `${base_url}/administrativo/api/catalog/products/list_modal_subgroup`],
    savesubgroup: ['savesubgroup', `${base_url}/administrativo/api/catalog/products/savesubgroup`],
    resolving_conflicts_subgroup: ['resolving_conflicts_subgroup', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_subgroup`],
    delete_subgroup: ['delete_subgroup', `${base_url}/administrativo/api/catalog/products/delete_subgroup`],
    views_groups: ['views_groups', `${base_url}/administrativo/api/catalog/products/view_group`],
    savegroup: ['savegroup', `${base_url}/administrativo/api/catalog/products/savegroup`],
    resolving_conflicts_group: ['resolving_conflicts_group', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_group`],
    delete_group: ['delete_group', `${base_url}/administrativo/api/catalog/products/delete_group`],
    views_locations: ['views_locations', `${base_url}/administrativo/api/catalog/products/views_location`],
    list_modal_location: ['list_modal_location', `${base_url}/administrativo/api/catalog/products/list_modal_location`],
    savelocation: ['savelocation', `${base_url}/administrativo/api/catalog/products/savelocation`],
    resolving_conflicts_location: ['resolving_conflicts_location', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_location`],
    delete_location: ['delete_location', `${base_url}/administrativo/api/catalog/products/delete_location`],
    views_presentations: ['views_presentations', `${base_url}/administrativo/api/catalog/products/views_presentation`],
    savepresentation: ['savepresentation', `${base_url}/administrativo/api/catalog/products/savepresentation`],
    resolving_conflicts_presentation: ['resolving_conflicts_presentation', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_presentation`],
    delete_presentation: ['delete_presentation', `${base_url}/administrativo/api/catalog/products/delete_presentation`],
    views_shops: ['views_shops', `${base_url}/administrativo/api/catalog/products/views_shop`],
    saveshop: ['saveshop', `${base_url}/administrativo/api/catalog/products/saveshop`],
    resolving_conflicts_shop: ['resolving_conflicts_shop', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_shop`],
    delete_shop: ['delete_shop', `${base_url}/administrativo/api/catalog/products/delete_shop`],
    views_type_products: ['views_type_products', `${base_url}/administrativo/api/catalog/products/views_type_product`],
    savetypeproduct: ['savetypeproduct', `${base_url}/administrativo/api/catalog/products/savetypeproduct`],
    resolving_conflicts_type_product: ['resolving_conflicts_type_product', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_type_product`],
    delete_type_product: ['delete_type_product', `${base_url}/administrativo/api/catalog/products/delete_type_product`],
    views_currencies: ['views_currencies', `${base_url}/administrativo/api/catalog/views_currency`],
    select_currencies: ['select_currencies', `${base_url}/administrativo/api/catalog/select_currencies`],
    savecurrency: ['savecurrency', `${base_url}/administrativo/api/catalog/savecurrency`],
    resolving_conflicts_currency: ['resolving_conflicts_currency', `${base_url}/administrativo/api/catalog/resolving_conflicts_currency`],
    delete_currency: ['delete_currency', `${base_url}/administrativo/api/catalog/delete_currency`],
    views_deposits: ['views_deposits', `${base_url}/administrativo/api/catalog/products/views_deposits`],
    savedeposit: ['savedeposit', `${base_url}/administrativo/api/catalog/products/savedeposit`],
    resolving_conflicts_deposit: ['resolving_conflicts_deposit', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_deposit`],
    delete_deposit: ['delete_deposit', `${base_url}/administrativo/api/catalog/products/delete_deposit`],
    select_deposits: ['select_deposits', `${base_url}/administrativo/api/catalog/products/select_deposits`],
    select_deposits_compra: ['select_deposits_compra', `${base_url}/administrativo/api/catalog/products/select_deposits_compra`],
    views_lines: ['views_lines', `${base_url}/administrativo/api/catalog/products/views_lines`],
    resolving_conflicts_line_product: ['resolving_conflicts_line_product', `${base_url}/administrativo/api/catalog/products/resolving_conflicts_line_product`],
    saveline: ['saveline', `${base_url}/administrativo/api/catalog/products/saveline`],
    delete_line: ['delete_line', `${base_url}/administrativo/api/catalog/products/delete_line`],
    views_suppliers: ['views_suppliers', `${base_url}/administrativo/api/views_suppliers`],
    savesupplier: ['savesupplier', `${base_url}/administrativo/api/savesupplier`],
    resolving_conflicts_supplier: ['resolving_conflicts_supplier', `${base_url}/administrativo/api/resolving_conflicts_supplier`],
    delete_supplier: ['delete_supplier', `${base_url}/administrativo/api/delete_supplier`],
    list_modal_country: ['list_modal_country', `${base_url}/inicio/api/list_modal_country`],
    list_modal_state: ['list_modal_state', `${base_url}/inicio/api/list_modal_state`],
    list_modal_city: ['list_modal_city', `${base_url}/inicio/api/list_modal_city`],
    list_modal_geo: ['list_modal_geo', `${base_url}/inicio/api/list_modal_geo`],
    selects_geo: ['selects_geo', `${base_url}/inicio/api/selects_geo`],
    loading_setting: ['loading_setting', `${base_url}/administrativo/api/loading_setting`],
    saveconfig_gnral: ['saveconfig_gnral', `${base_url}/administrativo/api/saveconfig_gnral`],
    views_products: ['views_products', `${base_url}/administrativo/api/catalog/products/views_product`],
    saveproduct: ['saveproduct', `${base_url}/administrativo/api/catalog/products/saveproduct`],
    delete_product: ['delete_product', `${base_url}/administrativo/api/catalog/products/delete_product`],
    change_active_product: ['change_active_product', `${base_url}/administrativo/api/catalog/products/change_active_product`],
    select_products: ['select_products', `${base_url}/administrativo/api/catalog/products/select_products`],
    views_spices: ['views_spices', `${base_url}/administrativo/api/catalog/products/views_spice`],
    savespices: ['savespices', `${base_url}/administrativo/api/catalog/products/savespice`],
    delete_spices: ['delete_spices', `${base_url}/administrativo/api/catalog/products/delete_spice`],
    select_spices: ['select_spices', `${base_url}/administrativo/api/catalog/products/select_spice`],
    list_modal_spices: ['list_modal_spices', `${base_url}/administrativo/api/catalog/products/list_modal_spice`],
    views_taxs: ['views_taxs', `${base_url}/administrativo/api/catalog/views_tax`],
    savetax: ['savetax', `${base_url}/administrativo/api/catalog/savetax`],
    resolving_conflicts_tax: ['resolving_conflicts_tax', `${base_url}/administrativo/api/catalog/resolving_conflicts_tax`],
    delete_tax: ['delete_tax', `${base_url}/administrativo/api/catalog/delete_tax`],
    list_modal_typeproduct: ['list_modal_typeproduct', `${base_url}/administrativo/api/catalog/products/list_modal_typeproduct`],
    list_modal_presentation: ['list_modal_presentation', `${base_url}/administrativo/api/catalog/products/list_modal_presentation`],
    list_modal_shop: ['list_modal_shop', `${base_url}/administrativo/api/catalog/products/list_modal_shop`],
    list_modal_kind: ['list_modal_kind', `${base_url}/administrativo/api/catalog/products/list_modal_kind`],
    select_taxs: ['select_taxs', `${base_url}/administrativo/api/catalog/select_taxs`],
    list_taxs_product: ['list_taxs_product', `${base_url}/administrativo/api/catalog/list_taxs_product`],
    select_suppliers: ['select_suppliers', `${base_url}/administrativo/api/select_suppliers`],
    searchgeo: ['searchgeo', `${base_url}/geo/searchgeo`],
    gettimezone: ['gettimezone', `${base_url}/get_timezones`],
    iniregister: ['iniregister', `${base_url}/inicio/api/iniregister`],
    register: ['register', `${base_url}/inicio/api/register`],
    iniplans: ['iniplans', `${base_url}/plan/api/iniplans`],
    plans: ['plans', `${base_url}/plan/api/plans`],
    inichoicecompany: ['inichoicecompany', `${base_url}/plan/api/inichoicecompany`],
    choicecompany: ['choicecompany', `${base_url}/plan/api/choicecompany`],
    inisupervisor: ['inisupervisor', `${base_url}/administrativo/api/inisupervisor`],
    register_company: ['register_company',  `${base_url}/inicio/api/register_company`],
    get_stations: ['get_stations', `${base_url}/puntodeventa/get_stations`],
    get_cashing: ['get_cashing', `${base_url}/puntodeventa/get_cashing`],
    add_cashing: ['add_cashing', `${base_url}/puntodeventa/add_cashing`],
    supervisor: ['supervisor', `${base_url}/administrativo/api/supervisor`],
    views_salesforce: ['views_salesforce', `${base_url}/administrativo/api/catalog/views_salesforce`],
    savesalesforce: ['savesalesforce', `${base_url}/administrativo/api/catalog/savesalesforce`],
    resolving_conflicts_salesforce: ['resolving_conflicts_salesforce', `${base_url}/administrativo/api/catalog/resolving_conflicts_salesforce`],
    delete_salesforce: ['delete_salesforce', `${base_url}/administrativo/api/catalog/delete_salesforce`],
    views_supervisor: ['views_supervisor', `${base_url}/administrativo/api/catalog/views_supervisor`],
    savesupervisor: ['savesupervisor', `${base_url}/administrativo/api/catalog/savesupervisor`],
    resolving_conflicts_supervisor: ['resolving_conflicts_supervisor', `${base_url}/administrativo/api/catalog/resolving_conflicts_supervisor`],
    delete_supervisor: ['delete_supervisor', `${base_url}/administrativo/api/catalog/delete_supervisor`],
    list_modal_salesforce: ['list_modal_salesforce', `${base_url}/administrativo/api/catalog/list_modal_salesforce`],
    list_modal_superus: ['list_modal_superus', `${base_url}/administrativo/api/catalog/list_modal_superus`],
    views_config_station: ['views_config_station', `${base_url}/administrativo/api/views_config_station`],
    saveconfig_station: ['saveconfig_station', `${base_url}/administrativo/api/saveconfig_station`],
    savepurchase: ['savepurchase', `${base_url}/administrativo/api/inventory/savepurchase`],
    password_reset: `${base_url}/password_reset`,
    get_products: ['get_products', `${base_url}/puntodeventa/get_products`],
    get_documents: ['get_documents', `${base_url}/puntodeventa/get_documents`],
    lots_price_producto: ['lots_price_producto', `${base_url}/administrativo/api/inventory/list_lotsprice`],
    register_customer: ['register_customer',`${base_url}/inicio/api/register_customer`],
    edit_customer: ['edit_customer',`${base_url}/inicio/api/edit_customer`],
    get_groups: ['get_groups',`${base_url}/inicio/api/get_groups`],
    get_group_customer: ['get_group',`${base_url}/inicio/api/get_group`],
    edit_group_customer: ['edit_group_customer',`${base_url}/inicio/api/edit_group`],
    delete_group_customer: ['delete_group_customer',`${base_url}/inicio/api/delete_group`],
    register_group_customer: ['register_group_customer',`${base_url}/inicio/api/register_group`],
    edit_subgroup_customer: ['edit_subgroup_customer',`${base_url}/inicio/api/edit_subgroup`],
    delete_subgroup_customer: ['delete_subgroup_customer',`${base_url}/inicio/api/delete_subgroup`],
    register_subgroup_customer: ['register_subgroup_customer',`${base_url}/inicio/api/register_subgroup`],
    get_subgroups: ['get_subgroups',`${base_url}/inicio/api/get_subgroups`],
    get_types: ['get_types',`${base_url}/inicio/api/get_types`],
    register_type: ['register_type',`${base_url}/inicio/api/register_type`],
    edit_type: ['edit_type',`${base_url}/inicio/api/edit_type`],
    delete_type: ['delete_type',`${base_url}/inicio/api/delete_type`],
    get_customers: ['get_customers',`${base_url}/inicio/api/get_customers`],
    get_customer: ['get_customer',`${base_url}/inicio/api/get_customer`],
    delete_customer: ['delete_customer',`${base_url}/inicio/api/delete_customer`],
    process_order: ['process_order', `${base_url}/puntodeventa/orders`],
    get_pedidos: ['get_pedidos', `${base_url}/puntodeventa/get_pedidos`],
    remove_order: ['remove_order', `${base_url}/puntodeventa/remove_order`],
    save_station: ['save_station', `${base_url}/administrativo/api/save_station`],
    views_station: ['views_station', `${base_url}/administrativo/api/views_station`],
    delete_station: ['delete_station', `${base_url}/administrativo/api/delete_station`],
    list_expirations_product: ['list_expirations_product', `${base_url}/administrativo/api/inventory/list_expirations_product`],
    list_existence_product: ['list_existence_product', `${base_url}/administrativo/api/inventory/list_existence_product`],
    check_years: ['check_years', `${base_url}/administrativo/api/inventory/check_years`],
    search_statistics: ['search_statistics', `${base_url}/administrativo/api/inventory/search_statistics`],
    customer_conflicts: ['customer_conflicts',`${base_url}/inicio/api/customer_conflicts`],
    solve_conflicts: ['solve_conflicts',`${base_url}/inicio/api/solve_conflicts`],
    check_conflicts: ['check_conflicts',`${base_url}/inicio/api/check_conflicts`],
    views_groups_customer: ['views_groups_customer', `${base_url}/administrativo/api/catalog/customer/groups`],
    views_type_customer: ['views_type_customer', `${base_url}/administrativo/api/catalog/customer/types`],
    resolving_conflicts_type_customer: ['resolving_conflicts_type_customer', `${base_url}/administrativo/api/catalog/customer/resolving_conflicts_type_customer`],
    delete_type_costumer: ['delete_type_costumer', `${base_url}/administrativo/api/catalog/customer/delete_type_costumer`],
    savetypecustomer: ['savetypecustomer', `${base_url}/administrativo/api/catalog/customer/savetypecustomer`],
    savegroup_customers: ['savegroup_customers', `${base_url}/administrativo/api/catalog/customer/savegroup`],
    resolving_conflicts_group_customer: ['resolving_conflicts_group_customer', `${base_url}/administrativo/api/catalog/customer/resolving_conflicts_group_customer`],
    delete_group_costumer: ['delete_group_costumer', `${base_url}/administrativo/api/catalog/customer/delete_group_costumer`],
    views_subgroups_customer: ['views_subgroups_customer', `${base_url}/administrativo/api/catalog/customer/views_subgroups_customer`],
    list_modal_group_customer: ['list_modal_group_customer', `${base_url}/administrativo/api/catalog/customer/list_modal_group_customer`],
    savesubgroup_customer: ['savesubgroup_customer', `${base_url}/administrativo/api/catalog/customer/savesubgroup_customer`],
    resolving_conflicts_subgroup_customer: ['resolving_conflicts_subgroup_customer',`${base_url}/administrativo/api/catalog/customer/resolving_conflicts_subgroup_customer`],
    delete_subgroup_customer_api: ['delete_subgroup_customer_api',`${base_url}/administrativo/api/catalog/customer/delete_subgroup_customer`],
    saveset_controls: ['saveset_controls', `${base_url}/administrativo/api/saveset_controls`],
    get_banks: ['get_banks',`${base_url}/puntodeventa/api/get_banks`],
    register_bank: ['register_bank',`${base_url}/puntodeventa/api/register_bank`],
    edit_bank: ['edit_bank',`${base_url}/puntodeventa/api/edit_bank`],
    delete_bank: ['delete_bank',`${base_url}/puntodeventa/api/delete_bank`],
    get_currencies: ['get_currencies',`${base_url}/puntodeventa/api/get_currencies`],
    views_sellers: ['views_sellers', `${base_url}/administrativo/api/catalog/views_sellers`],
    saveseller: ['saveseller', `${base_url}/administrativo/api/catalog/saveseller`],
    resolving_conflicts_seller: ['resolving_conflicts_seller', `${base_url}/administrativo/api/catalog/resolving_conflicts_seller`],
    delete_seller: ['delete_seller', `${base_url}/administrativo/api/catalog/delete_seller`],
    select_sellers: ['select_sellers', `${base_url}/administrativo/api/catalog/select_sellers`],
    select_users_company: ['select_users_company', `${base_url}/administrativo/api/catalog/select_users_company`],
    select_supervisors: ['select_supervisors', `${base_url}/administrativo/api/catalog/select_supervisors`],
    views_sellers_customers: ['views_sellers_customers', `${base_url}/administrativo/api/catalog/views_sellers_customers`],
    saveseller_customer: ['saveseller_customer', `${base_url}/administrativo/api/catalog/saveseller_customer`],
    delete_seller_customer: ['delete_seller_customer', `${base_url}/administrativo/api/catalog/delete_seller_customer`],
    select_customers: ['select_customers', `${base_url}/administrativo/api/catalog/select_customers`],
    select_facturas: ['select_facturas', `${base_url}/inicio/api/select_facturas`],
    sendBill: ['sendBill', `${base_url}/inicio/api/sendBill`],
    create_factura: ['create_factura', `${base_url}/puntodeventa/create_factura`],
    get_facturas: ['get_facturas', `${base_url}/puntodeventa/get_facturas`],
    anular_factura: ['anular_factura', `${base_url}/puntodeventa/anular_factura`],
    devolver_factura: ['devolver_factura', `${base_url}/puntodeventa/devolver_factura`],
    register_customer_pdv: ['register_customer_pdv',`${base_url}/inicio/api/register_customer_pdv`],
    calculate_parcial: ['calculate_parcial',`${base_url}/puntodeventa/calculate_parcial`],
    set_partial: ['set_partial',`${base_url}/puntodeventa/set_partial`],
    calculate_total: ['calculate_total',`${base_url}/puntodeventa/calculate_total`],
    set_total: ['set_total',`${base_url}/puntodeventa/set_total`],
    desblock: ['desblock', `${base_url}/inicio/api/desblock`],
    get_usercompanys: ['get_usercompanys', `${base_url}/inicio/api/get_usercompanys`],
    setprinter_config: ['setprinter_config', `${base_url}/administrativo/api/setprinter_config`],
    getprinter_config: ['getprinter_config', `${base_url}/administrativo/api/getprinter_config`],
    cerrar_caja: ['cerrar_caja', `${base_url}/puntodeventa/cerrar_caja`],
    get_laboral_days:['get_laboral_days', `${base_url}/administrativo/catalogo/get_laboral_days`],
    set_laboral_days:['set_laboral_days', `${base_url}/administrativo/catalogo/set_laboral_days`],
    delete_laboral_days:['delete_laboral_days', `${base_url}/administrativo/catalogo/delete_laboral_days`],
    delete_dispatch_areas:['delete_dispatch_areas', `${base_url}/administrativo/catalogo/delete_dispatch_areas`],
    register_dispatch_areas:['register_dispatch_areas', `${base_url}/administrativo/catalogo/register_dispatch_areas`],
    get_dispatch_areas:['get_dispatch_areas', `${base_url}/administrativo/catalogo/get_dispatch_areas`],
    get_station_config: ['get_station_config', `${base_url}/administrativo/api/get_station_config`],
    set_vehicles_customer:['set_vehicles_customer', `${base_url}/administrativo/catalogo/set_vehicles_customer`],
    delete_vehicles_customer:['delete_vehicles_customer', `${base_url}/administrativo/catalogo/delete_vehicles_customer`],
    get_vehicles_customer:['get_vehicles_customer', `${base_url}/administrativo/catalogo/get_vehicles_customer`],
    get_services:['get_services', `${base_url}/plan/get_services`],
    set_plans:['set_plans', `${base_url}/plan/set_plans`],
    get_plans:['get_plans', `${base_url}/plan/get_plans`],
    get_plans_types:['get_plans_types', `${base_url}/plan/get_plans_types`],
    get_plan_countries:['get_plan_countries', `${base_url}/plan/get_plan_countries`],
    set_plan_countries:['set_plan_countries', `${base_url}/plan/set_plan_countries`],
    delete_plan_countries:['delete_plan_countries', `${base_url}/plan/delete_plan_countries`],
    delete_plan:['delete_plan', `${base_url}/plan/delete_plan`],
    delete_service:['delete_service', `${base_url}/plan/delete_service`],
    get_pdf:['get_pdf', `${base_url}/plan/get_pdf`],
    get_companies:['get_companies', `${base_url}/plan/get_companies`],
    set_companies:['set_companies', `${base_url}/plan/set_companies`],
    delete_companies:['delete_companies', `${base_url}/plan/delete_companies`],
    get_setting: ['get_setting', `${base_url}/administrativo/api/get_setting`],
    savesalesforceasistente: ['savesalesforceasistente', `${base_url}/administrativo/api/catalog/savesalesforceasistente`],
    views_salesforceasistente: ['views_salesforceasistente', `${base_url}/administrativo/api/catalog/views_salesforceasistente`],
    list_modal_salesforceasistente: ['list_modal_salesforceasistente', `${base_url}/administrativo/api/catalog/list_modal_salesforceasistente`],
    delete_salesforceasistente: ['delete_salesforceasistente', `${base_url}/administrativo/api/catalog/delete_salesforceasistente`],
    saveinventaryinput: ['saveinventaryinput', `${base_url}/administrativo/api/catalog/saveinventaryinput`],
    views_inventaryinput: ['views_inventaryinput', `${base_url}/administrativo/api/catalog/views_inventaryinput`],
    delete_inventaryinput: ['delete_inventaryinput', `${base_url}/administrativo/api/catalog/delete_inventaryinput`],
    saveinventaryoutput: ['saveinventaryoutput', `${base_url}/administrativo/api/catalog/saveinventaryoutput`],
    views_inventaryoutput: ['views_inventaryoutput', `${base_url}/administrativo/api/catalog/views_inventaryoutput`],
    delete_inventaryoutput: ['delete_inventaryoutput', `${base_url}/administrativo/api/catalog/delete_inventaryoutput`],
    savereasonvisit: ['savereasonvisit', `${base_url}/administrativo/api/catalog/savereasonvisit`], 
    views_reasonvisit: ['views_reasonvisit', `${base_url}/administrativo/api/catalog/views_reasonvisit`],
    delete_reasonvisit: ['delete_reasonvisit', `${base_url}/administrativo/api/catalog/delete_reasonvisit`],
    savereasonbuying: ['savereasonbuying', `${base_url}/administrativo/api/catalog/savereasonbuying`],
    views_reasonbuying: ['views_reasonbuying', `${base_url}/administrativo/api/catalog/views_reasonbuying`],
    delete_reasonbuying: ['delete_reasonbuying', `${base_url}/administrativo/api/catalog/delete_reasonbuying`],
    savenotedebit: ['savenotedebit', `${base_url}/administrativo/api/catalog/savenotedebit`],
    views_notedebit: ['views_notedebit', `${base_url}/administrativo/api/catalog/views_notedebit`],
    delete_notedebit: ['delete_notedebit', `${base_url}/administrativo/api/catalog/delete_notedebit`],
    savenotecredit: ['savenotecredit', `${base_url}/administrativo/api/catalog/savenotecredit`],
    views_notecredit: ['views_notecredit', `${base_url}/administrativo/api/catalog/views_notecredit`],
    delete_notecredit: ['delete_notecredit', `${base_url}/administrativo/api/catalog/delete_notecredit`],
    saverejection: ['saverejection', `${base_url}/administrativo/api/catalog/saverejection`],
    views_rejection: ['views_rejection', `${base_url}/administrativo/api/catalog/views_rejection`],
    delete_rejection: ['delete_rejection', `${base_url}/administrativo/api/catalog/delete_rejection`],
    saverefrigerator: ['saverefrigerator', `${base_url}/administrativo/api/catalog/saverefrigerator`],
    views_refrigerator: ['views_refrigerator', `${base_url}/administrativo/api/catalog/views_refrigerator`],
    delete_refrigerator: ['delete_refrigerator', `${base_url}/administrativo/api/catalog/delete_refrigerator`],
    savepay: ['savepay', `${base_url}/administrativo/api/catalog/savepay`],
    views_pay: ['views_pay', `${base_url}/administrativo/api/catalog/views_pay`],
    delete_pay: ['delete_pay', `${base_url}/administrativo/api/catalog/delete_pay`],
    savemessage: ['savemessage', `${base_url}/administrativo/api/catalog/savemessage`],
    views_message: ['views_message', `${base_url}/administrativo/api/catalog/views_message`],
    delete_message: ['delete_message', `${base_url}/administrativo/api/catalog/delete_message`],
    savepromocion: ['savepromocion', `${base_url}/administrativo/api/catalog/savepromocion`],
    views_promociones: ['views_promociones', `${base_url}/administrativo/api/catalog/views_promociones`],
    delete_promociones: ['delete_promociones', `${base_url}/administrativo/api/catalog/delete_promociones`],
    view_inventory_promociones: ['view_inventory_promociones', `${base_url}/administrativo/api/catalog/view_inventory_promociones`],
    saveinventorypromocion: ['saveinventorypromocion', `${base_url}/administrativo/api/catalog/saveinventorypromocion`],
    create_payment_stripe:['create_payment_stripe', `${base_url}/payment_gateway/stripe/payment_intents`],
    confirmation_payu:['confirmation_payu', `${base_url}/payment_gateway/payu/confirmation`],
    get_gateways:['get_gateways', `${base_url}/payment_gateway/get_gateways`],
    save_gateway:['save_gateway', `${base_url}/payment_gateway/save_gateway`],
    get_bank_accounts:['get_bank_accounts', `${base_url}/payment_gateway/get_bank_accounts`],
    save_bank_account:['save_bank_account', `${base_url}/payment_gateway/save_bank_account`],
    create_bank_account:['create_bank_account', `${base_url}/payment_gateway/create_bank_account`],
    delete_bank_account:['delete_bank_account', `${base_url}/payment_gateway/delete_bank_account`],
    payment_bill:['payment_bill', `${base_url}/inicio/api/payment_gateway/payment_bill`],
};
