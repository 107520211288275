import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EntriesExitsService } from '../../entries-exits.service';
import { numValidators } from '../../../setting/validators';

@Injectable({
  providedIn: 'root'
})
export class AddOtherTaxPeService {

  formOtherTaxesPe: FormGroup; //Formulario otros impuestos Pedido

  constructor(public ViewService: EntriesExitsService) {
    this.formOtherTaxesPe = new FormGroup({
      codeOtherTaxPe: new FormControl(this.ViewService.codeOTPe ,[
        Validators.required,
        Validators.maxLength(20)
      ]),
      descOtherTaxPe: new FormControl(this.ViewService.descOTPe ,[
        Validators.required
      ]),
      montOtherTaxPe: new FormControl(this.ViewService.montOTPe ,[
        Validators.required,
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
      taxOtherTaxPe: new FormControl(this.ViewService.taxOTPe ,[
        Validators.required,
        Validators.min(0),
        Validators.max(999999999999999999),
        numValidators
      ]),
    });
  }
}
