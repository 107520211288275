import { Injectable } from '@angular/core';
import { ObjectsRechazo, ObjectRechazo } from './objectrechazo';
import { Url } from 'src/app/core/ajax/url';


@Injectable({
  providedIn: 'root'
})


export class CatalogoMotivorechazoService {

  pass: number | string; 
  confirmar: boolean;
  ambos: boolean;
  create: boolean;
  regresa: string | boolean;
  Modal: any
  closeModal: any;
  pk: number | string;
  newMU: boolean;
  typeModal;
  urlSave: Url | null;
  urlLoading: Url | null;
  urlreturn: boolean | string;
  habilitaBtn = false;
  valuesObject: ObjectsRechazo  | any;
  valuesObjects: ObjectsRechazo | any;
  valuesObjectsBase: ObjectsRechazo | any;
  viewCode: string;
  editCode: string;
  addCode: string;
  preguntaDelete: string | null;
  urlDelete: Url | null;
  strDelete?: string;
  strConecDelete?: string;
  resp: any;
  object: ObjectsRechazo;

  status: boolean;
  code: string;
  description: string;
  capacidad: string;
  cajas: boolean;
  unidad: boolean;
  cliente: boolean;
  oficina: boolean;
  vendedor: boolean;
  despachador: boolean;


  constructor() { 

  }
}