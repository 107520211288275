import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorCompraService {

  error_id: boolean;
  txt_id: string;
  error_order: boolean;
  txt_order: string;
  error_typetrans: boolean;
  txt_typetrans: string;
  error_deposit: boolean;
  txt_deposit: string;
  error_supplier: boolean;
  txt_supplier: string;
  error_lisaea: boolean;
  txt_lisaea: string;
  error_drink: boolean;
  txt_drink: string;

  error_currency: boolean;
  txt_currency: string;
  error_bill: boolean;
  txt_bill: string;
  error_control: boolean;
  txt_control: string;
  error_datedoc: boolean;
  txt_datedoc: string;
  error_arrival: boolean;
  txt_arrival: string;
  error_observ: boolean;
  txt_observ: string;
  error_totalorig: boolean;
  txt_totalorig: string;


  error_product: boolean;
  txt_product: string;

  constructor() { }
}
