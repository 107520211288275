import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PuntodeventaComponent } from './puntodeventa/puntodeventa.component';
import { AdministrativoComponent } from './administrativo/administrativo.component';


const routes: Routes = [
  {
    path: 'pdv',
    component: PuntodeventaComponent,
    loadChildren: './puntodeventa/puntodeventa.module#PuntodeventaModule'},
  {
    path: 'adm',
    component: AdministrativoComponent,
    loadChildren: './administrativo/administrativo.module#AdministrativoModule'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClienteRoutingModule { }
