import { Component, OnInit } from '@angular/core';
import { SupplierService } from '../services/supplier.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Config, Ajax } from 'src/app/core/ajax/ajax';
import Swal from 'sweetalert2';
import { InventaryProductService } from '../services/inventary-product.service';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';

@Component({
  selector: 'app-modal-proveedor',
  templateUrl: './modal-proveedor.component.html',
  styleUrls: ['./modal-proveedor.component.css']
})
export class ModalProveedorComponent implements OnInit {
  formSupplier: FormGroup;
  codeSupp: string;
  nameSupp: string;
  nitSupp: string;
  phoneSupp: string;
  emailSupp: string;
  contactSupp: string;
  addressSupp: string;
  typeSupp: number;
  statusSupp: boolean = true;
  selectCountry: number | null;
  selectState:  number | null;
  selectCity:  number | null | string;
  selectIdCountry: boolean;
  selectedOne: boolean = true;
  selectIdState: boolean;
  selectedTwo: boolean = true;
  selectIdCity: boolean;
  error_id: boolean;
  txt_id: string;
  error_code: boolean;
  txt_code: string;
  error_name: boolean;
  txt_name: string;
  error_nit: boolean;
  txt_nit: string;
  error_phone: boolean;
  txt_phone: string;
  error_email: boolean;
  txt_email: string;
  error_contact: boolean;
  txt_contact: string;
  error_address: boolean;
  txt_address: string;
  error_type: boolean;
  txt_type: string;
  error_country: boolean;
  txt_country: string;
  error_state: boolean;
  txt_state: string;
  error_city: boolean;
  txt_city: string;
  loadData: boolean = false;

  constructor(
    public SupplierService: SupplierService, 
    public auth: AuthService, 
    public ProductService: InventaryProductService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ){
    this.formSupplier = new FormGroup({
      suppcode: new FormControl(this.codeSupp,[
        Validators.required,
        Validators.maxLength(10)
      ]),
      suppname: new FormControl(this.nameSupp,[
        Validators.required,
        Validators.maxLength(100)
      ]),
      suppnit: new FormControl(this.nitSupp, [
        Validators.required,
        Validators.maxLength(20)
      ]),
      suppphone: new FormControl(this.phoneSupp, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      suppemail: new FormControl(this.emailSupp, [
        Validators.email,
        Validators.required,
        Validators.maxLength(254)
      ]),
      suppcontact: new FormControl(this.contactSupp, [
        Validators.required,
        Validators.maxLength(35)
      ]),
      suppaddress: new FormControl(this.addressSupp, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      supptype: new FormControl(this.typeSupp, [
        Validators.required
      ]),
      suppcountry: new FormControl(this.selectCountry, [
        Validators.required
      ]),
      suppstate: new FormControl(this.selectState, [
        Validators.required
      ]),
      suppcity: new FormControl(this.selectCity, [
        Validators.required
      ])
    });
  }

  ngOnInit() {
    if (!this.SupplierService.new) {
      if (typeof this.SupplierService.new != 'undefined') {
        this.SupplierService.modalType = 'catalogo';
        this.selectCountry = this.SupplierService.country;
        this.selectState = this.SupplierService.state;
        this.selectCity = this.SupplierService.city;
        this.selectIdCountry = true;
        this.selectedOne = false;
        this.selectIdState = true;
        this.selectedTwo = false;
        this.selectIdCity = true;
        this.codeSupp = this.SupplierService.object.code;
        this.nameSupp = this.SupplierService.object.name;
        this.nitSupp = this.SupplierService.object.nit;
        this.statusSupp = this.SupplierService.object.status;
        this.phoneSupp = this.SupplierService.object.phone;
        this.emailSupp = this.SupplierService.object.email;
        this.contactSupp = this.SupplierService.object.contact;
        this.addressSupp = this.SupplierService.object.address;
        this.typeSupp = this.SupplierService.object.type;
      }
      
    } else {
      this.selectIdCountry = false;
      this.selectedOne = true;
      this.selectIdState = false;
      this.selectedTwo = true;
      this.selectIdCity = false;
      if (this.ProductService.modelo === 'Producto') {
        this.SupplierService.modalType = this.ProductService.typeModal;
        this.SupplierService.modelo = this.ProductService.modelo;
      }
    }
    this.SupplierService.modeloBase = this.SupplierService.modelo;
  }

  getId(){
    return this.SupplierService.codeId;
  }

  saveSupplier(values){
    let id_obj: any;
    if(this.SupplierService.new){
      id_obj = '';
    }else{
      id_obj = this.SupplierService.pk;
    }
    if(values.codesupp.trim() && values.namesupp.trim() && values.nitsupp.trim() && values.phonesupp.trim() && values.emailsupp.trim() && values.contactsupp.trim() && values.addresssupp.trim()){
      const initAjax: Init = {
        method: 'put',
        url: this.listUrl.url('savesupplier'),
        auth: this.auth,
        body: {
          id_obj: id_obj,
          code: values.codesupp.toUpperCase().trim(),
          name: values.namesupp.toUpperCase().trim(),
          nit: values.nitsupp.toUpperCase().trim(),
          address: values.addresssupp.toUpperCase().trim(),
          email: values.emailsupp.toUpperCase().trim(),
          contact: values.contactsupp.toUpperCase().trim(),
          phone: values.phonesupp.toUpperCase().trim(),
          type: values.typesupp,
          status: values.status,
          country: values.selectcountry,
          state: values.selectstate,
          city: values.selectcity,
          tipo: this.SupplierService.modalType
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: 'Guardando Proveedor',
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if (resp.new) {
            if (resp.tipo == 'catalogo') {
              this.SupplierService.continuar = true;
              this.SupplierService.evenAdd.emit(resp);
            } else {
              if (this.SupplierService.modeloBase === 'Producto' && resp.tipo === 'supplier') {
                this.modalProduct(resp);
              } else if (this.SupplierService.modeloBase === 'Conflicto') {
                this.ResolvingService.seguir = true;
                this.ResolvingService.loadselect = true;
                this.ResolvingService.evenAdd.emit({'new':resp.objeto});
              }
            }
          } else {
            this.SupplierService.continuar = true;
            this.SupplierService.evenAdd.emit(resp);
          }
          this.SupplierService.closeModal.hide();
        }
      }).catch(error => {
        this.viewError(error);
      });
    }else{
      this.codeSupp = this.codeSupp.trim();
      this.nameSupp = this.nameSupp.trim();
      this.nitSupp = this.nitSupp.trim();
      this.phoneSupp = this.phoneSupp.trim();
      this.emailSupp = this.emailSupp.trim();
      this.contactSupp = this.contactSupp.trim();
      this.addressSupp = this.addressSupp.trim();
    }
  }

  modalProduct(resp){
    this.ProductService.selectIdSupplier = true;
    this.ProductService.selectSupplier = resp.pk;
    this.ProductService.descSupplier = resp.pk;
    this.ProductService.SupplierSelect = resp.objeto;
  }

  viewError(error){
    if(error.error_id != 'undefined'){
      if(error.error_id){
        this.error_id = error.error_id;
        this.txt_id = error.txt_id;
      }
    }
    if(error.error_code != 'undefined'){
      if(error.error_code){
        this.error_code = error.error_code;
        this.txt_code = error.txt_code;
      }
    }
    if(error.error_name != 'undefined'){
      if(error.error_name){
        this.error_name = error.error_name;
        this.txt_name = error.txt_name;
      }
    }
    if(error.error_nit != 'undefined'){
      if(error.error_nit){
        this.error_nit = error.error_nit;
        this.txt_nit = error.txt_nit;
      }
    }
    if(error.error_phone != 'undefined'){
      if(error.error_phone){
        this.error_phone = error.error_phone;
        this.txt_phone = error.txt_phone;
      }
    }
    if(error.error_email != 'undefined'){
      if(error.error_email){
        this.error_email = error.error_email;
        this.txt_email = error.txt_email;
      }
    }
    if(error.error_contact != 'undefined'){
      if(error.error_contact){
        this.error_contact = error.error_contact;
        this.txt_contact = error.txt_contact;
      }
    }
    if(error.error_address != 'undefined'){
      if(error.error_address){
        this.error_address = error.error_address;
        this.txt_address = error.txt_address;
      }
    }
    if(error.error_type != 'undefined'){
      if(error.error_type){
        this.error_type = error.error_type;
        this.txt_type = error.txt_type;
      }
    }
    if(error.error_country != 'undefined'){
      if(error.error_country){
        this.error_country = error.error_country;
        this.txt_country = error.txt_country;
      }
    }
    if(error.error_state != 'undefined'){
      if(error.error_state){
        this.error_state = error.error_state;
        this.txt_state = error.txt_state;
      }
    }
    if(error.error_city != 'undefined'){
      if(error.error_city){
        this.error_city = error.error_city;
        this.txt_city = error.txt_city;
      }
    }
  }

  closeModal(){
    this.SupplierService.closeModal.hide();
  }

  formatCode(){
    this.codeSupp = this.codeSupp.toUpperCase().trim();
    this.error_id = false;
    this.txt_id = '';
    this.error_code = false;
    this.txt_code = '';
    if (this.codeSupp) {
      while (this.codeSupp.length < 10) {
        this.codeSupp = '0'+this.codeSupp;
      } 
    }
  }

  formatName(){
    this.nameSupp = this.nameSupp.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_name = false;
    this.txt_name = '';
  }

  formatNit(){
    this.nitSupp = this.nitSupp.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_nit = false;
    this.txt_nit = '';
  }

  formatPhone(){
    this.phoneSupp = this.phoneSupp.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_phone = false;
    this.txt_phone = '';
  }

  formatEmail(){
    this.emailSupp = this.emailSupp.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_email = false;
    this.txt_email = '';
  }

  formatContact(){
    this.contactSupp = this.contactSupp.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_contact = false;
    this.txt_contact = '';
  }

  changeCountry(){
    if(this.selectCountry){
      this.loadData = true;      
      const initAjax: Init = {
        method: 'put',
        auth: this.auth,
        url: this.listUrl.url('list_modal_state'),
        body:{
          country: this.selectCountry
        }
      };
      const configAjax: Config = {
        visible: false,
        autoReNew: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          this.SupplierService.SelectState = resp.objects;
          this.selectIdState = false;
          this.selectedTwo = true;
          this.selectedOne = false;
          this.loadData = false;
        }
      });
    }else{
      this.selectState = null;
      this.selectCity = null;
      this.selectedOne = true;
      this.selectedTwo = true;
    }
  }

  changeState(){
    if(this.selectState){
      this.loadData = true;
      const initAjax: Init = {
        method: 'put',
        auth: this.auth,
        url: this.listUrl.url('list_modal_city'),
        body:{
          state: this.selectState
        }
      };
      const configAjax: Config = {
        visible: false,
        autoReNew: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if(resp.success) {
          this.SupplierService.SelectCity = resp.objects;
          this.selectIdCity = false;
          this.selectedTwo = false;
          this.loadData = false;
        }
      });
    }else{
      this.selectedTwo = true;
      this.selectCity = null;
    }
  }

  formatAddress(){
    this.addressSupp = this.addressSupp.toUpperCase();
    this.error_id = false;
    this.txt_id = '';
    this.error_address = false;
    this.txt_address = '';
  }

}