import { Socket } from 'ngx-socket-io';
import { Cache } from 'src/app/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ListUrl } from '../../constantes/request';
import { socketon } from 'src/app/core/constantes/socketon';

export class SocketService {

    public st: Socket;

    constructor(private auth: AuthService) {
        this.st = new Socket({ url: ListUrl.baseUrlSt, options: {
            autoConnect: false,
            query: `token=${this.auth.get_token()}&token_company=${Cache.getSession(session.tokenCompany)}&prueba=esto`
        } });
    }

    connect() {
        this.st.connect();
        this.event_on();
    }

    updateQuery() {
        this.st.ioSocket.io.opts.query = {
            token: this.auth.get_token(),
            token_company: Cache.getSession(session.tokenCompany),
        };
    }

    event_on() {
        this.st.on('reconnect_attempt', () => {
            this.updateQuery();
        });

        this.st.on(socketon.invitation_company , (data) => {
            // aqui desplegamos la notificacion
        });

    }

    // tslint:disable-next-line: ban-types
    emit(eventName: string, data?: any, callback?: Function) {
       this.st.emit(eventName, data, callback);
    }

    disconnect() {
        this.st.disconnect();
    }

    // tslint:disable-next-line: ban-types
    on(eventName: string, callback?: Function) {
        this.st.on(eventName, callback);
    }
}
