import { Component, OnInit } from '@angular/core';
import { EntriesExitsService } from '../../../services/entries-exits.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { numValidators } from '../../../setting/validators';
import { AddProductService } from '../../../services/forms/purchase/add-product.service';

@Component({
  selector: 'app-modal-price-charge-products',
  templateUrl: './modal-price-charge-products.component.html',
  styleUrls: ['./modal-price-charge-products.component.css']
})
export class ModalPriceChargeProductsComponent implements OnInit {

  formPrice: FormGroup;
  formAddProdCo: FormGroup; //Formuario Agregar Producto en Compra
  arrPrice = [];
  error_price = [];
  notEditLote = false;
  formSubmit = true;
  utiMin: number;

  constructor(
    public ViewService: EntriesExitsService,
    public formProdCo: AddProductService,
    private FormBuilder: FormBuilder
  ) {
    this.formPrice = this.FormBuilder.group({
      prices: this.FormBuilder.array([])
    });
  }

  getId() {
    return 'ADMINIU00003';
  }

  ngOnInit() {
    this.formAddProdCo = this.formProdCo.formAddProdCo;
    this.ViewService.boolClosePrices = false;
    if (this.ViewService.expProdCompra) {
      this.notEditLote = true;
      const values = this.ViewService.objectsExpiration;
      if (values.typeoption == 0) {
        for (const i in values.productos) {
          if (values.productos.hasOwnProperty(i)) {
            const elem = values.productos[i];
            this.error_price.push({'error_lote': false, 'txt_lote': ''});
            this.arrPrice.push({"lote": elem.lote, "utility": '', "price_unit": '', "price_box": ''});
            this.addItem(i);
          }
        }
      } else if (values.typeoption == 1) {
        this.error_price.push({'error_lote': false, 'txt_lote': ''});
        this.arrPrice.push({"lote": values.lote, "utility": '', "price_unit": '', "price_box": ''});
        this.addItem(0);
      } else {
        this.ViewService.closeModalPrices.hide();
      }
    } else {
      this.error_price.push({'error_lote': false, 'txt_lote': ''});
      this.arrPrice.push({"lote": '', "utility": '', "price_unit": '', "price_box": ''});
      this.addItem(0);
    }
  }

  get prices(): FormArray {
    return this.formPrice.get('prices') as FormArray;
  };

  get prodCoCtrl() { return this.formAddProdCo.get('prodCoCtrl'); }
  get boxCoCtrl() { return this.formAddProdCo.get('boxCoCtrl'); }
  get unitCoCtrl() { return this.formAddProdCo.get('unitCoCtrl'); }
  get pesoCoCtrl() { return this.formAddProdCo.get('pesoCoCtrl'); }
  get costCoCtrl() { return this.formAddProdCo.get('costCoCtrl'); }
  get disc1CoCtrl() { return this.formAddProdCo.get('disc1CoCtrl'); }
  get disc2CoCtrl() { return this.formAddProdCo.get('disc2CoCtrl'); }
  get disc3CoCtrl() { return this.formAddProdCo.get('disc3CoCtrl'); }
  get taxCoCtrl() { return this.formAddProdCo.get('taxCoCtrl'); }

  addItem(cont) {
    if (this.ViewService.statusMinProfit) {
      this.utiMin = this.ViewService.minProfitProd;
    } else {
      this.utiMin = 0;
    }
    const price_product =this.FormBuilder.group({
      id_product: new FormControl({value: this.ViewService.prodIdCo, disabled: true}),
      lote: new FormControl(this.arrPrice[cont]['lote'], [
        Validators.required,
        Validators.maxLength(30)
      ]),
      utility: new FormControl(this.arrPrice[cont]['utility'], [
        Validators.required,
        Validators.min(this.utiMin),
        Validators.max(9999),
        numValidators
      ]),
      price_unit: new FormControl(this.arrPrice[cont]['price_unit'], [
        Validators.required,
        Validators.min(0),
        Validators.max(99999999),
        numValidators
      ]),
      price_box: new FormControl(this.arrPrice[cont]['price_box'], [
        Validators.required,
        Validators.min(0),
        Validators.max(99999999),
        numValidators
      ]),
    });
    this.prices.push(price_product);
  }

  changeLote(i) {
    this.arrPrice[i].lote = this.arrPrice[i].lote.toUpperCase();
    this.error_price[i].error_lote = false;
    this.error_price[i].txt_lote = '';
    this.formSubmit = this.formPrice.invalid;
  }

  changeUtility(i) {
    if (this.arrPrice[i]['utility'] == null) {
      this.arrPrice[i]['price_unit'] = '';
      this.arrPrice[i]['price_box'] = '';
    } else {
      const CO = this.ViewService.costProd;
      const UT = this.arrPrice[i]['utility'];
      const UND_CAJA = this.ViewService.unitBox;
      const PU = (CO*(UT/100))+CO;
      const PC = (PU*UND_CAJA);
      this.prices.at(i).get('price_unit').setValue(parseFloat(PU.toFixed(4)));
      this.prices.at(i).get('price_box').setValue(parseFloat(PC.toFixed(4)));
    }
    this.formSubmit = this.formPrice.invalid;
  }

  changePriceUnit(i) {
    if (this.arrPrice[i]['price_unit'] == null) {
      this.arrPrice[i]['price_box'] = '';
      this.arrPrice[i]['utility'] = '';
    } else {
      const CO = this.ViewService.costProd;
      const UND_CAJA = this.ViewService.unitBox;
      const PU = this.arrPrice[i]['price_unit'];
      const PC = (PU*UND_CAJA);
      const UT = (((PU-CO)/CO)*100);
      this.prices.at(i).get('utility').setValue(parseFloat(UT.toFixed(8)));
      this.prices.at(i).get('price_box').setValue(parseFloat(PC.toFixed(4)));
    }
    this.formSubmit = this.formPrice.invalid;
  }

  changePriceBox(i) {
    if (this.arrPrice[i]['price_box'] == null) {
      this.arrPrice[i]['price_unit'] = '';
      this.arrPrice[i]['utility'] = '';
    } else {
      const CO = this.ViewService.costProd;
      const UND_CAJA = this.ViewService.unitBox;
      const PC = this.arrPrice[i]['price_box'];
      const PU = (PC/UND_CAJA);
      const UT = (((PU-CO)/CO)*100);
      this.prices.at(i).get('utility').setValue(parseFloat(UT.toFixed(8)));
      this.prices.at(i).get('price_unit').setValue(parseFloat(PU.toFixed(4)));
    }
    this.formSubmit = this.formPrice.invalid;
  }

  returnFEcha(value: Date){
    var dd = value.getDate();
    var mm = value.getMonth()+1; //January is 0!
    var yyyy = value.getFullYear();
    let dia: any;
    let mes:any;
    if (dd<10) {
      dia = '0'+dd;
    } else {
      dia = dd;
    }

    if(mm<10) {
      mes = '0'+mm;
    } else {
      mes = mm;
    } 

    var fecha = dia + '/' + mes + '/' + yyyy;
    return fecha;
  }

  loadPrices(values) {
    let valid = true;
    for (const i in values.prices) {
      if (values.prices.hasOwnProperty(i)) {
        const elem = values.prices[i];
        if (elem.lote.trim() == '') {
          this.arrPrice[i]['lote'] = '';
          valid = false;
        }
      }
    }
    if (valid) {
      if (this.ViewService.nroTypeTrans == 0) {
        let nro = this.ViewService.prodTableCompra.length;
        let obj: any;
        let obj_price = [];
        let unit_price: number;
        let lote: string;
        if (this.ViewService.disc1Compra || this.ViewService.disc2Compra || this.ViewService.disc3Compra) {
          let desc1: number = 0;
          let desc2: number = 0;
          let desc3: number = 0;
          if (this.ViewService.disc1Compra) {
            desc1 = ((this.ViewService.disc1Compra/100)*this.ViewService.costCompra);
          }
          if (this.ViewService.disc2Compra) {
            desc2 = ((this.ViewService.disc2Compra/100)*this.ViewService.costCompra);
          }
          if (this.ViewService.disc3Compra) {
            desc3 = ((this.ViewService.disc3Compra/100)*this.ViewService.costCompra);
          }
          unit_price = this.ViewService.costCompra - desc1 - desc2 - desc3;
        } else {
          unit_price = this.ViewService.costCompra;
        }
        if (this.ViewService.editProduct) {
          let pk = this.ViewService.pkProduct;
          let id_prod = this.ViewService.prodTableCompra[pk].id_product;
          const long = this.ViewService.prodExpiCompra.length;
          if (long > 0) {
            for (let i = long-1; i >= 0; i--) {
              const element = this.ViewService.prodExpiCompra[i];
              if (element.id_product == id_prod) {
                this.ViewService.prodExpiCompra.splice(i, 1);
              }
            }
          }
        }
        if (this.ViewService.expProdCompra) {
          const expirations = this.ViewService.objectsExpiration;
          if (expirations.typeoption == 0) {
            var fechas = [];
            for (const i in expirations.productos) {
              if (expirations.productos.hasOwnProperty(i)) {
                const elem = expirations.productos[i];
                if (typeof elem.expira != 'object') {
                  var des_fecha = elem.expira.split('/');
                  elem.expira = new Date(des_fecha[2],des_fecha[1]-1,des_fecha[0]);
                }
                fechas.push(elem.expira);
                if (typeof elem.expira === 'object') {
                  elem.expira = this.returnFEcha(elem.expira);
                }
                let nro_pri = obj_price.length;
                obj_price.push({'id': nro_pri, 'type': '', 'value': values.prices[i].price_unit, 'lot': elem.lote, 'expiration_date_lot': elem.expira, 'profit': values.prices[i].utility});
                let nro_exp = this.ViewService.prodExpiCompra.length;
                let obj_exp: any;
                obj_exp = {
                  'id': nro_exp,
                  'id_product': this.ViewService.prodIdCo,
                  'code': this.ViewService.codeProd,
                  'description': this.ViewService.descProd,
                  'units': 1,
                  'expiration': elem.expira,
                  'lote': elem.lote,
                };
                this.ViewService.prodExpiCompra.unshift(obj_exp);
              }
            }
            var fec = fechas[0];
            var nro_fech: any;
            for (const key in fechas) {
              if (fechas.hasOwnProperty(key)) {
                const fe = fechas[key];
                if (fe <= fec) {
                  fec = fe
                  nro_fech = key;
                }
              }
            }
            lote = values.prices[nro_fech].lote;
          } else {
            if (typeof this.ViewService.objectsExpiration.expira === 'object') {
              this.ViewService.objectsExpiration.expira = this.returnFEcha(this.ViewService.objectsExpiration.expira);
            }
            const prices = values.prices[0];
            let nro_pri = obj_price.length;
            obj_price.push({'id': nro_pri, 'type': '', 'value': prices.price_unit, 'lot': prices.lote, 'expiration_date_lot': this.ViewService.objectsExpiration.expira, 'profit': prices.utility});
            lote = prices.lote;
            let nro_exp = this.ViewService.prodExpiCompra.length;
            let obj_exp: any;
            obj_exp = {
              'id': nro_exp,
              'id_product': this.ViewService.prodIdCo,
              'code': this.ViewService.codeProd,
              'description': this.ViewService.descProd,
              'units': this.ViewService.unitCompra,
              'expiration': this.ViewService.objectsExpiration.expira,
              'lote': this.ViewService.objectsExpiration.lote,
            };
            this.ViewService.prodExpiCompra.unshift(obj_exp);
          }
          this.ViewService.viewExpProdCo = true;
        } else {
          const prices = values.prices[0];
          let nro_pri = obj_price.length;
          obj_price.push({'id': nro_pri, 'type': '', 'value': prices.price_unit, 'lot': prices.lote, 'expiration_date_lot': '', 'profit': prices.utility});
          lote = prices.lote;
        }
        const price_total = unit_price*this.ViewService.unitCompra;
        const tax = (Number(this.ViewService.taxDescCo)/100)*price_total;
        let returnable: any;
        let return_units: any;
        let return_pk_units: any;
        let return_box: any;
        let return_pk_box: any;
        for (const x in this.ViewService.ProductSelect) {
          if (this.ViewService.ProductSelect.hasOwnProperty(x)) {
            const elem = this.ViewService.ProductSelect[x];
            if (elem.id_product == this.ViewService.prodIdCo) {
              returnable = elem.returnable;
              if (elem.returnable) {
                for (const i in this.ViewService.ProductSelect) {
                  if (this.ViewService.ProductSelect.hasOwnProperty(i)) {
                    const element = this.ViewService.ProductSelect[i];
                    if (element.id_product == elem.returnable_code_bottle) {
                      return_pk_units = element.id_product;
                      return_units = element.product_code;
                    }
                    if (element.id_product == elem.returnable_code_box) {
                      return_pk_box = element.id_product;
                      return_box = element.product_code;
                    }
                  }
                }
              } else {
                return_pk_units = null;
                return_units = '';
                return_pk_box = null;
                return_box = '';
              }
            }
          }
        }
        if (this.ViewService.editProduct) {
          let pk = this.ViewService.pkProduct;
          let old_tax = Number(this.ViewService.prodTableCompra[pk].code_tax);
          let old_total_price = Number(this.ViewService.prodTableCompra[pk].total_price);
          this.ViewService.totalTaxCo = this.ViewService.totalTaxCo - old_tax + tax;
          if (tax == 0) {
            this.ViewService.totalExemptCo = this.ViewService.totalExemptCo - old_total_price + price_total;
          } else {
            this.ViewService.totalBICo = this.ViewService.totalBICo - old_total_price + price_total;
          }
          this.ViewService.prodTableCompra[pk].id_product = this.ViewService.prodIdCo;
          this.ViewService.prodTableCompra[pk].code = this.ViewService.prodCompra;
          this.ViewService.prodTableCompra[pk].description = this.ViewService.prodDescCo;
          this.ViewService.prodTableCompra[pk].box = this.ViewService.boxCompra;
          this.ViewService.prodTableCompra[pk].units = this.ViewService.unitCompra;
          this.ViewService.prodTableCompra[pk].weight = this.ViewService.pesoCompra;
          this.ViewService.prodTableCompra[pk].cost = this.ViewService.costCompra;
          this.ViewService.prodTableCompra[pk].discount1 = this.ViewService.disc1Compra;
          this.ViewService.prodTableCompra[pk].discount2 = this.ViewService.disc2Compra;
          this.ViewService.prodTableCompra[pk].discount3 = this.ViewService.disc3Compra;
          this.ViewService.prodTableCompra[pk].id_tax = this.ViewService.taxCompra;
          this.ViewService.prodTableCompra[pk].code_tax = tax;
          this.ViewService.prodTableCompra[pk].price_unit = unit_price;
          this.ViewService.prodTableCompra[pk].lot = lote;
          this.ViewService.prodTableCompra[pk].total_price = price_total;
          this.ViewService.prodTableCompra[pk].prices = obj_price;
          this.ViewService.prodTableCompra[pk].returnable = returnable;
          this.ViewService.prodTableCompra[pk].return_units = return_units
          this.ViewService.prodTableCompra[pk].return_pk_units = return_pk_units
          this.ViewService.prodTableCompra[pk].return_box = return_box
          this.ViewService.prodTableCompra[pk].return_pk_box = return_pk_box
          this.ViewService.prodTableCompra[pk].error = false;
          this.ViewService.prodTableCompra[pk].error_product = false;
          this.ViewService.prodTableCompra[pk].error_tax = false;
          this.ViewService.prodTableCompra[pk].error_box = false;
          this.ViewService.prodTableCompra[pk].error_units = false;
          this.ViewService.prodTableCompra[pk].error_weight = false;
          this.ViewService.prodTableCompra[pk].error_cost = false;
          this.ViewService.prodTableCompra[pk].error_discount1 = false;
          this.ViewService.prodTableCompra[pk].error_discount2 = false;
          this.ViewService.prodTableCompra[pk].error_discount3 = false;
          this.ViewService.prodTableCompra[pk].error_returnuntis = false;
          this.ViewService.prodTableCompra[pk].error_returnbox = false;
        } else {
          this.ViewService.totalTaxCo = this.ViewService.totalTaxCo + tax;
          if (tax == 0) {
            this.ViewService.totalExemptCo = this.ViewService.totalExemptCo + price_total;
          } else {
            this.ViewService.totalBICo = this.ViewService.totalBICo + price_total;
          }
          obj = {
            'id': nro,
            'id_product': this.ViewService.prodIdCo,
            'code': this.ViewService.prodCompra,
            'description': this.ViewService.prodDescCo, 
            'box': this.ViewService.boxCompra, 
            'units': this.ViewService.unitCompra, 
            'weight': this.ViewService.pesoCompra, 
            'cost': this.ViewService.costCompra, 
            'discount1': this.ViewService.disc1Compra, 
            'discount2': this.ViewService.disc2Compra, 
            'discount3': this.ViewService.disc3Compra, 
            'id_tax': this.ViewService.taxCompra, 
            'code_tax': tax, 
            'price_unit': unit_price, 
            'lot': lote, 
            'total_price': price_total,
            'prices': obj_price,
            'returnable': returnable,
            'return_units': return_units,
            'return_pk_units': return_pk_units,
            'return_box': return_box,
            'return_pk_box': return_pk_box,
            'error': false,
            'error_product': false,
            'error_tax': false,
            'error_box': false,
            'error_units': false,
            'error_weight': false,
            'error_cost': false,
            'error_discount1': false,
            'error_discount2': false,
            'error_discount3': false,
            'error_returnuntis': false,
            'error_returnbox': false
          };
          this.ViewService.prodTableCompra.unshift(obj);
        }
        this.ViewService.totalCo = this.ViewService.totalBICo + this.ViewService.totalTaxCo + this.ViewService.totOtherTaxCo + this.ViewService.totalOTCo + this.ViewService.totalExemptCo;
        this.ViewService.differenceCo = this.ViewService.totalCo - this.ViewService.origTotalCo;
        this.resetFormProdCo();
        this.ViewService.boolClosePrices = true;
        this.ViewService.boolCloseLoadProd = true;
        this.ViewService.editProduct = false;
        this.ViewService.pkProduct = null;
        this.ViewService.viewReturnCo = true;
        this.ViewService.closeModalPrices.hide();
      } else if (this.ViewService.nroTypeTrans == 1) {
      } else if (this.ViewService.nroTypeTrans == 2) {
      } else if (this.ViewService.nroTypeTrans == 3) {
      }
    }
  }

  resetFormProdCo() {
    this.formAddProdCo.reset();
    // this.prodCoCtrl.setValue('');
    this.boxCoCtrl.setValue(0);
    this.unitCoCtrl.setValue(1);
    this.pesoCoCtrl.setValue(0);
    this.costCoCtrl.setValue(0);
    this.disc1CoCtrl.setValue(0);
    this.disc2CoCtrl.setValue(0);
    this.disc3CoCtrl.setValue(0);
    this.taxCoCtrl.setValue('');
    this.formProdCo.disabled_fields = true;
  }

  closeModal() {
    this.ViewService.closeModalPrices.hide();
  }

}
