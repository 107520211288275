import { Injectable } from '@angular/core';
import { ObjectsOne } from './objectone';


export interface ObjectStation {    ​​​
  id: number;
  code: string;
  description: string;
  objs: any;
}

export interface ObjectsStation extends Array<ObjectStation> {
  [key: number]: ObjectStation;
}

@Injectable({
  providedIn: 'root'
})
export class StationConfigService {

  newGnrl: any;
  pkGnrl: any;

  printerConfig = [];

  n_control = false;

  station: string;
  selectStation: ObjectsStation;

  station_g: ObjectsStation;
  station_base: ObjectsStation;

  n_factu = null;
  serie_factu = null;
  ctrl_factu = 0;

  n_devol = null;
  serie_devol = null;
  ctrl_devol = 0;

  n_cred = null;
  serie_cred = null;
  ctrl_cred = 0;

  n_debit = null;
  serie_debit = null;
  ctrl_debit = 0;

  n_pedido = null;
  serie_pedido = null;

  // Impresion de factura, factura electronica
  i_fiscal = false; // Impresora Fiscal
  i_serial = null;
  n_corte = null;
  i_digital = false;
  i_email = false;
  i_electro = false;
  i_token = null;
  i_account = null;
  // Impresion de factura, factura electronica

  depart: string;
  selectDepart: ObjectsOne;

  toma_pedidos = false;
  factu_pedidos = false;
  select_deposi = false;
  a_factu_sby = false; // autorizar facturas standby
  des_key_manual = false; // Desactivar clave manual
  vari_rutas = false;
  print_sby = false; // imprimir standby
  user_sby = false; // usuario standby

  selec_type_price = false;
  price_base = false;
  price_a = false;
  price_b = false;
  price_c = false;
  price_d = false;
  price_e = false;
  noimagen = false; //Ocultar imagenes de productos en facturacion

  factu_negativo = false;
  select_mo_extra = false; // Seleccionar Moneda extranjera

  moneda: string;
  selectMoneda: ObjectsOne;

  loadData = false;

  station_selected = "";

  constructor() { }

  set selectStations(obj: any) {
    // Cargar el objeto base o general para listar en estacion
    this.selectStation = obj;
  }
}
