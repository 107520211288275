import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Pedido } from '../../interface/pedido';
import { Cache } from 'src/app/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { StationDocuments } from '../../interface/station_documents';
import { Utiliti } from 'src/app/core/viewLoader/viewLoader';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { formatCurrency } from '@angular/common';
import { Init } from 'src/app/core/ajax/init-ajax';
import { Config, Ajax } from 'src/app/core/ajax/ajax';

@Component({
  selector: 'app-totalizar',
  inputs: ['document_factura'],
  outputs: ['even_success'],
  templateUrl: './totalizar.component.html',
  styleUrls: ['./totalizar.component.css']
})
export class TotalizarComponent extends Utiliti implements OnInit {

  pedido: Pedido;
  document_factura: StationDocuments;
  even_success: EventEmitter<any> = new EventEmitter();
  detalle: any;
  banco: any;
  referencia: any;
  monto: any;
  pagos: Array<any>;
  totalPagado: any;
  cambio_disable = true;
  totalpagado_disable = true;
  bancos: any = [];

  miles = '.';
  decimal = ',';
  simbolo_moneda = '$';

  constructor(public bsModalRef: BsModalRef, public auth: AuthService, public listUrl: UrListService) {
    super();
    this.defaultAll();
    this.getBanks();
    if (Cache.getSession(session.pedido)) {
      this.pedido = Cache.getSession(session.pedido, true);
    } else {
      this.pedido = null;
    }
  }

  ngOnInit() {
  }

  defaultCamps() {
    this.banco = null;
    this.referencia = null;
    this.monto = null;
    this.detalle = null;
  }

  defaultAll() {
    this.pagos = [];
    this.totalPagado = 0;
    this.defaultCamps();
  }

  getId() {
    return 'ESTPVIU00009';
  }

  getBanks() {
  	const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('get_banks'),
      auth: this.auth
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        this.bancos = respo.objects;
      }
    }).catch(error => {
      return false;
    	});
  }

  get requirebank() {
    if (this.detalle == 'EFECTIVO' || this.detalle == 'RETENCION') {
      return true;
    } else {
      return false;
    }
  }

  get requireferencia() {
    if (this.detalle == 'EFECTIVO') {
      return true;
    } else {
      return false;
    }
  }

  get total(){
    let n = this.pedido.subtotal + this.pedido.impuesto;
    return Number(n.toFixed(2));
  }

  get cambio() {
    if (this.totalPagado < this.total) {
      return 0;
    } else {
      return Math.abs(this.total - this.totalPagado);
    }
  }

  async validateVacio(text) {
    try {
      const detalle = await this.v_empty(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  async validateDetalle() {
    try {
      const detalle = await this.v_empty(this.detalle);
      return true;
    } catch (error) {
      return false;
    }
  }

  async validateBanco() {
    try {
      const banco = await this.v_empty(this.banco);
      return true;
    } catch (error) {
      return false;
    }
  }

  async validateReferencia() {
    try {
      const referencia = await this.v_empty(this.referencia);
      return true;
    } catch (error) {
      return false;
    }
  }

  async validateMonto() {
    if (this.monto && this.monto > 0) {
      return true;
    }
    return false;
  }

  async validarpago() {
    if (await this.validateDetalle()) {
      if (await this.validateMonto()) {
        if (this.requirebank) {
          if (this.requireferencia) {
            this.add_pago();
          } else {
            if (await this.validateReferencia()) {
              this.add_pago();
            } else {
              this.error_d('Error de formulario', 'La Referencia no puede estar vacio');
            }
          }
        } else {
          if (await this.validateBanco()) {
            if (await this.validateReferencia()) {
              this.add_pago();
            } else {
              this.error_d('Error de formulario', 'La Referencia no puede estar vacio');
            }
          } else {
            this.error_d('Error de formulario', 'El Banco no puede estar vacio');
          }
        }
      } else {
        this.error_d('Error de formulario', 'El Monto no puede estar vacio');
      }
    } else {
      this.error_d('Error de formulario', 'El Detalle no puede estar vacio');
    }
  }

  eliminarpago(key: number) {
    this.totalPagado -= this.pagos[key].monto;
    if (this.totalPagado < 0) {
      this.totalPagado = 0;
    }
    this.pagos.splice(key, 1);
  }

  editarPago(key: number) {
    this.detalle = this.pagos[key].detalle;
    this.monto = this.pagos[key].monto;
    this.referencia = this.pagos[key].referencia;
    this.banco = this.pagos[key].banco;
    this.eliminarpago(key);
  }

  changeDetalle() {
    if (this.detalle == 'EFECTIVO') {
      this.banco = null;
      this.referencia = null;
    }
    if (this.detalle == 'RETENCION') {
      this.banco = null;
    }
  }

  error_d(title: string, text: string) {
    const oe: SweetAlertOptions = {
      icon: 'error',
      title: title,
      text: text,
      showConfirmButton: true,
      confirmButtonText: 'ok',
    };
    const alerer = Swal.fire(oe);
  }

  soNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    if (!pattern.test(event.key)) {
      // invalid chasracter, prevent input
      event.preventDefault();
      event.stopPropagation();
    }
  }

  validar_pagar() {
    if (this.totalPagado >= this.total) {
      this.pagar();
    } else {
      this.error_d('Error de Monto', 'El monto pagado no puede ser menor');
    }
  }

  pagar() {
    const initAjax: Init = {
      method: 'put',
      url: this.listUrl.url('create_factura'),
      auth: this.auth,
      body: {
        detailsr: this.pagos,
        detailsp: this.pedido.list_buy,
        headp: {
          station_code: this.pedido.headp.station_id,
          number: this.pedido.headp.number,
          customer: this.pedido.customer.id_customers,
          id_opening: this.pedido.cash.id_opening
        },
        headf: {
          station_code: this.document_factura.station_id,
          number: this.document_factura.number,
          customer: this.pedido.customer.id_customers,
          id_opening: this.pedido.cash.id_opening
        }
      }
    };
    const configAjax: Config = {
      titleLoading: 'Procesando Factura',
      titleSuccess: 'Factura Enviada'
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.defaultAll();
        this.even_success.emit(resp);
        this.closeModal();
      }
    }).catch(error => {
    });
  }

  add_pago() {
    if (this.pagos.length <= 8) {
      this.pagos.push({
        detalle: this.detalle,
        banco: this.banco,
        referencia: this.referencia,
        monto: this.monto
      });
      this.totalPagado = Number(this.totalPagado.toFixed(2)) + this.monto;
      this.defaultCamps();
    } else {
      this.error_d('Limite de Pagos', 'Solo se permiten 8 pagos');
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }


}
