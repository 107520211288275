export class Cache {

    static makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    static get local() {
        return localStorage;
    }

    static get session() {
        return sessionStorage;
    }

    static requireString(value: string) {
        if (typeof value === 'string') {
            return true;
        } else {
            throw Error('Se requiere que el valor sea un string');
        }
    }

    static requireObject(value: string) {
        if (typeof value === 'object') {
            return true;
        } else {
            throw Error('Se requiere que el valor sea un object');
        }
    }

    static getLocal(name: string, json: boolean = false) {
        Cache.requireString(name);
        if (Cache.local.getItem(name) == 'undefined') {
            Cache.removeLocalItem(name);
        }
        if (json) {
            return JSON.parse(Cache.local.getItem(name));
        } else {
            return Cache.local.getItem(name);
        }
    }

    static setLocal(name: string, value: any) {
        Cache.requireString(name);
        try {
            Cache.requireObject(value);
            Cache.local.setItem(name, JSON.stringify(value));
        } catch (error) {
            Cache.local.setItem(name, value);
        }
    }

    static getSession(name: string , json: boolean = false) {
        Cache.requireString(name);
        if (Cache.session.getItem(name) == 'undefined') {
            Cache.removeSessionItem(name);
        }
        if (json) {
            return JSON.parse(Cache.session.getItem(name));
        } else {
            return Cache.session.getItem(name);
        }
    }

    static setSession(name: string, value: any) {
        Cache.requireString(name);
        try {
            Cache.requireObject(value);
            Cache.session.setItem(name, JSON.stringify(value));
         } catch (error) {
             Cache.session.setItem(name, value);
         }
    }

    static getItem(name: string, json: boolean = false) {
        if (Cache.getSession(name)) {
            return Cache.getSession(name, json);
        } else if (Cache.getLocal(name)) {
            return Cache.getLocal(name, json);
        } else {
            throw Error('El item no se encuentra en ningun Storage');
        }
    }

    static removeLocalItem(name: string) {
        /**
         *  Remueve un item de local
         */
        Cache.requireString(name);
        Cache.local.removeItem(name);
    }
    static removeSessionItem(name: string) {
        /**
         * Remueve un item de session
         */
        Cache.requireString(name);
        Cache.session.removeItem(name);
    }

    static removeItem(name: string) {
        /**
         * remueve un item en cualquier storage
         */
        Cache.removeLocalItem(name);
        Cache.removeSessionItem(name);
    }

    static deleteAllCookies() {
        const cookies = document.cookie.split(';');
        for (const i of cookies) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    }

    static setCookieEncode(value: string) {
        // Cache.deleteAllCookies()
        document.cookie = value;
    }

    static setCookie(name: string, value: string) {
        /**
         * Guarda Una Cookie para luego ser enviada en las peticiones del navegador
         */
        Cache.setCookieEncode(`${name}=${value}; path=/;`);
    }

    static changeCookie(name: string, value: string) {
        /**
         * Cambiar una Cookie
         */
        Cache.setCookie(name, value);
    }

    static getPathName(name: string): Promise<Array<string>> {
        return new Promise((success, error) => {
            const path = window.location.pathname.split('/');
            if (path.length) {
                const existPath = path.filter(pa => pa === name );
                if (existPath.length) {
                    success(existPath);
                } else {
                    error('el pathname no existe');
                }
            } else {
                error('no hay patname');
            }
        });
    }

    static delete_cookie(name: string) {
        /**
         * Borra una cookie colocandole una fecha de expiracion
         */
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    static logout() {
        Cache.removeItem('token');
        Cache.removeItem('tokenrefres');
        Cache.removeItem('inicars');
        Cache.delete_cookie('tkauto');
        window.location.replace(`${window.location.origin}/signin`);
    }

    static getCookie(name: string): null | string {
        let cookieValue: null | string = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (const i of cookies) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === `${name}=`) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
     }
}
