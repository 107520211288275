import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as crypto from 'crypto-js';
import { PaymentGatewaysService } from '../../payment-gateways.service';

@Injectable({
  providedIn: 'root'
})
export class PayuService {

  constructor(private http: HttpClient, public PaymentGatewaysService: PaymentGatewaysService) { }

  pay() {
    var test = 0;
    var form = document.createElement("form");
    form.target = "view";
    form.method = "POST";
    form.action = "https://checkout.payulatam.com/ppp-web-gateway-payu";
    var params;
    if (this.PaymentGatewaysService.testMode == true) {
      form.action = "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/";
      params = {
        "merchantId": "508029",
        "accountId": "512321",
        "description": "Test PAYU",
        "referenceCode": "TestPayU",
        "amount": "20000",
        "tax": "3193",
        "taxReturnBase": "16806",
        "currency": "COP",
        "signature": "7ee7cf808ce6a39b17481c54f2c57acc",
        "test": "1",
        "buyerEmail": "test@test.com",
        "buyerFullName": "Usuario de prueba",
        "responseUrl": "http://www.test.com/response",
        "confirmationUrl": "http://www.test.com/confirmation"
      }
    } else {
      params = {
        "merchantId": "901544",
        "accountId": "908205",
        "description": this.getProducts(),
        "referenceCode": this.PaymentGatewaysService.referenceCode.toString(),
        "amount": this.PaymentGatewaysService.total.toString(),
        "tax": this.PaymentGatewaysService.tax.toString(),
        "taxReturnBase": this.PaymentGatewaysService.taxReturnBase.toString(),
        "currency": this.PaymentGatewaysService.currency,
        "signature": crypto.MD5("s42s7tErBu943F13WnZDde23uR"+"~"+"901544"+"~"+"asd"+"~"+this.PaymentGatewaysService.total.toString()+"~"+this.PaymentGatewaysService.currency).toString(),
        "test": "0",
        "buyerEmail": this.PaymentGatewaysService.email,
        "buyerFullName": this.PaymentGatewaysService.name,
        "responseUrl": "",
        "confirmationUrl": "http://www.apidev.bon-aventure.com/payment-gateway/payu/confirmation"
      }
    }
  	for (var i in params) {
  	    if (params.hasOwnProperty(i)) {
  	      var input = document.createElement('input');
  	      input.type = 'hidden';
  	      input.name = i;
  	      input.value = params[i];
  	      form.appendChild(input);
  	    }
  	}
  	document.body.appendChild(form);
  	form.submit();
  	window.open('', 'view');
	}

  getProducts() {
    var products = "";
    for(var i in this.PaymentGatewaysService.listProducts) {
      products = this.PaymentGatewaysService.listProducts[i].quantity+" "+this.PaymentGatewaysService.listProducts[i].name+" / ";
    }
    return products;
  }
}
