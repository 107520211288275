import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Cache } from 'src/app/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { Confir, Utiliti } from 'src/app/core/viewLoader/viewLoader';
import { Pedido, Pedidos } from './../../interface/pedido';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { Station } from '../../interface/station';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Search } from 'src/app/search';

@Component({
  selector: 'app-search-document',
  outputs: ['selectEvent', 'removeEvent'],
  templateUrl: './search-document.component.html',
  styleUrls: ['./search-document.component.css']
})
export class SearchDocumentComponent extends Confir implements OnInit {

  pedidos: Pedidos;
  selectEvent: EventEmitter<Pedido> = new EventEmitter();
  station: Station;
  removeEvent: EventEmitter<any> = new EventEmitter();
  pedido: Pedido;
  factura: Pedido;
  facturas: Pedidos;
  dv = true;
  an = true;

  // search
  pedi_show: Array<any>;
  pedi_base: Array<any>;
  pedi_search: Array<any>;
  pedi_in_search = ''; // input search

  fact_show: Array<any>;
  fact_base: Array<any>;
  fact_search: Array<any>;
  fact_in_search = ''; // input search

  resp_field: string; // campo que respondera los ajax cuando busquen en el back
  lmi = true;
  li = true;
  sb = true;
  uli = true;

  fields_search: Array<string>; // campos que se usaran para la busqueda rapida
  utili: Utiliti;
  // search

  constructor(
    public bsModalRef: BsModalRef,
    public auth: AuthService,
    public listUrl: UrListService,
  ) {
    super();
    this.utili = new Utiliti();
    this.pedi_base = [];
    this.pedi_search = [];
    this.pedi_show = [];
    this.fact_base = [];
    this.fact_search = [];
    this.fact_show = [];
    this.fields_search = ['number'];
    this.resp_field = 'pedido';
  }

  ngOnInit() {
    if (Cache.getSession(session.cash_open)) {
      this.station = Cache.getSession(session.cash_open, true);
    }
    if (Cache.getSession(session.pedidos)) {
      this.pedi_base = Cache.getSession(session.pedidos, true);
    } else {
      this.pedi_base = [];
    }
    if (Cache.getSession(session.pedido)) {
      this.pedido = Cache.getSession(session.pedido, true)
    } else {
      this.pedido = null;
    }
    this.facturas = [];
    this.factura = null;
    this.get_pedidos();
    this.get_facturas();
  }

  selectPedido(pedido: Pedido) {
    this.confir(
      `Seleccionar Pedido?`,
      `Realmente quiere selecionar el pedido <strong>${pedido.headp.prefix}${pedido.headp.number.padStart(9, '0')}</strong>`
    ).then(() => {
      this.verificarPedido(pedido);
    });
  }

  getId() {
    return 'ESTPVIU00004';
  }

  removePedido(pedido: Pedido) {
    const initB: Init = {
      method: 'put',
      url: this.listUrl.url('remove_order'),
      auth: this.auth,
      body: {
        pedido: pedido.headp.number,
        id_opening: pedido.cash.id_opening
      }
    };
    const ajax = new Ajax(initB);
    ajax.call().then(resp => {
      if (typeof resp.pedido != 'undefined') {
        const position = Search.position(this.pedi_base, (x: Pedido, e) => {
          return Search.igual(x.headp.number, e);
        }, resp.pedido);
        if (position != -1) {
          this.pedi_base.splice(position, 1);
          this.pedi_show = [].concat(this.pedi_base);
          this.pedi_search = [].concat(this.pedi_base);
          Cache.setSession(session.pedidos, this.pedi_base);
          this.removeEvent.emit();
        }
      }
    }).catch(error => {

    });
  }

  devoluFactura(factura: Pedido) {
    if (this.dv) {
      this.confir(
        `Devolución de Factura`,
        `¿Realmente quiere devolver la Factura <strong>${factura.headp.prefix}${factura.headp.number.padStart(9, '0')}</strong>?`
      ).then(() => {
        this.dv = false;
        const initB: Init = {
          method: 'put',
          url: this.listUrl.url('devolver_factura'),
          auth: this.auth,
          body: {
            number: factura.headp.number,
            id_opening: factura.cash.id_opening
          }
        };
        const ajax = new Ajax(initB);
        ajax.call().then(resp => {
          const position = Search.position(this.fact_base, (x: Pedido, e) => {
            return Search.igual(x.headp.number, e);
          }, factura.headp.number);
          if (position != -1) {
            this.fact_base.splice(position, 1);
            this.fact_show = [].concat(this.fact_base);
            this.fact_search = [].concat(this.fact_base);
          }
          this.dv = true;
        }).catch(error => {
          this.dv = true;
        });
      });
    }
  }

  anularFactura(factura: Pedido) {
    if (this.an) {
      this.confir(
        `Anular de Factura`,
        `¿Realmente quiere Anular la Factura <strong>${factura.headp.prefix}${factura.headp.number.padStart(9, '0')}</strong>?`
      ).then(() => {
        this.an = false;
        const initB: Init = {
          method: 'put',
          url: this.listUrl.url('anular_factura'),
          auth: this.auth,
          body: {
            number: factura.headp.number,
            id_opening: factura.cash.id_opening
          }
        };
        const ajax = new Ajax(initB);
        ajax.call().then(resp => {
          const position = Search.position(this.fact_base, (x: Pedido, e) => {
            return Search.igual(x.headp.number, e);
          }, factura.headp.number);
          if (position != -1) {
            this.fact_base.splice(position, 1);
            this.fact_show = [].concat(this.fact_base);
            this.fact_search = [].concat(this.fact_base);
          }
          this.an = true;
        }).catch(error => {
          this.an = true;
        });
      });
    }
  }

  get_pedidos() {
    const config: Config = {
      visible: true
    };
    const initB: Init = {
      method: 'put',
      url: this.listUrl.url('get_pedidos'),
      auth: this.auth,
      body: {
        id_opening: this.station.id_opening
      }
    };
    const ajax = new Ajax(initB, config);
    ajax.call().then(resp => {
      if (typeof resp.pedidos != undefined) {
        Search.forEachAll(resp.pedidos, (element: Pedido, key, all, next) => {
          const position = Search.position(this.pedi_base, (x: Pedido, e) => {
            return Search.igual(x.headp.number, e);
          }, element.headp.number);
          if (position != -1) {
            this.pedi_base[position] = element;
            next();
          } else {
            if (this.pedido == null) {
              this.pedi_base.push(element);
              next();
            } else {
              if (this.pedido.headp.number != element.headp.number) {
                this.pedi_base.push(element);
                next();
              } else {
                next();
              }
            }
          }
          next();
        }, (all) => {
          this.pedi_show = [].concat(this.pedi_base);
          this.pedi_search = [].concat(this.pedi_base);
          Cache.setSession(session.pedidos, this.pedi_base);
        }, true);
      }
    }).catch(error => {});
  }

  get_facturas() {
    const initB: Init = {
      method: 'put',
      url: this.listUrl.url('get_facturas'),
      auth: this.auth,
      body: {
        id_opening: this.station.id_opening
      }
    };
    const ajax = new Ajax(initB, {visible: false});
    ajax.call().then(resp => {
      if (typeof resp.pedidos != undefined) {
        Search.forEachAll(resp.pedidos, (element: Pedido, key, all, next) => {
          const position = Search.position(this.fact_base, (x: Pedido, e) => {
              return Search.igual(x.headp.number, e);
            }, element.headp.number);
          if (position != -1) {
            this.fact_base[position] = element;
          } else {
            if (this.factura == null) {
              this.fact_base.push(element);
            } else {
              if (this.factura.headp.number != element.headp.number) {
                this.fact_base.push(element);
              }
            }
          }
          next();
        }, (all) => {
          this.fact_show = [].concat(this.fact_base);
          this.fact_search = [].concat(this.fact_base);
        }, true);
      }
    }).catch(error => {});
  }

  verificarPedido(pedido: Pedido) {
    if (Cache.getSession(session.pedido)) {
      const oe: SweetAlertOptions = {
        icon: 'error',
        title: 'Pedido Pendiente',
        text: 'Guarde el pedido pendiente antes de agregar otro',
        showConfirmButton: true,
        confirmButtonText: 'ok',
        preConfirm: () => this.closeModal(),
      };
      const alerer = Swal.fire(oe);
    } else {
      this.selectEvent.emit(pedido);
      this.closeModal();
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  total(pedido: Pedido){
    return pedido.subtotal + pedido.impuesto;
  }

  search_speed(even: any, tipe) {
    this.utili.v_empty(this[tipe + '_in_search']).then( rp => {
        if (typeof this[tipe + '_search'] !== 'undefined' && this[tipe + '_search'].length && this[tipe + '_in_search'] != '') {
            this[tipe + '_show'] = Search.filter(this[tipe + '_search'], (x, e) => {
              return Search.any(this.fields_search.map( c => {
                return Search.search(x.headp[c], e);
              }));
            }, rp);
            if (even.keyCode == 13) {
              this.search_back(tipe);
            }
        }
    }).catch( er => {
        this[tipe + '_show'] = [].concat(this[tipe + '_base']);
        this[tipe + '_search'] = [].concat(this[tipe + '_base']);
    });
  }

  search_back(tipe) {
      if (this.sb) {
        this.sb = false;
        let url: any;
        if (tipe=='pedi') {
          url = this.listUrl.url('get_pedidos');
        } else if (tipe == 'fact') {
          url = this.listUrl.url('get_facturas');
        } else {
          return false;
        }
        const initB: Init = {
          method: 'put',
          url,
          auth: this.auth,
          body: {
            id_opening: this.station.id_opening,
            number: this[tipe + '_in_search']
          }
        };
        const ajax = new Ajax(initB);
        ajax.call().then(resp => {
            if (resp.success) {
                if (typeof resp[this.resp_field] != 'undefined') {
                    this[tipe + '_search'] = [].concat(resp[this.resp_field]);
                    this[tipe + '_show'] = [].concat(this[tipe + '_search']);
                }
            }
            this.sb = true;
        });
      }
  }

}
