import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClienteRoutingModule } from './cliente-routing.module';
import { PuntodeventaModule } from './puntodeventa/puntodeventa.module';
import { PuntodeventaComponent } from './puntodeventa/puntodeventa.component';
import { AdministrativoModule } from './administrativo/administrativo.module' ;
import { AdministrativoComponent } from './administrativo/administrativo.component';

@NgModule({
  declarations: [PuntodeventaComponent, AdministrativoComponent],
  imports: [
    CommonModule,
    PuntodeventaModule,
    ClienteRoutingModule,
    AdministrativoModule,
  ],
})
export class ClienteModule { }
