import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Ajax, Config } from 'src/app/core/ajax/ajax';
import { Init } from 'src/app/core/ajax/init-ajax';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UrListService } from 'src/app/core/ajax/ur-list.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CatalogotwoService } from '../services/catalogotwo.service';
import { ModalLocationComponent, ModalGroupComponent, ModalGroupCustomerComponent } from '../modals-one/modals-one.component';
import { CatalogoOneService } from '../services/catalogo-one.service';
import { CatalogoThreeService } from '../services/catalogo-three.service';
import { Subscription, combineLatest } from 'rxjs';
import { InventaryProductService } from '../services/inventary-product.service';
import { SellersService } from '../services/sellers.service';
import { ResolvingService } from 'src/app/core/conflicts-deleting/resolving.service';

@Component({
  selector: 'app-modals-double',
  inputs: [
    'titleModal', 'labelOne', 'titleAddOne', 'descOneInput', 'labelTwo', 
    'codInputuTwo', 'descTwoInput', 'typeSelectOne', 'modalType', 'lbldesc', 
    'labelDesc', 'lbldouble', 'titleSave', 'maxCode', 'maxDesc'
  ],
  templateUrl: './modals-double.component.html',
  styleUrls: ['./modals-double.component.css']
})
export class ModalsDoubleComponent implements OnInit {
  titleModal: string;
  labelOne: string;
  titleAddOne: string;
  descOneInput: string;
  labelTwo: string;
  codInputuTwo: string;
  descTwoInput: string;
  typeSelectOne: string;
  descOne: string | null;
  formTwo: FormGroup;
  pkOneMD: any;
  idOneMD: number | null;
  typeMD: string | null;
  modalType: string | null;
  codeTwoMD: string | null;
  descTwoMD: string | null;
  error_idprinc: boolean = false;
  txt_idprinc: string | null;
  error_idsecun: boolean = false;
  txt_idsecun: string | null;
  error_codesecun: boolean = false;
  txt_codesecun: string | null;
  error_descsecun: boolean = false;
  txt_descsecun: string | null;
  lbldesc: number;
  labelDesc: string;
  lbldouble: number;
  titleSave: string;
  maxCode: number;
  maxDesc: number;
  events: Subscription[] = [];
  codeId: string;

  constructor(
    public auth: AuthService, 
    private modalRef: BsModalRef, 
    public CatalogotwoService: CatalogotwoService, 
    public CatalogoThreeService: CatalogoThreeService, 
    public CatalogoOneService: CatalogoOneService, 
    public ProductService: InventaryProductService, 
    public SellersService: SellersService, 
    public ResolvingService: ResolvingService,
    private modalService: BsModalService, 
    private changeDetection: ChangeDetectorRef,
    private listUrl: UrListService
  ){
    this.formTwo = new FormGroup({
      mdcodeone: new FormControl(this.CatalogotwoService.selectOneMD,[
        Validators.required
      ]),
      mddescone: new FormControl(this.CatalogotwoService.descOneMD,[
        Validators.required
      ]),
      mdcodetwo: new FormControl(this.codeTwoMD,[
        Validators.required,
        Validators.maxLength(this.maxCode)
      ]),
      mddesctwo: new FormControl(this.descTwoMD,[
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ])
    });
  }

  getId() {
    return this.codeId;
  }

  ngOnInit(){
    this.loadObjSelect();
    if (this.CatalogotwoService.newMD) {
      this.codeId = this.CatalogotwoService.addCode;
      if (this.ProductService.selectGroup != null) {
        if (this.ProductService.modelo === 'Producto') {
          this.CatalogotwoService.OneSelect = this.ProductService.GroupSelect;
          for (const key in this.CatalogotwoService.OneSelect){
            if (this.CatalogotwoService.OneSelect.hasOwnProperty(key)) {
              const element = this.CatalogotwoService.OneSelect[key];
              if (this.ProductService.selectGroup == element.id) {
                this.CatalogotwoService.descOneMD = element.id;
                this.CatalogotwoService.selectID = true;
                this.CatalogotwoService.selectOneMD = element.id;
              }
            }
          }
        }        
      }else if (this.CatalogotwoService.selectOneMD && this.CatalogotwoService.typeModal != 'catalogo') {
        for (const key in this.CatalogotwoService.OneSelect){
          if(this.CatalogotwoService.OneSelect.hasOwnProperty(key)) {
            const element = this.CatalogotwoService.OneSelect[key];
            if (this.CatalogotwoService.selectOneMD == element.id) {
              this.CatalogotwoService.selectOneMD = element.id;
              this.CatalogotwoService.descOneMD = element.id;
              this.CatalogotwoService.selectID = true;
              break;
            }
          }
        }
      } else {
        this.CatalogotwoService.selectOneMD = null;
        this.CatalogotwoService.descOneMD = null;
      }
    } else {
      this.codeId = this.CatalogotwoService.editCode;
      this.CatalogotwoService.selectOneMD = this.CatalogotwoService.object.id_princ;
      this.CatalogotwoService.descOneMD = this.CatalogotwoService.object.id_princ;
      this.codeTwoMD = this.CatalogotwoService.object.code_secun;
      this.descTwoMD = this.CatalogotwoService.object.desc_secun;
      this.CatalogotwoService.selectID = true;
    }
  }

  loadObjSelect(){
    const initAjax: Init = {
      method: 'put',
      auth: this.auth,
      url: this.CatalogotwoService.urlSelect
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.CatalogotwoService.OneSelect = resp.objects;
      }
    });
  }

  changeCodeOne(){
    this.error_idprinc = false;
    this.error_idsecun = false;
    this.txt_idprinc = '';
    this.txt_idsecun = '';
    if (this.CatalogotwoService.selectOneMD) {
      for (const key in this.CatalogotwoService.OneSelect) {
        if (Object.prototype.hasOwnProperty.call(this.CatalogotwoService.OneSelect, key)) {
          const element = this.CatalogotwoService.OneSelect[key];
          if (this.CatalogotwoService.selectOneMD == element.id) {
            this.CatalogotwoService.descOneMD = element.id;
            break;
          }
        }
      }
    } else {
      this.CatalogotwoService.descOneMD = null;
    }
  }

  changeDescOne(){
    this.error_idprinc = false;
    this.error_idsecun = false;
    this.txt_idprinc = '';
    this.txt_idsecun = '';
    if (this.CatalogotwoService.descOneMD) {
      for (const key in this.CatalogotwoService.OneSelect) {
        if (Object.prototype.hasOwnProperty.call(this.CatalogotwoService.OneSelect, key)) {
          const element = this.CatalogotwoService.OneSelect[key];
          if (this.CatalogotwoService.descOneMD == element.id) {
            this.CatalogotwoService.selectOneMD = element.id;
            break;
          }
        }
      }
    } else {
      this.CatalogotwoService.selectOneMD = null;
    }
  }

  closeModal(){
    this.CatalogotwoService.closeModal.hide();
  }

  saveObject(){
    let id_obj: any;
    if(this.CatalogotwoService.newMD){
      id_obj = '';
    }else{
      id_obj = this.CatalogotwoService.pkSecun;
    }
    if(this.codeTwoMD.toUpperCase().trim() && this.descTwoMD.toUpperCase().trim()){
      const initAjax: Init = {
        method: 'put',
        url: this.CatalogotwoService.urlSave,
        auth: this.auth,
        body: {
          id_obj: id_obj,
          id_princ: this.CatalogotwoService.selectOneMD,
          code: this.codeTwoMD.toUpperCase().trim(),
          desc: this.descTwoMD.toUpperCase().trim(),
          tipo: this.CatalogotwoService.typeModal
        }
      };
      const configAjax: Config = {
        numInten : 1,
        titleLoading: `Guardando ${this.titleSave}`,
        titleSuccess: '',
        titleErrorNormal: 'Error',
        textErrorNormal: 'Ha ocurrido un Error.',
        confirmButtonTextError: 'Cerrar',
        validatejson: (resp: any, obj: Ajax) => {
          return new Promise((success, error) => {
            if (resp.success === false) {
              obj.declareConfig({
                alertaError : Swal.mixin({
                  icon: 'error',
                  title: 'Ocurrio un error',
                  text: resp.message,
                  showConfirmButton: false,
                  confirmButtonText: 'Cerrar',
                })
              });
              error(resp);
            } else {
              success(resp);
            }
          });
        }
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        if (resp.success) {
          if (resp.new) {
            if (resp.tipo == 'catalogo') {
              this.CatalogotwoService.continuar = true;
              this.CatalogotwoService.evenAdd.emit(resp);
            } else {
              if (this.CatalogotwoService.modeloBase === 'Tercero') {
                this.modalTerce(resp);
              } else if (this.CatalogotwoService.modeloBase === 'Producto') {
                this.modalProduct(resp);
              } else if (this.CatalogotwoService.modeloBase === 'Seller') {
                this.modalSeller(resp);
              } else if (this.CatalogotwoService.modeloBase === 'Conflicto') {
                this.ResolvingService.seguir = true;
                this.ResolvingService.loadselect = true;
                this.ResolvingService.evenAdd.emit({'new':resp.objeto});
              }
            }
          }else{
            this.CatalogotwoService.continuar = true;
            this.CatalogotwoService.evenAdd.emit(resp);
          }
          this.CatalogotwoService.closeModal.hide();
        }
      }).catch(error => {
        this.viewError(error);
      });
    }else{
      this.codeTwoMD = '';
      this.descTwoMD = '';
    }
  }

  modalSeller(resp) {
    this.SellersService.DepositSelect = resp.objeto;
    for (const key in this.SellersService.DepositSelect) {
      if (Object.prototype.hasOwnProperty.call(this.SellersService.DepositSelect, key)) {
        const deposit = this.SellersService.DepositSelect[key];
        if (deposit.id == resp.pk) {
          this.SellersService.CodeDepo = resp.pk;
          this.SellersService.DescDepo = deposit.desc;
          break;
        }
      }
    }
  }

  modalProduct(resp){
    this.loadKindProduct(resp.pk_two);
    this.ProductService.selectIdGroup = true;
    this.ProductService.selectedGroup = true;
    this.ProductService.selectIdSubgroup = true;
    this.ProductService.selectGroup = resp.pk_one;
    this.ProductService.descGroup = resp.desc_one;
    this.ProductService.GroupSelect = resp.obj_one;
    this.ProductService.selectSubgroup = resp.pk_two;
    this.ProductService.descSubgroup = resp.pk_two;
    this.ProductService.SubgroupSelect = resp.objeto;
    this.ProductService.descKind = null;
  }

  loadKindProduct(subgrupo){
    const initAjax: Init = {
      auth: this.auth,
      method: 'put',
      body: {
        pk: subgrupo
      },
      url: this.listUrl.url('list_modal_kind')
    };
    const configAjax: Config = {
      visible: false,
      autoReNew: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp=>{
      if (resp.success) {
        this.ProductService.selectIdKind = false;
        this.ProductService.KindSelect = resp.objects;
        this.ProductService.selectedSubGroup = true;
      }
    });
  }

  modalTerce(resp){
    this.CatalogoThreeService.selectID = true;
    this.CatalogoThreeService.OneSelect = resp.obj_one;
    this.CatalogoThreeService.selectOneMT = resp.pk_one;
    this.CatalogoThreeService.descOneMT = resp.desc_one;
    this.CatalogoThreeService.selectedOne = true;
    this.CatalogoThreeService.selectIDTwo = true;
    this.CatalogoThreeService.selectTwoMT = resp.pk_two;
    this.CatalogoThreeService.TwoSelect = resp.objeto;
    this.CatalogoThreeService.descTwoMT = resp.desc_two;
    if(this.ProductService.modelo === 'Producto'){
      this.modalProduct(resp);
    }
  }

  formatCodeSecun(){
    this.codeTwoMD = this.codeTwoMD.toUpperCase().trim();
    this.error_idsecun = false;
    this.txt_idsecun = '';
    this.error_codesecun = false;
    this.txt_codesecun = '';
    if (this.codeTwoMD) {
      while (this.codeTwoMD.length < this.maxCode) {
        this.codeTwoMD = '0'+this.codeTwoMD;
      } 
    }
  }

  formatDescSecun(){
    this.descTwoMD = this.descTwoMD.toUpperCase();
    this.error_idsecun = false;
    this.txt_idsecun = '';
    this.error_descsecun = false;
    this.txt_descsecun = '';
  }

  viewError(error){
    if(error.error_idprinc != 'undefined'){
      if(error.error_idprinc){
        this.error_idprinc = error.error_idprinc;
        this.txt_idprinc = error.txt_idprinc;
      }
    }
    if(error.error_idsecun != 'undefined'){
      if(error.error_idsecun){
        this.error_idsecun = error.error_idsecun;
        this.txt_idsecun = error.txt_idsecun;
      }
    }
    if(error.error_codesecun != 'undefined'){
      if(error.error_codesecun){
        this.error_codesecun = error.error_codesecun;
        this.txt_codesecun = error.txt_codesecun;
      }
    }
    if(error.error_descsecun != 'undefined'){
      if(error.error_descsecun){
        this.error_descsecun = error.error_descsecun;
        this.txt_descsecun = error.txt_descsecun;
      }
    }
  }

  unsubscribe() {
    this.events.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.events = [];
  }

  openModalPrinct(tipo, model){
    this.CatalogotwoService.typeModal = tipo;
    this.CatalogotwoService.modelo = model;

    const _combine = combineLatest(
      this.modalService.onHide
    ).subscribe(() => this.changeDetection.markForCheck());

    this.events.push(
      this.modalService.onHide.subscribe(() => {
        this.CatalogotwoService.typeModal = this.CatalogotwoService.typeModalBase;
        this.unsubscribe();
      })
    );
    
    this.events.push(_combine);

    const initialState = {
      componentParent: this
    };

    this.modalRef = this.modalService.show(this.CatalogotwoService.princModal, {initialState});
    this.modalRef.setClass('modal-dialog-centered zindex-one');
    this.CatalogoOneService.closeModal = this.modalRef;
  }
}

@Component({
  selector: 'app-modal-deposit',
  template: `
    <app-modals-double
      titleModal="Agregar Deposito"
      labelOne="Ubicación:"
      titleAddOne="Crear Ubicación"
      descOneInput="DESCRIPCION UBICACION"
      labelTwo="Codigo:"
      codInputuTwo="CODIGO DEPOSITO"
      descTwoInput="DESCRIPCION DEPOSITO"
      typeSelectOne="deposito"
      modalType="catalogo"
      lbldesc=1
      labelDesc="Descripcion:"
      lbldouble=0
      titleSave="Deposito"
      maxCode=10
      maxDesc=60
      >
    </app-modals-double>
  `
})
export class ModalDepositComponent implements OnInit{
  constructor(
    public CatalogotwoService: CatalogotwoService, 
    public CatalogoThreeService: CatalogoThreeService, 
    public ProductService: InventaryProductService, 
    public SellersService: SellersService, 
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ){}

  ngOnInit(){
    this.CatalogotwoService.urlSelect = this.listUrl.url('list_modal_location');
    this.CatalogotwoService.urlSave = this.listUrl.url('savedeposit');
    this.CatalogotwoService.princModal = ModalLocationComponent;
    if (typeof this.CatalogoThreeService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogotwoService.modelo = this.CatalogoThreeService.modelo;
    } else if(typeof this.ProductService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.ProductService.typeModal;
      this.CatalogotwoService.modelo = this.ProductService.modelo;
    } else if(typeof this.SellersService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.SellersService.typeModal;
      this.CatalogotwoService.modelo = this.SellersService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.ResolvingService.typeModal;
      this.CatalogotwoService.modelo = this.ResolvingService.modelo;
    } else {
      this.CatalogotwoService.typeModal = 'catalogo';
    }
    this.CatalogotwoService.typeModalBase = this.CatalogotwoService.typeModal;
    this.CatalogotwoService.modeloBase = this.CatalogotwoService.modelo;
  }
}

@Component({
  selector: 'app-modal-subgroup',
  template: `
    <app-modals-double
      titleModal="Agregar Subgrupo"
      labelOne="Grupo:"
      titleAddOne="Crear Grupo"
      descOneInput="DESCRIPCION GRUPO"
      labelTwo="Subgrupo:"
      codInputuTwo="CODIGO SUBGRUPO"
      descTwoInput="DESCRIPCION SUBGRUPO"
      typeSelectOne="subgrupo"
      modalType="catalogo"
      lbldouble=1
      lbldesc=0
      titleSave="Subgrupo"
      maxCode=10
      maxDesc=80
      >
    </app-modals-double>
  `
})
export class ModalSubgroupComponent implements OnInit{
  constructor(
    public CatalogotwoService: CatalogotwoService, 
    public CatalogoThreeService: CatalogoThreeService, 
    public ProductService: InventaryProductService, 
    public SellersService: SellersService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ){}

  ngOnInit(){
    this.CatalogotwoService.urlSelect = this.listUrl.url('list_modal_group');
    this.CatalogotwoService.urlSave = this.listUrl.url('savesubgroup');
    this.CatalogotwoService.princModal = ModalGroupComponent;
    if (typeof this.CatalogoThreeService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogotwoService.modelo = this.CatalogoThreeService.modelo;
    } else if (typeof this.ProductService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.ProductService.typeModal;
      this.CatalogotwoService.modelo = this.ProductService.modelo;
    } else if(typeof this.SellersService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.SellersService.typeModal;
      this.CatalogotwoService.modelo = this.SellersService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.ResolvingService.typeModal;
      this.CatalogotwoService.modelo = this.ResolvingService.modelo;
    } else {
      this.CatalogotwoService.typeModal = 'catalogo';
    }
    this.CatalogotwoService.typeModalBase = this.CatalogotwoService.typeModal;
    this.CatalogotwoService.modeloBase = this.CatalogotwoService.modelo;
  }
}

@Component({
  selector: 'app-modal-subgroup',
  template: `
    <app-modals-double
      titleModal="Agregar Subgrupo"
      labelOne="Grupo:"
      titleAddOne="Crear Grupo"
      descOneInput="DESCRIPCION GRUPO"
      labelTwo="Subgrupo:"
      codInputuTwo="CODIGO SUBGRUPO"
      descTwoInput="DESCRIPCION SUBGRUPO"
      typeSelectOne="subgrupo"
      modalType="catalogo"
      lbldouble=1
      lbldesc=0
      titleSave="Subgrupo"
      maxCode=10
      maxDesc=80
      >
    </app-modals-double>
  `
})
export class ModalSubgroupCustomerComponent implements OnInit{
  constructor(
    public CatalogotwoService: CatalogotwoService, 
    public CatalogoThreeService: CatalogoThreeService, 
    public ProductService: InventaryProductService, 
    public SellersService: SellersService,
    public ResolvingService: ResolvingService,
    private listUrl: UrListService
  ){}

  ngOnInit(){
    this.CatalogotwoService.urlSelect = this.listUrl.url('list_modal_group_customer');
    this.CatalogotwoService.urlSave = this.listUrl.url('savesubgroup_customer');
    this.CatalogotwoService.princModal = ModalGroupCustomerComponent;
    if (typeof this.CatalogoThreeService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.CatalogoThreeService.typeModal;
      this.CatalogotwoService.modelo = this.CatalogoThreeService.modelo;
    } else if (typeof this.ProductService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.ProductService.typeModal;
      this.CatalogotwoService.modelo = this.ProductService.modelo;
    } else if (typeof this.SellersService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.SellersService.typeModal;
      this.CatalogotwoService.modelo = this.SellersService.modelo;
    } else if (typeof this.ResolvingService.typeModal != 'undefined') {
      this.CatalogotwoService.typeModal = this.ResolvingService.typeModal;
      this.CatalogotwoService.modelo = this.ResolvingService.modelo;
    } else {
      this.CatalogotwoService.typeModal = 'catalogo';
    }
    this.CatalogotwoService.typeModalBase = this.CatalogotwoService.typeModal;
    this.CatalogotwoService.modeloBase = this.CatalogotwoService.modelo;
  }
}
