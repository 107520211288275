import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule } from 'ngx-socket-io';
import { AuthService } from './core/auth/auth.service';
import { UrListService } from './core/ajax/ur-list.service';
import { CarshopService } from './core/carshop/carshop.service';
import { AuthGuardService } from './core/canActivate/auth-guard-login';
import { AuthAnonymoGuard } from './core/canActivate/auth-anonymo.guard';
import { SelectCompanyGuard } from './core/canActivate/select-company.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './core/signin/signin.component';
import { MenuComponent } from './core/menu/menu.component';
import { ClienteComponent } from './cliente/cliente.component';
import { SelectCompanyComponent } from './core/select-company/select-company.component';
import { HomeComponent } from './core/home/home.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { SignupComponent } from './servicios/signup/signup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CarshopModalComponent } from './core/carshop/carshop-modal/carshop-modal.component';
import { InvitationModalComponent } from './cliente/administrativo/invitation-modal/invitation-modal.component';
import {
  ModalsDoubleComponent,
  ModalSubgroupComponent,
  ModalDepositComponent,
  ModalSubgroupCustomerComponent } from './cliente/administrativo/modals-double/modals-double.component';
import {
  ModalsOneComponent,
  ModalGroupComponent,
  ModalLocationComponent,
  ModalPresentationComponent,
  ModalShopComponent,
  ModalTypeProductComponent,
  ModalGroupCustomerComponent,
  ModalTypeCostumerComponent,
  ModalCurrencyComponent,
  ModalSalesForceComponent ,
  ModalTaxComponent,
  ModalVehicleCustomerComponent  } from './cliente/administrativo/modals-one/modals-one.component';
import { ModalsTripleComponent, ModalsLineComponent } from './cliente/administrativo/modals-triple/modals-triple.component';
import { ModalProveedorComponent } from './cliente/administrativo/modal-proveedor/modal-proveedor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProveedorComponent } from './cliente/administrativo/proveedor/proveedor.component';
import { ModalExpiraProductComponent } from './cliente/administrativo/modals/entries-exits/modal-expira-product/modal-expira-product.component';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatSelectModule } from '@angular/material';
import { ModalPriceChargeProductsComponent } from './cliente/administrativo/modals/entries-exits/modal-price-charge-products/modal-price-charge-products.component';
import { ChatClientComponent } from './core/chat-client/chat-client.component';
import { ViewPermGuard } from './core/canActivate/view-perm.guard';
import { ModalSupervisorComponent } from './cliente/administrativo/modal-supervisor/modal-supervisor.component';
import { ViewProductExpiraComponent } from './cliente/administrativo/modals/entries-exits/view-product-expira/view-product-expira.component';
import { ViewDetailProductComponent } from './cliente/administrativo/modals/entries-exits/view-detail-product/view-detail-product.component';
import { LoadProductComponent } from './cliente/administrativo/modals/entries-exits/load-product/load-product.component';
import { OtherExpensesComponent } from './cliente/administrativo/modals/entries-exits/other-expenses/other-expenses.component';
import { OtherTaxesComponent } from './cliente/administrativo/modals/entries-exits/other-taxes/other-taxes.component';
import { ReturnableComponent } from './cliente/administrativo/modals/entries-exits/returnable/returnable.component';
import { ChangeReturnableComponent } from './cliente/administrativo/modals/entries-exits/change-returnable/change-returnable.component';
import { ModalProductoComponent } from './cliente/administrativo/modal-producto/modal-producto.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PasswordResetComponent } from './servicios/password-reset/password-reset.component';
import { ClienteModule } from './cliente/cliente.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SelectCompanyModalComponent } from './core/select-company-modal/select-company-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalStationComponent } from './cliente/administrativo/modal-station/modal-station/modal-station.component';
import { ExpirationsComponent } from './cliente/administrativo/modals/products/expirations/expirations.component';
import { StatisticsComponent } from './cliente/administrativo/modals/products/statistics/statistics.component';
import { MenuMovilModalComponent } from './core/menu-movil-modal/menu-movil-modal.component';
import { LoadSellerComponent } from './cliente/administrativo/modals/load-seller/load-seller.component';
import { LoadSellerCustomerComponent } from './cliente/administrativo/modals/load-seller-customer/load-seller-customer.component';
import { ModalSetprinterComponent } from './cliente/administrativo/modal-setprinter/modal-setprinter.component';
import { CierreCajaComponent } from './cliente/puntodeventa/cierre-caja/cierre-caja.component';
import { BlockComponent } from './core/block/block.component';
import { BlockGuard } from './core/canActivate/block.guard';
import { ModalEspecieComponent } from './cliente/administrativo/modal-especie/modal-especie.component';
import { ConflictsDeletingComponent } from './core/conflicts-deleting/conflicts-deleting.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalPaypalComponent } from './core/payment-gateways/payment-methods/paypal/modal-paypal/modal-paypal.component';
import { ModalPayuComponent } from './core/payment-gateways/payment-methods/payu/modal-payu/modal-payu.component';
import { ModalStripeComponent } from './core/payment-gateways/payment-methods/stripe/modal-stripe/modal-stripe.component';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalVehicleComponent } from './cliente/administrativo/modals/modal-vehicle/modal-vehicle.component';
import { AdministrativoModule } from './cliente/administrativo/administrativo.module';
import { ExistenceComponent } from './cliente/administrativo/modals/products/existence/existence.component';
import { ModalAsistenteComponent } from './cliente/administrativo/modals/fuerzadeventa/modal-asistente/modal-asistente.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    MenuComponent,
    ClienteComponent,
    SelectCompanyComponent,
    HomeComponent,
    InvitationModalComponent,
    ChatClientComponent,
    ModalsDoubleComponent,
    ModalSubgroupComponent,
    ModalSubgroupCustomerComponent,
    ModalsOneComponent,
    ModalGroupComponent,
    ModalLocationComponent,
    ModalPresentationComponent,
    ModalShopComponent,
    ModalTypeProductComponent,
    ModalGroupCustomerComponent,
    ModalTypeCostumerComponent,
    ModalCurrencyComponent,
    ModalDepositComponent,
    ModalsTripleComponent,
    ModalsLineComponent,
    ModalProveedorComponent,
    ProveedorComponent,
    ModalExpiraProductComponent,
    ModalPriceChargeProductsComponent,
    ServiciosComponent,
    SignupComponent,
    CarshopModalComponent,
    ModalSalesForceComponent,
    ModalSupervisorComponent,
    ModalTaxComponent,
    ViewProductExpiraComponent,
    ViewDetailProductComponent,
    LoadProductComponent,
    OtherExpensesComponent,
    OtherTaxesComponent,
    ModalProductoComponent,
    ReturnableComponent,
    ChangeReturnableComponent,
    PasswordResetComponent,
    SelectCompanyModalComponent,
    ModalStationComponent,
    ExpirationsComponent,
    StatisticsComponent,
    MenuMovilModalComponent,
    LoadSellerComponent,
    LoadSellerCustomerComponent,
    ModalSetprinterComponent,
    CierreCajaComponent,
    BlockComponent,
    ModalEspecieComponent,
    ConflictsDeletingComponent,
    ModalPaypalComponent,
    ModalPayuComponent,
    ModalStripeComponent,
    ModalVehicleCustomerComponent,
    ModalVehicleComponent,
    ExistenceComponent,
    ModalAsistenteComponent,
  ],
  imports: [
    BrowserModule,
    ClienteModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    SocketIoModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelectModule,
    PopoverModule.forRoot(),
    AdministrativoModule
  ],
  entryComponents: [
    InvitationModalComponent, ModalsDoubleComponent, ModalSubgroupComponent, ModalSubgroupCustomerComponent, ModalsOneComponent, ModalGroupComponent,
    ModalLocationComponent, ModalPresentationComponent, ModalShopComponent, ModalTypeProductComponent, ModalGroupCustomerComponent, ModalTypeCostumerComponent, ModalCurrencyComponent,
    ModalDepositComponent, ModalsTripleComponent, ModalsLineComponent, ModalProveedorComponent, ProveedorComponent,
    ModalExpiraProductComponent, ModalPriceChargeProductsComponent, ChatClientComponent, CarshopModalComponent,
    ModalSalesForceComponent, ModalSupervisorComponent, ModalTaxComponent, ViewProductExpiraComponent, ViewDetailProductComponent, 
    LoadProductComponent, OtherExpensesComponent, OtherTaxesComponent, ModalProductoComponent, ReturnableComponent,
    ChangeReturnableComponent, SelectCompanyModalComponent, ModalStationComponent, ExpirationsComponent, StatisticsComponent, 
    MenuMovilModalComponent, LoadSellerComponent, LoadSellerCustomerComponent, ModalSetprinterComponent, CierreCajaComponent,
    ModalEspecieComponent, ConflictsDeletingComponent,ModalVehicleCustomerComponent, ExistenceComponent,ModalAsistenteComponent
  ],
  providers: [
    UrListService,
    AuthService,
    AuthGuardService,
    AuthAnonymoGuard,
    SelectCompanyGuard,
    CarshopService,
    ViewPermGuard,
    BlockGuard,
    ModalAsistenteComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
